import React, { createRef } from 'react';
import DataService from '../data/DataService';
import { FormCovenantHealthRequestResource } from '../dto/FormCovenantHealthRequestResource';
import { IFormMethodOutput } from '../interfaces/IFormMethodOutput';
import { IFormConfiguration } from '../interfaces/IFormConfiguration';
import { IFormState } from '../interfaces/IFormState';
import { IFormValidation } from '../interfaces/IFormValidation';
import Navigation from '../layouts/Navigation';
import { Checkbox, defaultCalendarStrings, IChoiceGroupOption, ISelectableOption, Label, PrimaryButton, Spinner, SpinnerSize, Stack, TextField, IChoiceGroupOptionStyles, IRefObject, DefaultButton, DialogFooter, Dialog, DialogType, ContextualMenu, TooltipHost, Icon, IChoiceGroupOptionProps, ICheckboxStyleProps, ICheckboxStyles } from '@fluentui/react';
import Upload from '../components/Upload';
import ChoiceGroupWithValidation from '../components/ChoiceGroupWithValidation';
import Utils from '../utils/Utils';
import Auth from '../Auth';
import { RouteComponentProps } from 'react-router-dom';
import FormLogo from '../assets/CovenantLogo.png';
import AcuteCareBedsIcon from '../assets/acuteCareBedsIcon.png';
import ContinuingCareBedsIcon from '../assets/continuingCareBedsIcon.png';
import IndependentLivingIcon from '../assets/independentLivingIcon.png';
import { ISelectableOptionWithTooltip } from '../interfaces/ISelectableOptionWithTooltip';
import dateFormat from 'dateformat';
import DatePickerWithValidation from '../components/DatePickerWithValidation';
import { TEXT_DOUBLE_COLUMN, TEXT_SINGLE_COLUMN, FILE_SIZE_LIMIT, AUTO_SAVE_INTERVAL } from '../constants/Constants';

export interface IStudyLocationParticipating {
    groupName: string;
    description: string;
    value: string;
    acronym: string;
    acuteCareBed: boolean;
    continuingCareBed: boolean;
    independentLiving: boolean;
}

export interface IStudyLocationDepartment {
    groupName: string;
    description: string;
    value: string;
    acronym: string;
}

export interface IFormCovenantHealthRequestProps extends RouteComponentProps {
}

export interface IFormCovenantHealthRequestState extends IFormState {
    acutecareBedsFilter: boolean;
    continuingCareBedsFilter: boolean;
    independentLivingFilter: boolean;
    resizeStudyLocations: boolean;
}

export default class FormCovenantHealthRequest extends React.Component<IFormCovenantHealthRequestProps, IFormCovenantHealthRequestState> {
    applicationName: string = 'CovenantHealthRequest';
    fileSizeLimit: number = FILE_SIZE_LIMIT;
    dataService: DataService = new DataService();
    utils: Utils = new Utils();
    pathApplicationId = (window.location.pathname.split('/').pop() as string);
    saveIntervalId: number;

    // create object array for study locations
    studyLocationsParticipating: IStudyLocationParticipating[] = this._setStudyLocationsParticipating();
    studyLocationsDepartments: IStudyLocationDepartment[] = this._setStudyLocationsDepartments();

    // create and set choices fields (checkbox and dropdown (ISelectableOptionWithTooltip[]), radio (IChoiceGroupOption[]))
    studyReviewedByChoices: IChoiceGroupOption[] = this._setStudyReviewedByChoices();
    studyLocationsOtherChoices: IChoiceGroupOption[] = this._setStudyLocationsOtherChoices();
    studyDatabasesChoices: ISelectableOptionWithTooltip[] = this._setStudyDatabasesChoices();
    projectInvolveChartReviewChoices: IChoiceGroupOption[] = this._setProjectInvolveChartReviewChoices();
    databasePurposeColumnAChoices: IChoiceGroupOption[] = this._setDatabasePurposeColumnAChoices();
    databasePurposeColumnBChoices: IChoiceGroupOption[] = this._setDatabasePurposeColumnBChoices();
    researchContextChoices: IChoiceGroupOption[] = this._setResearchContextChoices();
    researchFocusPerspectiveChoices: IChoiceGroupOption[] = this._setResearchFocusPerspectiveChoices();
    serviceAreaChoices: IChoiceGroupOption[] = this._setServiceAreaChoices();
    therapeuticDepartmentChoices: IChoiceGroupOption[] = this._setTherapeuticDepartmentChoices();
    therapeuticDepartmentSectorChoices: IChoiceGroupOption[] = this._setTherapeuticDepartmentSectorChoices();
    databasePurposeDisciplinesChoices: ISelectableOptionWithTooltip[] = this._setDatabasePurposeDisciplinesChoices();
    strategicObjectivesServeChoices: ISelectableOptionWithTooltip[] = this._setStrategicObjectivesServeChoices();
    strategicObjectivesTransfChoices: ISelectableOptionWithTooltip[] = this._setStrategicObjectivesTransfChoices();
    strategicObjectivesContribChoices: ISelectableOptionWithTooltip[] = this._setStrategicObjectivesContribChoices();
    PICovenantHealthPrivilegesChoices: IChoiceGroupOption[] = this._setPICovenantHealthPrivilegesChoices();
    attachedDocumentsInfoChoices: ISelectableOptionWithTooltip[] = this._setAttachedDocumentsInfoChoices();

    // create a ref for each field that can be focused. For example: On a validation error
    studyTitleRef: IRefObject<any> = createRef();
    studyReviewedByRef: IRefObject<any> = createRef();
    PIEmailRef: IRefObject<any> = createRef();
    researchCoordinatorEmailRef: IRefObject<any> = createRef();
    studyEthicsBoardNumberRef: IRefObject<any> = createRef();
    PINameRef: IRefObject<any> = createRef();
    PIPhoneNumberRef: IRefObject<any> = createRef();
    PIUniversityAffiliationRef: IRefObject<any> = createRef();
    PICovenantHealthPrivilegesRef: IRefObject<any> = createRef();
    researchCoordinatorNameRef: IRefObject<any> = createRef();
    researchCoordinatorPhoneNumberRef: IRefObject<any> = createRef();
    studyLocationsParticipatingRef: IRefObject<any> = createRef();
    studyLocationsDepartmentsRef: IRefObject<any> = createRef();
    studyLocationsOtherRef: IRefObject<any> = createRef();
    studyLocationsOtherDescriptionRef: IRefObject<any> = createRef();
    studyLocationsDepartmentsOtherRef: IRefObject<any> = createRef();
    projectStartDateRef: IRefObject<any> = createRef();
    projectCompletionDateRef: IRefObject<any> = createRef();
    studyDatabasesRef: IRefObject<any> = createRef();
    studyDatabasesOtherRef: IRefObject<any> = createRef();
    projectInvolveChartReviewRef: IRefObject<any> = createRef();
    projectChartReviewerRef: IRefObject<any> = createRef();
    projectChartNumberReviewsRef: IRefObject<any> = createRef();
    projectChartDiagnosesProceduresRef: IRefObject<any> = createRef();
    projectChartTimePeriodRef: IRefObject<any> = createRef();
    databasePurposeColumnARef: IRefObject<any> = createRef();
    databasePurposeColumnAOtherRef: IRefObject<any> = createRef();
    databasePurposeColumnBRef: IRefObject<any> = createRef();
    researchContextRef: IRefObject<any> = createRef();
    researchFocusPerspectiveRef: IRefObject<any> = createRef();
    serviceAreaRef: IRefObject<any> = createRef();
    therapeuticDepartmentRef: IRefObject<any> = createRef();
    therapeuticDepartmentSectorRef: IRefObject<any> = createRef();
    strategicObjectivesRef: IRefObject<any> = createRef();
    formAgreementRef: IRefObject<any> = createRef();
    uploadRef: React.RefObject<Upload> = createRef<Upload>();

    constructor(props: IFormCovenantHealthRequestProps) {
        super(props);
        const formConfiguration: IFormConfiguration[] = [];
        const formItem: FormCovenantHealthRequestResource = new FormCovenantHealthRequestResource();
        const formItemLastSaved: FormCovenantHealthRequestResource = new FormCovenantHealthRequestResource();
        const formValidation: IFormValidation[] = [];
        // add validation information for each field to be validated
        formValidation.push({ propertyName: 'studyTitle', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.studyTitleRef });
        formValidation.push({ propertyName: 'studyReviewedBy', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.studyReviewedByRef });
        formValidation.push({ propertyName: 'PIEmail', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.PIEmailRef });
        formValidation.push({ propertyName: 'researchCoordinatorEmail', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.researchCoordinatorEmailRef });
        formValidation.push({ propertyName: 'studyEthicsBoardNumber', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.studyEthicsBoardNumberRef });
        formValidation.push({ propertyName: 'PIName', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.PINameRef });
        formValidation.push({ propertyName: 'PIPhoneNumber', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.PIPhoneNumberRef });
        formValidation.push({ propertyName: 'PIUniversityAffiliation', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.PIUniversityAffiliationRef });
        formValidation.push({ propertyName: 'PICovenantHealthPrivileges', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.PICovenantHealthPrivilegesRef });
        formValidation.push({ propertyName: 'researchCoordinatorName', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.researchCoordinatorNameRef });
        formValidation.push({ propertyName: 'researchCoordinatorPhoneNumber', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.researchCoordinatorPhoneNumberRef });
        formValidation.push({ propertyName: 'studyLocationsParticipating', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.studyLocationsParticipatingRef });
        formValidation.push({ propertyName: 'studyLocationsDepartments', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.studyLocationsDepartmentsRef });
        formValidation.push({ propertyName: 'studyLocationsOther', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.studyLocationsOtherRef });
        formValidation.push({ propertyName: 'studyLocationsOtherDescription', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.studyLocationsOtherDescriptionRef });
        formValidation.push({ propertyName: 'studyLocationsDepartmentsOther', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.studyLocationsDepartmentsOtherRef });
        formValidation.push({ propertyName: 'projectStartDate', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.projectStartDateRef });
        formValidation.push({ propertyName: 'projectCompletionDate', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.projectCompletionDateRef });
        formValidation.push({ propertyName: 'studyDatabases', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.studyDatabasesRef });
        formValidation.push({ propertyName: 'studyDatabasesOther', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.studyDatabasesOtherRef });
        formValidation.push({ propertyName: 'projectInvolveChartReview', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.projectInvolveChartReviewRef });
        formValidation.push({ propertyName: 'projectChartReviewer', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.projectChartReviewerRef });
        formValidation.push({ propertyName: 'projectChartNumberReviews', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.projectChartNumberReviewsRef });
        formValidation.push({ propertyName: 'projectChartDiagnosesProcedures', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.projectChartDiagnosesProceduresRef });
        formValidation.push({ propertyName: 'projectChartTimePeriod', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.projectChartTimePeriodRef });
        formValidation.push({ propertyName: 'databasePurposeColumnA', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.databasePurposeColumnARef });
        formValidation.push({ propertyName: 'databasePurposeColumnAOther', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.databasePurposeColumnAOtherRef });
        formValidation.push({ propertyName: 'databasePurposeColumnB', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.databasePurposeColumnBRef });
        formValidation.push({ propertyName: 'researchContext', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.researchContextRef });
        formValidation.push({ propertyName: 'researchFocusPerspective', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.researchFocusPerspectiveRef });
        formValidation.push({ propertyName: 'serviceArea', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.serviceAreaRef });
        formValidation.push({ propertyName: 'therapeuticDepartment', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.therapeuticDepartmentRef });
        formValidation.push({ propertyName: 'therapeuticDepartmentSector', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.therapeuticDepartmentSectorRef });
        formValidation.push({ propertyName: 'strategicObjectives', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.strategicObjectivesRef });
        formValidation.push({ propertyName: 'formAgreement', isValid: true, validationType: 'custom', errorMessage: 'You must agree to the disclaimer and the terms of use to submit your application.', propertyRef: this.formAgreementRef });

        this.state = {
            formConfiguration: formConfiguration,
            formItem: formItem,
            formItemLastSaved: formItemLastSaved,
            formVisible: false,
            formValidation: formValidation,
            UserFirstName: '',
            UserLastName: '',
            UserEmail: '',
            UserPhoneNumber: '',
            clearDialogHidden: true,
            submitDialogHidden: true,
            amendDialogHidden: true,
            helpRequestDialogHidden: true,
            notificationDialogHidden: true,
            notificationDialogTitle: '',
            notificationDialogMessage: '',
            redirectToDashboard: false,
            formAgreement: true,
            acutecareBedsFilter: false,
            continuingCareBedsFilter: false,
            independentLivingFilter: false,
            resizeStudyLocations: false,
            showApplicantInstitutionOther: false,
            showRequesterInstitutionOther: false,
            isFormDisabled: false
        };
    };

    private _onAgreementChange = (propertyName: string, ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined): void => {
        this.setState({
            formAgreement: checked ? true : false,
        });
        this._clearErrorMessage(propertyName);
    }

    private _onTextFieldChange = (propertyName: string, event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        const formValidation = this.state.formValidation;
        const formValidationItem = this.utils.getFormValidationItem(formValidation, propertyName);
        (this.state.formItem as any)[propertyName] = newValue;
        this.setState({
            formItem: this.state.formItem,
        });
        if (formValidationItem && formValidationItem.charLimit) {
            formValidationItem.remainingChars = formValidationItem.charLimit - (newValue ? newValue.length : 0);
            this.setState({
                formValidation: formValidation,
            });
        }
        this._clearErrorMessage(propertyName);
    }

    private _onChoiceGroupChange = (propertyName: string, ev?: React.FormEvent<HTMLInputElement | HTMLElement>, option?: IChoiceGroupOption): void => {
        (this.state.formItem as any)[propertyName] = option?.key.toString();
        this.setState({
            formItem: this.state.formItem,
        });
        this._clearOtherFieldValues(propertyName);
        this._clearErrorMessage(propertyName);
    }

    private _onDatePickerChange = (propertyName: string, date: Date | null | undefined): void => {
        (this.state.formItem as any)[propertyName] = date;
        this.setState({
            formItem: this.state.formItem,
        });
        this._clearErrorMessage(propertyName);
    }

    private _onFilterCheckBoxChange = (propertyName: string, ev?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean): void => {
        const checkedValue = checked ? true : false;
        switch (propertyName) {
            case "acuteCareBedsFilter":
                this.setState({
                    acutecareBedsFilter: checkedValue,
                });
                break;
            case "continuingCareBedsFilter":
                this.setState({
                    continuingCareBedsFilter: checkedValue,
                });
                break;
            case "independentLivingFilter":
                this.setState({
                    independentLivingFilter: checkedValue,
                });
                break;
        }

        // handle height resizing of study locations since it can be dynamically sized.
        const element = document.getElementById('studyLocationsRoot');
        if ((!checkedValue && propertyName === "acuteCareBedsFilter" && !this.state.continuingCareBedsFilter && !this.state.independentLivingFilter) ||
            (!checkedValue && propertyName === "continuingCareBedsFilter" && !this.state.acutecareBedsFilter && !this.state.independentLivingFilter) ||
            (!checkedValue && propertyName === "independentLivingFilter" && !this.state.acutecareBedsFilter && !this.state.continuingCareBedsFilter)) {
            if (element) {
                element.style.height = '930px';
            }
        } else {
            if (element) {
                element.style.height = '';
            }
            this.setState({
                resizeStudyLocations: true,
            });
        }
    }

    private _onCheckBoxChange = (propertyName: string, ev?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean, isYesNoCheck?: boolean): void => {
        const newOptions: string[] = [];
        const checkBoxValue: string = (ev?.target as HTMLInputElement).name;
        if (isYesNoCheck) {
            (this.state.formItem as any)[propertyName] = ((this.state.formItem as any)[propertyName] === "" || (this.state.formItem as any)[propertyName] === "No") ? "Yes" : "No";
        } else {
            if ((this.state.formItem as any)[propertyName]) {
                (this.state.formItem as any)[propertyName].forEach((selectedOption: string) => {
                    newOptions.push(selectedOption);
                });
            }
            if (checked && !newOptions.includes(checkBoxValue)) {
                newOptions.push(checkBoxValue);
            } else {
                newOptions.splice(newOptions.indexOf(checkBoxValue), 1);
            }
            (this.state.formItem as any)[propertyName] = newOptions;
        }
        this.setState({
            formItem: this.state.formItem,
        });
        this._clearOtherFieldValues(propertyName);
        this._clearErrorMessage(propertyName);
    }

    private _loadCheckboxes(propertyName: string, choices: ISelectableOptionWithTooltip[], componentRef?: IRefObject<any>, width?: number) {
        const Checkboxes: JSX.Element[] = [];
        const newOptions: string[] = [];
        const checkboxStyles = (styleProps: ICheckboxStyleProps): ICheckboxStyles => {
            const chkStyles: ICheckboxStyles = width ? {
                root: {
                    width: width,
                }, label: { alignItems: 'flex-start' }, checkbox: [
                    { backgroundColor: "white" },
                    styleProps.checked && {
                        backgroundColor: "rgb(0, 90, 158)",
                    },
                ],
            } : {
                label: { alignItems: 'flex-start' }, checkbox: [
                    { backgroundColor: "white" },
                    styleProps.checked && {
                        backgroundColor: "rgb(0, 90, 158)",
                    },
                ],
            };
            return chkStyles;
        };
        if ((this.state.formItem as any)[propertyName]) {
            (this.state.formItem as any)[propertyName].forEach((selectedOption: string) => {
                newOptions.push(selectedOption);
            });
        }
        choices.forEach((choice: ISelectableOptionWithTooltip, index) => {
            let isChecked: boolean = newOptions.includes((choice.key as string)) ? true : false;
            if (choice.tooltipText) {
                Checkboxes.push(
                    <span key={index} style={{ display: 'flex' }}>
                        <Checkbox {...(componentRef && Checkboxes.length === 0 ? { componentRef: componentRef } : {})} styles={checkboxStyles} key={(choice.key as string)} label={(choice.text as string)} name={(choice.key as string)} checked={isChecked} onChange={(ev, checked) => { this._onCheckBoxChange(propertyName, ev, checked); }}
                            onRenderLabel={(p) => { return <span className="ms-Checkbox-text" style={{ marginLeft: '4px', color: 'rgb(50, 49, 48)' }}>{choice.customLabel ? choice.customLabel : choice.text}</span> }}
                        />
                        <TooltipHost
                            styles={{ root: { marginLeft: '8px', alignSelf: 'center' } }}
                            content={choice.tooltipText}
                            tooltipProps={{
                                styles: {
                                    content: { fontWeight: '600' }
                                }
                            }}>
                            <Icon iconName="Info" />
                        </TooltipHost>
                    </span>
                );
            } else {
                Checkboxes.push(
                    <Checkbox {...(componentRef && Checkboxes.length === 0 ? { componentRef: componentRef } : {})} styles={checkboxStyles} key={(choice.key as string)} label={(choice.text as string)} name={(choice.key as string)} checked={isChecked} onChange={(ev, checked) => { this._onCheckBoxChange(propertyName, ev, checked); }}
                        onRenderLabel={(p) => { return <span className="ms-Checkbox-text" style={{ marginLeft: '4px', color: 'rgb(50, 49, 48)' }}>{choice.customLabel ? choice.customLabel : choice.text}</span> }}
                    />
                );
            }
        });
        return Checkboxes;
    }

    private _removeUnavailableStudyLocationsDepartments = (availableDepartments: IStudyLocationDepartment[], selectedOptions: string[]): void => {
        const newSelectedOptions: string[] = [];
        if (selectedOptions.length > 0) {
            for (const selectedOption of selectedOptions) {
                if (availableDepartments.some(department => department.value === selectedOption)) {
                    newSelectedOptions.push(selectedOption);
                }
            }
        }
        if (selectedOptions.length !== newSelectedOptions.length) {
            const formItem = this.state.formItem as FormCovenantHealthRequestResource;
            formItem.studyLocationsDepartments = newSelectedOptions;
            this.setState({
                formItem: formItem,
            });
        }
    }

    private _loadStudyLocationsDepartments(width?: number) {
        const items: JSX.Element[] = [];
        const selectedOptions: string[] = [];
        const checkboxStyles = (styleProps: ICheckboxStyleProps): ICheckboxStyles => {
            const chkStyles: ICheckboxStyles = width ? {
                root: {
                    width: width,
                }, label: { alignItems: 'flex-start' }, checkbox: [
                    { backgroundColor: "white" },
                    styleProps.checked && {
                        backgroundColor: "rgb(0, 90, 158)",
                    },
                ],
            } : {
                label: { alignItems: 'flex-start' }, checkbox: [
                    { backgroundColor: "white" },
                    styleProps.checked && {
                        backgroundColor: "rgb(0, 90, 158)",
                    },
                ],
            };
            return chkStyles;
        };
        let currentGroup: string = '';
        const formItem = this.state.formItem as FormCovenantHealthRequestResource;
        const studyLocationsParticipating = [...this.studyLocationsParticipating];
        const studyLocationsDepartments = [...this.studyLocationsDepartments];
        const selectedLocations = studyLocationsParticipating.filter((studyLocationParticipating: IStudyLocationParticipating) => {
            return formItem.studyLocationsParticipating.includes(studyLocationParticipating.value);
        });
        if (selectedLocations.length > 0) {
            const availableDepartments: IStudyLocationDepartment[] = [];
            const locationsAcuteCareBeds = studyLocationsParticipating.filter((studyLocationParticipating: IStudyLocationParticipating) => {
                return studyLocationParticipating.acuteCareBed;
            });
            const locationsContinuingCareBeds = studyLocationsParticipating.filter((studyLocationParticipating: IStudyLocationParticipating) => {
                return studyLocationParticipating.continuingCareBed;
            });
            const locationsIndependentLiving = studyLocationsParticipating.filter((studyLocationParticipating: IStudyLocationParticipating) => {
                return studyLocationParticipating.independentLiving;
            });
            for (const studyLocationsDepartment of studyLocationsDepartments) {
                for (const selectedLocation of selectedLocations) {
                    if (studyLocationsDepartment.acronym === selectedLocation.acronym) {
                        availableDepartments.push(studyLocationsDepartment);
                    }
                }
            }
            // When "ALL Acute Care sites" is selected
            if (selectedLocations.some(studyLocationParticipating => studyLocationParticipating.acronym === 'COV-H')) {
                for (const locationAcuteCareBeds of locationsAcuteCareBeds) {
                    for (const studyLocationsDepartment of studyLocationsDepartments) {
                        if (studyLocationsDepartment.acronym === locationAcuteCareBeds.acronym) {
                            // add items if not already there
                            if (!availableDepartments.some(department => department.value === studyLocationsDepartment.value)) {
                                availableDepartments.push(studyLocationsDepartment);
                            }
                        }
                    }
                }
            }
            // When "ALL Continuing Care sites" is selected
            if (selectedLocations.some(studyLocationParticipating => studyLocationParticipating.acronym === 'COV-C')) {
                for (const locationContinuingCareBeds of locationsContinuingCareBeds) {
                    for (const studyLocationsDepartment of studyLocationsDepartments) {
                        if (studyLocationsDepartment.acronym === locationContinuingCareBeds.acronym) {
                            // add items if not already there
                            if (!availableDepartments.some(department => department.value === studyLocationsDepartment.value)) {
                                availableDepartments.push(studyLocationsDepartment);
                            }
                        }
                    }
                }
            }
            // When "ALL Independent Living sites" is selected
            if (selectedLocations.some(studyLocationParticipating => studyLocationParticipating.acronym === 'COV-L')) {
                for (const locationIndependentLiving of locationsIndependentLiving) {
                    for (const studyLocationsDepartment of studyLocationsDepartments) {
                        if (studyLocationsDepartment.acronym === locationIndependentLiving.acronym) {
                            // add items if not already there
                            if (!availableDepartments.some(department => department.value === studyLocationsDepartment.value)) {
                                availableDepartments.push(studyLocationsDepartment);
                            }
                        }
                    }
                }
            }
            // When "COV Corporate" is selected
            if (selectedLocations.some(studyLocationParticipating => studyLocationParticipating.acronym === 'COVCorp')) {
                for (const studyLocationParticipating of studyLocationsParticipating) {
                    for (const studyLocationsDepartment of studyLocationsDepartments) {
                        if (studyLocationsDepartment.acronym === studyLocationParticipating.acronym) {
                            // add items if not already there
                            if (!availableDepartments.some(department => department.value === studyLocationsDepartment.value)) {
                                availableDepartments.push(studyLocationsDepartment);
                            }
                        }
                    }
                }
            }

            if ((formItem as any)['studyLocationsDepartments']) {
                (formItem as any)['studyLocationsDepartments'].forEach((selectedOption: string) => {
                    selectedOptions.push(selectedOption);
                });
            }
            this._removeUnavailableStudyLocationsDepartments(availableDepartments, selectedOptions);

            availableDepartments.forEach((studyLocationsDepartment, index) => {

                if (currentGroup !== studyLocationsDepartment.groupName) {
                    currentGroup = studyLocationsDepartment.groupName;
                    items.push(
                        <div key={'div_' + index} className='groupName'>{studyLocationsDepartment.groupName}</div>
                    );
                }

                const choice: ISelectableOption = { key: studyLocationsDepartment.value, text: studyLocationsDepartment.description };
                let isChecked: boolean = selectedOptions.includes((choice.key as string)) ? true : false;
                items.push(
                    <Checkbox {...(items.length === 0 ? { componentRef: this.studyLocationsDepartmentsRef } : {})} styles={checkboxStyles} key={(choice.key as string)} label={(choice.text as string)} name={(choice.key as string)} checked={isChecked} onChange={(ev, checked) => { this._onCheckBoxChange('studyLocationsDepartments', ev, checked); }} />
                );
            });
        }
        return items;
    }

    private _loadStudyLocationsParticipating(width?: number) {
        const items: JSX.Element[] = [];
        const selectedOptions: string[] = [];
        const checkboxStyles = (styleProps: ICheckboxStyleProps): ICheckboxStyles => {
            const chkStyles: ICheckboxStyles = width ? {
                root: {
                    width: width,
                    marginRight: 5
                }, label: { alignItems: 'flex-start' }, checkbox: [
                    { backgroundColor: "white" },
                    styleProps.checked && {
                        backgroundColor: "rgb(0, 90, 158)",
                    },
                ],
            } : {
                root: {
                    marginRight: 5
                },
                label: { alignItems: 'flex-start' }, checkbox: [
                    { backgroundColor: "white" },
                    styleProps.checked && {
                        backgroundColor: "rgb(0, 90, 158)",
                    },
                ],
            };
            return chkStyles;
        };
        let currentGroup: string = '';
        let studyLocationsParticipating = [...this.studyLocationsParticipating];
        if (this.state.acutecareBedsFilter || this.state.continuingCareBedsFilter || this.state.independentLivingFilter) {
            studyLocationsParticipating = studyLocationsParticipating.filter((studyLocationParticipating: IStudyLocationParticipating) => {
                if (this.state.acutecareBedsFilter && studyLocationParticipating.acuteCareBed) {
                    return true;
                }
                if (this.state.continuingCareBedsFilter && studyLocationParticipating.continuingCareBed) {
                    return true;
                }
                if (this.state.independentLivingFilter && studyLocationParticipating.independentLiving) {
                    return true;
                }
                return false;
            });
        }

        if ((this.state.formItem as any)['studyLocationsParticipating']) {
            (this.state.formItem as any)['studyLocationsParticipating'].forEach((selectedOption: string) => {
                selectedOptions.push(selectedOption);
            });
        }
        studyLocationsParticipating.forEach((studyLocationParticipating, index) => {
            if (currentGroup !== studyLocationParticipating.groupName) {
                currentGroup = studyLocationParticipating.groupName;
                items.push(
                    <div key={'div_GroupName' + index} className='groupName'>{studyLocationParticipating.groupName}</div>
                );
            }
            const icons: JSX.Element[] = [];
            if (studyLocationParticipating.acuteCareBed) {
                icons.push(
                    <img key={'img_AcuteCareBedsIcon' + index} src={AcuteCareBedsIcon} alt="Acute Care Beds" />
                );
            }
            if (studyLocationParticipating.continuingCareBed) {
                icons.push(
                    <img key={'img_ContinuingCareBedsIcon' + index} src={ContinuingCareBedsIcon} alt="Continuing Care Beds" />
                );
            }
            if (studyLocationParticipating.independentLiving) {
                icons.push(
                    <img key={'img_IndependentLivingIcon' + index} src={IndependentLivingIcon} alt="Independent Living" />
                );
            }
            const choice: ISelectableOption = { key: studyLocationParticipating.value, text: studyLocationParticipating.description };
            let isChecked: boolean = selectedOptions.includes((choice.key as string)) ? true : false;
            items.push(
                <span key={'spanGroupName' + index} className='studyLocationCheckBoxContainer'>
                    <Checkbox {...(items.length === 0 ? { componentRef: this.studyLocationsParticipatingRef } : {})} styles={checkboxStyles} key={(choice.key as string)} label={(choice.text as string)} name={(choice.key as string)} checked={isChecked} onChange={(ev, checked) => { this._onCheckBoxChange('studyLocationsParticipating', ev, checked); }} />
                    {icons}
                </span>
            );
        });
        return items;
    }

    private _setStudyLocationsDepartments() {
        const items: IStudyLocationDepartment[] = [];
        items.push({ groupName: 'Banff: Mineral Springs Hospital (MSH)', description: 'Continuing Care', value: 'MSH - Continuing Care', acronym: 'MSH', });
        items.push({ groupName: 'Banff: Mineral Springs Hospital (MSH)', description: 'Diagnostic Services', value: 'MSH - Diagnostic Services', acronym: 'MSH', });
        items.push({ groupName: 'Banff: Mineral Springs Hospital (MSH)', description: 'Emergency department', value: 'MSH - Emergency department', acronym: 'MSH', });
        items.push({ groupName: 'Banff: Mineral Springs Hospital (MSH)', description: 'Emergency Medical Services (EMS)', value: 'MSH - Emergency Medical Services (EMS)', acronym: 'MSH', });
        items.push({ groupName: 'Banff: Mineral Springs Hospital (MSH)', description: 'Health Information Management', value: 'MSH - Health Information Management', acronym: 'MSH', });
        items.push({ groupName: 'Banff: Mineral Springs Hospital (MSH)', description: 'Laboratory', value: 'MSH - Laboratory', acronym: 'MSH', });
        items.push({ groupName: 'Banff: Mineral Springs Hospital (MSH)', description: 'Medicine', value: 'MSH - Medicine', acronym: 'MSH', });
        items.push({ groupName: 'Banff: Mineral Springs Hospital (MSH)', description: 'P.A.R.T.Y. Program', value: 'MSH - P.A.R.T.Y. Program', acronym: 'MSH', });
        items.push({ groupName: 'Banff: Mineral Springs Hospital (MSH)', description: 'Palliative Care', value: 'MSH - Palliative Care', acronym: 'MSH', });
        items.push({ groupName: 'Banff: Mineral Springs Hospital (MSH)', description: 'Pharmacy', value: 'MSH - Pharmacy', acronym: 'MSH', });
        items.push({ groupName: 'Banff: Mineral Springs Hospital (MSH)', description: 'Rehabilitation', value: 'MSH - Rehabilitation', acronym: 'MSH', });
        items.push({ groupName: 'Banff: Mineral Springs Hospital (MSH)', description: 'Spiritual care', value: 'MSH - Spiritual care', acronym: 'MSH', });
        items.push({ groupName: 'Banff: Mineral Springs Hospital (MSH)', description: 'Staff, Volunteers, and Physicians', value: 'MSH - Staff, Volunteers, and Physicians', acronym: 'MSH', });
        items.push({ groupName: 'Banff: Mineral Springs Hospital (MSH)', description: 'Surgery & anesthesia', value: 'MSH - Surgery & anesthesia', acronym: 'MSH', });
        items.push({ groupName: 'Beaumont: Chateau Vitaline (CV)', description: 'Food Services', value: 'CV - Food Services', acronym: 'CV', });
        items.push({ groupName: 'Beaumont: Chateau Vitaline (CV)', description: 'Memory care', value: 'CV - Memory care', acronym: 'CV', });
        items.push({ groupName: 'Beaumont: Chateau Vitaline (CV)', description: 'Staff', value: 'CV - Staff', acronym: 'CV', });
        items.push({ groupName: 'Beaumont: Chateau Vitaline (CV)', description: 'Supportive living', value: 'CV - Supportive living', acronym: 'CV', });
        items.push({ groupName: 'Bonnyville: Bonnyville Health Centre (BHC)', description: 'Aboriginal Liaison', value: 'BHC - Aboriginal Liaison', acronym: 'BHC', });
        items.push({ groupName: 'Bonnyville: Bonnyville Health Centre (BHC)', description: 'Ambulatory care', value: 'BHC - Ambulatory care', acronym: 'BHC', });
        items.push({ groupName: 'Bonnyville: Bonnyville Health Centre (BHC)', description: 'Ambulatory: Dietary/Nutrition counselling', value: 'BHC - Ambulatory: Dietary/Nutrition counselling', acronym: 'BHC', });
        items.push({ groupName: 'Bonnyville: Bonnyville Health Centre (BHC)', description: 'Ambulatory: Endoscopy', value: 'BHC - Ambulatory: Endoscopy', acronym: 'BHC', });
        items.push({ groupName: 'Bonnyville: Bonnyville Health Centre (BHC)', description: 'Cardiac Sciences: Cardiology', value: 'BHC - Cardiac Sciences: Cardiology', acronym: 'BHC', });
        items.push({ groupName: 'Bonnyville: Bonnyville Health Centre (BHC)', description: 'Cardiac Sciences: Stress testing clinic', value: 'BHC - Cardiac Sciences: Stress testing clinic', acronym: 'BHC', });
        items.push({ groupName: 'Bonnyville: Bonnyville Health Centre (BHC)', description: 'Community Cancer Centre', value: 'BHC - Community Cancer Centre', acronym: 'BHC', });
        items.push({ groupName: 'Bonnyville: Bonnyville Health Centre (BHC)', description: 'Continuing Care', value: 'BHC - Continuing Care', acronym: 'BHC', });
        items.push({ groupName: 'Bonnyville: Bonnyville Health Centre (BHC)', description: 'Diagnostic Services', value: 'BHC - Diagnostic Services', acronym: 'BHC', });
        items.push({ groupName: 'Bonnyville: Bonnyville Health Centre (BHC)', description: 'Dietary/Nutrition', value: 'BHC - Dietary/Nutrition', acronym: 'BHC', });
        items.push({ groupName: 'Bonnyville: Bonnyville Health Centre (BHC)', description: 'Emergency', value: 'BHC - Emergency', acronym: 'BHC', });
        items.push({ groupName: 'Bonnyville: Bonnyville Health Centre (BHC)', description: 'Family medicine', value: 'BHC - Family medicine', acronym: 'BHC', });
        items.push({ groupName: 'Bonnyville: Bonnyville Health Centre (BHC)', description: 'Food Services', value: 'BHC - Food Services', acronym: 'BHC', });
        items.push({ groupName: 'Bonnyville: Bonnyville Health Centre (BHC)', description: 'Health Information Management', value: 'BHC - Health Information Management', acronym: 'BHC', });
        items.push({ groupName: 'Bonnyville: Bonnyville Health Centre (BHC)', description: 'Laboratory', value: 'BHC - Laboratory', acronym: 'BHC', });
        items.push({ groupName: 'Bonnyville: Bonnyville Health Centre (BHC)', description: 'Laboratory Pathology, regional', value: 'BHC - Laboratory Pathology, regional', acronym: 'BHC', });
        items.push({ groupName: 'Bonnyville: Bonnyville Health Centre (BHC)', description: 'Labour/Delivery Obstetrics', value: 'BHC - Labour/Delivery Obstetrics', acronym: 'BHC', });
        items.push({ groupName: 'Bonnyville: Bonnyville Health Centre (BHC)', description: 'Medicine', value: 'BHC - Medicine', acronym: 'BHC', });
        items.push({ groupName: 'Bonnyville: Bonnyville Health Centre (BHC)', description: 'Orthopedics', value: 'BHC - Orthopedics', acronym: 'BHC', });
        items.push({ groupName: 'Bonnyville: Bonnyville Health Centre (BHC)', description: 'Palliative Care/Hospice', value: 'BHC - Palliative Care/Hospice', acronym: 'BHC', });
        items.push({ groupName: 'Bonnyville: Bonnyville Health Centre (BHC)', description: 'PARR Clinics', value: 'BHC - PARR Clinics', acronym: 'BHC', });
        items.push({ groupName: 'Bonnyville: Bonnyville Health Centre (BHC)', description: 'Pediatrics', value: 'BHC - Pediatrics', acronym: 'BHC', });
        items.push({ groupName: 'Bonnyville: Bonnyville Health Centre (BHC)', description: 'Pharmacy', value: 'BHC - Pharmacy', acronym: 'BHC', });
        items.push({ groupName: 'Bonnyville: Bonnyville Health Centre (BHC)', description: 'Rehabilitation Medicine', value: 'BHC - Rehabilitation Medicine', acronym: 'BHC', });
        items.push({ groupName: 'Bonnyville: Bonnyville Health Centre (BHC)', description: 'Respiratory therapy', value: 'BHC - Respiratory therapy', acronym: 'BHC', });
        items.push({ groupName: 'Bonnyville: Bonnyville Health Centre (BHC)', description: 'Spiritual care', value: 'BHC - Spiritual care', acronym: 'BHC', });
        items.push({ groupName: 'Bonnyville: Bonnyville Health Centre (BHC)', description: 'Staff', value: 'BHC - Staff', acronym: 'BHC', });
        items.push({ groupName: 'Bonnyville: Bonnyville Health Centre (BHC)', description: 'Surgery: anesthesia', value: 'BHC - Surgery: anesthesia', acronym: 'BHC', });
        items.push({ groupName: 'Bonnyville: Bonnyville Health Centre (BHC)', description: 'Surgery: surgery', value: 'BHC - Surgery: surgery', acronym: 'BHC', });
        items.push({ groupName: 'Bonnyville: Bonnyville Health Centre (BHC)', description: 'Urology', value: 'BHC - Urology', acronym: 'BHC', });
        items.push({ groupName: 'Calgary: Evanston Summit (ES)', description: 'Food services', value: 'ES - Food services', acronym: 'ES', });
        items.push({ groupName: 'Calgary: Evanston Summit (ES)', description: 'Independent living', value: 'ES - Independent living', acronym: 'ES', });
        items.push({ groupName: 'Calgary: Evanston Summit (ES)', description: 'Staff', value: 'ES - Staff', acronym: 'ES', });
        items.push({ groupName: 'Calgary: Holy Cross Manor (HCM)', description: 'Food Services', value: 'HCM - Food Services', acronym: 'HCM', });
        items.push({ groupName: 'Calgary: Holy Cross Manor (HCM)', description: 'Memory care', value: 'HCM - Memory care', acronym: 'HCM', });
        items.push({ groupName: 'Calgary: Holy Cross Manor (HCM)', description: 'Staff', value: 'HCM - Staff', acronym: 'HCM', });
        items.push({ groupName: 'Calgary: Holy Cross Manor (HCM)', description: 'Supportive living', value: 'HCM - Supportive living', acronym: 'HCM', });
        items.push({ groupName: 'Calgary: St. Marguerite Manor (SMM)', description: 'Food Services', value: 'SMM - Food Services', acronym: 'SMM', });
        items.push({ groupName: 'Calgary: St. Marguerite Manor (SMM)', description: 'Hospice', value: 'SMM - Hospice', acronym: 'SMM', });
        items.push({ groupName: 'Calgary: St. Marguerite Manor (SMM)', description: 'Memory care', value: 'SMM - Memory care', acronym: 'SMM', });
        items.push({ groupName: 'Calgary: St. Marguerite Manor (SMM)', description: 'Staff', value: 'SMM - Staff', acronym: 'SMM', });
        items.push({ groupName: 'Calgary: St. Marguerite Manor (SMM)', description: 'Supportive living', value: 'SMM - Supportive living', acronym: 'SMM', });
        items.push({ groupName: 'Calgary: St. Teresa Place (STP)', description: 'Food services', value: 'STP - Food services', acronym: 'STP', });
        items.push({ groupName: 'Calgary: St. Teresa Place (STP)', description: 'Memory care', value: 'STP - Memory care', acronym: 'STP', });
        items.push({ groupName: 'Calgary: St. Teresa Place (STP)', description: 'Staff', value: 'STP - Staff', acronym: 'STP', });
        items.push({ groupName: 'Calgary: St. Teresa Place (STP)', description: 'Supportive living', value: 'STP - Supportive living', acronym: 'STP', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Ambulatory care', value: 'SMH - Ambulatory care', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Cardiac testing support unit', value: 'SMH - Cardiac testing support unit', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Child health', value: 'SMH - Child health', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Community Cancer Centre', value: 'SMH - Community Cancer Centre', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Coronary care', value: 'SMH - Coronary care', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Diabetic Unit', value: 'SMH - Diabetic Unit', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Diagnostic Services', value: 'SMH - Diagnostic Services', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Emergency', value: 'SMH - Emergency', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Endoscopy', value: 'SMH - Endoscopy', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Enterostomy Clinic', value: 'SMH - Enterostomy Clinic', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Fluoroscopy', value: 'SMH - Fluoroscopy', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Food Services', value: 'SMH - Food Services', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Geriatric services', value: 'SMH - Geriatric services', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Gynaecology', value: 'SMH - Gynaecology', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Health Information Management', value: 'SMH - Health Information Management', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Laboratory', value: 'SMH - Laboratory', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Medicine', value: 'SMH - Medicine', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Mental Health', value: 'SMH - Mental Health', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Nutritional services/counselling', value: 'SMH - Nutritional services/counselling', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Obstetrics', value: 'SMH - Obstetrics', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Occupational Therapy', value: 'SMH - Occupational Therapy', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Operating Room', value: 'SMH - Operating Room', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Orthopedics', value: 'SMH - Orthopedics', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Outpatient Surgery', value: 'SMH - Outpatient Surgery', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Palliative Care', value: 'SMH - Palliative Care', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Pediatrics', value: 'SMH - Pediatrics', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Pharmacy', value: 'SMH - Pharmacy', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Physiotherapy', value: 'SMH - Physiotherapy', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Post Anaesthetic Recovery Room Clinic (PARR)', value: 'SMH - Post Anaesthetic Recovery Room Clinic (PARR)', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Psychiatry', value: 'SMH - Psychiatry', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Radiology', value: 'SMH - Radiology', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Rehabilitation', value: 'SMH - Rehabilitation', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Respiratory Therapy', value: 'SMH - Respiratory Therapy', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Spiritual care', value: 'SMH - Spiritual care', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Staff', value: 'SMH - Staff', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Stroke Prevention Clinic', value: 'SMH - Stroke Prevention Clinic', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Surgery', value: 'SMH - Surgery', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Surgery Anesthesia', value: 'SMH - Surgery Anesthesia', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Surgery plastic', value: 'SMH - Surgery plastic', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Ultrasound', value: 'SMH - Ultrasound', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Urology', value: 'SMH - Urology', acronym: 'SMH', });
        items.push({ groupName: 'Camrose: St. Mary\'s Hospital (SMH)', description: 'Women\'s health', value: 'SMH - Women\'s health', acronym: 'SMH', });
        items.push({ groupName: 'Castor: Our Lady of the Rosary Hospital (OLRH)', description: 'Ambulatory', value: 'OLRH - Ambulatory', acronym: 'OLRH', });
        items.push({ groupName: 'Castor: Our Lady of the Rosary Hospital (OLRH)', description: 'Community respite care', value: 'OLRH - Community respite care', acronym: 'OLRH', });
        items.push({ groupName: 'Castor: Our Lady of the Rosary Hospital (OLRH)', description: 'Continuing care', value: 'OLRH - Continuing care', acronym: 'OLRH', });
        items.push({ groupName: 'Castor: Our Lady of the Rosary Hospital (OLRH)', description: 'Diagnostic Services', value: 'OLRH - Diagnostic Services', acronym: 'OLRH', });
        items.push({ groupName: 'Castor: Our Lady of the Rosary Hospital (OLRH)', description: 'Emergency', value: 'OLRH - Emergency', acronym: 'OLRH', });
        items.push({ groupName: 'Castor: Our Lady of the Rosary Hospital (OLRH)', description: 'Family Medicine Centre', value: 'OLRH - Family Medicine Centre', acronym: 'OLRH', });
        items.push({ groupName: 'Castor: Our Lady of the Rosary Hospital (OLRH)', description: 'Foot care services', value: 'OLRH - Foot care services', acronym: 'OLRH', });
        items.push({ groupName: 'Castor: Our Lady of the Rosary Hospital (OLRH)', description: 'Health Information Management', value: 'OLRH - Health Information Management', acronym: 'OLRH', });
        items.push({ groupName: 'Castor: Our Lady of the Rosary Hospital (OLRH)', description: 'Laboratory', value: 'OLRH - Laboratory', acronym: 'OLRH', });
        items.push({ groupName: 'Castor: Our Lady of the Rosary Hospital (OLRH)', description: 'Medicine', value: 'OLRH - Medicine', acronym: 'OLRH', });
        items.push({ groupName: 'Castor: Our Lady of the Rosary Hospital (OLRH)', description: 'Palliative Care', value: 'OLRH - Palliative Care', acronym: 'OLRH', });
        items.push({ groupName: 'Castor: Our Lady of the Rosary Hospital (OLRH)', description: 'Pharmacy', value: 'OLRH - Pharmacy', acronym: 'OLRH', });
        items.push({ groupName: 'Castor: Our Lady of the Rosary Hospital (OLRH)', description: 'Rehabilitation services', value: 'OLRH - Rehabilitation services', acronym: 'OLRH', });
        items.push({ groupName: 'Castor: Our Lady of the Rosary Hospital (OLRH)', description: 'Respiratory therapy', value: 'OLRH - Respiratory therapy', acronym: 'OLRH', });
        items.push({ groupName: 'Castor: Our Lady of the Rosary Hospital (OLRH)', description: 'Spiritual care', value: 'OLRH - Spiritual care', acronym: 'OLRH', });
        items.push({ groupName: 'Castor: Our Lady of the Rosary Hospital (OLRH)', description: 'Staff', value: 'OLRH - Staff', acronym: 'OLRH', });
        items.push({ groupName: 'Edmonton: Centre de santé St-Thomas (CSST)', description: 'Food Services', value: 'CSST - Food Services', acronym: 'CSST', });
        items.push({ groupName: 'Edmonton: Centre de santé St-Thomas (CSST)', description: 'Independent living', value: 'CSST - Independent living', acronym: 'CSST', });
        items.push({ groupName: 'Edmonton: Centre de santé St-Thomas (CSST)', description: 'Memory care', value: 'CSST - Memory care', acronym: 'CSST', });
        items.push({ groupName: 'Edmonton: Centre de santé St-Thomas (CSST)', description: 'Staff', value: 'CSST - Staff', acronym: 'CSST', });
        items.push({ groupName: 'Edmonton: Centre de santé St-Thomas (CSST)', description: 'Supportive living', value: 'CSST - Supportive living', acronym: 'CSST', });
        items.push({ groupName: 'Edmonton: Covenant Wellness Community (CWC)', description: 'Ambulatory', value: 'CWC - Ambulatory', acronym: 'CWC', });
        items.push({ groupName: 'Edmonton: Covenant Wellness Community (CWC)', description: 'Continuing Care', value: 'CWC - Continuing Care', acronym: 'CWC', });
        items.push({ groupName: 'Edmonton: Covenant Wellness Community (CWC)', description: 'Food/Nutrition Services', value: 'CWC - Food/Nutrition Services', acronym: 'CWC', });
        items.push({ groupName: 'Edmonton: Covenant Wellness Community (CWC)', description: 'Rehabilitation', value: 'CWC - Rehabilitation', acronym: 'CWC', });
        items.push({ groupName: 'Edmonton: Covenant Wellness Community (CWC)', description: 'Spiritual Care', value: 'CWC - Spiritual Care', acronym: 'CWC', });
        items.push({ groupName: 'Edmonton: Covenant Wellness Community (CWC)', description: 'Staff', value: 'CWC - Staff', acronym: 'CWC', });
        items.push({ groupName: 'Edmonton: Covenant Wellness Community (CWC)', description: 'Supportive Living', value: 'CWC - Supportive Living', acronym: 'CWC', });
        items.push({ groupName: 'Edmonton: Edmonton General Continuing Care Centre (EGCCC)', description: 'Continuing Care', value: 'EGCCC - Continuing Care', acronym: 'EGCCC', });
        items.push({ groupName: 'Edmonton: Edmonton General Continuing Care Centre (EGCCC)', description: 'Food/Nutrition Services', value: 'EGCCC - Food/Nutrition Services', acronym: 'EGCCC', });
        items.push({ groupName: 'Edmonton: Edmonton General Continuing Care Centre (EGCCC)', description: 'Geriatric Psychiatry', value: 'EGCCC - Geriatric Psychiatry', acronym: 'EGCCC', });
        items.push({ groupName: 'Edmonton: Edmonton General Continuing Care Centre (EGCCC)', description: 'GF Macdonald Centre for Lung Health', value: 'EGCCC - GF Macdonald Centre for Lung Health', acronym: 'EGCCC', });
        items.push({ groupName: 'Edmonton: Edmonton General Continuing Care Centre (EGCCC)', description: 'Health Information Management', value: 'EGCCC - Health Information Management', acronym: 'EGCCC', });
        items.push({ groupName: 'Edmonton: Edmonton General Continuing Care Centre (EGCCC)', description: 'Hemodialysis Unit', value: 'EGCCC - Hemodialysis Unit', acronym: 'EGCCC', });
        items.push({ groupName: 'Edmonton: Edmonton General Continuing Care Centre (EGCCC)', description: 'Medical device reprocessing', value: 'EGCCC - Medical device reprocessing', acronym: 'EGCCC', });
        items.push({ groupName: 'Edmonton: Edmonton General Continuing Care Centre (EGCCC)', description: 'Palliative care', value: 'EGCCC - Palliative care', acronym: 'EGCCC', });
        items.push({ groupName: 'Edmonton: Edmonton General Continuing Care Centre (EGCCC)', description: 'Pharmacy', value: 'EGCCC - Pharmacy', acronym: 'EGCCC', });
        items.push({ groupName: 'Edmonton: Edmonton General Continuing Care Centre (EGCCC)', description: 'Rehabilitation: Clinical therapeutics', value: 'EGCCC - Rehabilitation: Clinical therapeutics', acronym: 'EGCCC', });
        items.push({ groupName: 'Edmonton: Edmonton General Continuing Care Centre (EGCCC)', description: 'Rehabilitation: Community rehabilitation program', value: 'EGCCC - Rehabilitation: Community rehabilitation program', acronym: 'EGCCC', });
        items.push({ groupName: 'Edmonton: Edmonton General Continuing Care Centre (EGCCC)', description: 'Rehabilitation: Recreation therapy', value: 'EGCCC - Rehabilitation: Recreation therapy', acronym: 'EGCCC', });
        items.push({ groupName: 'Edmonton: Edmonton General Continuing Care Centre (EGCCC)', description: 'Social work', value: 'EGCCC - Social work', acronym: 'EGCCC', });
        items.push({ groupName: 'Edmonton: Edmonton General Continuing Care Centre (EGCCC)', description: 'Spiritual care', value: 'EGCCC - Spiritual care', acronym: 'EGCCC', });
        items.push({ groupName: 'Edmonton: Edmonton General Continuing Care Centre (EGCCC)', description: 'Staff', value: 'EGCCC - Staff', acronym: 'EGCCC', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Ambulatory: Day Medical Unit', value: 'GNCH - Ambulatory: Day Medical Unit', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Ambulatory: Diabetes and Metabolic Centre', value: 'GNCH - Ambulatory: Diabetes and Metabolic Centre', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Ambulatory: Endoscopy', value: 'GNCH - Ambulatory: Endoscopy', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Ambulatory: Family Medicine Centre', value: 'GNCH - Ambulatory: Family Medicine Centre', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Ambulatory: Home Parental Therapy', value: 'GNCH - Ambulatory: Home Parental Therapy', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Ambulatory: IV Clinic', value: 'GNCH - Ambulatory: IV Clinic', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Ambulatory: Orthopedic Clinic', value: 'GNCH - Ambulatory: Orthopedic Clinic', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Ambulatory: Urodynamic Rehab Clinic', value: 'GNCH - Ambulatory: Urodynamic Rehab Clinic', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Ambulatory: Wound Clinic', value: 'GNCH - Ambulatory: Wound Clinic', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Cardiac Sciences: Cardiac Rehabilitation', value: 'GNCH - Cardiac Sciences: Cardiac Rehabilitation', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Cardiac Sciences: Congestive Heart Failure Clinic', value: 'GNCH - Cardiac Sciences: Congestive Heart Failure Clinic', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Cardiac Sciences: Coronary Care Unit (CCU)', value: 'GNCH - Cardiac Sciences: Coronary Care Unit (CCU)', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Cardiac Sciences: Electrodiagnostics/ECG Lab', value: 'GNCH - Cardiac Sciences: Electrodiagnostics/ECG Lab', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Cardiac Sciences: Holter monitoring/Stress testing', value: 'GNCH - Cardiac Sciences: Holter monitoring/Stress testing', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Cardiac Sciences: Pacemaker Clinic', value: 'GNCH - Cardiac Sciences: Pacemaker Clinic', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Diagnostics: Diagnostic imaging', value: 'GNCH - Diagnostics: Diagnostic imaging', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Diagnostics: Radiology, CT, Ultrasound & Lithotripsy', value: 'GNCH - Diagnostics: Radiology, CT, Ultrasound & Lithotripsy', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Emergency', value: 'GNCH - Emergency', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Food/Nutrition Services', value: 'GNCH - Food/Nutrition Services', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Geriatric Assessment Unit', value: 'GNCH - Geriatric Assessment Unit', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Geriatrics', value: 'GNCH - Geriatrics', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Health Information Management', value: 'GNCH - Health Information Management', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Hemodialysis: Diabetic Nephropathy Prevention Clinic', value: 'GNCH - Hemodialysis: Diabetic Nephropathy Prevention Clinic', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Hemodialysis: Renal Insufficiency Program', value: 'GNCH - Hemodialysis: Renal Insufficiency Program', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Infectious diseases', value: 'GNCH - Infectious diseases', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Intensive Care Unit (ICU)', value: 'GNCH - Intensive Care Unit (ICU)', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Laboratory', value: 'GNCH - Laboratory', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Labour/Delivery Obstetrics', value: 'GNCH - Labour/Delivery Obstetrics', acronym: 'GNCH', });
        //items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Medical affairs (physicians)', value: 'GNCH - Medical affairs (physicians)', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Medicine', value: 'GNCH - Medicine', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Neonatal Intensive Care Unit (NICU)', value: 'GNCH - Neonatal Intensive Care Unit (NICU)', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Neurology/Stroke', value: 'GNCH - Neurology/Stroke', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Neurophysiology: EEG/EMG/EVP', value: 'GNCH - Neurophysiology: EEG/EMG/EVP', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Orthopedics', value: 'GNCH - Orthopedics', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Palliative Care', value: 'GNCH - Palliative Care', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Pediatrics: Children\'s Health Clinic', value: 'GNCH - Pediatrics: Children\'s Health Clinic', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Pharmacy', value: 'GNCH - Pharmacy', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Psychiatry', value: 'GNCH - Psychiatry', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Psychology', value: 'GNCH - Psychology', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Rehabilitation: Audiology/Speech/Language Pathology', value: 'GNCH - Rehabilitation: Audiology/Speech/Language Pathology', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Rehabilitation: Occupational therapy', value: 'GNCH - Rehabilitation: Occupational therapy', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Rehabilitation: Physiotherapy', value: 'GNCH - Rehabilitation: Physiotherapy', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Respiratory', value: 'GNCH - Respiratory', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Social Work', value: 'GNCH - Social Work', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Spiritual care', value: 'GNCH - Spiritual care', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Staff', value: 'GNCH - Staff', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Surgery: Anesthesia', value: 'GNCH - Surgery: Anesthesia', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Surgery: Medical Device Reprocessing', value: 'GNCH - Surgery: Medical Device Reprocessing', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Surgery: Surgery', value: 'GNCH - Surgery: Surgery', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Grey Nuns Community Hospital (GNCH)', description: 'Women\'s Health', value: 'GNCH - Women\'s Health', acronym: 'GNCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Ambulatory Parenteral Therapy', value: 'MCH - Ambulatory Parenteral Therapy', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Ambulatory: Diabetes Education Centre', value: 'MCH - Ambulatory: Diabetes Education Centre', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Ambulatory: Endoscopy', value: 'MCH - Ambulatory: Endoscopy', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Ambulatory: Family Medicine Centre', value: 'MCH - Ambulatory: Family Medicine Centre', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Ambulatory: Home Parenteral Therapy', value: 'MCH - Ambulatory: Home Parenteral Therapy', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Ambulatory: IV Clinic', value: 'MCH - Ambulatory: IV Clinic', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Ambulatory: Northern Alberta Continence Services (NACS)', value: 'MCH - Ambulatory: Northern Alberta Continence Services (NACS)', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Ambulatory: Orthopedic Clinic', value: 'MCH - Ambulatory: Orthopedic Clinic', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Ambulatory: Pain Clinic', value: 'MCH - Ambulatory: Pain Clinic', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Ambulatory: Wound Clinic', value: 'MCH - Ambulatory: Wound Clinic', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Cardiac Sciences: Coronary Care Unit (CCU)', value: 'MCH - Cardiac Sciences: Coronary Care Unit (CCU)', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Cardiac Sciences: Electrodiagnostics/ECG Lab', value: 'MCH - Cardiac Sciences: Electrodiagnostics/ECG Lab', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Cardiac Sciences: Holter monitoring/Stress testing', value: 'MCH - Cardiac Sciences: Holter monitoring/Stress testing', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Cardiac Sciences: Pacemaker Clinic', value: 'MCH - Cardiac Sciences: Pacemaker Clinic', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Diagnostics: Diagnostic imaging', value: 'MCH - Diagnostics: Diagnostic imaging', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Diagnostics: Radiology, CT, Ultrasound & Lithotripsy', value: 'MCH - Diagnostics: Radiology, CT, Ultrasound & Lithotripsy', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Emergency', value: 'MCH - Emergency', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Food/Nutrition Services', value: 'MCH - Food/Nutrition Services', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Geriatric Assessment Clinic', value: 'MCH - Geriatric Assessment Clinic', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Geriatrics', value: 'MCH - Geriatrics', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Health Information Management', value: 'MCH - Health Information Management', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Infectious diseases', value: 'MCH - Infectious diseases', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Institute Reconstructive Sciences in Medicine (iRSM)', value: 'MCH - Institute Reconstructive Sciences in Medicine (iRSM)', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Intensive Care Unit (ICU)', value: 'MCH - Intensive Care Unit (ICU)', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Laboratory', value: 'MCH - Laboratory', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Labour/Delivery Obstetrics', value: 'MCH - Labour/Delivery Obstetrics', acronym: 'MCH', });
        //items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Medical affairs (physicians)', value: 'MCH - Medical affairs (physicians)', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Medicine', value: 'MCH - Medicine', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Neonatal Internsive Care Unit (NICU)', value: 'MCH - Neonatal Internsive Care Unit (NICU)', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Neurology/Stroke', value: 'MCH - Neurology/Stroke', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Neurophysiology: EEG/EMG/EVP', value: 'MCH - Neurophysiology: EEG/EMG/EVP', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Palliative Care', value: 'MCH - Palliative Care', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Pediatrics: Children\'s Health Clinic', value: 'MCH - Pediatrics: Children\'s Health Clinic', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Pediatrics: Neuro developmental clinic', value: 'MCH - Pediatrics: Neuro developmental clinic', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Pharmacy', value: 'MCH - Pharmacy', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Psychiatry', value: 'MCH - Psychiatry', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Psychiatry: Mental health outpatient', value: 'MCH - Psychiatry: Mental health outpatient', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Psychology', value: 'MCH - Psychology', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Radiology, CT, Ultrasound & Lithotripsy', value: 'MCH - Radiology, CT, Ultrasound & Lithotripsy', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Rehabilitation: Audiology/Speech/Language Pathology', value: 'MCH - Rehabilitation: Audiology/Speech/Language Pathology', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Rehabilitation: Better balance fall prevention program', value: 'MCH - Rehabilitation: Better balance fall prevention program', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Rehabilitation: Occupational therapy', value: 'MCH - Rehabilitation: Occupational therapy', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Rehabilitation: Osteoporosis program', value: 'MCH - Rehabilitation: Osteoporosis program', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Rehabilitation: Physiotherapy', value: 'MCH - Rehabilitation: Physiotherapy', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Rehabilitation: Recreation therapy', value: 'MCH - Rehabilitation: Recreation therapy', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Respiratory', value: 'MCH - Respiratory', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Social Work', value: 'MCH - Social Work', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Spiritual Care', value: 'MCH - Spiritual Care', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Staff', value: 'MCH - Staff', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Surgery: Anesthesia', value: 'MCH - Surgery: Anesthesia', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Surgery: Medical Device Reprocessing', value: 'MCH - Surgery: Medical Device Reprocessing', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Surgery: Surgery', value: 'MCH - Surgery: Surgery', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Misericordia Community Hospital (MCH)', description: 'Women\'s Health', value: 'MCH - Women\'s Health', acronym: 'MCH', });
        items.push({ groupName: 'Edmonton: Teaching and Research Continuing Care Centre (TRCCC)', description: 'Continuing Care', value: 'TRCCC - Continuing Care', acronym: 'TRCCC', });
        items.push({ groupName: 'Edmonton: Teaching and Research Continuing Care Centre (TRCCC)', description: 'Food/Nutrition Services', value: 'TRCCC - Food/Nutrition Services', acronym: 'TRCCC', });
        items.push({ groupName: 'Edmonton: Teaching and Research Continuing Care Centre (TRCCC)', description: 'Palliative Care', value: 'TRCCC - Palliative Care', acronym: 'TRCCC', });
        items.push({ groupName: 'Edmonton: Teaching and Research Continuing Care Centre (TRCCC)', description: 'Spiritual Care', value: 'TRCCC - Spiritual Care', acronym: 'TRCCC', });
        items.push({ groupName: 'Edmonton: Teaching and Research Continuing Care Centre (TRCCC)', description: 'Staff', value: 'TRCCC - Staff', acronym: 'TRCCC', });
        items.push({ groupName: 'Edmonton: Teaching and Research Continuing Care Centre (TRCCC)', description: 'Supportive Living', value: 'TRCCC - Supportive Living', acronym: 'TRCCC', });
        items.push({ groupName: 'Edmonton: St. Joseph\'s Auxiliary Hospital (SJAH)', description: 'Ambulatory: Peritoneal dialysis program (CAPD)', value: 'SJAH - Ambulatory: Peritoneal dialysis program (CAPD)', acronym: 'SJAH', });
        items.push({ groupName: 'Edmonton: St. Joseph\'s Auxiliary Hospital (SJAH)', description: 'Community day support', value: 'SJAH - Community day support', acronym: 'SJAH', });
        items.push({ groupName: 'Edmonton: St. Joseph\'s Auxiliary Hospital (SJAH)', description: 'Continuing care', value: 'SJAH - Continuing care', acronym: 'SJAH', });
        items.push({ groupName: 'Edmonton: St. Joseph\'s Auxiliary Hospital (SJAH)', description: 'Food/Nutrition Services', value: 'SJAH - Food/Nutrition Services', acronym: 'SJAH', });
        items.push({ groupName: 'Edmonton: St. Joseph\'s Auxiliary Hospital (SJAH)', description: 'Health Information Management', value: 'SJAH - Health Information Management', acronym: 'SJAH', });
        items.push({ groupName: 'Edmonton: St. Joseph\'s Auxiliary Hospital (SJAH)', description: 'Palliative care', value: 'SJAH - Palliative care', acronym: 'SJAH', });
        items.push({ groupName: 'Edmonton: St. Joseph\'s Auxiliary Hospital (SJAH)', description: 'Spiritual care', value: 'SJAH - Spiritual care', acronym: 'SJAH', });
        items.push({ groupName: 'Edmonton: St. Joseph\'s Auxiliary Hospital (SJAH)', description: 'Staff', value: 'SJAH - Staff', acronym: 'SJAH', });
        items.push({ groupName: 'Edmonton: Villa Caritas (VC)', description: 'Food/Nutrition Services', value: 'VC - Food/Nutrition Services', acronym: 'VC', });
        items.push({ groupName: 'Edmonton: Villa Caritas (VC)', description: 'Geriatric Psychiatry', value: 'VC - Geriatric Psychiatry', acronym: 'VC', });
        items.push({ groupName: 'Edmonton: Villa Caritas (VC)', description: 'Health Information Management', value: 'VC - Health Information Management', acronym: 'VC', });
        items.push({ groupName: 'Edmonton: Villa Caritas (VC)', description: 'Rehabilitation: Occupational therapy', value: 'VC - Rehabilitation: Occupational therapy', acronym: 'VC', });
        items.push({ groupName: 'Edmonton: Villa Caritas (VC)', description: 'Rehabilitation: Physiotherapy', value: 'VC - Rehabilitation: Physiotherapy', acronym: 'VC', });
        items.push({ groupName: 'Edmonton: Villa Caritas (VC)', description: 'Rehabilitation: Recreation therapy', value: 'VC - Rehabilitation: Recreation therapy', acronym: 'VC', });
        items.push({ groupName: 'Edmonton: Villa Caritas (VC)', description: 'Rehabilitation: Speech language pathology', value: 'VC - Rehabilitation: Speech language pathology', acronym: 'VC', });
        items.push({ groupName: 'Edmonton: Villa Caritas (VC)', description: 'Social work', value: 'VC - Social work', acronym: 'VC', });
        items.push({ groupName: 'Edmonton: Villa Caritas (VC)', description: 'Spiritual care', value: 'VC - Spiritual care', acronym: 'VC', });
        items.push({ groupName: 'Edmonton: Villa Caritas (VC)', description: 'Staff', value: 'VC - Staff', acronym: 'VC', });
        items.push({ groupName: 'Killam: Killam Health Centre (KHC)', description: 'Acute care', value: 'KHC - Acute care', acronym: 'KHC', });
        items.push({ groupName: 'Killam: Killam Health Centre (KHC)', description: 'Ambulatory: dietary/nutrition counselling', value: 'KHC - Ambulatory: dietary/nutrition counselling', acronym: 'KHC', });
        items.push({ groupName: 'Killam: Killam Health Centre (KHC)', description: 'Community day support program', value: 'KHC - Community day support program', acronym: 'KHC', });
        items.push({ groupName: 'Killam: Killam Health Centre (KHC)', description: 'Continuing care', value: 'KHC - Continuing care', acronym: 'KHC', });
        items.push({ groupName: 'Killam: Killam Health Centre (KHC)', description: 'Diagnostic Services', value: 'KHC - Diagnostic Services', acronym: 'KHC', });
        items.push({ groupName: 'Killam: Killam Health Centre (KHC)', description: 'Dietary/Nutrition', value: 'KHC - Dietary/Nutrition', acronym: 'KHC', });
        items.push({ groupName: 'Killam: Killam Health Centre (KHC)', description: 'Emergency', value: 'KHC - Emergency', acronym: 'KHC', });
        items.push({ groupName: 'Killam: Killam Health Centre (KHC)', description: 'Health Information Management', value: 'KHC - Health Information Management', acronym: 'KHC', });
        items.push({ groupName: 'Killam: Killam Health Centre (KHC)', description: 'Laboratory', value: 'KHC - Laboratory', acronym: 'KHC', });
        items.push({ groupName: 'Killam: Killam Health Centre (KHC)', description: 'Palliative Care', value: 'KHC - Palliative Care', acronym: 'KHC', });
        items.push({ groupName: 'Killam: Killam Health Centre (KHC)', description: 'Rehabilitation', value: 'KHC - Rehabilitation', acronym: 'KHC', });
        items.push({ groupName: 'Killam: Killam Health Centre (KHC)', description: 'Respiratory Clinic', value: 'KHC - Respiratory Clinic', acronym: 'KHC', });
        items.push({ groupName: 'Killam: Killam Health Centre (KHC)', description: 'Spiritual care', value: 'KHC - Spiritual care', acronym: 'KHC', });
        items.push({ groupName: 'Killam: Killam Health Centre (KHC)', description: 'Staff', value: 'KHC - Staff', acronym: 'KHC', });
        items.push({ groupName: 'Lethbridge: Garry Station (GS)', description: 'Food services', value: 'GS - Food services', acronym: 'GS', });
        items.push({ groupName: 'Lethbridge: Garry Station (GS)', description: 'Staff', value: 'GS - Staff', acronym: 'GS', });
        items.push({ groupName: 'Lethbridge: Garry Station (GS)', description: 'Supportive living', value: 'GS - Supportive living', acronym: 'GS', });
        items.push({ groupName: 'Lethbridge: Martha\'s House (MH)', description: 'Food Services', value: 'MH - Food Services', acronym: 'MH', });
        items.push({ groupName: 'Lethbridge: Martha\'s House (MH)', description: 'Independent living', value: 'MH - Independent living', acronym: 'MH', });
        items.push({ groupName: 'Lethbridge: Martha\'s House (MH)', description: 'Staff', value: 'MH - Staff', acronym: 'MH', });
        items.push({ groupName: 'Lethbridge: St. Michael\'s Health Centre (SMHC)', description: 'Community day program', value: 'SMHC - Community day program', acronym: 'SMHC', });
        items.push({ groupName: 'Lethbridge: St. Michael\'s Health Centre (SMHC)', description: 'Continuing care', value: 'SMHC - Continuing care', acronym: 'SMHC', });
        items.push({ groupName: 'Lethbridge: St. Michael\'s Health Centre (SMHC)', description: 'Continuing care, complex mental health & addictions', value: 'SMHC - Continuing care, complex mental health & addictions', acronym: 'SMHC', });
        items.push({ groupName: 'Lethbridge: St. Michael\'s Health Centre (SMHC)', description: 'Health Information Management', value: 'SMHC - Health Information Management', acronym: 'SMHC', });
        items.push({ groupName: 'Lethbridge: St. Michael\'s Health Centre (SMHC)', description: 'Palliative Care', value: 'SMHC - Palliative Care', acronym: 'SMHC', });
        items.push({ groupName: 'Lethbridge: St. Michael\'s Health Centre (SMHC)', description: 'Post Acute Rehabilitation Program (PARP)', value: 'SMHC - Post Acute Rehabilitation Program (PARP)', acronym: 'SMHC', });
        items.push({ groupName: 'Lethbridge: St. Michael\'s Health Centre (SMHC)', description: 'Rehabilitation: Recreation therapy', value: 'SMHC - Rehabilitation: Recreation therapy', acronym: 'SMHC', });
        items.push({ groupName: 'Lethbridge: St. Michael\'s Health Centre (SMHC)', description: 'Spiritual care', value: 'SMHC - Spiritual care', acronym: 'SMHC', });
        items.push({ groupName: 'Lethbridge: St. Michael\'s Health Centre (SMHC)', description: 'Staff', value: 'SMHC - Staff', acronym: 'SMHC', });
        items.push({ groupName: 'Lethbridge: St. Michael\'s Health Centre (SMHC)', description: 'Supportive living', value: 'SMHC - Supportive living', acronym: 'SMHC', });
        items.push({ groupName: 'Lethbridge: St. Michael\'s Health Centre (SMHC)', description: 'Supportive living dementia', value: 'SMHC - Supportive living dementia', acronym: 'SMHC', });
        items.push({ groupName: 'Lethbridge: St. Therese Villa (STV)', description: 'Health Information Management', value: 'STV - Health Information Management', acronym: 'STV', });
        items.push({ groupName: 'Lethbridge: St. Therese Villa (STV)', description: 'Memory care', value: 'STV - Memory care', acronym: 'STV', });
        items.push({ groupName: 'Lethbridge: St. Therese Villa (STV)', description: 'Spiritual care', value: 'STV - Spiritual care', acronym: 'STV', });
        items.push({ groupName: 'Lethbridge: St. Therese Villa (STV)', description: 'Staff', value: 'STV - Staff', acronym: 'STV', });
        items.push({ groupName: 'Mundare: Mary Immaculate Care Centre (MICC)', description: 'Continuing care', value: 'MICC - Continuing care', acronym: 'MICC', });
        items.push({ groupName: 'Mundare: Mary Immaculate Care Centre (MICC)', description: 'Food/Nutrition Services', value: 'MICC - Food/Nutrition Services', acronym: 'MICC', });
        items.push({ groupName: 'Mundare: Mary Immaculate Care Centre (MICC)', description: 'Health Information Management', value: 'MICC - Health Information Management', acronym: 'MICC', });
        items.push({ groupName: 'Mundare: Mary Immaculate Care Centre (MICC)', description: 'Laboratory', value: 'MICC - Laboratory', acronym: 'MICC', });
        items.push({ groupName: 'Mundare: Mary Immaculate Care Centre (MICC)', description: 'Palliative Care', value: 'MICC - Palliative Care', acronym: 'MICC', });
        items.push({ groupName: 'Mundare: Mary Immaculate Care Centre (MICC)', description: 'Pharmacy', value: 'MICC - Pharmacy', acronym: 'MICC', });
        items.push({ groupName: 'Mundare: Mary Immaculate Care Centre (MICC)', description: 'Rehabilitation: Occupational Therapy', value: 'MICC - Rehabilitation: Occupational Therapy', acronym: 'MICC', });
        items.push({ groupName: 'Mundare: Mary Immaculate Care Centre (MICC)', description: 'Spiritual care', value: 'MICC - Spiritual care', acronym: 'MICC', });
        items.push({ groupName: 'Mundare: Mary Immaculate Care Centre (MICC)', description: 'Staff', value: 'MICC - Staff', acronym: 'MICC', });
        items.push({ groupName: 'Red Deer: Villa Marie (VM)', description: 'Continuing care', value: 'VM - Continuing care', acronym: 'VM', });
        items.push({ groupName: 'Red Deer: Villa Marie (VM)', description: 'Food/Nutrition Services', value: 'VM - Food/Nutrition Services', acronym: 'VM', });
        items.push({ groupName: 'Red Deer: Villa Marie (VM)', description: 'Memory care', value: 'VM - Memory care', acronym: 'VM', });
        items.push({ groupName: 'Red Deer: Villa Marie (VM)', description: 'Pharmacy', value: 'VM - Pharmacy', acronym: 'VM', });
        items.push({ groupName: 'Red Deer: Villa Marie (VM)', description: 'Staff', value: 'VM - Staff', acronym: 'VM', });
        items.push({ groupName: 'Red Deer: Villa Marie (VM)', description: 'Supportive living', value: 'VM - Supportive living', acronym: 'VM', });
        items.push({ groupName: 'St. Albert: Foyer Lacombe (FL)', description: 'Continuing care', value: 'FL - Continuing care', acronym: 'FL', });
        items.push({ groupName: 'St. Albert: Foyer Lacombe (FL)', description: 'Food/Nutrition services', value: 'FL - Food/Nutrition services', acronym: 'FL', });
        items.push({ groupName: 'St. Albert: Foyer Lacombe (FL)', description: 'Palliative care/Hospice', value: 'FL - Palliative care/Hospice', acronym: 'FL', });
        items.push({ groupName: 'St. Albert: Foyer Lacombe (FL)', description: 'Staff', value: 'FL - Staff', acronym: 'FL', });
        items.push({ groupName: 'St. Albert: Youville Home (YH)', description: 'Continuing care', value: 'YH - Continuing care', acronym: 'YH', });
        items.push({ groupName: 'St. Albert: Youville Home (YH)', description: 'Food/Nutrition Services', value: 'YH - Food/Nutrition Services', acronym: 'YH', });
        items.push({ groupName: 'St. Albert: Youville Home (YH)', description: 'Health Information Management', value: 'YH - Health Information Management', acronym: 'YH', });
        items.push({ groupName: 'St. Albert: Youville Home (YH)', description: 'Spiritual care', value: 'YH - Spiritual care', acronym: 'YH', });
        items.push({ groupName: 'St. Albert: Youville Home (YH)', description: 'Staff', value: 'YH - Staff', acronym: 'YH', });
        items.push({ groupName: 'Trochu: St. Mary\'s Health Care Centre (SMHCC)', description: 'Continuing Care', value: 'SMHCC - Continuing Care', acronym: 'SMHCC', });
        items.push({ groupName: 'Trochu: St. Mary\'s Health Care Centre (SMHCC)', description: 'Food/Nutrition Services', value: 'SMHCC - Food/Nutrition Services', acronym: 'SMHCC', });
        items.push({ groupName: 'Trochu: St. Mary\'s Health Care Centre (SMHCC)', description: 'Health Information Management', value: 'SMHCC - Health Information Management', acronym: 'SMHCC', });
        items.push({ groupName: 'Trochu: St. Mary\'s Health Care Centre (SMHCC)', description: 'Laboratory', value: 'SMHCC - Laboratory', acronym: 'SMHCC', });
        items.push({ groupName: 'Trochu: St. Mary\'s Health Care Centre (SMHCC)', description: 'Meals on wheels', value: 'SMHCC - Meals on wheels', acronym: 'SMHCC', });
        items.push({ groupName: 'Trochu: St. Mary\'s Health Care Centre (SMHCC)', description: 'Rehabilitation Medicine', value: 'SMHCC - Rehabilitation Medicine', acronym: 'SMHCC', });
        items.push({ groupName: 'Trochu: St. Mary\'s Health Care Centre (SMHCC)', description: 'Rehabilitation: Physiotherapy', value: 'SMHCC - Rehabilitation: Physiotherapy', acronym: 'SMHCC', });
        items.push({ groupName: 'Trochu: St. Mary\'s Health Care Centre (SMHCC)', description: 'Spiritual care', value: 'SMHCC - Spiritual care', acronym: 'SMHCC', });
        items.push({ groupName: 'Trochu: St. Mary\'s Health Care Centre (SMHCC)', description: 'Staff', value: 'SMHCC - Staff', acronym: 'SMHCC', });
        items.push({ groupName: 'Vegreville: St. Joseph\'s General Hospital (SJGH)', description: 'Ambulatory: Dietary/Nutritional counselling', value: 'SJGH - Ambulatory: Dietary/Nutritional counselling', acronym: 'SJGH', });
        items.push({ groupName: 'Vegreville: St. Joseph\'s General Hospital (SJGH)', description: 'Ambulatory: Podiatry Clinic', value: 'SJGH - Ambulatory: Podiatry Clinic', acronym: 'SJGH', });
        items.push({ groupName: 'Vegreville: St. Joseph\'s General Hospital (SJGH)', description: 'Ambulatory: Respiratory Therapy', value: 'SJGH - Ambulatory: Respiratory Therapy', acronym: 'SJGH', });
        items.push({ groupName: 'Vegreville: St. Joseph\'s General Hospital (SJGH)', description: 'Ambulatory: Swallowing Assessment Unit', value: 'SJGH - Ambulatory: Swallowing Assessment Unit', acronym: 'SJGH', });
        items.push({ groupName: 'Vegreville: St. Joseph\'s General Hospital (SJGH)', description: 'Ambulatory: Well Woman Clinic', value: 'SJGH - Ambulatory: Well Woman Clinic', acronym: 'SJGH', });
        items.push({ groupName: 'Vegreville: St. Joseph\'s General Hospital (SJGH)', description: 'Cardiac Sciences: Cardiac rehabilitation program', value: 'SJGH - Cardiac Sciences: Cardiac rehabilitation program', acronym: 'SJGH', });
        items.push({ groupName: 'Vegreville: St. Joseph\'s General Hospital (SJGH)', description: 'Cardiac Sciences: Cardiac Stress Testing Clinic', value: 'SJGH - Cardiac Sciences: Cardiac Stress Testing Clinic', acronym: 'SJGH', });
        items.push({ groupName: 'Vegreville: St. Joseph\'s General Hospital (SJGH)', description: 'Cardiac Sciences: Cardiology', value: 'SJGH - Cardiac Sciences: Cardiology', acronym: 'SJGH', });
        items.push({ groupName: 'Vegreville: St. Joseph\'s General Hospital (SJGH)', description: 'Diagnostics: Imaging', value: 'SJGH - Diagnostics: Imaging', acronym: 'SJGH', });
        items.push({ groupName: 'Vegreville: St. Joseph\'s General Hospital (SJGH)', description: 'Diagnostics: Ultrasound', value: 'SJGH - Diagnostics: Ultrasound', acronym: 'SJGH', });
        items.push({ groupName: 'Vegreville: St. Joseph\'s General Hospital (SJGH)', description: 'Dietary/Nutritional Services', value: 'SJGH - Dietary/Nutritional Services', acronym: 'SJGH', });
        items.push({ groupName: 'Vegreville: St. Joseph\'s General Hospital (SJGH)', description: 'Emergency', value: 'SJGH - Emergency', acronym: 'SJGH', });
        items.push({ groupName: 'Vegreville: St. Joseph\'s General Hospital (SJGH)', description: 'Geriatrics', value: 'SJGH - Geriatrics', acronym: 'SJGH', });
        items.push({ groupName: 'Vegreville: St. Joseph\'s General Hospital (SJGH)', description: 'Health Information Management', value: 'SJGH - Health Information Management', acronym: 'SJGH', });
        items.push({ groupName: 'Vegreville: St. Joseph\'s General Hospital (SJGH)', description: 'Hemodialysis - Northern AB Renal Program', value: 'SJGH - Hemodialysis - Northern AB Renal Program', acronym: 'SJGH', });
        items.push({ groupName: 'Vegreville: St. Joseph\'s General Hospital (SJGH)', description: 'Laboratory', value: 'SJGH - Laboratory', acronym: 'SJGH', });
        //items.push({ groupName: 'Vegreville: St. Joseph\'s General Hospital (SJGH)', description: 'Medical affairs (physicians)', value: 'SJGH - Medical affairs (physicians)', acronym: 'SJGH', });
        items.push({ groupName: 'Vegreville: St. Joseph\'s General Hospital (SJGH)', description: 'Medicine', value: 'SJGH - Medicine', acronym: 'SJGH', });
        items.push({ groupName: 'Vegreville: St. Joseph\'s General Hospital (SJGH)', description: 'Nutritional Counselling', value: 'SJGH - Nutritional Counselling', acronym: 'SJGH', });
        items.push({ groupName: 'Vegreville: St. Joseph\'s General Hospital (SJGH)', description: 'Palliative Care', value: 'SJGH - Palliative Care', acronym: 'SJGH', });
        items.push({ groupName: 'Vegreville: St. Joseph\'s General Hospital (SJGH)', description: 'Pediatric Clinic', value: 'SJGH - Pediatric Clinic', acronym: 'SJGH', });
        items.push({ groupName: 'Vegreville: St. Joseph\'s General Hospital (SJGH)', description: 'Pharmacy', value: 'SJGH - Pharmacy', acronym: 'SJGH', });
        items.push({ groupName: 'Vegreville: St. Joseph\'s General Hospital (SJGH)', description: 'Primary Care Network', value: 'SJGH - Primary Care Network', acronym: 'SJGH', });
        items.push({ groupName: 'Vegreville: St. Joseph\'s General Hospital (SJGH)', description: 'Rehabilitation: Occupational Therapy', value: 'SJGH - Rehabilitation: Occupational Therapy', acronym: 'SJGH', });
        items.push({ groupName: 'Vegreville: St. Joseph\'s General Hospital (SJGH)', description: 'Rehabilitation: Physical Therapy', value: 'SJGH - Rehabilitation: Physical Therapy', acronym: 'SJGH', });
        items.push({ groupName: 'Vegreville: St. Joseph\'s General Hospital (SJGH)', description: 'Respiratory', value: 'SJGH - Respiratory', acronym: 'SJGH', });
        items.push({ groupName: 'Vegreville: St. Joseph\'s General Hospital (SJGH)', description: 'Spiritual care', value: 'SJGH - Spiritual care', acronym: 'SJGH', });
        items.push({ groupName: 'Vegreville: St. Joseph\'s General Hospital (SJGH)', description: 'Staff', value: 'SJGH - Staff', acronym: 'SJGH', });
        items.push({ groupName: '', description: 'Medical Affairs/Physicians', value: 'COVH Corp - Medical Affairs/Physicians', acronym: 'COVH Corp', });
        items.push({ groupName: '', description: 'Community respite care', value: 'SJH - Community respite care', acronym: 'SJH', });
        items.push({ groupName: '', description: 'Hospice', value: 'SJH - Hospice', acronym: 'SJH', });
        items.push({ groupName: '', description: 'In-home palliative bed program', value: 'SJH - In-home palliative bed program', acronym: 'SJH', });
        items.push({ groupName: '', description: 'Palliative care', value: 'SJH - Palliative care', acronym: 'SJH', });
        items.push({ groupName: '', description: 'Staff', value: 'SJH - Staff', acronym: 'SJH', });
        items.push({ groupName: 'Medicine Hat: St. Joseph\'s Home for the Aged (SJHA)', description: 'Health Information Management', value: 'SJHA - Health Information Management', acronym: 'SJHA', });
        items.push({ groupName: 'Medicine Hat: St. Joseph\'s Home for the Aged (SJHA)', description: 'Palliative Care', value: 'SJHA - Palliative Care', acronym: 'SJHA', });
        items.push({ groupName: 'Medicine Hat: St. Joseph\'s Home for the Aged (SJHA)', description: 'Staff', value: 'SJHA - Staff', acronym: 'SJHA', });
        return items;
    }

    private _setStudyLocationsParticipating() {
        const items: IStudyLocationParticipating[] = [];
        items.push({ groupName: 'Banff', description: 'Mineral Springs Hospital (MSH)', value: 'Banff: Mineral Springs Hospital (MSH)', acronym: 'MSH', acuteCareBed: true, continuingCareBed: true, independentLiving: false, });
        items.push({ groupName: 'Beaumont', description: 'Chateau Vitaline (CV) - Covenant Care', value: 'Beaumont: Chateau Vitaline (CV)', acronym: 'CV', acuteCareBed: false, continuingCareBed: true, independentLiving: false, });
        items.push({ groupName: 'Bonnyville', description: 'Bonnyville Health Centre (BHC)', value: 'Bonnyville: Bonnyville Health Centre (BHC)', acronym: 'BHC', acuteCareBed: true, continuingCareBed: true, independentLiving: false, });
        items.push({ groupName: 'Calgary', description: 'Evanston Summit (ES) - Covenant Living', value: 'Calgary: Evanston Summit (ES)', acronym: 'ES', acuteCareBed: false, continuingCareBed: false, independentLiving: true, });
        items.push({ groupName: 'Calgary', description: 'Holy Cross Manor (HCM) - Covenant Care', value: 'Calgary: Holy Cross Manor (HCM)', acronym: 'HCM', acuteCareBed: false, continuingCareBed: true, independentLiving: false, });
        items.push({ groupName: 'Calgary', description: 'St. Marguerite Manor (SMM) - Covenant Care', value: 'Calgary: St. Marguerite Manor (SMM)', acronym: 'SMM', acuteCareBed: false, continuingCareBed: true, independentLiving: false, });
        items.push({ groupName: 'Calgary', description: 'St. Teresa Place (STP) - Covenant Care', value: 'Calgary: St. Teresa Place (STP)', acronym: 'STP', acuteCareBed: false, continuingCareBed: true, independentLiving: false, });
        items.push({ groupName: 'Camrose', description: 'St. Mary\'s Hospital (SMH)', value: 'Camrose: St. Mary\'s Hospital (SMH)', acronym: 'SMH', acuteCareBed: true, continuingCareBed: false, independentLiving: false, });
        items.push({ groupName: 'Castor', description: 'Our Lady of the Rosary Hospital (OLRH)', value: 'Castor: Our Lady of the Rosary Hospital (OLRH)', acronym: 'OLRH', acuteCareBed: true, continuingCareBed: true, independentLiving: false, });
        items.push({ groupName: 'Edmonton', description: 'Centre de santé St-Thomas (CSST) - Covenant Care', value: 'Edmonton: Centre de santé St-Thomas (CSST)', acronym: 'CSST', acuteCareBed: false, continuingCareBed: false, independentLiving: true, });
        items.push({ groupName: 'Edmonton', description: 'Covenant Wellness Community (CWC)', value: 'Edmonton: Covenant Wellness Community (CWC)', acronym: 'CWC', acuteCareBed: false, continuingCareBed: true, independentLiving: true, });
        items.push({ groupName: 'Edmonton', description: 'Edmonton General Continuing Care Centre (EGCCC)', value: 'Edmonton: Edmonton General Continuing Care Centre (EGCCC)', acronym: 'EGCCC', acuteCareBed: false, continuingCareBed: true, independentLiving: false, });
        items.push({ groupName: 'Edmonton', description: 'Grey Nuns Community Hospital (GNCH)', value: 'Edmonton: Grey Nuns Community Hospital (GNCH)', acronym: 'GNCH', acuteCareBed: true, continuingCareBed: false, independentLiving: false, });
        items.push({ groupName: 'Edmonton', description: 'Misericordia Community Hospital (MCH)', value: 'Edmonton: Misericordia Community Hospital (MCH)', acronym: 'MCH', acuteCareBed: true, continuingCareBed: false, independentLiving: false, });
        items.push({ groupName: 'Edmonton', description: 'St. Joseph\'s Auxiliary Hospital (SJAH)', value: 'Edmonton: St. Joseph\'s Auxiliary Hospital (SJAH)', acronym: 'SJAH', acuteCareBed: false, continuingCareBed: true, independentLiving: false, });
        items.push({ groupName: 'Edmonton', description: 'Teaching and Research Continuing Care Centre (TRCCC)', value: 'Edmonton: placeholder (TRCCC)', acronym: 'TRCCC', acuteCareBed: false, continuingCareBed: true, independentLiving: true, });
        items.push({ groupName: 'Edmonton', description: 'Villa Caritas (VC)', value: 'Edmonton: Villa Caritas (VC)', acronym: 'VC', acuteCareBed: true, continuingCareBed: false, independentLiving: false, });
        items.push({ groupName: 'Killam', description: 'Killam Health Centre (KHC)', value: 'Killam: Killam Health Centre (KHC)', acronym: 'KHC', acuteCareBed: true, continuingCareBed: true, independentLiving: false, });
        items.push({ groupName: 'Lethbridge', description: 'Garry Station (GS) - Covenant Care', value: 'Lethbridge: Garry Station (GS)', acronym: 'GS', acuteCareBed: false, continuingCareBed: true, independentLiving: false, });
        items.push({ groupName: 'Lethbridge', description: 'Martha\'s House (MH) - Covenant Living', value: 'Lethbridge: Martha\'s House (MH)', acronym: 'MH', acuteCareBed: false, continuingCareBed: false, independentLiving: true, });
        items.push({ groupName: 'Lethbridge', description: 'St. Michael\'s Health Centre (SMHC)', value: 'Lethbridge: St. Michael\'s Health Centre (SMHC)', acronym: 'SMHC', acuteCareBed: false, continuingCareBed: true, independentLiving: false, });
        items.push({ groupName: 'Lethbridge', description: 'St. Therese Villa (STV)', value: 'Lethbridge: St. Therese Villa (STV)', acronym: 'STV', acuteCareBed: false, continuingCareBed: true, independentLiving: false, });
        items.push({ groupName: 'Medicine Hat', description: 'St. Joseph\'s Home for the Aged (SJHA)', value: 'Medicine Hat: St. Joseph\'s Home for the Aged (SJHA)', acronym: 'SJHA', acuteCareBed: false, continuingCareBed: true, independentLiving: false, });
        items.push({ groupName: 'Mundare', description: 'Mary Immaculate Care Centre (MICC)', value: 'Mundare: Mary Immaculate Care Centre (MICC)', acronym: 'MICC', acuteCareBed: false, continuingCareBed: true, independentLiving: false, });
        items.push({ groupName: 'Red Deer', description: 'Villa Marie (VM) - Covenant Care', value: 'Red Deer: Villa Marie (VM)', acronym: 'VM', acuteCareBed: false, continuingCareBed: true, independentLiving: false, });
        items.push({ groupName: 'St. Albert', description: 'Foyer Lacombe (FL) - Covenant Care', value: 'St. Albert: Foyer Lacombe (FL)', acronym: 'FL', acuteCareBed: false, continuingCareBed: true, independentLiving: false, });
        items.push({ groupName: 'St. Albert', description: 'Youville Home (YH)', value: 'St. Albert: Youville Home (YH)', acronym: 'YH', acuteCareBed: false, continuingCareBed: true, independentLiving: false, });
        items.push({ groupName: 'Trochu', description: 'St. Mary\'s Health Care Centre (SMHCC)', value: 'Trochu: St. Mary\'s Health Care Centre (SMHCC)', acronym: 'SMHCC', acuteCareBed: false, continuingCareBed: true, independentLiving: false, });
        items.push({ groupName: 'Vegreville', description: 'St. Joseph\'s General Hospital (SJGH)', value: 'Vegreville: St. Joseph\'s General Hospital (SJGH)', acronym: 'SJGH', acuteCareBed: true, continuingCareBed: false, independentLiving: false, });
        items.push({ groupName: '', description: 'I need assistance in choosing sites', value: 'I need assistance in choosing sites', acronym: '', acuteCareBed: false, continuingCareBed: false, independentLiving: false, });
        items.push({ groupName: '', description: 'ALL Acute Care sites', value: 'ALL Covenant Health sites (COV-H)', acronym: 'COV-H', acuteCareBed: true, continuingCareBed: false, independentLiving: false, });
        items.push({ groupName: '', description: 'ALL Continuing Care sites', value: 'ALL Covenant Care sites (COV-C)', acronym: 'COV-C', acuteCareBed: false, continuingCareBed: true, independentLiving: false, });
        items.push({ groupName: '', description: 'ALL Independent Living sites', value: 'ALL Covenant Living sites (COV-L)', acronym: 'COV-L', acuteCareBed: false, continuingCareBed: false, independentLiving: true, });
        items.push({ groupName: '', description: 'COV Corporate', value: 'ALL Covenant Corporate (COVCorp)', acronym: 'COVCorp', acuteCareBed: true, continuingCareBed: true, independentLiving: true, });
        return items;
    }

    private _setStudyReviewedByChoices() {
        const choices: IChoiceGroupOption[] = [];
        const style: IChoiceGroupOptionStyles = {
            root: {
                alignItems: 'flex-start',
                width: 380,
            },
        };
        choices.push({ key: "Conjoint Health Research Ethics Board (CHREB)", text: "Conjoint Health Research Ethics Board (CHREB)", styles: style });
        choices.push({ key: "Health Research Ethics Board (HREB)", text: "Health Research Ethics Board (HREB)", styles: style });
        choices.push({ key: "Health Research Ethics Board of Alberta (HREBA)", text: "Health Research Ethics Board of Alberta (HREBA)", styles: style });
        choices.push({ key: "Other Canadian REB (access advertising for participation only)", text: "Other Canadian REB (access advertising for participation only)", styles: style });
        return choices;
    }

    private _setStudyDatabasesChoices() {
        const choices: ISelectableOptionWithTooltip[] = [];
        choices.push({ key: "Connect Care", text: "Connect Care", });
        choices.push({ key: "Netcare", text: "Netcare", });
        choices.push({ key: "PAX", text: "PAX", });
        choices.push({ key: "Other", text: "Other (Please specify)", });
        return choices;
    }

    private _setProjectInvolveChartReviewChoices() {
        const choices: IChoiceGroupOption[] = [];
        choices.push({ key: "Yes", text: "Yes", });
        choices.push({ key: "No", text: "No", });
        return choices;
    }

    private _setStudyLocationsOtherChoices() {
        const choices: IChoiceGroupOption[] = [];
        choices.push({ key: "No", text: "No", });
        choices.push({ key: "Yes", text: "Yes", });
        return choices;
    }

    private _setDatabasePurposeColumnAChoices() {
        const choices: IChoiceGroupOption[] = [];
        choices.push({
            key: "Interventional Research", text: "Interventional Research", onRenderField: (props, render) => {
                return this._renderChoiceGroupOptionTooltip(props, render, "Any research project that prospectively assigns human subjects to intervention and comparison groups to study the cause-and-effect relationship between a medical intervention and a health outcome.");
            }
        });
        choices.push({
            key: "Observational Research", text: "Observational Research", onRenderField: (props, render) => {
                return this._renderChoiceGroupOptionTooltip(props, render, "A type of study in which individuals are observed or certain outcomes are measured. No attempt is made to affect the outcome (for example, no treatment is given).");
            }
        });
        choices.push({ key: "Sequel (study#)", text: "Sequel Study Number (please specify)", });
        return choices;
    }

    private _setDatabasePurposeColumnBChoices() {
        const choices: IChoiceGroupOption[] = [];
        choices.push({
            key: "Chart Review", text: "Chart Review", onRenderField: (props, render) => {
                return this._renderChoiceGroupOptionTooltip(props, render, "A retrospective method of collecting data that involves reviewing medical records.");
            }
        });
        choices.push({
            key: "Clinical Trial", text: "Clinical Trial", onRenderField: (props, render) => {
                return this._renderChoiceGroupOptionTooltip(props, render, "Any investigation in human subjects intended to discover or verify the clinical, pharmacological, and/or other pharmaco-dynamic effects of an investigational product, and/or to identify any adverse reactions to an investigational product(s), and/or study absorption, distribution, metabolism, and excretion of an investigational product(s) with the object of ascertaining its safety and/or efficacy. The terms clinical trial and clinical study are synonymous.");
            }
        });
        choices.push({
            key: "Epidemiological Study", text: "Epidemiological Study", onRenderField: (props, render) => {
                return this._renderChoiceGroupOptionTooltip(props, render, "A study of the patterns of determinants and antecedents of disease in human populations utilizing biology, clinical medicine, and statistics in an effort to understand the etiology (causes) of illness and/or disease.");
            }
        });
        choices.push({
            key: "Multi-Centre Trial", text: "Multi-Centre Trial", onRenderField: (props, render) => {
                return this._renderChoiceGroupOptionTooltip(props, render, "A single trial conducted according to a single protocol but at more than one site.");
            }
        });
        choices.push({
            key: "Pilot Study", text: "Pilot Study", onRenderField: (props, render) => {
                return this._renderChoiceGroupOptionTooltip(props, render, "A small scale preliminary study conducted before the main research, in order to check the feasibility or to improve the design of the research.");
            }
        });
        choices.push({
            key: "Qualitative Study", text: "Qualitative Study", onRenderField: (props, render) => {
                return this._renderChoiceGroupOptionTooltip(props, render, "Investigative methodologies described as ethnographic, naturalistic, anthropological, field, or participant observer research. It emphasizes the importance of looking at variables in the natural setting in which they are found. This differs from quantitative research which attempts to gather data by objective methods to provide information about relations, comparisons, and predictions and attempts to remove the investigator from the investigation.");
            }
        });
        choices.push({
            key: "Technology Assessment / Development", text: "Technology Assessment / Development", onRenderField: (props, render) => {
                return this._renderChoiceGroupOptionTooltip(props, render, "Technology assessment is hereby defined as any clinical research studying or evaluating new technologies (for example; medical equipment, biologic specimen/sample or testing with no direct patient contact). Biologic specimens and samples are/can be obtained from human subjects.");
            }
        });
        return choices;
    }

    private _setResearchContextChoices() {
        const choices: IChoiceGroupOption[] = [];
        choices.push({ key: "Urban", text: "Urban", });
        choices.push({ key: "Rural", text: "Rural", });
        choices.push({ key: "All", text: "All", });
        return choices;
    }

    private _setResearchFocusPerspectiveChoices() {
        const choices: IChoiceGroupOption[] = [];
        choices.push({ key: "Capacity Building", text: "Capacity Building", });
        choices.push({ key: "Professional Practice", text: "Professional Practice", });
        choices.push({ key: "Patient-centered", text: "Patient-centered", });
        choices.push({ key: "Systems-centered", text: "Systems-centered", });
        choices.push({ key: "Prevention", text: "Prevention", });
        choices.push({ key: "Innovation", text: "Innovation", });
        return choices;
    }

    private _setServiceAreaChoices() {
        const choices: IChoiceGroupOption[] = [];
        choices.push({ key: "Acute", text: "Acute", });
        choices.push({ key: "Community", text: "Community", });
        choices.push({ key: "Sub-acute", text: "Sub-acute", });
        choices.push({ key: "Palliative", text: "Palliative", });
        choices.push({ key: "CC or DAL or AL", text: "CC or DAL or AL", });
        choices.push({ key: "Rehab", text: "Rehab", });
        return choices;
    }

    private _setTherapeuticDepartmentChoices() {
        const choices: IChoiceGroupOption[] = [];
        choices.push({ key: "Addictions & Mental Health", text: "Addictions & Mental Health", });
        choices.push({ key: "Anesthesia", text: "Anesthesia", });
        choices.push({ key: "Cardiac Services", text: "Cardiac Services", });
        choices.push({ key: "Child Health", text: "Child Health", });
        choices.push({ key: "Corporate", text: "Corporate", });
        choices.push({ key: "Dental/Oral Health", text: "Dental/Oral Health", });
        choices.push({ key: "Devices", text: "Devices", });
        choices.push({ key: "Diagnostic Imaging", text: "Diagnostic Imaging", });
        choices.push({ key: "Family Health", text: "Family Health", });
        choices.push({ key: "Geriatrics", text: "Geriatrics", });
        choices.push({ key: "ICU", text: "ICU", });
        choices.push({ key: "Laboratory", text: "Laboratory", });
        choices.push({ key: "Medicine", text: "Medicine", });
        choices.push({ key: "Palliative Care", text: "Palliative Care", });
        choices.push({ key: "Podiatry", text: "Podiatry", });
        choices.push({ key: "Practice", text: "Practice", });
        choices.push({ key: "Surgery", text: "Surgery", });
        choices.push({ key: "Women's Health", text: "Women's Health", });
        return choices;
    }

    private _setTherapeuticDepartmentSectorChoices() {
        const choices: IChoiceGroupOption[] = [];
        choices.push({ key: "Addictions & Mental Health - Psychiatry (adult)", text: "Psychiatry (adult)", });
        choices.push({ key: "Addictions & Mental Health - Psychiatry (child)", text: "Psychiatry (child)", });
        choices.push({ key: "Addictions & Mental Health - Psychiatry (geriatric)", text: "Psychiatry (geriatric)", });
        choices.push({ key: "Anesthesia - Anesthesia", text: "Anesthesia", });
        choices.push({
            key: "Cardiac Services - Cardiology/Vascular diseases", text: "Cardiology/Vascular diseases", onRenderField: (props, render) => {
                return this._renderChoiceGroupOptionTooltip(props, render, "Diseases having to do with the structure and function of the heart and blood vessels. Studies in these areas include: heart failure, coronary artery disease, high cholesterol, blood clots, circulation disorders, and others*");
            }
        });
        choices.push({ key: "Child Health - Neonatal-Perinatal medicine", text: "Neonatal-Perinatal medicine", });
        choices.push({ key: "Child Health - Pediatrics", text: "Pediatrics", });
        choices.push({ key: "Corporate - Innovation Project", text: "Innovation project", });
        choices.push({ key: "Corporate - organization business project", text: "Organization business project", });
        choices.push({ key: "Corporate - Staff personal post-secondary project", text: "Staff personal post-secondary project", });
        choices.push({ key: "Dental/Oral Health - Dental & Oral Health", text: "Dental/Oral Health", });
        choices.push({ key: "Devices - Cardiology", text: "Cardiology", });
        choices.push({ key: "Devices - Speech Pathology & Audiology", text: "Speech Pathology & Audiology", });
        choices.push({ key: "Diagnostic Imaging - Nuclear Medicine", text: "Nuclear Medicine", });
        choices.push({ key: "Diagnostic Imaging - Radiology Diagnostic", text: "Radiology Diagnostic", });
        choices.push({ key: "Family Health - Anesthesia", text: "Anesthesia", });
        choices.push({ key: "Family Health - Emergency Medicine", text: "Emergency Medicine", });
        choices.push({ key: "Family Health - Endocrinology & Metabolism", text: "Endocrinology & Metabolism", });
        choices.push({ key: "Family Health - Family Practice", text: "Family Practice", });
        choices.push({ key: "Family Health - Family Practice with Obstetrics", text: "Family Practice w/ Obstetrics", });
        choices.push({ key: "Family Health - Infectious Diseases", text: "Infectious Diseases", });
        choices.push({ key: "Family Health - Obstetrics & Gynecology", text: "Obstetrics & Gynecology", });
        choices.push({ key: "Family Health - Palliative Care (Family Health)", text: "Palliative Care (Family Health)", });
        choices.push({ key: "Family Health - Patient-centered Health Team", text: "Patient-centered Health Team", });
        choices.push({ key: "Geriatrics - Geriatric Medicine", text: "Geriatric Medicine", });
        choices.push({ key: "Geriatrics - Geriatrics (Family Health)", text: "Geriatrics (Family Health)", });
        choices.push({ key: "ICU - Critical Care Medicine", text: "Critical Care Medicine", });
        choices.push({ key: "Laboratory - Hematopathology", text: "Hematopathology", });
        choices.push({ key: "Laboratory - Laboratory Medicine General", text: "Laboratory Medicine General", });
        choices.push({ key: "Laboratory - Pathology", text: "Pathology, Anatomical", });
        choices.push({ key: "Laboratory - Pathology, General", text: "Pathology, General", });
        choices.push({ key: "Medicine - Clinical Immunology & Allergy", text: "Clinical Immunology & Allergy", });
        choices.push({
            key: "Medicine - Endocrinology & Metabolism", text: "Endocrinology & Metabolism", onRenderField: (props, render) => {
                return this._renderChoiceGroupOptionTooltip(props, render, "Field relating to hormone-manufacturing glands such as the pituitary, thyroid, parathyroid, and adrenal glands, as well as the ovary and testis, the placenta, and the pancreas. Studies in this area include: diabetes and diabetes-related disorders, diet and nutrition, hormone-replacement therapy, menopause, obesity, and others.");
            }
        });
        choices.push({
            key: "Medicine - Gastroenterology", text: "Gastroenterology", onRenderField: (props, render) => {
                return this._renderChoiceGroupOptionTooltip(props, render, "The study of gastrointestinal organs and diseases relating to them. This includes any part of the digestive tract from mouth to anus, liver, biliary tract, and the pancreas. Studies in this area include: constipation, Crohn's diseases, diarrhea, gall bladder disease, heartburn, hemorrhoids, irritable Bowel Syndrome (IBS), ulcers, liver disease, stomach cancer, and others.");
            }
        });
        choices.push({ key: "Medicine - Infectious Diseases", text: "Infectious Diseases", });
        choices.push({ key: "Medicine - Internal Medicine", text: "Internal Medicine", });
        choices.push({
            key: "Medicine - Nephrology/Urology", text: "Nephrology/Urology", onRenderField: (props, render) => {
                return this._renderChoiceGroupOptionTooltip(props, render, "The studies and the treatment of diseases of the kidney and the urinary tract. Studies in these areas include: bladder cancer, impotence, kidney disease, kidney stones, mastectomy, nocturia, renal cell carcinoma, urinary tract infections, and others.");
            }
        });
        choices.push({ key: "Medicine - Neurology", text: "Neurology", });
        choices.push({ key: "Medicine - Physical Medicine & Rehab", text: "Physical Medicine & Rehab", });
        choices.push({ key: "Medicine - Respiratory/Pulmonary", text: "Respiratory/Pulmonary", });
        choices.push({ key: "Medicine - Rheumatology", text: "Rheumatology", });
        choices.push({ key: "Palliative Care - Genetics", text: "Genetics", });
        choices.push({ key: "Palliative Care - Palliative Care", text: "Palliative Care", });
        choices.push({ key: "Podiatry - Podiatry", text: "Podiatry", });
        choices.push({ key: "Practice - Allied Health", text: "Allied Health", });
        choices.push({ key: "Practice - Nursing", text: "Nursing", });
        choices.push({ key: "Practice - Physicians", text: "Physicians", });
        choices.push({ key: "Surgery - Maxillofacial Prosthodontics (Dent.)", text: "Maxillofacial Prosthodontics (Dent.)", });
        choices.push({ key: "Surgery - Obstetrics & Gynecology", text: "Obstetrics & Gynecology", });
        choices.push({
            key: "Surgery - Oral & Maxillofacial (Dent.)", text: "Oral & Maxillofacial (Dent.)", onRenderField: (props, render) => {
                return this._renderChoiceGroupOptionTooltip(props, render, "Surgery relating to the teeth, jaw, face and its structures. Studies in these areas include: acute and chronic dental pain, oral cavity cancer, oral facial pain, oral medicine, and saliva and salivary gland dysfunction.");
            }
        });
        choices.push({
            key: "Surgery - Otolaryngology", text: "Otolaryngology", onRenderField: (props, render) => {
                return this._renderChoiceGroupOptionTooltip(props, render, "Also known as ENT (ears, nose, and throat); this is the study of diseases involving the ears and the larynx. Studies in this area include: allergy, ear infections, pneumonia, rhinitis, sinus infections, strep throat, and others.");
            }
        });
        choices.push({ key: "Surgery - Podiatry", text: "Podiatry", });
        choices.push({ key: "Surgery - Surgery, General", text: "Surgery, General", });
        choices.push({ key: "Surgery - Surgery, Orthopedic", text: "Surgery, Orthopedic", });
        choices.push({
            key: "Surgery - Surgery, Plastic", text: "Surgery, Plastic", onRenderField: (props, render) => {
                return this._renderChoiceGroupOptionTooltip(props, render, "Fields concerned with skin disorders and reconstruction or replacement of deformed, damaged, or lost parts of the body. Also concerns cosmetic surgery. Studies in these areas include: acne, congenital skin diseases, genital herpes, genital warts, liposuction, psoriasis, skin wounds, athlete's foot, venous leg ulcers, and others.");
            }
        });
        choices.push({ key: "Surgery - Surgery, Vascular", text: "Surgery, Vascular", });
        choices.push({ key: "Surgery - Urology", text: "Urology", });
        choices.push({
            key: "Women's Health - Obstetrics & Gynecology", text: "Obstetrics & Gynecology", onRenderField: (props, render) => {
                return this._renderChoiceGroupOptionTooltip(props, render, "Research pertaining to the care of women during pregnancy and childbirth, as well as to the study of the women’s reproductive system in general.  Studies in these areas include: contraception, hormone- replacement therapy, menopause, menstrual disorders, ovarian cysts, PMS, pregnancy/labor/delivery, yeast infections, and other.");
            }
        });
        choices.push({ key: "Women's Health - Urogynecology", text: "Uro-gynecology", });
        return choices;
    }

    private _setPICovenantHealthPrivilegesChoices() {
        const choices: IChoiceGroupOption[] = [];
        choices.push({ key: "Yes", text: "Yes", });
        choices.push({ key: "No", text: "No", });
        return choices;
    }

    private _renderChoiceGroupOptionTooltip(props: IChoiceGroupOptionProps | undefined, render: ((props?: IChoiceGroupOptionProps | undefined) => JSX.Element | null) | undefined, tooltipText: string): JSX.Element | null {
        return <div>
            {render!(props)}
            <TooltipHost
                styles={{ root: { marginLeft: '8px', verticalAlign: 'middle' } }}
                content={tooltipText}
                tooltipProps={{
                    styles: {
                        content: { fontWeight: '600' }
                    }
                }}>
                <Icon iconName="Info" />
            </TooltipHost>
        </div>;
    }

    private _filterTherapeuticDepartmentSectorChoices = (): IChoiceGroupOption[] => {
        const choices: IChoiceGroupOption[] = [];
        const formItem = this.state.formItem as FormCovenantHealthRequestResource;
        const sectorChoices = this.therapeuticDepartmentSectorChoices;
        for (const sectorChoice of sectorChoices) {
            if (formItem.therapeuticDepartment.length > 0 && sectorChoice.key.indexOf(formItem.therapeuticDepartment) === 0) {
                choices.push(sectorChoice);
            }
        }
        if (formItem.therapeuticDepartmentSector.length > 0) {
            const index = choices.findIndex(choice => choice.key === formItem.therapeuticDepartmentSector);
            if (index === -1) {
                formItem.therapeuticDepartmentSector = "";
                this.setState({
                    formItem: formItem,
                });
            }
        }
        return choices
    }

    private _setDatabasePurposeDisciplinesChoices() {
        const choices: ISelectableOptionWithTooltip[] = [];
        choices.push({ key: "Physicians including specialists", text: "Physicians including specialists", });
        choices.push({ key: "Dieticians", text: "Dieticians", });
        choices.push({ key: "Lab Technicians", text: "Lab Technicians", });
        choices.push({ key: "Nurses", text: "Nurses", });
        choices.push({ key: "Nurse Practitioners", text: "Nurse Practitioners", });
        choices.push({ key: "Occupational Therapists", text: "Occupational Therapists", });
        choices.push({ key: "Pharmacists", text: "Pharmacists", });
        choices.push({ key: "Physiotherapists", text: "Physiotherapists", });
        choices.push({ key: "Radiologic Technologists", text: "Radiologic Technologists", });
        choices.push({ key: "Respiratory Therapists", text: "Respiratory Therapists", });
        choices.push({ key: "Social Worker", text: "Social Worker", });
        choices.push({ key: "Speech Pathologist", text: "Speech Pathologist", });
        return choices;
    }

    private _setStrategicObjectivesServeChoices() {
        const choices: ISelectableOptionWithTooltip[] = [];
        choices.push({ key: "S1 People", text: "S1 People", tooltipText: "We will attract, engage, develop and retain mission-driven employees, physicians and volunteers and support them in discovering and deepening their own calling.", });
        choices.push({ key: "S2 Quality", text: "S2 Quality", tooltipText: "We will provide safe, high quality, person-centered care across the continuum of services.", });
        choices.push({ key: "S3 Resources", text: "S3 Resources", tooltipText: "We will utilize and enhance information, infrastructure and financial resources to their fullest benefit.", });
        return choices;
    }

    private _setStrategicObjectivesTransfChoices() {
        const choices: ISelectableOptionWithTooltip[] = [];
        choices.push({ key: "T1 Innovation", text: "T1 Innovation", tooltipText: "We will transform care for those with addiction and mental illness to enable them to live with dignity, hope as connected members of their community.", });
        choices.push({ key: "T2 Acute and Community Care Optimization", text: "T2 Acute and Community Care Optimization", tooltipText: "We will be leaders and advocates for robust palliative and end of life care as an essential part of an integrated health system.", });
        choices.push({ key: "T3 Seniors", text: "T3 Seniors", tooltipText: "We will optimize care and service in acute care and community, responding to future needs and changing demographics.", });
        choices.push({ key: "T4 Palliative and End of Life Care", text: "T4 Palliative and End of Life Care", tooltipText: "We will champion a future where seniors and their care givers live well, feel connected, and have a sense of fulfillment every day.", });
        choices.push({ key: "T5 Addiction and Mental Health", text: "T5 Addiction and Mental Health", tooltipText: "We will transform care for those with addiction and mental illness to enable them to live with dignity, hope as connected members of their community.", });
        return choices;
    }

    private _setStrategicObjectivesContribChoices() {
        const choices: ISelectableOptionWithTooltip[] = [];
        choices.push({ key: "C1 Mission", text: "C1 Mission", tooltipText: "We will be leaders and partners acting as catalysts for transformation in order to meet the needs of those we serve.", });
        choices.push({ key: "C2 Catholic Leadership", text: "C2 Catholic Leadership", tooltipText: "We will leverage the legacy and strength of Catholic healthcare nationally and internationally to be of greater service.", });
        return choices;
    }

    private _setAttachedDocumentsInfoChoices() {
        const choices: ISelectableOptionWithTooltip[] = [];
        choices.push({ key: "HIA Research Agreement", text: "HIA Research Agreement", customLabel: <span>HIA Research Agreement <span className='requiredFieldCustom'>(required)</span></span> });
        choices.push({ key: "Relevant sponsor or granting agency agreements for legal review", text: "Relevant sponsor or granting agency agreements for legal review", customLabel: <span>Relevant sponsor or granting agency agreements for legal review <span className='italicFieldCustom'>(if applicable)</span></span> });
        choices.push({ key: "Relevant protocols and quotation request to Lab Pharmacy or Diagnostic Imaging services", text: "Relevant protocols and quotation request to Lab Pharmacy or Diagnostic Imaging services", customLabel: <span>Relevant protocols and quotation request to Lab Pharmacy or Diagnostic Imaging services <span className='italicFieldCustom'>(if applicable)</span></span> });
        return choices;
    }

    private _displayClearDialog() {
        this.setState({
            clearDialogHidden: false,
        });
    }

    private _displaySubmitDialog() {
        this.setState({
            submitDialogHidden: false,
        });
    }

    private _displayAmendDialog() {
        this.setState({
            amendDialogHidden: false,
        });
    }

    private _displayHelpDialog() {
        this.setState({
            helpRequestDialogHidden: false,
        });
    }

    private async _dismissClearDialog() {
        this.setState({
            clearDialogHidden: true
        });
    }

    private async _dismissSubmitDialog() {
        this.setState({
            submitDialogHidden: true
        });
    }

    private async _dismissAmendDialog() {
        this.setState({
            amendDialogHidden: true
        });
    }

    private async _dismissHelpDialog() {
        this.setState({
            helpRequestDialogHidden: true
        });
    }

    private async _dismissNotificationDialog() {
        if (this.state.redirectToDashboard) {
            this._clearFormTimer();
            this.props.history.push('/');
        } else {
            this.setState({
                notificationDialogHidden: true,
            });
        }
    }

    private async _getFormConfiguration() {
        const formConfiguration = await this.dataService.getFormConfiguration(this.applicationName);
        this.setState({
            formConfiguration: formConfiguration,
        });
    }

    private async _getFormCovenantHealthRequest(applicationId: string) {
        const formOperationOutput = await this.dataService.getFormItem(this.applicationName, applicationId);
        let formItem = (formOperationOutput.formItem as FormCovenantHealthRequestResource);
        if (formItem.status === "Submitted" || formItem.status === "Rejected") {
            const amendmentItem = new FormCovenantHealthRequestResource();
            amendmentItem.applicationId = formItem.applicationId;
            amendmentItem.amendmentId = formItem.amendmentId;
            amendmentItem.edgeId = formItem.edgeId;
            amendmentItem.userId = formItem.userId;
            amendmentItem.status = formItem.status;
            formItem = amendmentItem;
        }
        if (formOperationOutput.completed) {
            this.setState({
                formItem: formItem,
                formItemLastSaved: {...formItem},
                formVisible: true,
            });
            await this.uploadRef.current && this.uploadRef.current!.callGetAttachments(formItem);
        } else {
            alert('Could not get form data.');
            this.props.history.push('/');
        }
    }

    private _clearOtherFieldValues(propertyName: string) {
        let changed = false;
        const formItem = this.state.formItem as FormCovenantHealthRequestResource;
        switch (propertyName) {
            case "studyLocationsOther":
                if (formItem.studyLocationsOther !== "Yes" && formItem.studyLocationsOtherDescription !== "") {
                    formItem.studyLocationsOtherDescription = "";
                    changed = true;
                }
                break;
            case 'studyDatabases':
                if (!formItem.studyDatabases.includes('Other') && formItem.studyDatabasesOther !== "") {
                    formItem.studyDatabasesOther = "";
                    changed = true;
                }
                break;
            case "projectInvolveChartReview":
                if (formItem.projectInvolveChartReview !== "Yes" && formItem.projectChartReviewer !== "") {
                    formItem.projectChartReviewer = "";
                    changed = true;
                }
                if (formItem.projectInvolveChartReview !== "Yes" && formItem.projectChartNumberReviews !== "") {
                    formItem.projectChartNumberReviews = "";
                    changed = true;
                }
                if (formItem.projectInvolveChartReview !== "Yes" && formItem.projectChartDiagnosesProcedures !== "") {
                    formItem.projectChartDiagnosesProcedures = "";
                    changed = true;
                }
                if (formItem.projectInvolveChartReview !== "Yes" && formItem.projectChartTimePeriod !== "") {
                    formItem.projectChartTimePeriod = "";
                    changed = true;
                }
                break;
            case "databasePurposeColumnA":
                if (formItem.databasePurposeColumnA !== "Sequel (study#)" && formItem.databasePurposeColumnAOther !== "") {
                    formItem.databasePurposeColumnAOther = "";
                    changed = true;
                }
                break;
        }
        if (changed) {
            this.setState({
                formItem: formItem,
            });
        }
    }

    private _clearErrorMessage(propertyName: string) {
        const properties: string[] = [];
        const formItem = this.state.formItem as FormCovenantHealthRequestResource;
        properties.push(propertyName);
        switch (propertyName) {
            case "studyLocationsOther":
                properties.push('studyLocationsOtherDescription');
                break;
            case "studyLocationsOtherDescription":
                properties.push('studyLocationsParticipating');
                break;
            case "studyLocationsDepartments":
                properties.push('studyLocationsDepartmentsOther');
                break;
            case "studyLocationsDepartmentsOther":
                properties.push('studyLocationsDepartments');
                break;
            case "studyDatabases":
                if (!formItem.studyDatabases.includes('Other')) {
                    properties.push('studyDatabasesOther');
                }
                break;
            case "projectInvolveChartReview":
                properties.push('projectChartReviewer');
                properties.push('projectChartNumberReviews');
                properties.push('projectChartDiagnosesProcedures');
                properties.push('projectChartTimePeriod');
                break;
            case "databasePurposeColumnA":
                properties.push('databasePurposeColumnAOther');
                break;
            case "therapeuticDepartmentSectorOther":
                properties.push('therapeuticDepartmentSector');
                break;
        }
        const formValidation = this.state.formValidation;
        let changed = false;
        properties.forEach((property) => {
            const formValidationItem = this.utils.getFormValidationItem(formValidation, property);
            if (formValidationItem && !formValidationItem.isValid) {
                formValidationItem.isValid = true;
                changed = true;
            }
        });
        if (changed) {
            this.setState({
                formValidation: formValidation,
            });
        }
    }

    private _validateForm() {
        let isValid = true;
        const formItem = this.state.formItem as FormCovenantHealthRequestResource;
        const formValidation = this.state.formValidation;
        let focusSet = false;
        let focusItem: IRefObject<any> | null = null;
        for (const formValidationItem of formValidation) {
            switch (formValidationItem.validationType) {
                case 'required':
                    if ((formItem as any)[formValidationItem.propertyName] === undefined || (formItem as any)[formValidationItem.propertyName] === null || (formItem as any)[formValidationItem.propertyName].length === 0) {
                        isValid = false;
                        formValidationItem.isValid = false;
                        if (!focusSet) {
                            focusItem = formValidationItem.propertyRef;
                            focusSet = true;
                        }
                    }
                    break;
                case 'custom':
                    switch (formValidationItem.propertyName) {
                        case 'therapeuticDepartmentSector':
                            if (formItem.therapeuticDepartmentSector.length === 0 && formItem.therapeuticDepartmentSectorOther.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'PIEmail':
                            if (formItem.PIEmail.length === 0 || (formItem.PIEmail.length > 0 && !this.utils.isValidEmail(formItem.PIEmail))) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (formItem.PIEmail.length > 0) {
                                    formValidationItem.errorMessage = 'Please enter a valid email address';
                                }
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'researchCoordinatorEmail':
                            if (formItem.researchCoordinatorEmail.length === 0 || (formItem.researchCoordinatorEmail.length > 0 && !this.utils.isValidEmail(formItem.researchCoordinatorEmail))) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (formItem.researchCoordinatorEmail.length > 0) {
                                    formValidationItem.errorMessage = 'Please enter a valid email address';
                                }
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'studyLocationsParticipating':
                            if (formItem.studyLocationsParticipating.length === 0 && formItem.studyLocationsOtherDescription.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'studyLocationsDepartments':
                            if (formItem.studyLocationsDepartments.length === 0 && formItem.studyLocationsDepartmentsOther.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'studyLocationsOtherDescription':
                            if (formItem.studyLocationsOther === 'Yes' && formItem.studyLocationsOtherDescription.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'studyLocationsDepartmentsOther':
                            if (formItem.studyLocationsDepartments.length === 0 && formItem.studyLocationsDepartmentsOther.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'studyDatabasesOther':
                            if (formItem.studyDatabases.includes('Other') && formItem.studyDatabasesOther.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'projectChartReviewer':
                            if (formItem.projectInvolveChartReview === 'Yes' && formItem.projectChartReviewer.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'projectChartNumberReviews':
                            if (formItem.projectInvolveChartReview === 'Yes' && formItem.projectChartNumberReviews.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'projectChartDiagnosesProcedures':
                            if (formItem.projectInvolveChartReview === 'Yes' && formItem.projectChartDiagnosesProcedures.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'projectChartTimePeriod':
                            if (formItem.projectInvolveChartReview === 'Yes' && formItem.projectChartTimePeriod.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'databasePurposeColumnAOther':
                            if (formItem.databasePurposeColumnA === 'Sequel (study#)' && formItem.databasePurposeColumnAOther.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'formAgreement':
                            if (!this.state.formAgreement) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'projectCompletionDate':
                            if (formItem.projectCompletionDate === undefined) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            if (!formItem.projectStartDate && formItem.projectCompletionDate) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                formValidationItem.errorMessage = 'No start date informed. Please enter a start date';
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            if (formItem.projectStartDate && formItem.projectCompletionDate && (formItem.projectStartDate > formItem.projectCompletionDate)) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                formValidationItem.errorMessage = 'This date cannot be before the start date';
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                    }
                    break;
            }
        }
        if (!isValid) {
            this.setState({
                formValidation: formValidation,
            });
            this._dismissSubmitDialog().then(() => {
                if (focusItem) {
                    (focusItem as any).current.focus();
                }
            });
        }
        return isValid;
    }

    private async _saveFormCovenantHealthRequest(disableNotification?: boolean) {
        await this._updateToken();
        let formOperationOutput: IFormMethodOutput;
        const formItem = this.state.formItem;
        if (this.state.formItem.applicationId.length === 0) {
            formOperationOutput = await this.dataService.insertFormItem(this.applicationName, formItem);
        } else {
            formOperationOutput = await this.dataService.updateFormItem(this.applicationName, formItem);
        }
        if (formOperationOutput.completed) {
            await this.uploadRef.current && this.uploadRef.current!.uploadFiles(formOperationOutput.formItem as FormCovenantHealthRequestResource);
            this.setState({
                formItem: (formOperationOutput.formItem as FormCovenantHealthRequestResource),
                formItemLastSaved: {...(formOperationOutput.formItem as FormCovenantHealthRequestResource)},
                notificationDialogHidden: disableNotification ? true : false,
                notificationDialogTitle: 'Save Form',
                notificationDialogMessage: `Application (${formOperationOutput.formItem?.applicationId}) saved. \n\nThis application is in draft form and will not be processed until it is submitted. \n\nRemember that this application is available on the dashboard. You can come back and continue completing your application later.`,
            });
        } else {
            this.setState({
                notificationDialogHidden: disableNotification ? true : false,
                notificationDialogTitle: 'Error',
                notificationDialogMessage: 'An error occurred while saving the application.',
            });
        }
    }

    private async _submitFormCovenantHealthRequest() {
        if (this._validateForm()) {
            await this._saveFormCovenantHealthRequest(true);
            if (this.state.formItem.applicationId !== '') {
                const formOperationOutput = await this.dataService.submitFormItem(this.applicationName, this.state.formItem.applicationId);
                if (formOperationOutput.completed) {
                    this._clearFormTimer();
                    this.setState({
                        submitDialogHidden: true,
                        notificationDialogHidden: false,
                        notificationDialogTitle: 'Submit Application',
                        notificationDialogMessage: `This request has been completed and submitted. \n\nPlease take note of the Application ID: (${formOperationOutput.formItem?.applicationId}). Remember to check your e-mail regularly, as it will be the main source of contact during the approval process. \n\nSending this request does not guarantee its approval.`,
                        redirectToDashboard: true,
                    });
                } else {
                    this.setState({
                        submitDialogHidden: true,
                        notificationDialogHidden: false,
                        notificationDialogTitle: 'Error',
                        notificationDialogMessage: 'An error occurred while submitting the application.',
                    });
                }
            } else {
                this.setState({
                    submitDialogHidden: true,
                    notificationDialogHidden: false,
                    notificationDialogTitle: 'Error',
                    notificationDialogMessage: 'An error occurred while submitting the application.',
                });
            }
        }
    }

    private async _amendFormCovenantHealthRequest() {
        await this._updateToken();
        const formItem = this.state.formItem
        const formOperationOutput = await this.dataService.amendFormItem(this.applicationName, formItem);
        if (formOperationOutput.completed) {
            await this.uploadRef.current && this.uploadRef.current!.uploadFiles(formOperationOutput.formItem as FormCovenantHealthRequestResource);
            this._clearFormTimer();
            this.setState({
                amendDialogHidden: true,
                notificationDialogHidden: false,
                notificationDialogTitle: 'Amend Application',
                notificationDialogMessage: `Application (${formOperationOutput.formItem?.applicationId}) amended.`,
                redirectToDashboard: true,
            });
        } else {
            this.setState({
                amendDialogHidden: true,
                notificationDialogHidden: false,
                notificationDialogTitle: 'Error',
                notificationDialogMessage: 'An error occurred while amending the application.',
            });
        }
    }

    private async _helpRequestFormCovenantHealthRequest() {
        await this._updateToken();
        let formOperationOutput: IFormMethodOutput;
        const formItem = this.state.formItem;
        formItem.status = 'Help Requested';
        if (this.state.formItem.applicationId.length === 0) {
            formOperationOutput = await this.dataService.insertFormItem(this.applicationName, formItem);
        } else {
            formOperationOutput = await this.dataService.updateFormItem(this.applicationName, formItem);
        }
        if (formOperationOutput.completed) {
            if (this.state.formItem.applicationId !== '') {
                const formOperationOutput = await this.dataService.requestHelpFormItem(this.applicationName, this.state.formItem.applicationId);
                if (formOperationOutput.completed) {
                    this.setState({
                        helpRequestDialogHidden: true,
                        formItem: (formOperationOutput.formItem as FormCovenantHealthRequestResource),
                        notificationDialogHidden: false,
                        notificationDialogTitle: 'Request Help',
                        notificationDialogMessage: `Application (${formOperationOutput.formItem?.applicationId}) saved. \n\nA case manager will contact you to help.`,
                        redirectToDashboard: true,
                    });
                } else {
                    this.setState({
                        helpRequestDialogHidden: true,
                        notificationDialogHidden: false,
                        notificationDialogTitle: 'Error',
                        notificationDialogMessage: 'An error occurred while requesting help.',
                    });
                }
            } else {
                this.setState({
                    helpRequestDialogHidden: true,
                    notificationDialogHidden: false,
                    notificationDialogTitle: 'Error',
                    notificationDialogMessage: 'An error occurred while requesting help.',
                });
            }
        } else {
            this.setState({
                helpRequestDialogHidden: true,
                notificationDialogHidden: false,
                notificationDialogTitle: 'Error',
                notificationDialogMessage: 'An error occurred while requesting help.',
            });
        }
    }

    private async _clearFormCovenantHealthRequest() {
        await this._updateToken();
        const clearedItem = new FormCovenantHealthRequestResource();
        clearedItem.applicationId = this.state.formItem.applicationId;
        clearedItem.amendmentId = this.state.formItem.amendmentId;
        clearedItem.edgeId = this.state.formItem.edgeId;
        clearedItem.userId = this.state.formItem.userId;
        clearedItem.status = this.state.formItem.status;
        const formValidation = this.state.formValidation;
        for (const formValidationItem of formValidation) {
            formValidationItem.isValid = true;
        }
        // delete attachments if any
        if (this.state.formItem.applicationId.length > 0) {
            const attachments = await this.dataService.getFormAttachments(this.applicationName, this.state.formItem);
            for (const attachment of attachments) {
                await this.dataService.deleteFormAttachment(attachment.path);
            }
        }
        this.setState({
            formItem: clearedItem,
            formValidation: formValidation,
        });
        this._dismissClearDialog().then(() => {
            if (this.studyTitleRef) {
                (this.studyTitleRef as any).current.focus();
            }
        });
    }

    private async _retrieveFormInformation() {
        await this._getUserInfo();
        await this._getFormConfiguration();
        this.saveIntervalId = this._saveFormTimer();
        if (this.pathApplicationId.length > 0 && this.pathApplicationId !== 'FormCovenantHealthRequest') {
            this._getFormCovenantHealthRequest(this.pathApplicationId);
        } else {
            if (this.state.formItem.applicationId.length === 0) {
                const formItem = this.state.formItem as FormCovenantHealthRequestResource;
                formItem.UserFirstName = this.state.UserFirstName;
                formItem.UserLastName = this.state.UserLastName;
                formItem.UserEmail = this.state.UserEmail;
                formItem.UserPhoneNumber = this.state.UserPhoneNumber;
                await this._saveFormCovenantHealthRequest(true);
            }
            this.setState({
                formVisible: true
            });
        }
    }

    private async _getUserInfo() {
        const authResult = await Auth.acquireTokenSilent();
        if (authResult) {
            this.setState({
                UserFirstName: (authResult.idTokenClaims as any).given_name ? (authResult.idTokenClaims as any).given_name : '',
                UserLastName: (authResult.idTokenClaims as any).family_name ? (authResult.idTokenClaims as any).family_name : '',
                UserEmail: (authResult.idTokenClaims as any).emails[0] ? (authResult.idTokenClaims as any).emails[0] : '',
                UserPhoneNumber: (authResult.idTokenClaims as any).extension_PhoneNumber ? (authResult.idTokenClaims as any).extension_PhoneNumber : '',
            });
        }
    }

    private _clearFormTimer() {
        window.clearInterval(this.saveIntervalId);
    }

    private _saveFormTimer(): number {
        return window.setInterval(() => {
            if (this.state.formItem.status === 'Draft' && JSON.stringify(this.state.formItem) !== JSON.stringify(this.state.formItemLastSaved)) {
                this._saveFormCovenantHealthRequest(true);
            }
        }, AUTO_SAVE_INTERVAL);
    }

    private async _updateToken() {
        try {
            await this.dataService.getToken();
        } catch (error) {
            this.props.history.push('/logout');
        }
    }

    public componentDidMount() {
        this._retrieveFormInformation();
        // set default height of study locations
        const element = document.getElementById('studyLocationsRoot');
        if (element) {
            element.style.height = '930px';
        }
    }

    public componentDidUpdate() {
        // handle height resizing of study locations since it can be dynamically sized.
        if (this.state.resizeStudyLocations) {
            const element = document.getElementById('studyLocationsRoot');
            if (element) {
                element.style.height = ((element.clientHeight / 2) + 30) + 'px';
                this.setState({
                    resizeStudyLocations: false,
                });
            }
        }
    }

    public render(): React.ReactElement {
        const formItem = this.state.formItem as FormCovenantHealthRequestResource;
        const { formConfiguration, formVisible, formValidation, UserFirstName, UserLastName, UserEmail, clearDialogHidden, submitDialogHidden, amendDialogHidden, helpRequestDialogHidden, notificationDialogHidden, notificationDialogTitle, notificationDialogMessage, acutecareBedsFilter, continuingCareBedsFilter, independentLivingFilter } = this.state;
        const spinner = !formVisible ? <Spinner size={SpinnerSize.large} label="Loading..." ariaLive="assertive" /> : null;
        const studyLocationsParticipatingCheckBoxes = this._loadStudyLocationsParticipating();
        const studyLocationsDepartmentsCheckBoxes = this._loadStudyLocationsDepartments();
        const studyDatabasesCheckBoxes = this._loadCheckboxes("studyDatabases", this.studyDatabasesChoices, this.studyDatabasesRef);
        const databasePurposeDisciplinesCheckBoxes = this._loadCheckboxes("databasePurposeDisciplines", this.databasePurposeDisciplinesChoices);
        const strategicObjectivesServeCheckBoxes = this._loadCheckboxes("strategicObjectives", this.strategicObjectivesServeChoices);
        const strategicObjectivesTransfCheckBoxes = this._loadCheckboxes("strategicObjectives", this.strategicObjectivesTransfChoices);
        const strategicObjectivesContribCheckBoxes = this._loadCheckboxes("strategicObjectives", this.strategicObjectivesContribChoices);
        const attachedDocumentsInfoCheckBoxes = this._loadCheckboxes("attachedDocumentsInfo", this.attachedDocumentsInfoChoices);
        const therapeuticDepartmentSectorChoices = this._filterTherapeuticDepartmentSectorChoices();
        const clearFormButton = <DefaultButton className="clearButton" text="Clear Form" onClick={this._displayClearDialog.bind(this)} />;
        const saveButton = formItem.status === 'Draft' ? <DefaultButton text="Save Form" onClick={() => this._saveFormCovenantHealthRequest()} /> : null;
        const submitButton = formItem.status === 'Draft' ? <PrimaryButton text="Submit Form" onClick={this._displaySubmitDialog.bind(this)} /> : null;
        const amendButton = (formItem.status === 'Submitted' || formItem.status === 'Rejected') ? <PrimaryButton text="Amend Form" onClick={this._displayAmendDialog.bind(this)} /> : null;
        const helpRequestButton = formItem.status === 'Draft' ? <DefaultButton className="floatHelpButton" text="Request Help" onClick={this._displayHelpDialog.bind(this)} /> : null;
        const checkboxStyles = (styleProps: ICheckboxStyleProps): ICheckboxStyles => {
            const chkStyles: ICheckboxStyles = {
                root: { marginRight: 5 },
                label: { alignItems: 'flex-start' }, checkbox: [
                    { backgroundColor: "white" },
                    styleProps.checked && {
                        backgroundColor: "rgb(0, 90, 158)",
                    },
                ],
            };
            return chkStyles;
        };
        return (
            <main className="App-formbody">
                <Navigation
                    title="Covenant Request"
                    isForm={true}
                    UserFirstName={UserFirstName}
                    UserLastName={UserLastName}
                    UserEmail={UserEmail}
                    clearFormTimer={this._clearFormTimer.bind(this)} />
                {spinner}
                <form style={!formVisible ? { display: 'none' } : {}}>
                    <section className="applicationIDSection">
                        <span className='col1'><h2>Application Number: {formItem.applicationId}</h2></span>
                        <span className='col2'><img style={{ maxWidth: "422px" }} src={FormLogo} alt="Form logo" /></span>
                    </section>
                    <section>
                        <h2>1. Full Long Title of Study</h2>
                        <TextField
                            className='requiredField'
                            errorMessage={this.utils.getErrorMessage(formValidation, 'studyTitle')}
                            label="Title of Study"
                            componentRef={this.studyTitleRef}
                            styles={TEXT_SINGLE_COLUMN}
                            value={formItem.studyTitle}
                            onChange={(event, newValue) => { this._onTextFieldChange("studyTitle", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h2 className='requiredItem'>2. Study Reviewed By</h2>
                        <ChoiceGroupWithValidation
                            selectedKey={formItem.studyReviewedBy}
                            onChange={(event, option) => { this._onChoiceGroupChange("studyReviewedBy", event, option); }}
                            options={this.studyReviewedByChoices}
                            componentRef={this.studyReviewedByRef}
                            styles={{ flexContainer: { display: "grid", gridAutoFlow: "column", gridTemplateRows: "repeat(2, auto)" } }}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'studyReviewedBy')}
                        />
                        <TextField
                            className='requiredField'
                            styles={TEXT_SINGLE_COLUMN}
                            label="Ethics board study number"
                            ariaLabel="Ethics board study number"
                            errorMessage={this.utils.getErrorMessage(formValidation, 'studyEthicsBoardNumber')}
                            componentRef={this.studyEthicsBoardNumberRef}
                            value={formItem.studyEthicsBoardNumber}
                            onChange={(event, newValue) => { this._onTextFieldChange("studyEthicsBoardNumber", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h2>3. Personal Information</h2>
                        <Stack wrap styles={{ root: { height: 90, maxWidth: 800 } }} >
                            <TextField
                                className='requiredField'
                                label="Name of Principal Investigator (PI)"
                                styles={TEXT_DOUBLE_COLUMN}
                                errorMessage={this.utils.getErrorMessage(formValidation, 'PIName')}
                                componentRef={this.PINameRef}
                                value={formItem.PIName}
                                onChange={(event, newValue) => { this._onTextFieldChange("PIName", event, newValue); }}
                            />
                            <TextField
                                className='requiredField'
                                label="Phone Number"
                                styles={TEXT_DOUBLE_COLUMN}
                                errorMessage={this.utils.getErrorMessage(formValidation, 'PIPhoneNumber')}
                                componentRef={this.PIPhoneNumberRef}
                                value={formItem.PIPhoneNumber}
                                onChange={(event, newValue) => { this._onTextFieldChange("PIPhoneNumber", event, newValue); }}
                            />
                        </Stack>
                        <Stack wrap styles={{ root: { height: 90, maxWidth: 800 } }} >
                            <TextField
                                className='requiredField'
                                label="Email"
                                styles={TEXT_DOUBLE_COLUMN}
                                errorMessage={this.utils.getErrorMessage(formValidation, 'PIEmail')}
                                componentRef={this.PIEmailRef}
                                value={formItem.PIEmail}
                                onChange={(event, newValue) => { this._onTextFieldChange("PIEmail", event, newValue); }}
                            />
                            <TextField
                                className='requiredField'
                                label="I have a University Affliliation with"
                                styles={TEXT_DOUBLE_COLUMN}
                                errorMessage={this.utils.getErrorMessage(formValidation, 'PIUniversityAffiliation')}
                                componentRef={this.PIUniversityAffiliationRef}
                                value={formItem.PIUniversityAffiliation}
                                onChange={(event, newValue) => { this._onTextFieldChange("PIUniversityAffiliation", event, newValue); }}
                            />
                        </Stack>
                        <Label className='requiredItem'>I have Covenant privileges and/or am a Covenant employee</Label>
                        <ChoiceGroupWithValidation
                            selectedKey={formItem.PICovenantHealthPrivileges}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'PICovenantHealthPrivileges')}
                            componentRef={this.PICovenantHealthPrivilegesRef}
                            onChange={(event, option) => { this._onChoiceGroupChange("PICovenantHealthPrivileges", event, option); }}
                            options={this.PICovenantHealthPrivilegesChoices}
                        />
                    </section>
                    <section>
                        <h2>4. Study Involvement</h2>
                        <h3>Specify Research Coordinator and/or Research Assistant(s) who will be involved with this study</h3>
                        <TextField
                            className='requiredField'
                            label="Name"
                            styles={TEXT_SINGLE_COLUMN}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'researchCoordinatorName')}
                            componentRef={this.researchCoordinatorNameRef}
                            value={formItem.researchCoordinatorName}
                            onChange={(event, newValue) => { this._onTextFieldChange("researchCoordinatorName", event, newValue); }}
                        />
                        <Stack wrap styles={{ root: { height: 90, maxWidth: 800 } }} >
                            <TextField
                                className='requiredField'
                                errorMessage={this.utils.getErrorMessage(formValidation, 'researchCoordinatorEmail')}
                                componentRef={this.researchCoordinatorEmailRef}
                                label="Email"
                                styles={TEXT_DOUBLE_COLUMN}
                                value={formItem.researchCoordinatorEmail}
                                onChange={(event, newValue) => { this._onTextFieldChange("researchCoordinatorEmail", event, newValue); }}
                            />
                            <TextField
                                className='requiredField'
                                label="Phone Number"
                                styles={TEXT_DOUBLE_COLUMN}
                                errorMessage={this.utils.getErrorMessage(formValidation, 'researchCoordinatorPhoneNumber')}
                                componentRef={this.researchCoordinatorPhoneNumberRef}
                                value={formItem.researchCoordinatorPhoneNumber}
                                onChange={(event, newValue) => { this._onTextFieldChange("researchCoordinatorPhoneNumber", event, newValue); }}
                            />
                        </Stack>
                    </section>
                    <section>
                        <h2 className='requiredItem'>5. Study Locations</h2>
                        <h3>Locations participating in the study. (For a listing of patient populations served within each location, please reference <a href='http://www.covenanthealth.ca/facilities.html' target="_blank" rel="noreferrer">http://www.covenanthealth.ca/facilities.html</a>)</h3>
                        <Label>Filter Options</Label>
                        <Stack wrap styles={{ root: { marginTop: '15px', height: 30, maxWidth: 800 } }} tokens={{ childrenGap: "10" }} >
                            <span className='studyLocationCheckBoxContainer'>
                                <Checkbox
                                    styles={checkboxStyles}
                                    key={('acuteCareBedsFilter' as string)}
                                    label={"Acute Care Beds"}
                                    name={"Acute Care Beds"}
                                    checked={acutecareBedsFilter}
                                    onChange={(ev, checked) => { this._onFilterCheckBoxChange('acuteCareBedsFilter', ev, checked); }} />
                                <img src={AcuteCareBedsIcon} alt="Acute Care Beds" />
                            </span>
                            <span className='studyLocationCheckBoxContainer'>
                                <Checkbox
                                    styles={checkboxStyles}
                                    key={('continuingCareBedsFilter' as string)}
                                    label={"Continuing Care Beds"}
                                    name={"Continuing Care Beds"}
                                    checked={continuingCareBedsFilter}
                                    onChange={(ev, checked) => { this._onFilterCheckBoxChange('continuingCareBedsFilter', ev, checked); }} />
                                <img src={ContinuingCareBedsIcon} alt="Continuing Care Beds" />
                            </span>
                            <span className='studyLocationCheckBoxContainer'>
                                <Checkbox
                                    styles={checkboxStyles}
                                    key={('independentLivingFilter' as string)}
                                    label={"Independent Living"}
                                    name={"Independent Living"}
                                    checked={independentLivingFilter}
                                    onChange={(ev, checked) => { this._onFilterCheckBoxChange('independentLivingFilter', ev, checked); }} />
                                <img src={IndependentLivingIcon} alt="Independent Living" />
                            </span>
                        </Stack>
                        <Stack id='studyLocationsRoot' wrap styles={{ root: { marginTop: '15px', maxWidth: 800 } }} tokens={{ childrenGap: "10" }} >
                            {studyLocationsParticipatingCheckBoxes}
                        </Stack>
                        <span>
                            <div role="alert">
                                <p className="ms-TextField-errorMessage">
                                    <span data-automation-id="error-message">{this.utils.getErrorMessage(formValidation, 'studyLocationsParticipating')}</span>
                                </p>
                            </div>
                        </span>
                        <br></br>
                        <Label className='requiredItem'>Alberta Health Services (AHS) Locations?</Label>
                        <ChoiceGroupWithValidation
                            selectedKey={formItem.studyLocationsOther}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'studyLocationsOther')}
                            componentRef={this.studyLocationsOtherRef}
                            onChange={(event, option) => { this._onChoiceGroupChange("studyLocationsOther", event, option); }}
                            options={this.studyLocationsOtherChoices}
                        />
                        <TextField
                            disabled={formItem.studyLocationsOther !== 'Yes'}
                            styles={TEXT_SINGLE_COLUMN}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'studyLocationsOtherDescription')}
                            componentRef={this.studyLocationsOtherDescriptionRef}
                            value={formItem.studyLocationsOtherDescription}
                            onChange={(event, newValue) => { this._onTextFieldChange("studyLocationsOtherDescription", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h2 className='requiredItem'>6. Departments impacted by Study</h2>
                        <h3>List all departments/units that will be impacted by the study and how they will be affected (e.g. Health Records - charts pulled…; Family Medicine Clinic - patients recruited…).</h3>
                        {(studyLocationsDepartmentsCheckBoxes.length === 0 && formItem.studyLocationsOtherDescription.length === 0) ? <Label>Select a study location to display the available departments/units</Label> : null}
                        {(studyLocationsDepartmentsCheckBoxes.length > 0) ?
                            <div>
                                <Stack styles={{ root: { marginTop: '15px' } }} tokens={{ childrenGap: "10" }}>
                                    {studyLocationsDepartmentsCheckBoxes}
                                </Stack><span>
                                    <div role="alert">
                                        <p className="ms-TextField-errorMessage">
                                            <span data-automation-id="error-message">{this.utils.getErrorMessage(formValidation, 'studyLocationsDepartments')}</span>
                                        </p>
                                    </div>
                                </span><br></br>
                            </div> : null}
                        <TextField
                            errorMessage={this.utils.getErrorMessage(formValidation, 'studyLocationsDepartmentsOther')}
                            componentRef={this.studyLocationsDepartmentsOtherRef}
                            label="Other departments/units that will be impacted by the study"
                            styles={TEXT_SINGLE_COLUMN}
                            value={formItem.studyLocationsDepartmentsOther}
                            onChange={(event, newValue) => { this._onTextFieldChange("studyLocationsDepartmentsOther", event, newValue); }}
                        />
                        <h2>Estimated duration of project</h2>
                        <Stack wrap styles={{ root: { height: 90, maxWidth: 800 } }} >
                            <div style={{ width: '50%' }}>
                                <Label>Start Date</Label>
                                <DatePickerWithValidation
                                    showGoToToday
                                    ariaLabel='Start Date'
                                    styles={{ root: { maxWidth: 390, width: '100%' } }}
                                    strings={defaultCalendarStrings}
                                    errorMessage={this.utils.getErrorMessage(formValidation, 'projectStartDate')}
                                    componentRef={this.projectStartDateRef}
                                    value={!formItem.projectStartDate ? undefined : formItem.projectStartDate}
                                    formatDate={(date?: Date | undefined): string => { return dateFormat(date!, "dd/mm/yy");; }}
                                    onSelectDate={(date) => { this._onDatePickerChange("projectStartDate", date); }}
                                />
                            </div>
                            <div style={{ width: '50%', marginLeft: '10px' }}>
                                <Label>Completion Date</Label>
                                <DatePickerWithValidation
                                    showGoToToday
                                    ariaLabel='Completion Date'
                                    styles={{ root: { maxWidth: 390, width: '100%' } }}
                                    strings={defaultCalendarStrings}
                                    errorMessage={this.utils.getErrorMessage(formValidation, 'projectCompletionDate')}
                                    componentRef={this.projectCompletionDateRef}
                                    value={!formItem.projectCompletionDate ? undefined : formItem.projectCompletionDate}
                                    formatDate={(date?: Date | undefined): string => { return dateFormat(date!, "dd/mm/yy");; }}
                                    onSelectDate={(date) => { this._onDatePickerChange("projectCompletionDate", date); }}
                                />
                            </div>
                        </Stack>
                    </section>
                    <section>
                        <h2 className='requiredItem'>7. Database List</h2>
                        <h3>Please note that you cannot recruit patients from these systems without use of an intermediary. Separate database agreements may also be required. A navigator will advise you accordingly.</h3>
                        <Label>List all databases you will be using as part of your research study</Label>
                        <Stack wrap styles={{ root: { marginTop: '15px', height: 100, maxWidth: 800 } }} tokens={{ childrenGap: "10" }} >
                            {studyDatabasesCheckBoxes}
                            <TextField
                                disabled={!formItem.studyDatabases.includes('Other')}
                                styles={{ root: { marginLeft: '30px !important', maxWidth: 400, width: '100%', } }}
                                ariaLabel="Other databases you will be using as part of your research study"
                                errorMessage={this.utils.getErrorMessage(formValidation, 'studyDatabasesOther')}
                                componentRef={this.studyDatabasesOtherRef}
                                value={formItem.studyDatabasesOther}
                                onChange={(event, newValue) => { this._onTextFieldChange("studyDatabasesOther", event, newValue); }}
                            />
                        </Stack>
                        <span>
                            <div role="alert">
                                <p className="ms-TextField-errorMessage">
                                    <span data-automation-id="error-message">{this.utils.getErrorMessage(formValidation, 'studyDatabases')}</span>
                                </p>
                            </div>
                        </span>
                    </section>
                    <section>
                        <h2>8. Chart Review</h2>
                        <h3>Please fill in the following if applicable</h3>
                        <Label className='requiredItem'>Does the project involve Chart Review?</Label>
                        <ChoiceGroupWithValidation
                            selectedKey={formItem.projectInvolveChartReview}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'projectInvolveChartReview')}
                            componentRef={this.projectInvolveChartReviewRef}
                            onChange={(event, option) => { this._onChoiceGroupChange("projectInvolveChartReview", event, option); }}
                            options={this.projectInvolveChartReviewChoices}
                        />
                        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                            <Label>Who will be reviewing the charts</Label>
                        </div>
                        <TextField
                            disabled={formItem.projectInvolveChartReview !== 'Yes'}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'projectChartReviewer')}
                            componentRef={this.projectChartReviewerRef}
                            styles={TEXT_SINGLE_COLUMN}
                            value={formItem.projectChartReviewer}
                            onChange={(event, newValue) => { this._onTextFieldChange("projectChartReviewer", event, newValue); }}
                        />
                        <TextField
                            disabled={formItem.projectInvolveChartReview !== 'Yes'}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'projectChartNumberReviews')}
                            componentRef={this.projectChartNumberReviewsRef}
                            label="Number of charts to be reviewed"
                            styles={TEXT_SINGLE_COLUMN}
                            value={formItem.projectChartNumberReviews}
                            onChange={(event, newValue) => { this._onTextFieldChange("projectChartNumberReviews", event, newValue); }}
                        />
                        <TextField
                            disabled={formItem.projectInvolveChartReview !== 'Yes'}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'projectChartDiagnosesProcedures')}
                            componentRef={this.projectChartDiagnosesProceduresRef}
                            label="Diagnoses or Procedures"
                            styles={TEXT_SINGLE_COLUMN}
                            value={formItem.projectChartDiagnosesProcedures}
                            onChange={(event, newValue) => { this._onTextFieldChange("projectChartDiagnosesProcedures", event, newValue); }}
                        />
                        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                            <Label>Time period for patient chart review</Label>
                            <TooltipHost
                                styles={{ root: { marginLeft: '8px', verticalAlign: 'middle' } }}
                                content={"From - To"}
                                tooltipProps={{
                                    styles: {
                                        content: { fontWeight: '600' }
                                    }
                                }}>
                                <Icon iconName="Info" />
                            </TooltipHost>
                        </div>
                        <TextField
                            disabled={formItem.projectInvolveChartReview !== 'Yes'}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'projectChartTimePeriod')}
                            componentRef={this.projectChartTimePeriodRef}
                            styles={TEXT_SINGLE_COLUMN}
                            value={formItem.projectChartTimePeriod}
                            onChange={(event, newValue) => { this._onTextFieldChange("projectChartTimePeriod", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h2>9. Elements Represented by your Study</h2>
                        <h3>For Database purposes, please complete the following sections by indicating the appropriate elements that best represent your study. (indicate one type from each column)</h3>
                        <Label className='requiredItem'>Column A</Label>
                        <ChoiceGroupWithValidation
                            selectedKey={formItem.databasePurposeColumnA}
                            onChange={(event, option) => { this._onChoiceGroupChange("databasePurposeColumnA", event, option); }}
                            options={this.databasePurposeColumnAChoices}
                            componentRef={this.databasePurposeColumnARef}
                            styles={{ flexContainer: { display: "grid", gridAutoFlow: "column", gridTemplateRows: "repeat(2, auto)" } }}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'databasePurposeColumnA')}
                        />
                        <TextField
                            disabled={formItem.databasePurposeColumnA !== 'Sequel (study#)'}
                            styles={{ root: { marginLeft: '390px !important', marginTop: '-35px', marginBottom: '30px !important', maxWidth: 400, width: '100%', } }}
                            ariaLabel="Other databases you will be using as part of your research study"
                            errorMessage={this.utils.getErrorMessage(formValidation, 'databasePurposeColumnAOther')}
                            componentRef={this.databasePurposeColumnAOtherRef}
                            value={formItem.databasePurposeColumnAOther}
                            onChange={(event, newValue) => { this._onTextFieldChange("databasePurposeColumnAOther", event, newValue); }}
                        />
                        <Label className='requiredItem'>Column B</Label>
                        <ChoiceGroupWithValidation
                            selectedKey={formItem.databasePurposeColumnB}
                            onChange={(event, option) => { this._onChoiceGroupChange("databasePurposeColumnB", event, option); }}
                            options={this.databasePurposeColumnBChoices}
                            componentRef={this.databasePurposeColumnBRef}
                            styles={{ flexContainer: { display: "grid", gridAutoFlow: "column", gridTemplateRows: "repeat(4, auto)" } }}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'databasePurposeColumnB')}
                        />
                        <br></br>
                        <h3>If you are unsure whether your project is research or a quality project, access a <a href="https://www.ualberta.ca/research/media-library/reo/human-ethics-files/forms-files/guidelines-for-differentiating-among-research.pdf" target="_blank" rel="noreferrer">GUIDE</a> outlining the differences. <br />If you are still unsure, you can submit your project details via this <a href="https://www.ualberta.ca/research/media-library/reo/human-ethics-files/forms-files/guidelines-for-differentiating-among-research.pdf" target="_blank" rel="noreferrer">QI/Research Determination form</a> and submit the outcome to the CHRC office at research@covenanthealth.ca <br />Written confirmation from the HREB is required to proceed without ethics review. <br />Please note that your project may still require review for operational access.</h3>
                    </section>
                    <section>
                        <h2>10. Indicate Context for Research</h2>
                        <Label className='requiredItem'>Indicate the primary context for your research</Label>
                        <ChoiceGroupWithValidation
                            selectedKey={formItem.researchContext}
                            onChange={(event, option) => { this._onChoiceGroupChange("researchContext", event, option); }}
                            options={this.researchContextChoices}
                            componentRef={this.researchContextRef}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'researchContext')}
                        />
                    </section>
                    <section>
                        <h2>11. Indicate Strategic Perspective</h2>
                        <Label className='requiredItem'>Indicate the primary research focus from a strategic perspective</Label>
                        <ChoiceGroupWithValidation
                            selectedKey={formItem.researchFocusPerspective}
                            onChange={(event, option) => { this._onChoiceGroupChange("researchFocusPerspective", event, option); }}
                            options={this.researchFocusPerspectiveChoices}
                            componentRef={this.researchFocusPerspectiveRef}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'researchFocusPerspective')}
                        />
                    </section>
                    <section>
                        <h2>12. Covenant Service</h2>
                        <Label className='requiredItem'>Indicate the primary Covenant service area</Label>
                        <ChoiceGroupWithValidation
                            selectedKey={formItem.serviceArea}
                            onChange={(event, option) => { this._onChoiceGroupChange("serviceArea", event, option); }}
                            options={this.serviceAreaChoices}
                            componentRef={this.serviceAreaRef}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'serviceArea')}
                        />
                    </section>
                    <section>
                        <h2>13. Therapeutic Department and Corresponding Section (Choose one)</h2>
                        <Label className='requiredItem'>Department</Label>
                        <ChoiceGroupWithValidation
                            selectedKey={formItem.therapeuticDepartment}
                            onChange={(event, option) => { this._onChoiceGroupChange("therapeuticDepartment", event, option); }}
                            options={this.therapeuticDepartmentChoices}
                            componentRef={this.therapeuticDepartmentRef}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'therapeuticDepartment')}
                        />
                        <br></br>
                        <Label className='requiredItem'>Section</Label>
                        {therapeuticDepartmentSectorChoices.length === 0 ? <Label>Select a Department to display the available sections</Label> : null}
                        <ChoiceGroupWithValidation
                            selectedKey={formItem.therapeuticDepartmentSector}
                            onChange={(event, option) => { this._onChoiceGroupChange("therapeuticDepartmentSector", event, option); }}
                            options={therapeuticDepartmentSectorChoices}
                            componentRef={this.therapeuticDepartmentSectorRef}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'therapeuticDepartmentSector')}
                        />
                        {therapeuticDepartmentSectorChoices.length !== 0 ? <TextField
                            label="Other section not listed"
                            styles={TEXT_SINGLE_COLUMN}
                            value={formItem.therapeuticDepartmentSectorOther}
                            onChange={(event, newValue) => { this._onTextFieldChange("therapeuticDepartmentSectorOther", event, newValue); }}
                        /> : null}
                    </section>
                    <section>
                        <h2>14. For Databases Purposes - Teams</h2>
                        <h3>(please list all disciplines participating as part of your study team)</h3>
                        <Stack wrap styles={{ root: { marginTop: '15px', height: 240, maxWidth: 800 } }} tokens={{ childrenGap: "10" }} >
                            {databasePurposeDisciplinesCheckBoxes}
                        </Stack>
                    </section>
                    <section>
                        <h2 className='requiredItem'>15. Indicate which Strategic Objectives your Study Impacts at Covenant</h2>
                        <h3>(check all that apply)</h3>
                        <Label>SERVE: We will strengthen our mission, live our values and enhance our culture in order to meet the needs of those we serve through excellence in care, an empowered and highly engaged team, and wise use of our resources.</Label>
                        <Stack styles={{ root: { marginTop: '15px' } }} tokens={{ childrenGap: "10" }} >
                            {strategicObjectivesServeCheckBoxes}
                        </Stack>
                        <br></br>
                        <Label>TRANSFORM & GROW: We will transform the health system through innovation, collaboration, growth, and sharing learnings to respond to the strengths and needs of our communities, especially those most vulnerable.</Label>
                        <Stack styles={{ root: { marginTop: '15px' } }} tokens={{ childrenGap: "10" }} >
                            {strategicObjectivesTransfCheckBoxes}
                        </Stack>
                        <br></br>
                        <Label>CONTRIBUTE: We will advance our mission and leverage the legacy of Catholic healthcare for the benefit of those we serve.</Label>
                        <Stack styles={{ root: { marginTop: '15px' } }} tokens={{ childrenGap: "10" }} >
                            {strategicObjectivesContribCheckBoxes}
                        </Stack>
                        <span>
                            <div role="alert">
                                <p className="ms-TextField-errorMessage">
                                    <span data-automation-id="error-message">{this.utils.getErrorMessage(formValidation, 'strategicObjectives')}</span>
                                </p>
                            </div>
                        </span>
                    </section>
                    <section>
                        <h2>16. Project Documents</h2>
                        <Label>Please submit the following signed documents. (Documents can be found <a href='https://www.covenanthealth.ca/research-centre/research-administration/research-policy-process' target='_blank' rel="noreferrer">here</a>)</Label>
                        <Stack tokens={{ childrenGap: 10 }}>
                            {attachedDocumentsInfoCheckBoxes}
                        </Stack>
                        <Upload
                            uploadKey={this.state.uploadElementKey}
                            ref={this.uploadRef}
                            applicationName={this.applicationName}
                            fileSizeLimit={this.fileSizeLimit} />
                    </section>
                    <section>
                        <h2>17. Additional Information</h2>
                        <TextField
                            label="Additional Comments/Notes"
                            styles={TEXT_SINGLE_COLUMN}
                            multiline={true}
                            rows={5}
                            value={formItem.additionalComments}
                            onChange={(event, newValue) => { this._onTextFieldChange("additionalComments", event, newValue); }}
                        />
                    </section>
                    <section>
                        <Label>{this.utils.parseHTML(this.utils.getConfigurationValue(formConfiguration, 'ThankyouMessage'))}</Label>
                        <br />
                        {helpRequestButton}
                        <div style={{ display: 'grid', gridTemplateColumns: '115px 140px auto 130px', gridTemplateRows: 'auto', marginTop: '15px' }}>
                            <div style={{ gridArea: '1 / 1 / 1 / 1' }}>
                                {clearFormButton}
                            </div>
                            <div style={{ gridArea: '1 / 2 / 1 / 2', marginLeft: '10px' }}>
                                {saveButton}
                                {amendButton}
                            </div>
                            <div style={{ gridArea: '1 / 4 / 1 / 4', textAlign: 'right' }}>
                                {submitButton}
                            </div>
                        </div>
                    </section>
                    <Dialog
                        className='renderLinefeed'
                        hidden={clearDialogHidden}
                        onDismiss={() => { this._dismissClearDialog(); }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: 'Clear Form',
                            subText: 'Are you sure you want to clear the form? \nThis will remove all entered data.',
                        }}
                        modalProps={{
                            isBlocking: true,
                            styles: { main: { maxWidth: 450 } },
                            dragOptions: {
                                moveMenuItemText: 'Move',
                                closeMenuItemText: 'Close',
                                menu: ContextualMenu,
                            },
                        }}
                    >
                        <DialogFooter>
                            <DefaultButton onClick={() => { this._dismissClearDialog(); }} text="Cancel" />
                            <PrimaryButton onClick={() => { this._clearFormCovenantHealthRequest(); }} text="Yes, Clear Form" />
                        </DialogFooter>
                    </Dialog>
                    <Dialog
                        className='renderLinefeed'
                        hidden={submitDialogHidden}
                        onDismiss={() => { this._dismissSubmitDialog(); }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: 'Submit application',
                            subText: `Are you sure you want to submit this application ${formItem.applicationId.length > 0 ? '(' + formItem.applicationId + ')' : ''}?`,
                        }}
                        modalProps={{
                            isBlocking: true,
                            styles: { main: { maxWidth: 450 } },
                            dragOptions: {
                                moveMenuItemText: 'Move',
                                closeMenuItemText: 'Close',
                                menu: ContextualMenu,
                            },
                        }}
                    >
                        <DialogFooter>
                            <DefaultButton onClick={() => { this._dismissSubmitDialog(); }} text="Cancel" />
                            <PrimaryButton onClick={() => { this._submitFormCovenantHealthRequest(); }} text="Yes, Submit Application" />
                        </DialogFooter>
                    </Dialog>
                    <Dialog
                        className='renderLinefeed'
                        hidden={amendDialogHidden}
                        onDismiss={() => { this._dismissAmendDialog(); }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: 'Amend Application',
                            subText: `You are about to modify your submitted application ${formItem.applicationId}. \nPlease select "Yes, Amend Application" to proceed with your changes.`,
                        }}
                        modalProps={{
                            isBlocking: true,
                            styles: { main: { maxWidth: 450 } },
                            dragOptions: {
                                moveMenuItemText: 'Move',
                                closeMenuItemText: 'Close',
                                menu: ContextualMenu,
                            },
                        }}
                    >
                        <DialogFooter>
                            <DefaultButton onClick={() => { this._dismissAmendDialog(); }} text="Cancel" />
                            <PrimaryButton onClick={() => { this._amendFormCovenantHealthRequest(); }} text="Yes, Amend Application" />
                        </DialogFooter>
                    </Dialog>
                    <Dialog
                        className='renderLinefeed'
                        hidden={helpRequestDialogHidden}
                        onDismiss={() => { this._dismissHelpDialog(); }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: 'Request Help',
                            subText: `Do you want to save the form as is and request help from the case manager to complete filling the form? Please complete as much fields as possible before requesting help.`,
                        }}
                        modalProps={{
                            isBlocking: true,
                            styles: { main: { maxWidth: 450 } },
                            dragOptions: {
                                moveMenuItemText: 'Move',
                                closeMenuItemText: 'Close',
                                menu: ContextualMenu,
                            },
                        }}
                    >
                        <DialogFooter>
                            <DefaultButton onClick={() => { this._dismissHelpDialog(); }} text="Cancel" />
                            <PrimaryButton onClick={() => { this._helpRequestFormCovenantHealthRequest(); }} text="Yes, request for help" />
                        </DialogFooter>
                    </Dialog>
                    <Dialog
                        className='renderLinefeed'
                        hidden={notificationDialogHidden}
                        onDismiss={() => { this._dismissNotificationDialog(); }}
                        dialogContentProps={{
                            type: DialogType.close,
                            title: notificationDialogTitle,
                            subText: notificationDialogMessage,
                        }}
                    />
                </form>
            </main>
        );
    }
}