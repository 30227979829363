import React, { createRef } from 'react';
import DataService from '../data/DataService';
import { FormARECCISecondOpinionResource } from '../dto/FormARECCISecondOpinionResource';
import { IFormMethodOutput } from '../interfaces/IFormMethodOutput';
import { IFormConfiguration } from '../interfaces/IFormConfiguration';
import { IFormState } from '../interfaces/IFormState';
import { IFormValidation } from '../interfaces/IFormValidation';
import Navigation from '../layouts/Navigation';
import { Checkbox, DatePicker, defaultCalendarStrings, IChoiceGroupOption, Label, PrimaryButton, Spinner, SpinnerSize, Stack, TextField, IRefObject, DefaultButton, Dialog, DialogFooter, DialogType, ContextualMenu, TooltipHost, Icon, ICheckboxStyleProps, ICheckboxStyles } from '@fluentui/react';
import Upload from '../components/Upload';
import Utils from '../utils/Utils';
import Auth from '../Auth';
import { RouteComponentProps } from 'react-router-dom';
import FormLogo from '../assets/ARECCILogo.jpg';
import CheckboxWithValidation from '../components/CheckboxWithValidation';
import { ISelectableOptionWithTooltip } from '../interfaces/ISelectableOptionWithTooltip';
import dateFormat from 'dateformat';
import DatePickerWithValidation from '../components/DatePickerWithValidation';
import { TEXT_DOUBLE_COLUMN, TEXT_SINGLE_COLUMN, FILE_SIZE_LIMIT, AUTO_SAVE_INTERVAL } from '../constants/Constants';


export interface IFormARECCISecondOpinionProps extends RouteComponentProps {
}

export default class FormARECCISecondOpinion extends React.Component<IFormARECCISecondOpinionProps, IFormState> {
    applicationName: string = 'ARECCISecondOpinionReview';
    fileSizeLimit: number = FILE_SIZE_LIMIT;
    dataService: DataService = new DataService();
    utils: Utils = new Utils();
    pathApplicationId = (window.location.pathname.split('/').pop() as string);
    saveIntervalId: number;

    // create and set choices fields (checkbox and dropdown (ISelectableOptionWithTooltip[]), radio (IChoiceGroupOption[]))
    projectTypeChoices: ISelectableOptionWithTooltip[] = this._setProjectTypeChoices();
    attachedDocumentsInfoChoices: ISelectableOptionWithTooltip[] = this._setAttachedDocumentsInfoChoices();

    // create a ref for each field that can be focused. For example: On a validation error
    submissionDateRef: IRefObject<any> = createRef();
    projectSponsorEmailRef: IRefObject<any> = createRef();
    projectManagerEmailRef: IRefObject<any> = createRef();
    projectTypeOtherRef: IRefObject<any> = createRef();
    targetCompletionDateRef: IRefObject<any> = createRef();
    formAgreementRef: IRefObject<any> = createRef();
    uploadRef: React.RefObject<Upload> = createRef<Upload>();

    constructor(props: IFormARECCISecondOpinionProps) {
        super(props);
        const formConfiguration: IFormConfiguration[] = [];
        const formItem: FormARECCISecondOpinionResource = new FormARECCISecondOpinionResource();
        const formItemLastSaved: FormARECCISecondOpinionResource = new FormARECCISecondOpinionResource();
        const formValidation: IFormValidation[] = [];
        // add validation information for each field to be validated
        formValidation.push({ propertyName: 'projectSponsorEmail', isValid: true, validationType: 'custom', errorMessage: '', propertyRef: this.projectSponsorEmailRef });
        formValidation.push({ propertyName: 'projectManagerEmail', isValid: true, validationType: 'custom', errorMessage: '', propertyRef: this.projectManagerEmailRef });
        formValidation.push({ propertyName: 'projectTypeOther', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.projectTypeOtherRef });
        formValidation.push({ propertyName: 'targetCompletionDate', isValid: true, validationType: 'custom', errorMessage: 'This date cannot be before the Target Start Date', propertyRef: this.targetCompletionDateRef });
        formValidation.push({ propertyName: 'formAgreement', isValid: true, validationType: 'custom', errorMessage: 'You must agree to the disclaimer and the terms of use to submit your application.', propertyRef: this.formAgreementRef });

        this.state = {
            formConfiguration: formConfiguration,
            formItem: formItem,
            formItemLastSaved: formItemLastSaved,
            formVisible: false,
            formValidation: formValidation,
            UserFirstName: '',
            UserLastName: '',
            UserEmail: '',
            UserPhoneNumber: '',
            clearDialogHidden: true,
            submitDialogHidden: true,
            amendDialogHidden: true,
            helpRequestDialogHidden: true,
            notificationDialogHidden: true,
            notificationDialogTitle: '',
            notificationDialogMessage: '',
            redirectToDashboard: false,
            formAgreement: false,
            uploadElementKey: 1,
            showApplicantInstitutionOther: false,
            showRequesterInstitutionOther: false,
            isFormDisabled: false
        };
    };

    private _onAgreementChange = (propertyName: string, ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined): void => {
        this.setState({
            formAgreement: checked ? true : false,
        });
        this._clearErrorMessage(propertyName);
    }

    private _onTextFieldChange = (propertyName: string, event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        const formValidation = this.state.formValidation;
        const formValidationItem = this.utils.getFormValidationItem(formValidation, propertyName);
        (this.state.formItem as any)[propertyName] = newValue;
        this.setState({
            formItem: this.state.formItem,
        });
        if (formValidationItem && formValidationItem.charLimit) {
            formValidationItem.remainingChars = formValidationItem.charLimit - (newValue ? newValue.length : 0);
            this.setState({
                formValidation: formValidation,
            });
        }
        this._clearErrorMessage(propertyName);
    }

    private _onChoiceGroupChange = (propertyName: string, ev?: React.FormEvent<HTMLInputElement | HTMLElement>, option?: IChoiceGroupOption): void => {
        (this.state.formItem as any)[propertyName] = option?.key.toString();
        this.setState({
            formItem: this.state.formItem,
        });
        this._clearOtherFieldValues(propertyName);
        this._clearErrorMessage(propertyName);
    }

    private _onDatePickerChange = (propertyName: string, date: Date | null | undefined): void => {
        (this.state.formItem as any)[propertyName] = date;
        this.setState({
            formItem: this.state.formItem,
        });
        this._clearErrorMessage(propertyName);
    }

    private _onCheckBoxChange = (propertyName: string, ev?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean, isYesNoCheck?: boolean): void => {
        const newOptions: string[] = [];
        const checkBoxValue: string = (ev?.target as HTMLInputElement).name;
        if (isYesNoCheck) {
            (this.state.formItem as any)[propertyName] = ((this.state.formItem as any)[propertyName] === "" || (this.state.formItem as any)[propertyName] === "No") ? "Yes" : "No";
        } else {
            if ((this.state.formItem as any)[propertyName]) {
                (this.state.formItem as any)[propertyName].forEach((selectedOption: string) => {
                    newOptions.push(selectedOption);
                });
            }
            if (checked && !newOptions.includes(checkBoxValue)) {
                newOptions.push(checkBoxValue);
            } else {
                newOptions.splice(newOptions.indexOf(checkBoxValue), 1);
            }
            (this.state.formItem as any)[propertyName] = newOptions;
        }
        this.setState({
            formItem: this.state.formItem,
        });
        this._clearOtherFieldValues(propertyName);
        this._clearErrorMessage(propertyName);
    }

    private _loadCheckboxes(propertyName: string, choices: ISelectableOptionWithTooltip[], componentRef?: IRefObject<any>, width?: number) {
        const Checkboxes: JSX.Element[] = [];
        const newOptions: string[] = [];
        const checkboxStyles = (styleProps: ICheckboxStyleProps): ICheckboxStyles => {
            const chkStyles: ICheckboxStyles = width ? {
                root: {
                    width: width,
                }, label: { alignItems: 'flex-start' }, checkbox: [
                    { backgroundColor: "white" },
                    styleProps.checked && {
                        backgroundColor: "rgb(0, 90, 158)",
                    },
                ],
            } : {
                label: { alignItems: 'flex-start' }, checkbox: [
                    { backgroundColor: "white" },
                    styleProps.checked && {
                        backgroundColor: "rgb(0, 90, 158)",
                    },
                ],
            };
            return chkStyles;
        };
        if ((this.state.formItem as any)[propertyName]) {
            (this.state.formItem as any)[propertyName].forEach((selectedOption: string) => {
                newOptions.push(selectedOption);
            });
        }
        choices.forEach((choice: ISelectableOptionWithTooltip, index) => {
            let isChecked: boolean = newOptions.includes((choice.key as string)) ? true : false;
            if (choice.tooltipText) {
                Checkboxes.push(
                    <span key={index} style={{ display: 'flex' }}>
                        <Checkbox {...(componentRef && Checkboxes.length === 0 ? { componentRef: componentRef } : {})} styles={checkboxStyles} key={(choice.key as string)} label={(choice.text as string)} name={(choice.key as string)} checked={isChecked} onChange={(ev, checked) => { this._onCheckBoxChange(propertyName, ev, checked); }}
                            onRenderLabel={(p) => { return <span className="ms-Checkbox-text" style={{ marginLeft: '4px', color: 'rgb(50, 49, 48)' }}>{choice.customLabel ? choice.customLabel : choice.text}</span> }}
                        />
                        <TooltipHost
                            styles={{ root: { marginLeft: '8px', alignSelf: 'center' } }}
                            content={choice.tooltipText}
                            tooltipProps={{
                                styles: {
                                    content: { fontWeight: '600' }
                                }
                            }}>
                            <Icon iconName="Info" />
                        </TooltipHost>
                    </span>
                );
            } else {
                Checkboxes.push(
                    <Checkbox {...(componentRef && Checkboxes.length === 0 ? { componentRef: componentRef } : {})} styles={checkboxStyles} key={(choice.key as string)} label={(choice.text as string)} name={(choice.key as string)} checked={isChecked} onChange={(ev, checked) => { this._onCheckBoxChange(propertyName, ev, checked); }}
                        onRenderLabel={(p) => { return <span className="ms-Checkbox-text" style={{ marginLeft: '4px', color: 'rgb(50, 49, 48)' }}>{choice.customLabel ? choice.customLabel : choice.text}</span> }}
                    />
                );
            }
        });
        return Checkboxes;
    }

    private _setProjectTypeChoices() {
        const choices: ISelectableOptionWithTooltip[] = [];
        choices.push({ key: "Needs Assessment", text: "Needs Assessment", });
        choices.push({ key: "Program Evaluation", text: "Program Evaluation", });
        choices.push({ key: "Quality Improvement", text: "Quality Improvement", });
        choices.push({ key: "Other (Please specify)", text: "Other (Please specify)", });
        return choices;
    }

    private _setAttachedDocumentsInfoChoices() {
        const choices: ISelectableOptionWithTooltip[] = [];
        choices.push({ key: "Completed ARECCI Ethics Screening Tool", text: "Completed ARECCI Ethics Screening Tool", });
        choices.push({ key: "Completed ARECCI Ethics Guidelines Tool", text: "Completed ARECCI Ethics Guidelines Tool", });
        choices.push({ key: "Data Collection Tool(s) (if applicable)", text: "Data Collection Tool(s) (if applicable)", });
        choices.push({ key: "Project Charter or Proposal (if applicable)", text: "Project Charter or Proposal (if applicable)", });
        choices.push({ key: "Additional Material to support the review", text: "Additional Material to support the review", });
        return choices;
    }

    private _displayClearDialog() {
        this.setState({
            clearDialogHidden: false,
        });
    }

    private _displaySubmitDialog() {
        this.setState({
            submitDialogHidden: false,
        });
    }

    private _displayAmendDialog() {
        this.setState({
            amendDialogHidden: false,
        });
    }

    private _displayHelpDialog() {
        this.setState({
            helpRequestDialogHidden: false,
        });
    }

    private async _dismissClearDialog() {
        this.setState({
            clearDialogHidden: true
        });
    }

    private async _dismissSubmitDialog() {
        this.setState({
            submitDialogHidden: true
        });
    }

    private async _dismissAmendDialog() {
        this.setState({
            amendDialogHidden: true
        });
    }

    private async _dismissHelpDialog() {
        this.setState({
            helpRequestDialogHidden: true
        });
    }

    private async _dismissNotificationDialog() {
        if (this.state.redirectToDashboard) {
            this._clearFormTimer();
            this.props.history.push('/');
        } else {
            this.setState({
                notificationDialogHidden: true,
            });
        }
    }

    private async _getFormConfiguration() {
        const formConfiguration = await this.dataService.getFormConfiguration(this.applicationName);
        this.setState({
            formConfiguration: formConfiguration,
        });
    }

    private async _getFormARECCISecondOpinion(applicationId: string) {
        const formOperationOutput = await this.dataService.getFormItem(this.applicationName, applicationId);
        let formItem = (formOperationOutput.formItem as FormARECCISecondOpinionResource);
        if (formItem.status === "Submitted" || formItem.status === "Rejected") {
            const amendmentItem = new FormARECCISecondOpinionResource();
            amendmentItem.applicationId = formItem.applicationId;
            amendmentItem.amendmentId = formItem.amendmentId;
            amendmentItem.edgeId = formItem.edgeId;
            amendmentItem.userId = formItem.userId;
            amendmentItem.status = formItem.status;
            formItem = amendmentItem;
        }
        if (formOperationOutput.completed) {
            this.setState({
                formItem: formItem,
                formItemLastSaved: { ...formItem },
                formVisible: true,
            });
            await this.uploadRef.current && this.uploadRef.current!.callGetAttachments(formItem);
        } else {
            alert('Could not get form data.');
            this.props.history.push('/');
        }
    }

    private _clearOtherFieldValues(propertyName: string) {
        let changed = false;
        const formItem = this.state.formItem as FormARECCISecondOpinionResource;
        switch (propertyName) {
            case 'projectType':
                if (!formItem.projectType.includes('Other (Please specify)') && formItem.projectTypeOther !== "") {
                    formItem.projectTypeOther = "";
                    changed = true;
                }
                break;
        }
        if (changed) {
            this.setState({
                formItem: formItem,
            });
        }
    }

    private _clearErrorMessage(propertyName: string) {
        const properties: string[] = [];
        const formItem = this.state.formItem as FormARECCISecondOpinionResource;
        properties.push(propertyName);
        switch (propertyName) {
            case "projectType":
                if (!formItem.projectType.includes('Other (Please specify)')) {
                    properties.push('projectTypeOther');
                }
                break;
        }
        const formValidation = this.state.formValidation;
        let changed = false;
        properties.forEach((property) => {
            const formValidationItem = this.utils.getFormValidationItem(formValidation, property);
            if (formValidationItem && !formValidationItem.isValid) {
                formValidationItem.isValid = true;
                changed = true;
            }
        });
        if (changed) {
            this.setState({
                formValidation: formValidation,
            });
        }
    }

    private _validateForm() {
        let isValid = true;
        const formItem = this.state.formItem as FormARECCISecondOpinionResource;
        const formValidation = this.state.formValidation;
        let focusSet = false;
        let focusItem: IRefObject<any> | null = null;
        for (const formValidationItem of formValidation) {
            switch (formValidationItem.validationType) {
                case 'required':
                    if ((formItem as any)[formValidationItem.propertyName] === undefined || (formItem as any)[formValidationItem.propertyName] === null || (formItem as any)[formValidationItem.propertyName].length === 0) {
                        isValid = false;
                        formValidationItem.isValid = false;
                        if (!focusSet) {
                            focusItem = formValidationItem.propertyRef;
                            focusSet = true;
                        }
                    }
                    break;
                case 'custom':
                    switch (formValidationItem.propertyName) {
                        case 'projectSponsorEmail':
                            if (formItem.projectSponsorEmail.length > 0 && !this.utils.isValidEmail(formItem.projectSponsorEmail)) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                formValidationItem.errorMessage = 'Please enter a valid email address';
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'projectManagerEmail':
                            if (formItem.projectManagerEmail.length > 0 && !this.utils.isValidEmail(formItem.projectManagerEmail)) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                formValidationItem.errorMessage = 'Please enter a valid email address';
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'projectTypeOther':
                            if (formItem.projectType.includes('Other (Please specify)') && formItem.projectTypeOther.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'targetCompletionDate':
                            if (!formItem.targetStartDate && formItem.targetCompletionDate) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                formValidationItem.errorMessage = 'No target start date informed. Please enter the date';
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            if (formItem.targetStartDate && formItem.targetCompletionDate && (formItem.targetStartDate > formItem.targetCompletionDate)) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'formAgreement':
                            if (!this.state.formAgreement) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                    }
                    break;
            }
        }
        if (!isValid) {
            this.setState({
                formValidation: formValidation,
            });
            this._dismissSubmitDialog().then(() => {
                if (focusItem) {
                    (focusItem as any).current.focus();
                }
            });
        }
        return isValid;
    }

    private async _saveFormARECCISecondOpinion(disableNotification?: boolean) {
        await this._updateToken();
        let formOperationOutput: IFormMethodOutput;
        const formItem = this.state.formItem;
        if (this.state.formItem.applicationId.length === 0) {
            formOperationOutput = await this.dataService.insertFormItem(this.applicationName, formItem);
        } else {
            formOperationOutput = await this.dataService.updateFormItem(this.applicationName, formItem);
        }
        if (formOperationOutput.completed) {
            await this.uploadRef.current && this.uploadRef.current!.uploadFiles(formOperationOutput.formItem as FormARECCISecondOpinionResource);
            this.setState({
                formItem: (formOperationOutput.formItem as FormARECCISecondOpinionResource),
                formItemLastSaved: { ...(formOperationOutput.formItem as FormARECCISecondOpinionResource) },
                notificationDialogHidden: disableNotification ? true : false,
                notificationDialogTitle: 'Save Form',
                notificationDialogMessage: `Application (${formOperationOutput.formItem?.applicationId}) saved. \n\nThis application is in draft form and will not be processed until it is submitted. \n\nRemember that this application is available on the dashboard. You can come back and continue completing your application later.`,
            });
        } else {
            this.setState({
                notificationDialogHidden: disableNotification ? true : false,
                notificationDialogTitle: 'Error',
                notificationDialogMessage: 'An error occurred while saving the application.',
            });
        }
    }

    private async _submitFormARECCISecondOpinion() {
        if (this._validateForm()) {
            await this._saveFormARECCISecondOpinion(true);
            if (this.state.formItem.applicationId !== '') {
                const formOperationOutput = await this.dataService.submitFormItem(this.applicationName, this.state.formItem.applicationId);
                if (formOperationOutput.completed) {
                    this._clearFormTimer();
                    this.setState({
                        submitDialogHidden: true,
                        notificationDialogHidden: false,
                        notificationDialogTitle: 'Submit Application',
                        notificationDialogMessage: `This request has been completed and submitted. \n\nPlease take note of the Application ID: (${formOperationOutput.formItem?.applicationId}). Remember to check your e-mail regularly, as it will be the main source of contact during the approval process. \n\nSending this request does not guarantee its approval.`,
                        redirectToDashboard: true,
                    });
                } else {
                    this.setState({
                        submitDialogHidden: true,
                        notificationDialogHidden: false,
                        notificationDialogTitle: 'Error',
                        notificationDialogMessage: 'An error occurred while submitting the application.',
                    });
                }
            } else {
                this.setState({
                    submitDialogHidden: true,
                    notificationDialogHidden: false,
                    notificationDialogTitle: 'Error',
                    notificationDialogMessage: 'An error occurred while submitting the application.',
                });
            }
        }
    }

    private async _amendFormARECCISecondOpinion() {
        await this._updateToken();
        const formItem = this.state.formItem
        const formOperationOutput = await this.dataService.amendFormItem(this.applicationName, formItem);
        if (formOperationOutput.completed) {
            await this.uploadRef.current && this.uploadRef.current!.uploadFiles(formOperationOutput.formItem as FormARECCISecondOpinionResource);
            this._clearFormTimer();
            this.setState({
                amendDialogHidden: true,
                notificationDialogHidden: false,
                notificationDialogTitle: 'Amend Application',
                notificationDialogMessage: `Application (${formOperationOutput.formItem?.applicationId}) amended.`,
                redirectToDashboard: true,
            });
        } else {
            this.setState({
                amendDialogHidden: true,
                notificationDialogHidden: false,
                notificationDialogTitle: 'Error',
                notificationDialogMessage: 'An error occurred while amending the application.',
            });
        }
    }

    private async _helpRequestFormARECCISecondOpinion() {
        await this._updateToken();
        let formOperationOutput: IFormMethodOutput;
        const formItem = this.state.formItem;
        formItem.status = 'Help Requested';
        if (this.state.formItem.applicationId.length === 0) {
            formOperationOutput = await this.dataService.insertFormItem(this.applicationName, formItem);
        } else {
            formOperationOutput = await this.dataService.updateFormItem(this.applicationName, formItem);
        }
        if (formOperationOutput.completed) {
            if (this.state.formItem.applicationId !== '') {
                const formOperationOutput = await this.dataService.requestHelpFormItem(this.applicationName, this.state.formItem.applicationId);
                if (formOperationOutput.completed) {
                    this.setState({
                        helpRequestDialogHidden: true,
                        formItem: (formOperationOutput.formItem as FormARECCISecondOpinionResource),
                        notificationDialogHidden: false,
                        notificationDialogTitle: 'Request Help',
                        notificationDialogMessage: `Application (${formOperationOutput.formItem?.applicationId}) saved. \n\nA case manager will contact you to help.`,
                        redirectToDashboard: true,
                    });
                } else {
                    this.setState({
                        helpRequestDialogHidden: true,
                        notificationDialogHidden: false,
                        notificationDialogTitle: 'Error',
                        notificationDialogMessage: 'An error occurred while requesting help.',
                    });
                }
            } else {
                this.setState({
                    helpRequestDialogHidden: true,
                    notificationDialogHidden: false,
                    notificationDialogTitle: 'Error',
                    notificationDialogMessage: 'An error occurred while requesting help.',
                });
            }
        } else {
            this.setState({
                helpRequestDialogHidden: true,
                notificationDialogHidden: false,
                notificationDialogTitle: 'Error',
                notificationDialogMessage: 'An error occurred while requesting help.',
            });
        }
    }

    private async _clearFormARECCISecondOpinion() {
        await this._updateToken();
        const clearedItem = new FormARECCISecondOpinionResource();
        clearedItem.applicationId = this.state.formItem.applicationId;
        clearedItem.amendmentId = this.state.formItem.amendmentId;
        clearedItem.edgeId = this.state.formItem.edgeId;
        clearedItem.userId = this.state.formItem.userId;
        clearedItem.status = this.state.formItem.status;
        const formValidation = this.state.formValidation;
        for (const formValidationItem of formValidation) {
            formValidationItem.isValid = true;
        }
        // delete attachments if any
        if (this.state.formItem.applicationId.length > 0) {
            const attachments = await this.dataService.getFormAttachments(this.applicationName, this.state.formItem);
            for (const attachment of attachments) {
                await this.dataService.deleteFormAttachment(attachment.path);
            }
        }
        this.setState({
            formItem: clearedItem,
            formValidation: formValidation,
            ...(this.state.uploadElementKey && { uploadElementKey: this.state.uploadElementKey + 1 }),
        });
        this._dismissClearDialog().then(() => {
            if (this.submissionDateRef) {
                (this.submissionDateRef as any).current.focus();
            }
        });
    }

    private async _retrieveFormInformation() {
        await this._getUserInfo();
        await this._getFormConfiguration();
        this.saveIntervalId = this._saveFormTimer();
        if (this.pathApplicationId.length > 0 && this.pathApplicationId !== 'FormARECCISecondOpinion') {
            this._getFormARECCISecondOpinion(this.pathApplicationId);
        } else {
            if (this.state.formItem.applicationId.length === 0) {
                const formItem = this.state.formItem as FormARECCISecondOpinionResource;
                formItem.UserFirstName = this.state.UserFirstName;
                formItem.UserLastName = this.state.UserLastName;
                formItem.UserEmail = this.state.UserEmail;
                formItem.UserPhoneNumber = this.state.UserPhoneNumber;
                await this._saveFormARECCISecondOpinion(true);
            }
            this.setState({
                formVisible: true
            });
        }
    }

    private async _getUserInfo() {
        const authResult = await Auth.acquireTokenSilent();
        if (authResult) {
            this.setState({
                UserFirstName: (authResult.idTokenClaims as any).given_name ? (authResult.idTokenClaims as any).given_name : '',
                UserLastName: (authResult.idTokenClaims as any).family_name ? (authResult.idTokenClaims as any).family_name : '',
                UserEmail: (authResult.idTokenClaims as any).emails[0] ? (authResult.idTokenClaims as any).emails[0] : '',
                UserPhoneNumber: (authResult.idTokenClaims as any).extension_PhoneNumber ? (authResult.idTokenClaims as any).extension_PhoneNumber : '',
            });
        }
    }

    private _clearFormTimer() {
        window.clearInterval(this.saveIntervalId);
    }

    private _saveFormTimer(): number {
        return window.setInterval(() => {
            if (this.state.formItem.status === 'Draft' && JSON.stringify(this.state.formItem) !== JSON.stringify(this.state.formItemLastSaved)) {
                this._saveFormARECCISecondOpinion(true);
            }
        }, AUTO_SAVE_INTERVAL);
    }

    private async _updateToken() {
        try {
            await this.dataService.getToken();
        } catch (error) {
            this.props.history.push('/logout');
        }
    }

    public componentDidMount() {
        this._retrieveFormInformation();
    }

    public render(): React.ReactElement {
        const formItem = this.state.formItem as FormARECCISecondOpinionResource;
        const { formConfiguration, formVisible, formValidation, UserFirstName, UserLastName, UserEmail, clearDialogHidden, submitDialogHidden, amendDialogHidden, helpRequestDialogHidden, notificationDialogHidden, notificationDialogTitle, notificationDialogMessage } = this.state;
        const spinner = !formVisible ? <Spinner size={SpinnerSize.large} label="Loading..." ariaLive="assertive" /> : null;
        const projectTypeCheckBoxes = this._loadCheckboxes("projectType", this.projectTypeChoices);
        const attachedDocumentsInfoCheckBoxes = this._loadCheckboxes("attachedDocumentsInfo", this.attachedDocumentsInfoChoices);
        const clearFormButton = <DefaultButton className="clearButton" text="Clear Form" onClick={this._displayClearDialog.bind(this)} />;
        const saveButton = formItem.status === 'Draft' ? <DefaultButton text="Save Form" onClick={() => this._saveFormARECCISecondOpinion()} /> : null;
        const submitButton = formItem.status === 'Draft' ? <PrimaryButton text="Submit Form" onClick={this._displaySubmitDialog.bind(this)} /> : null;
        const amendButton = (formItem.status === 'Submitted' || formItem.status === 'Rejected') ? <PrimaryButton text="Amend Form" onClick={this._displayAmendDialog.bind(this)} /> : null;
        const helpRequestButton = formItem.status === 'Draft' ? <DefaultButton className="floatHelpButton" text="Request Help" onClick={this._displayHelpDialog.bind(this)} /> : null;
        return (
            <main className="App-formbody">
                <Navigation
                    title="Request for ARECCI Second Opinion Review"
                    isForm={true}
                    UserFirstName={UserFirstName}
                    UserLastName={UserLastName}
                    UserEmail={UserEmail}
                    clearFormTimer={this._clearFormTimer.bind(this)} />
                {spinner}
                <form style={!formVisible ? { display: 'none' } : {}}>
                    <section className="applicationIDSection">
                        <span className='col1'><h2>Application Number: {formItem.applicationId}</h2></span>
                        <span className='col2'><img src={FormLogo} alt="Form logo" /></span>
                    </section>
                    <section>
                        <h2>Project Information</h2>
                        <Stack wrap styles={{ root: { height: 90, maxWidth: 800 } }} >
                            <Label>Date of submission</Label>
                            <DatePicker
                                showGoToToday
                                ariaLabel='Date of submission'
                                styles={{ root: { maxWidth: 390, width: '100%' } }}
                                strings={defaultCalendarStrings}
                                componentRef={this.submissionDateRef}
                                value={!formItem.submissionDate ? undefined : formItem.submissionDate}
                                formatDate={(date?: Date | undefined): string => { return dateFormat(date!, "dd/mm/yy"); }}
                                onSelectDate={(date) => { this._onDatePickerChange("submissionDate", date); }}
                            />
                            <TextField
                                label="Project Sponsor (Name)"
                                styles={TEXT_DOUBLE_COLUMN}
                                value={formItem.projectSponsor}
                                onChange={(event, newValue) => { this._onTextFieldChange("projectSponsor", event, newValue); }}
                            />
                        </Stack>
                        <Stack wrap styles={{ root: { height: 90, maxWidth: 800 } }} >
                            <TextField
                                errorMessage={this.utils.getErrorMessage(formValidation, 'projectSponsorEmail')}
                                componentRef={this.projectSponsorEmailRef}
                                label="Project Sponsor (Email)"
                                styles={TEXT_DOUBLE_COLUMN}
                                value={formItem.projectSponsorEmail}
                                onChange={(event, newValue) => { this._onTextFieldChange("projectSponsorEmail", event, newValue); }}
                            />
                            <TextField
                                label="Project Sponsor (Organization)"
                                styles={TEXT_DOUBLE_COLUMN}
                                value={formItem.projectSponsorOrganization}
                                onChange={(event, newValue) => { this._onTextFieldChange("projectSponsorOrganization", event, newValue); }}
                            />
                        </Stack>
                        <Stack wrap styles={{ root: { height: 90, maxWidth: 800 } }} >
                            <TextField
                                label="Project Lead/Manager (Name)"
                                styles={TEXT_DOUBLE_COLUMN}
                                value={formItem.projectManager}
                                onChange={(event, newValue) => { this._onTextFieldChange("projectManager", event, newValue); }}
                            />
                            <TextField
                                label="Position"
                                styles={TEXT_DOUBLE_COLUMN}
                                value={formItem.projectManagerPosition}
                                onChange={(event, newValue) => { this._onTextFieldChange("projectManagerPosition", event, newValue); }}
                            />
                        </Stack>
                        <Stack wrap styles={{ root: { height: 90, maxWidth: 800 } }} >
                            <TextField
                                label="Phone"
                                styles={TEXT_DOUBLE_COLUMN}
                                value={formItem.projectManagerPhone}
                                onChange={(event, newValue) => { this._onTextFieldChange("projectManagerPhone", event, newValue); }}
                            />
                            <TextField
                                errorMessage={this.utils.getErrorMessage(formValidation, 'projectManagerEmail')}
                                componentRef={this.projectManagerEmailRef}
                                label="Email"
                                styles={TEXT_DOUBLE_COLUMN}
                                value={formItem.projectManagerEmail}
                                onChange={(event, newValue) => { this._onTextFieldChange("projectManagerEmail", event, newValue); }}
                            />
                        </Stack>
                        <TextField
                            label="Project Title"
                            styles={TEXT_SINGLE_COLUMN}
                            value={formItem.projectTitle}
                            onChange={(event, newValue) => { this._onTextFieldChange("projectTitle", event, newValue); }}
                        />
                        <Label>Purpose of Project (Brief Description - Non-confidential)</Label>
                        <Label style={{ margin: 0, marginTop: '-10px', fontSize: '0.875rem', color: '#8a8a8a', fontWeight: 400 }}><ul style={{ margin: 0, padding: '0 0 0 18px', listStyle: 'circle' }}>
                            <li>Aim statement</li>
                            <li>Rationale for project</li>
                            <li>Describe current thinking about activities of project</li>
                            <li>What are you trying to accomplish?</li>
                            <li>Anticipated Outcomes</li>
                        </ul></Label>
                        <TextField
                            styles={TEXT_SINGLE_COLUMN}
                            multiline={true}
                            rows={5}
                            value={formItem.projectPurpose}
                            onChange={(event, newValue) => { this._onTextFieldChange("projectPurpose", event, newValue); }}
                        />
                        <Label>Type of Project</Label>
                        <Stack tokens={{ childrenGap: 10 }}>
                            {projectTypeCheckBoxes}
                        </Stack>
                        <TextField
                            disabled={!formItem.projectType.includes('Other (Please specify)')}
                            styles={{ root: { maxWidth: 400, width: '100%', } }}
                            ariaLabel="Type of Project, Other description"
                            errorMessage={this.utils.getErrorMessage(formValidation, 'projectTypeOther')}
                            componentRef={this.projectTypeOtherRef}
                            value={formItem.projectTypeOther}
                            onChange={(event, newValue) => { this._onTextFieldChange("projectTypeOther", event, newValue); }}
                        />
                        <TextField
                            label="Project Site"
                            styles={TEXT_SINGLE_COLUMN}
                            value={formItem.projectSite}
                            onChange={(event, newValue) => { this._onTextFieldChange("projectSite", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h2>Estimated Timeline</h2>
                        <Stack wrap styles={{ root: { height: 90, maxWidth: 800 } }} >
                            <Label>Target Start Date</Label>
                            <DatePicker
                                showGoToToday
                                ariaLabel='Target Start Date'
                                styles={{ root: { maxWidth: 390, width: '100%' } }}
                                strings={defaultCalendarStrings}
                                value={!formItem.targetStartDate ? undefined : formItem.targetStartDate}
                                formatDate={(date?: Date | undefined): string => { return dateFormat(date!, "dd/mm/yy"); }}
                                onSelectDate={(date) => { this._onDatePickerChange("targetStartDate", date); }}
                            />
                            <div style={{ width: '100%', marginLeft: '20px' }}>
                                <Label>Target Completion Date</Label>
                                <DatePickerWithValidation
                                    showGoToToday
                                    styles={{ root: { maxWidth: 390, width: '100%' } }}
                                    ariaLabel='Target Completion Date'
                                    strings={defaultCalendarStrings}
                                    errorMessage={this.utils.getErrorMessage(formValidation, 'targetCompletionDate')}
                                    componentRef={this.targetCompletionDateRef}
                                    value={!formItem.targetCompletionDate ? undefined : formItem.targetCompletionDate}
                                    formatDate={(date?: Date | undefined): string => { return dateFormat(date!, "dd/mm/yy"); }}
                                    onSelectDate={(date) => { this._onDatePickerChange("targetCompletionDate", date); }}
                                />
                            </div>
                        </Stack>
                    </section>
                    <section>
                        <TextField
                            label="ARECCI Screening Score"
                            styles={TEXT_SINGLE_COLUMN}
                            value={formItem.ARECCIScreeningScore}
                            onChange={(event, newValue) => { this._onTextFieldChange("ARECCIScreeningScore", event, newValue); }}
                        />
                        <Label>Areas of Ethical Concern in the Project</Label>
                        <Label style={{ margin: 0, marginTop: '-10px', fontSize: '0.875rem', color: '#8a8a8a', fontWeight: 400 }}>Please explain the elevated ARECCI Score (Describe Project specific details)</Label>
                        <TextField
                            styles={TEXT_SINGLE_COLUMN}
                            multiline={true}
                            rows={5}
                            value={formItem.areasEthicalConcern}
                            onChange={(event, newValue) => { this._onTextFieldChange("areasEthicalConcern", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h2>Attachments</h2>
                        <Label>Please attach a copy of the following documents, if available:</Label>
                        <Stack tokens={{ childrenGap: 10 }}>
                            {attachedDocumentsInfoCheckBoxes}
                        </Stack>
                        <Upload
                            uploadKey={this.state.uploadElementKey}
                            ref={this.uploadRef}
                            applicationName={this.applicationName}
                            fileSizeLimit={this.fileSizeLimit} />
                    </section>
                    <section>
                        <h2>Additional Information</h2>
                        <TextField
                            label="Additional Comments/Notes"
                            styles={TEXT_SINGLE_COLUMN}
                            multiline={true}
                            rows={5}
                            value={formItem.additionalComments}
                            onChange={(event, newValue) => { this._onTextFieldChange("additionalComments", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h1>Disclaimer</h1>
                        <h2>{this.utils.getConfigurationValue(formConfiguration, 'TermsOfUseTitle')}</h2>
                        <Label className='renderLinefeed'>{this.utils.getConfigurationValue(formConfiguration, 'TermsOfUse')}</Label>
                        <br />
                        <h2>{this.utils.getConfigurationValue(formConfiguration, 'UseDiscloseWarrantyTitle')}</h2>
                        <Label className='renderLinefeed'>{this.utils.getConfigurationValue(formConfiguration, 'UseDiscloseWarranty')}</Label>
                        <Label>If you have a question or concerns about any collection, use or disclosure of information by EDGE Alberta, please contact the ARECCI Manager, by email at <a href='mailto:ARECCI@albertainnovates.ca'>ARECCI@albertainnovates.ca</a></Label>
                        <CheckboxWithValidation
                            label={"I Agree"}
                            componentRef={this.formAgreementRef}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'formAgreement')}
                            onChange={(event, checked) => { this._onAgreementChange("formAgreement", event, checked); }}
                        />
                        {helpRequestButton}
                        <div style={{ display: 'grid', gridTemplateColumns: '115px 140px auto 130px', gridTemplateRows: 'auto', marginTop: '15px' }}>
                            <div style={{ gridArea: '1 / 1 / 1 / 1' }}>
                                {clearFormButton}
                            </div>
                            <div style={{ gridArea: '1 / 2 / 1 / 2', marginLeft: '10px' }}>
                                {saveButton}
                                {amendButton}
                            </div>
                            <div style={{ gridArea: '1 / 4 / 1 / 4', textAlign: 'right' }}>
                                {submitButton}
                            </div>
                        </div>
                    </section>
                    <Dialog
                        className='renderLinefeed'
                        hidden={clearDialogHidden}
                        onDismiss={() => { this._dismissClearDialog(); }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: 'Clear Form',
                            subText: 'Are you sure you want to clear the form? \nThis will remove all entered data.',
                        }}
                        modalProps={{
                            isBlocking: true,
                            styles: { main: { maxWidth: 450 } },
                            dragOptions: {
                                moveMenuItemText: 'Move',
                                closeMenuItemText: 'Close',
                                menu: ContextualMenu,
                            },
                        }}
                    >
                        <DialogFooter>
                            <DefaultButton onClick={() => { this._dismissClearDialog(); }} text="Cancel" />
                            <PrimaryButton onClick={() => { this._clearFormARECCISecondOpinion(); }} text="Yes, Clear Form" />
                        </DialogFooter>
                    </Dialog>
                    <Dialog
                        className='renderLinefeed'
                        hidden={submitDialogHidden}
                        onDismiss={() => { this._dismissSubmitDialog(); }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: 'Submit application',
                            subText: `Are you sure you want to submit this application ${formItem.applicationId.length > 0 ? '(' + formItem.applicationId + ')' : ''}?`,
                        }}
                        modalProps={{
                            isBlocking: true,
                            styles: { main: { maxWidth: 450 } },
                            dragOptions: {
                                moveMenuItemText: 'Move',
                                closeMenuItemText: 'Close',
                                menu: ContextualMenu,
                            },
                        }}
                    >
                        <DialogFooter>
                            <DefaultButton onClick={() => { this._dismissSubmitDialog(); }} text="Cancel" />
                            <PrimaryButton onClick={() => { this._submitFormARECCISecondOpinion(); }} text="Yes, Submit Application" />
                        </DialogFooter>
                    </Dialog>
                    <Dialog
                        className='renderLinefeed'
                        hidden={amendDialogHidden}
                        onDismiss={() => { this._dismissAmendDialog(); }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: 'Amend Application',
                            subText: `You are about to modify your submitted application ${formItem.applicationId}. \nPlease select "Yes, Amend Application" to proceed with your changes.`,
                        }}
                        modalProps={{
                            isBlocking: true,
                            styles: { main: { maxWidth: 450 } },
                            dragOptions: {
                                moveMenuItemText: 'Move',
                                closeMenuItemText: 'Close',
                                menu: ContextualMenu,
                            },
                        }}
                    >
                        <DialogFooter>
                            <DefaultButton onClick={() => { this._dismissAmendDialog(); }} text="Cancel" />
                            <PrimaryButton onClick={() => { this._amendFormARECCISecondOpinion(); }} text="Yes, Amend Application" />
                        </DialogFooter>
                    </Dialog>
                    <Dialog
                        className='renderLinefeed'
                        hidden={helpRequestDialogHidden}
                        onDismiss={() => { this._dismissHelpDialog(); }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: 'Request Help',
                            subText: `Do you want to save the form as is and request help from the case manager to complete filling the form? Please complete as much fields as possible before requesting help.`,
                        }}
                        modalProps={{
                            isBlocking: true,
                            styles: { main: { maxWidth: 450 } },
                            dragOptions: {
                                moveMenuItemText: 'Move',
                                closeMenuItemText: 'Close',
                                menu: ContextualMenu,
                            },
                        }}
                    >
                        <DialogFooter>
                            <DefaultButton onClick={() => { this._dismissHelpDialog(); }} text="Cancel" />
                            <PrimaryButton onClick={() => { this._helpRequestFormARECCISecondOpinion(); }} text="Yes, request for help" />
                        </DialogFooter>
                    </Dialog>
                    <Dialog
                        className='renderLinefeed'
                        hidden={notificationDialogHidden}
                        onDismiss={() => { this._dismissNotificationDialog(); }}
                        dialogContentProps={{
                            type: DialogType.close,
                            title: notificationDialogTitle,
                            subText: notificationDialogMessage,
                        }}
                    />
                </form>
            </main>
        );
    }
}