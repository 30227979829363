import React, { createRef } from 'react';
import DataService from '../data/DataService';
import { FormDirectoryIntakeResource } from '../dto/FormDirectoryIntakeResource';
import { IFormMethodOutput } from '../interfaces/IFormMethodOutput';
import { IFormConfiguration } from '../interfaces/IFormConfiguration';
import { IFormState } from '../interfaces/IFormState';
import { IFormValidation } from '../interfaces/IFormValidation';
import Navigation from '../layouts/Navigation';
import { Checkbox, ChoiceGroup, IChoiceGroupOption, Label, PrimaryButton, Spinner, SpinnerSize, Stack, TextField, IChoiceGroupOptionStyles, IRefObject, DefaultButton, DialogFooter, Dialog, DialogType, ContextualMenu, TooltipHost, Icon, ICheckboxStyleProps, ICheckboxStyles, ISelectableOption } from '@fluentui/react';
import ChoiceGroupWithValidation from '../components/ChoiceGroupWithValidation';
import Utils from '../utils/Utils';
import Auth from '../Auth';
import { RouteComponentProps } from 'react-router-dom';
import { ISelectableOptionWithTooltip } from '../interfaces/ISelectableOptionWithTooltip';
import { TEXT_SINGLE_COLUMN, FILE_SIZE_LIMIT, AUTO_SAVE_INTERVAL } from '../constants/Constants';

export interface ISpecialityArea {
    groupName: string;
    description: string;
    value: string;
}

export interface IFormDirectoryIntakeProps extends RouteComponentProps {
}

export default class FormDirectoryIntake extends React.Component<IFormDirectoryIntakeProps, IFormState> {
    applicationName: string = 'DirectoryIntakeForm';
    fileSizeLimit: number = FILE_SIZE_LIMIT;
    dataService: DataService = new DataService();
    utils: Utils = new Utils();
    pathApplicationId = (window.location.pathname.split('/').pop() as string);
    saveIntervalId: number;

    // create and set choices fields (checkbox and dropdown (ISelectableOptionWithTooltip[]), radio (IChoiceGroupOption[]))
    roleChoices: IChoiceGroupOption[] = this._setRoleChoices();
    researchTypeChoices: IChoiceGroupOption[] = this._setResearchTypeChoices();
    covenantHealthPrivilegesChoices: IChoiceGroupOption[] = this._setCovenantHealthPrivilegesChoices();
    CoPInfoChoices: ISelectableOptionWithTooltip[] = this._setCoPInfoChoices();

    // create a ref for each field that can be focused. For example: On a validation error
    IntakeUserNameRef: IRefObject<any> = createRef();
    UserTitleRef: IRefObject<any> = createRef();
    UserCompanyRef: IRefObject<any> = createRef();
    UserDepartmentRef: IRefObject<any> = createRef();
    UserEmailRef: IRefObject<any> = createRef();
    roleRef: IRefObject<any> = createRef();
    roleOtherRef: IRefObject<any> = createRef();
    specialityAreaRef: IRefObject<any> = createRef();
    specialityAreaCancerOtherRef: IRefObject<any> = createRef();
    specialityAreaOtherRef: IRefObject<any> = createRef();
    topicsRef: IRefObject<any> = createRef();
    researchTypeRef: IRefObject<any> = createRef();
    researchTypeOtherRef: IRefObject<any> = createRef();
    CoPInfoRef: IRefObject<any> = createRef();
    CoPInfoOtherRef: IRefObject<any> = createRef();

    constructor(props: IFormDirectoryIntakeProps) {
        super(props);
        const formConfiguration: IFormConfiguration[] = [];
        const formItem: FormDirectoryIntakeResource = new FormDirectoryIntakeResource();
        const formItemLastSaved: FormDirectoryIntakeResource = new FormDirectoryIntakeResource();
        const formValidation: IFormValidation[] = [];
        // add validation information for each field to be validated
        formValidation.push({ propertyName: 'IntakeUserName', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.IntakeUserNameRef });
        formValidation.push({ propertyName: 'UserTitle', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.UserTitleRef });
        formValidation.push({ propertyName: 'UserCompany', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.UserCompanyRef });
        formValidation.push({ propertyName: 'UserDepartment', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.UserDepartmentRef });
        formValidation.push({ propertyName: 'UserEmail', isValid: true, validationType: 'custom', errorMessage: '', propertyRef: this.UserEmailRef });
        formValidation.push({ propertyName: 'role', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.roleRef });
        formValidation.push({ propertyName: 'roleOther', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.roleOtherRef });
        formValidation.push({ propertyName: 'specialityArea', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.specialityAreaRef });
        formValidation.push({ propertyName: 'specialityAreaCancerOther', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.specialityAreaCancerOtherRef });
        formValidation.push({ propertyName: 'specialityAreaOther', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.specialityAreaOtherRef });
        formValidation.push({ propertyName: 'topics', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.topicsRef });
        formValidation.push({ propertyName: 'researchTypeOther', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.researchTypeOtherRef });
        formValidation.push({ propertyName: 'CoPInfoOther', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.CoPInfoOtherRef });

        this.state = {
            formConfiguration: formConfiguration,
            formItem: formItem,
            formItemLastSaved: formItemLastSaved,
            formVisible: false,
            formValidation: formValidation,
            UserFirstName: '',
            UserLastName: '',
            UserEmail: '',
            UserPhoneNumber: '',
            clearDialogHidden: true,
            submitDialogHidden: true,
            amendDialogHidden: true,
            helpRequestDialogHidden: true,
            notificationDialogHidden: true,
            notificationDialogTitle: '',
            notificationDialogMessage: '',
            redirectToDashboard: false,
            formAgreement: false,
            uploadElementKey: 1,
            showApplicantInstitutionOther: false,
            showRequesterInstitutionOther: false,
            isFormDisabled: false
        };
    };

    private _onAgreementChange = (propertyName: string, ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined): void => {
        this.setState({
            formAgreement: checked ? true : false,
        });
        this._clearErrorMessage(propertyName);
    }

    private _onTextFieldChange = (propertyName: string, event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        const formValidation = this.state.formValidation;
        const formValidationItem = this.utils.getFormValidationItem(formValidation, propertyName);
        (this.state.formItem as any)[propertyName] = newValue;
        this.setState({
            formItem: this.state.formItem,
        });
        if (formValidationItem && formValidationItem.charLimit) {
            formValidationItem.remainingChars = formValidationItem.charLimit - (newValue ? newValue.length : 0);
            this.setState({
                formValidation: formValidation,
            });
        }
        this._clearErrorMessage(propertyName);
    }

    private _onChoiceGroupChange = (propertyName: string, ev?: React.FormEvent<HTMLInputElement | HTMLElement>, option?: IChoiceGroupOption): void => {
        (this.state.formItem as any)[propertyName] = option?.key.toString();
        this.setState({
            formItem: this.state.formItem,
        });
        this._clearOtherFieldValues(propertyName);
        this._clearErrorMessage(propertyName);
    }

    private _onDatePickerChange = (propertyName: string, date: Date | null | undefined): void => {
        (this.state.formItem as any)[propertyName] = date;
        this.setState({
            formItem: this.state.formItem,
        });
        this._clearErrorMessage(propertyName);
    }

    private _onCheckBoxChange = (propertyName: string, ev?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean, isYesNoCheck?: boolean): void => {
        const newOptions: string[] = [];
        const checkBoxValue: string = (ev?.target as HTMLInputElement).name;
        if (isYesNoCheck) {
            (this.state.formItem as any)[propertyName] = ((this.state.formItem as any)[propertyName] === "" || (this.state.formItem as any)[propertyName] === "No") ? "Yes" : "No";
        } else {
            if ((this.state.formItem as any)[propertyName]) {
                (this.state.formItem as any)[propertyName].forEach((selectedOption: string) => {
                    newOptions.push(selectedOption);
                });
            }
            if (checked && !newOptions.includes(checkBoxValue)) {
                newOptions.push(checkBoxValue);
            } else {
                newOptions.splice(newOptions.indexOf(checkBoxValue), 1);
            }
            (this.state.formItem as any)[propertyName] = newOptions;
        }
        this.setState({
            formItem: this.state.formItem,
        });
        this._clearOtherFieldValues(propertyName);
        this._clearErrorMessage(propertyName);
    }

    private _loadCheckboxes(propertyName: string, choices: ISelectableOptionWithTooltip[], componentRef?: IRefObject<any>, width?: number) {
        const Checkboxes: JSX.Element[] = [];
        const newOptions: string[] = [];
        const checkboxStyles = (styleProps: ICheckboxStyleProps): ICheckboxStyles => {
            const chkStyles: ICheckboxStyles = width ? {
                root: {
                    width: width,
                }, label: { alignItems: 'flex-start' }, checkbox: [
                    { backgroundColor: "white" },
                    styleProps.checked && {
                        backgroundColor: "rgb(0, 90, 158)",
                    },
                ],
            } : {
                label: { alignItems: 'flex-start' }, checkbox: [
                    { backgroundColor: "white" },
                    styleProps.checked && {
                        backgroundColor: "rgb(0, 90, 158)",
                    },
                ],
            };
            return chkStyles;
        };
        if ((this.state.formItem as any)[propertyName]) {
            (this.state.formItem as any)[propertyName].forEach((selectedOption: string) => {
                newOptions.push(selectedOption);
            });
        }
        choices.forEach((choice: ISelectableOptionWithTooltip, index) => {
            let isChecked: boolean = newOptions.includes((choice.key as string)) ? true : false;
            if (choice.tooltipText) {
                Checkboxes.push(
                    <span key={index} style={{ display: 'flex' }}>
                        <Checkbox {...(componentRef && Checkboxes.length === 0 ? { componentRef: componentRef } : {})} disabled={this.state.isFormDisabled} styles={checkboxStyles} key={(choice.key as string)} label={(choice.text as string)} name={(choice.key as string)} checked={isChecked} onChange={(ev, checked) => { this._onCheckBoxChange(propertyName, ev, checked); }}
                            onRenderLabel={(p) => { return <span className="ms-Checkbox-text" style={{ marginLeft: '4px', color: 'rgb(50, 49, 48)' }}>{choice.customLabel ? choice.customLabel : choice.text}</span> }}
                        />
                        <TooltipHost
                            styles={{ root: { marginLeft: '8px', alignSelf: 'center' } }}
                            content={choice.tooltipText}
                            tooltipProps={{
                                styles: {
                                    content: { fontWeight: '600' }
                                }
                            }}>
                            <Icon iconName="Info" />
                        </TooltipHost>
                    </span>
                );
            } else {
                Checkboxes.push(
                    <Checkbox {...(componentRef && Checkboxes.length === 0 ? { componentRef: componentRef } : {})} disabled={this.state.isFormDisabled} styles={checkboxStyles} key={(choice.key as string)} label={(choice.text as string)} name={(choice.key as string)} checked={isChecked} onChange={(ev, checked) => { this._onCheckBoxChange(propertyName, ev, checked); }}
                        onRenderLabel={(p) => { return <span className="ms-Checkbox-text" style={{ marginLeft: '4px', color: 'rgb(50, 49, 48)' }}>{choice.customLabel ? choice.customLabel : choice.text}</span> }}
                    />
                );
            }
        });
        return Checkboxes;
    }

    private _setRoleChoices() {
        const choices: IChoiceGroupOption[] = [];
        const style: IChoiceGroupOptionStyles = {
            root: {
                alignItems: 'flex-start',
                width: 380,
            },
        };
        choices.push({ key: "Academic", text: "Academic", styles: style });
        choices.push({ key: "Clinician", text: "Clinician", styles: style });
        choices.push({ key: "Community/Municipal Organization Representative (including policy maker)", text: "Community/Municipal Organization Representative (including policy maker)", styles: style });
        choices.push({ key: "Developer", text: "Developer", styles: style });
        choices.push({ key: "Evaluator", text: "Evaluator", styles: style });
        choices.push({ key: "Federal/Provincial Representative (including policy maker)", text: "Federal/Provincial Representative (including policy maker)", styles: style });
        choices.push({ key: "Healthcare Provider", text: "Healthcare Provider", styles: style });
        choices.push({ key: "Health System/Care Manager", text: "Health System/Care Manager", styles: style });
        choices.push({ key: "Masters Student", text: "Masters Student", styles: style });
        choices.push({ key: "Patient and Community Engagement Researcher (PaCER)", text: "Patient and Community Engagement Researcher (PaCER)", styles: style });
        choices.push({ key: "Patient Partner", text: "Patient Partner", styles: style });
        choices.push({ key: "PhD Student", text: "PhD Student", styles: style });
        choices.push({ key: "Postdoctoral/Fellow", text: "Postdoctoral/Fellow", styles: style });
        choices.push({ key: "Quality Improvement Facilitator", text: "Quality Improvement Facilitator", styles: style });
        choices.push({ key: "Research Administrator", text: "Research Administrator", styles: style });
        choices.push({ key: "Research Assistant", text: "Research Assistant", styles: style });
        choices.push({ key: "Research Manager", text: "Research Manager", styles: style });
        choices.push({ key: "Research Nurse", text: "Research Nurse", styles: style });
        choices.push({ key: "Resident", text: "Resident", styles: style });
        choices.push({ key: "Study Coordinator", text: "Study Coordinator", styles: style });
        choices.push({ key: "Undergraduate Student", text: "Undergraduate Student", styles: style });
        choices.push({ key: "Other (please describe)", text: "Other (please describe)", styles: style });
        return choices;
    }

    private _setResearchTypeChoices() {
        const choices: IChoiceGroupOption[] = [];
        choices.push({ key: "Biomedical", text: "Biomedical", });
        choices.push({ key: "Clinical", text: "Clinical", });
        choices.push({ key: "Digital Health", text: "Digital Health", });
        choices.push({ key: "Health Services Policy", text: "Health Services Policy", });
        choices.push({ key: "Medical Device", text: "Medical Device", });
        choices.push({ key: "Population & Public Health", text: "Population & Public Health", });
        choices.push({ key: "Other (please describe)", text: "Other (please describe)", });
        return choices;
    }

    private _loadSpecialityAreaChoices(width?: number) {
        const items: JSX.Element[] = [];
        const checkboxStyles = (styleProps: ICheckboxStyleProps): ICheckboxStyles => {
            const chkStyles: ICheckboxStyles = width ? {
                root: {
                    width: width,
                }, label: { alignItems: 'flex-start' }, checkbox: [
                    { backgroundColor: "white" },
                    styleProps.checked && {
                        backgroundColor: "rgb(0, 90, 158)",
                    },
                ],
            } : {
                label: { alignItems: 'flex-start' }, checkbox: [
                    { backgroundColor: "white" },
                    styleProps.checked && {
                        backgroundColor: "rgb(0, 90, 158)",
                    },
                ],
            };
            return chkStyles;
        };
        const checkboxStylesGroupItem = (styleProps: ICheckboxStyleProps): ICheckboxStyles => {
            const chkStyles: ICheckboxStyles = width ? {
                root: {
                    width: width,
                    marginLeft: '30px',
                }, label: { alignItems: 'flex-start' }, checkbox: [
                    { backgroundColor: "white" },
                    styleProps.checked && {
                        backgroundColor: "rgb(0, 90, 158)",
                    },
                ],
            } : {
                root: {
                    marginLeft: '30px',
                },
                label: { alignItems: 'flex-start' }, checkbox: [
                    { backgroundColor: "white" },
                    styleProps.checked && {
                        backgroundColor: "rgb(0, 90, 158)",
                    },
                ],
            };
            return chkStyles;
        };
        let currentGroup: string = '';
        const specialityAreas: ISpecialityArea[] = [];
        const formItem: FormDirectoryIntakeResource = this.state.formItem as FormDirectoryIntakeResource;
        specialityAreas.push({ groupName: '', description: 'Anesthesiology', value: 'Anesthesiology', });
        specialityAreas.push({ groupName: '', description: 'Business', value: 'Business', });
        specialityAreas.push({ groupName: 'Cancer', description: 'Bone', value: 'Cancer - Bone', });
        specialityAreas.push({ groupName: 'Cancer', description: 'Brain/CNS', value: 'Cancer - Brain/CNS', });
        specialityAreas.push({ groupName: 'Cancer', description: 'Breast', value: 'Cancer - Breast', });
        specialityAreas.push({ groupName: 'Cancer', description: 'Gastrointestinal', value: 'Cancer - Gastrointestinal', });
        specialityAreas.push({ groupName: 'Cancer', description: 'Genitourinary', value: 'Cancer - Genitourinary', });
        specialityAreas.push({ groupName: 'Cancer', description: 'Gynecological', value: 'Cancer - Gynecological', });
        specialityAreas.push({ groupName: 'Cancer', description: 'Head and neck', value: 'Cancer - Head and neck', });
        specialityAreas.push({ groupName: 'Cancer', description: 'Hematology', value: 'Cancer - Hematology', });
        specialityAreas.push({ groupName: 'Cancer', description: 'Lung', value: 'Cancer - Lung', });
        specialityAreas.push({ groupName: 'Cancer', description: 'Neuroblastoma', value: 'Cancer - Neuroblastoma', });
        specialityAreas.push({ groupName: 'Cancer', description: 'Retinoblastoma', value: 'Cancer - Retinoblastoma', });
        specialityAreas.push({ groupName: 'Cancer', description: 'Sarcoma', value: 'Cancer - Sarcoma', });
        specialityAreas.push({ groupName: 'Cancer', description: 'Skin/Melanoma', value: 'Cancer - Skin/Melanoma', });
        specialityAreas.push({ groupName: 'Cancer', description: 'Other (please describe)', value: 'Cancer - Other (please describe)', });
        specialityAreas.push({ groupName: 'Cardiology', description: 'Cardiac Surgery', value: 'Cardiology - Cardiac Surgery', });
        specialityAreas.push({ groupName: '', description: 'Clinical Immunology & Allergy', value: 'Clinical Immunology & Allergy', });
        specialityAreas.push({ groupName: '', description: 'Clinical Pharmacology & Toxicology', value: 'Clinical Pharmacology & Toxicology', });
        specialityAreas.push({ groupName: '', description: 'Communications', value: 'Communications', });
        specialityAreas.push({ groupName: '', description: 'Computing Science', value: 'Computing Science', });
        specialityAreas.push({ groupName: '', description: 'Critical Care Medicine', value: 'Critical Care Medicine', });
        specialityAreas.push({ groupName: '', description: 'Dermatology', value: 'Dermatology', });
        specialityAreas.push({ groupName: '', description: 'Diagnostic Radiation', value: 'Diagnostic Radiation', });
        specialityAreas.push({ groupName: '', description: 'Emergency Medicine', value: 'Emergency Medicine', });
        specialityAreas.push({ groupName: '', description: 'Endocrinology & Metabolism', value: 'Endocrinology & Metabolism', });
        specialityAreas.push({ groupName: '', description: 'Gastroenterology', value: 'Gastroenterology', });
        specialityAreas.push({ groupName: 'General Pathology', description: 'Anatomical Pathology', value: 'General Pathology - Anatomical Pathology', });
        specialityAreas.push({ groupName: 'General Pathology', description: 'Forensic Pathology', value: 'General Pathology - Forensic Pathology', });
        specialityAreas.push({ groupName: 'General Pathology', description: 'Hematological Pathology', value: 'General Pathology - Hematological Pathology', });
        specialityAreas.push({ groupName: '', description: 'General Surgery', value: 'General Surgery', });
        specialityAreas.push({ groupName: '', description: 'Geriatric Medicine', value: 'Geriatric Medicine', });
        specialityAreas.push({ groupName: '', description: 'Hematology', value: 'Hematology', });
        specialityAreas.push({ groupName: '', description: 'Infectious Diseases', value: 'Infectious Diseases', });
        specialityAreas.push({ groupName: '', description: 'Internal Medicine', value: 'Internal Medicine', });
        specialityAreas.push({ groupName: '', description: 'Medical Biochemistry', value: 'Medical Biochemistry', });
        specialityAreas.push({ groupName: '', description: 'Medical Genetics and Genomics', value: 'Medical Genetics and Genomics', });
        specialityAreas.push({ groupName: '', description: 'Medical Microbiology', value: 'Medical Microbiology', });
        specialityAreas.push({ groupName: '', description: 'Nephrology', value: 'Nephrology', });
        specialityAreas.push({ groupName: '', description: 'Neurology', value: 'Neurology', });
        specialityAreas.push({ groupName: '', description: 'Nuclear Medicine', value: 'Nuclear Medicine', });
        specialityAreas.push({ groupName: 'Obstetrics & Gynecology', description: 'Gynecologic Reproductive Endocrinologist', value: 'Obstetrics & Gynecology - Gynecologic Reproductive Endocrinologist', });
        specialityAreas.push({ groupName: 'Obstetrics & Gynecology', description: 'Maternal-Fetal Medicine', value: 'Obstetrics & Gynecology - Maternal-Fetal Medicine', });
        specialityAreas.push({ groupName: 'Obstetrics & Gynecology', description: 'Neonatal-Perinatal Medicine', value: 'Obstetrics & Gynecology - Neonatal-Perinatal Medicine', });
        specialityAreas.push({ groupName: '', description: 'Occupational Medicine', value: 'Occupational Medicine', });
        specialityAreas.push({ groupName: '', description: 'Ophthalmology', value: 'Ophthalmology', });
        specialityAreas.push({ groupName: '', description: 'Orthopedic Surgery', value: 'Orthopedic Surgery', });
        specialityAreas.push({ groupName: '', description: 'Otolaryngology-Head and Neck Surgery', value: 'Otolaryngology-Head and Neck Surgery', });
        specialityAreas.push({ groupName: '', description: 'Pain Medicine', value: 'Pain Medicine', });
        specialityAreas.push({ groupName: '', description: 'Palliative Medicine', value: 'Palliative Medicine', });
        specialityAreas.push({ groupName: 'Pediatrics', description: 'Adolescent Medicine', value: 'Pediatrics - Adolescent Medicine', });
        specialityAreas.push({ groupName: 'Pediatrics', description: 'Developmental Pediatrics', value: 'Pediatrics - Developmental Pediatrics', });
        specialityAreas.push({ groupName: 'Pediatrics', description: 'Pediatric Emergency Medicine', value: 'Pediatrics - Pediatric Emergency Medicine', });
        specialityAreas.push({ groupName: 'Pediatrics', description: 'Pediatric Radiology', value: 'Pediatrics - Pediatric Radiology', });
        specialityAreas.push({ groupName: 'Pediatrics', description: 'Pediatric Surgery', value: 'Pediatrics - Pediatric Surgery', });
        specialityAreas.push({ groupName: 'Psychiatry', description: 'Child and Adolescent Psychiatry', value: 'Psychiatry - Child and Adolescent Psychiatry', });
        specialityAreas.push({ groupName: 'Psychiatry', description: 'Geriatric Psychiatry', value: 'Psychiatry - Geriatric Psychiatry', });
        specialityAreas.push({ groupName: '', description: 'Public Health and Preventative Medicine', value: 'Public Health and Preventative Medicine', });
        specialityAreas.push({ groupName: '', description: 'Rheumatology', value: 'Rheumatology', });
        specialityAreas.push({ groupName: '', description: 'Urology', value: 'Urology', });
        specialityAreas.push({ groupName: '', description: 'Other (please describe)', value: 'Other (please describe)', });
        specialityAreas.forEach((specialityArea, index) => {

            if (currentGroup !== specialityArea.groupName) {
                currentGroup = specialityArea.groupName;
                items.push(
                    <div key={'div_' + index} style={{ borderTop: "1px solid #c7d7eb", paddingTop: 15 }} className='groupName'>{specialityArea.groupName}</div>
                );
            }

            const choice: ISelectableOption = { key: specialityArea.value, text: specialityArea.description };
            let isChecked: boolean = formItem.specialityArea.includes((choice.key as string)) ? true : false;
            if (currentGroup !== '') {
                items.push(
                    <Checkbox {...(items.length === 0 ? { componentRef: this.specialityAreaRef } : {})} disabled={this.state.isFormDisabled} styles={checkboxStylesGroupItem} key={(choice.key as string)} label={(choice.text as string)} name={(choice.key as string)} checked={isChecked} onChange={(ev, checked) => { this._onCheckBoxChange('specialityArea', ev, checked); }} />
                );
            } else {
                items.push(
                    <Checkbox {...(items.length === 0 ? { componentRef: this.specialityAreaRef } : {})} disabled={this.state.isFormDisabled} styles={checkboxStyles} key={(choice.key as string)} label={(choice.text as string)} name={(choice.key as string)} checked={isChecked} onChange={(ev, checked) => { this._onCheckBoxChange('specialityArea', ev, checked); }} />
                );
            }
            if (specialityArea.value === 'Cancer - Other (please describe)') {
                items.push(
                    <TextField
                        disabled={!formItem.specialityArea.includes('Cancer - Other (please describe)') || this.state.isFormDisabled}
                        styles={{ root: { maxWidth: 400, width: '100%', marginLeft: '30px' } }}
                        ariaLabel="Cancer - Other description"
                        errorMessage={this.utils.getErrorMessage(this.state.formValidation, 'specialityAreaCancerOther')}
                        componentRef={this.specialityAreaCancerOtherRef}
                        value={formItem.specialityAreaCancerOther}
                        onChange={(event, newValue) => { this._onTextFieldChange("specialityAreaCancerOther", event, newValue); }}
                    />
                );
            }
            if (specialityArea.value === 'Other (please describe)') {
                items.push(
                    <TextField
                        disabled={!formItem.specialityArea.includes('Other (please describe)') || this.state.isFormDisabled}
                        styles={{ root: { marginLeft: 'initial !important', maxWidth: 400, width: '100%', } }}
                        ariaLabel="Other description"
                        errorMessage={this.utils.getErrorMessage(this.state.formValidation, 'specialityAreaOther')}
                        componentRef={this.specialityAreaOtherRef}
                        value={formItem.specialityAreaOther}
                        onChange={(event, newValue) => { this._onTextFieldChange("specialityAreaOther", event, newValue); }}
                    />
                );
            }
        });
        return items;
    }

    private _setCoPInfoChoices() {
        const choices: ISelectableOptionWithTooltip[] = [];
        choices.push({ key: "Events", text: "Events", });
        choices.push({ key: "Networking Opportunities", text: "Networking Opportunities", });
        choices.push({ key: "Mentorship Opportunities", text: "Mentorship Opportunities", });
        choices.push({ key: "Funding Calls", text: "Funding Calls", });
        choices.push({ key: "Other (please describe)", text: "Other (please describe)", });
        return choices;
    }

    private _setCovenantHealthPrivilegesChoices() {
        const choices: IChoiceGroupOption[] = [];
        choices.push({ key: "Yes", text: "Yes", });
        choices.push({ key: "No", text: "No", });
        return choices;
    }

    private _displayClearDialog() {
        this.setState({
            clearDialogHidden: false,
        });
    }

    private _displaySubmitDialog() {
        this.setState({
            submitDialogHidden: false,
        });
    }

    private _displayAmendDialog() {
        this.setState({
            amendDialogHidden: false,
        });
    }

    private _displayHelpDialog() {
        this.setState({
            helpRequestDialogHidden: false,
        });
    }

    private async _dismissClearDialog() {
        this.setState({
            clearDialogHidden: true
        });
    }

    private async _dismissSubmitDialog() {
        this.setState({
            submitDialogHidden: true
        });
    }

    private async _dismissAmendDialog() {
        this.setState({
            amendDialogHidden: true
        });
    }

    private async _dismissHelpDialog() {
        this.setState({
            helpRequestDialogHidden: true
        });
    }

    private async _dismissNotificationDialog() {
        if (this.state.redirectToDashboard) {
            this._clearFormTimer();
            this.props.history.push('/');
        } else {
            this.setState({
                notificationDialogHidden: true,
            });
        }
    }

    private async _getFormConfiguration() {
        const formConfiguration = await this.dataService.getFormConfiguration(this.applicationName);
        this.setState({
            formConfiguration: formConfiguration,
        });
    }

    private async _getFormDirectoryIntake(applicationId: string) {
        const formOperationOutput = await this.dataService.getFormItem(this.applicationName, applicationId);
        let formItem = (formOperationOutput.formItem as FormDirectoryIntakeResource);
        if (formItem.status === "Submitted" || formItem.status === "Rejected") {
            const amendmentItem = new FormDirectoryIntakeResource();
            amendmentItem.applicationId = formItem.applicationId;
            amendmentItem.amendmentId = formItem.amendmentId;
            amendmentItem.edgeId = formItem.edgeId;
            amendmentItem.userId = formItem.userId;
            amendmentItem.status = formItem.status;
            formItem = amendmentItem;
        }
        if (formOperationOutput.completed) {
            this.setState({
                formItem: formItem,
                formItemLastSaved: { ...formItem },
                isFormDisabled: formItem.status === 'Approved' || formItem.status === 'Completed',
                formVisible: true,
            });
        } else {
            alert('Could not get form data.');
            this.props.history.push('/');
        }
    }

    private _clearOtherFieldValues(propertyName: string) {
        let changed = false;
        const formItem = this.state.formItem as FormDirectoryIntakeResource;
        switch (propertyName) {
            case "role":
                if (formItem.role !== "Other (please describe)" && formItem.roleOther !== "") {
                    formItem.roleOther = "";
                    changed = true;
                }
                break;
            case "specialityArea":
                if (!formItem.specialityArea.includes('Cancer - Other (please describe)') && formItem.specialityAreaCancerOther !== "") {
                    formItem.specialityAreaCancerOther = "";
                    changed = true;
                }
                if (!formItem.specialityArea.includes('Other (please describe)') && formItem.specialityAreaOther !== "") {
                    formItem.specialityAreaOther = "";
                    changed = true;
                }
                break;
            case "researchType":
                if (formItem.researchType !== "Other (please describe)" && formItem.researchTypeOther !== "") {
                    formItem.researchTypeOther = "";
                    changed = true;
                }
                break;
            case "CoPInfo":
                if (!formItem.CoPInfo.includes('Other (please describe)') && formItem.CoPInfoOther !== "") {
                    formItem.CoPInfoOther = "";
                    changed = true;
                }
                break;
        }
        if (changed) {
            this.setState({
                formItem: formItem,
            });
        }
    }

    private _clearErrorMessage(propertyName: string) {
        const properties: string[] = [];
        properties.push(propertyName);
        switch (propertyName) {
            case "role":
                properties.push('roleOther');
                break;
            case "specialityArea":
                properties.push('specialityAreaCancerOther');
                properties.push('specialityAreaOther');
                break;
            case "researchType":
                properties.push('researchTypeOther');
                break;
            case "CoPInfo":
                properties.push('CoPInfoOther');
                break;
        }
        const formValidation = this.state.formValidation;
        let changed = false;
        properties.forEach((property) => {
            const formValidationItem = this.utils.getFormValidationItem(formValidation, property);
            if (formValidationItem && !formValidationItem.isValid) {
                formValidationItem.isValid = true;
                changed = true;
            }
        });
        if (changed) {
            this.setState({
                formValidation: formValidation,
            });
        }
    }

    private _validateForm() {
        let isValid = true;
        const formItem = this.state.formItem as FormDirectoryIntakeResource;
        const formValidation = this.state.formValidation;
        let focusSet = false;
        let focusItem: IRefObject<any> | null = null;
        for (const formValidationItem of formValidation) {
            switch (formValidationItem.validationType) {
                case 'required':
                    if ((formItem as any)[formValidationItem.propertyName] === undefined || (formItem as any)[formValidationItem.propertyName] === null || (formItem as any)[formValidationItem.propertyName].length === 0) {
                        isValid = false;
                        formValidationItem.isValid = false;
                        if (!focusSet) {
                            focusItem = formValidationItem.propertyRef;
                            focusSet = true;
                        }
                    }
                    break;
                case 'custom':
                    switch (formValidationItem.propertyName) {
                        case 'UserEmail':
                            if (formItem.UserEmail.length === 0 || !this.utils.isValidEmail(formItem.UserEmail)) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (formItem.UserEmail.length === 0) {
                                    formValidationItem.errorMessage = 'This field cannot be empty';
                                } else {
                                    formValidationItem.errorMessage = 'Please enter a valid email address';
                                }
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'roleOther':
                            if (formItem.role === 'Other (please describe)' && formItem.roleOther.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'specialityArea':
                            if (formItem.specialityArea.length === 0 || formItem.specialityArea.length > 3) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (formItem.specialityArea.length === 0) {
                                    formValidationItem.errorMessage = 'This field cannot be empty';
                                } else {
                                    formValidationItem.errorMessage = 'You can only select up to 3 items'
                                }
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'specialityAreaCancerOther':
                            if (formItem.specialityArea.includes('Cancer - Other (please describe)') && formItem.specialityAreaCancerOther.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'specialityAreaOther':
                            if (formItem.specialityArea.includes('Other (please describe)') && formItem.specialityAreaOther.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'researchTypeOther':
                            if (formItem.researchType === 'Other (please describe)' && formItem.researchTypeOther.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'CoPInfoOther':
                            if (formItem.CoPInfo.includes('Other (please describe)') && formItem.CoPInfoOther.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                    }
                    break;
            }
        }
        if (!isValid) {
            this.setState({
                formValidation: formValidation,
            });
            this._dismissSubmitDialog().then(() => {
                if (focusItem) {
                    (focusItem as any).current.focus();
                }
            });
        }
        return isValid;
    }

    private async _saveFormDirectoryIntake(disableNotification?: boolean) {
        await this._updateToken();
        let formOperationOutput: IFormMethodOutput;
        const formItem = this.state.formItem;
        if (this.state.formItem.applicationId.length === 0) {
            formOperationOutput = await this.dataService.insertFormItem(this.applicationName, formItem);
        } else {
            formOperationOutput = await this.dataService.updateFormItem(this.applicationName, formItem);
        }
        if (formOperationOutput.completed) {
            this.setState({
                formItem: (formOperationOutput.formItem as FormDirectoryIntakeResource),
                formItemLastSaved: { ...(formOperationOutput.formItem as FormDirectoryIntakeResource) },
                notificationDialogHidden: disableNotification ? true : false,
                notificationDialogTitle: 'Save Form',
                notificationDialogMessage: `Form (${formOperationOutput.formItem?.applicationId}) saved. \n\nThis form is in draft form and will not be processed until it is submitted. \n\nRemember that this form is available on the dashboard. You can come back and continue completing your form later.`,
            });
        } else {
            this.setState({
                notificationDialogHidden: disableNotification ? true : false,
                notificationDialogTitle: 'Error',
                notificationDialogMessage: 'An error occurred while saving the form.',
            });
        }
    }

    private async _submitFormDirectoryIntake() {
        if (this._validateForm()) {
            await this._saveFormDirectoryIntake(true);
            if (this.state.formItem.applicationId !== '') {
                const formOperationOutput = await this.dataService.submitFormItem(this.applicationName, this.state.formItem.applicationId);
                if (formOperationOutput.completed) {
                    this._clearFormTimer();
                    this.setState({
                        submitDialogHidden: true,
                        notificationDialogHidden: false,
                        notificationDialogTitle: 'Submit Form',
                        notificationDialogMessage: `This request has been completed and submitted.`,
                        redirectToDashboard: true,
                    });
                } else {
                    this.setState({
                        submitDialogHidden: true,
                        notificationDialogHidden: false,
                        notificationDialogTitle: 'Error',
                        notificationDialogMessage: 'An error occurred while submitting the form.',
                    });
                }
            } else {
                this.setState({
                    submitDialogHidden: true,
                    notificationDialogHidden: false,
                    notificationDialogTitle: 'Error',
                    notificationDialogMessage: 'An error occurred while submitting the form.',
                });
            }
        }
    }

    private async _amendFormDirectoryIntake() {
        await this._updateToken();
        const formItem = this.state.formItem
        const formOperationOutput = await this.dataService.amendFormItem(this.applicationName, formItem);
        if (formOperationOutput.completed) {
            this._clearFormTimer();
            this.setState({
                amendDialogHidden: true,
                notificationDialogHidden: false,
                notificationDialogTitle: 'Amend Form',
                notificationDialogMessage: `Form (${formOperationOutput.formItem?.applicationId}) amended.`,
                redirectToDashboard: true,
            });
        } else {
            this.setState({
                amendDialogHidden: true,
                notificationDialogHidden: false,
                notificationDialogTitle: 'Error',
                notificationDialogMessage: 'An error occurred while amending the form.',
            });
        }
    }

    private async _helpRequestFormDirectoryIntake() {
        await this._updateToken();
        let formOperationOutput: IFormMethodOutput;
        const formItem = this.state.formItem;
        formItem.status = 'Help Requested';
        if (this.state.formItem.applicationId.length === 0) {
            formOperationOutput = await this.dataService.insertFormItem(this.applicationName, formItem);
        } else {
            formOperationOutput = await this.dataService.updateFormItem(this.applicationName, formItem);
        }
        if (formOperationOutput.completed) {
            if (this.state.formItem.applicationId !== '') {
                const formOperationOutput = await this.dataService.requestHelpFormItem(this.applicationName, this.state.formItem.applicationId);
                if (formOperationOutput.completed) {
                    this.setState({
                        helpRequestDialogHidden: true,
                        formItem: (formOperationOutput.formItem as FormDirectoryIntakeResource),
                        notificationDialogHidden: false,
                        notificationDialogTitle: 'Request Help',
                        notificationDialogMessage: `Form (${formOperationOutput.formItem?.applicationId}) saved. \n\nA case manager will contact you to help.`,
                        redirectToDashboard: true,
                    });
                } else {
                    this.setState({
                        helpRequestDialogHidden: true,
                        notificationDialogHidden: false,
                        notificationDialogTitle: 'Error',
                        notificationDialogMessage: 'An error occurred while requesting help.',
                    });
                }
            } else {
                this.setState({
                    helpRequestDialogHidden: true,
                    notificationDialogHidden: false,
                    notificationDialogTitle: 'Error',
                    notificationDialogMessage: 'An error occurred while requesting help.',
                });
            }
        } else {
            this.setState({
                helpRequestDialogHidden: true,
                notificationDialogHidden: false,
                notificationDialogTitle: 'Error',
                notificationDialogMessage: 'An error occurred while requesting help.',
            });
        }
    }

    private async _clearFormDirectoryIntake() {
        await this._updateToken();
        const clearedItem = new FormDirectoryIntakeResource();
        clearedItem.applicationId = this.state.formItem.applicationId;
        clearedItem.amendmentId = this.state.formItem.amendmentId;
        clearedItem.edgeId = this.state.formItem.edgeId;
        clearedItem.userId = this.state.formItem.userId;
        clearedItem.status = this.state.formItem.status;
        const formValidation = this.state.formValidation;
        for (const formValidationItem of formValidation) {
            formValidationItem.isValid = true;
        }
        // delete attachments if any
        if (this.state.formItem.applicationId.length > 0) {
            const attachments = await this.dataService.getFormAttachments(this.applicationName, this.state.formItem);
            for (const attachment of attachments) {
                await this.dataService.deleteFormAttachment(attachment.path);
            }
        }
        this.setState({
            formItem: clearedItem,
            formValidation: formValidation,
            ...(this.state.uploadElementKey && { uploadElementKey: this.state.uploadElementKey + 1 }),
        });
        this._dismissClearDialog().then(() => {
            if (this.IntakeUserNameRef) {
                (this.IntakeUserNameRef as any).current.focus();
            }
        });
    }

    private async _retrieveFormInformation() {
        await this._getUserInfo();
        await this._getFormConfiguration();
        this.saveIntervalId = this._saveFormTimer();
        if (this.pathApplicationId.length > 0 && this.pathApplicationId !== 'FormDirectoryIntake') {
            this._getFormDirectoryIntake(this.pathApplicationId);
        } else {
            if (this.state.formItem.applicationId.length === 0) {
                const formItem = this.state.formItem as FormDirectoryIntakeResource;
                formItem.IntakeUserName = this.state.UserFirstName + ' ' + this.state.UserLastName;
                formItem.UserEmail = this.state.UserEmail;
                formItem.UserPhoneNumber = this.state.UserPhoneNumber;
                await this._saveFormDirectoryIntake(true);
            }
            this.setState({
                formVisible: true
            });
        }
    }

    private async _getUserInfo() {
        const authResult = await Auth.acquireTokenSilent();
        if (authResult) {
            this.setState({
                UserFirstName: (authResult.idTokenClaims as any).given_name ? (authResult.idTokenClaims as any).given_name : '',
                UserLastName: (authResult.idTokenClaims as any).family_name ? (authResult.idTokenClaims as any).family_name : '',
                UserEmail: (authResult.idTokenClaims as any).emails[0] ? (authResult.idTokenClaims as any).emails[0] : '',
                UserPhoneNumber: (authResult.idTokenClaims as any).extension_PhoneNumber ? (authResult.idTokenClaims as any).extension_PhoneNumber : '',
            });
        }
    }

    private _clearFormTimer() {
        window.clearInterval(this.saveIntervalId);
    }

    private _saveFormTimer(): number {
        return window.setInterval(() => {
            if (this.state.formItem.status === 'Draft' && JSON.stringify(this.state.formItem) !== JSON.stringify(this.state.formItemLastSaved)) {
                this._saveFormDirectoryIntake(true);
            }
        }, AUTO_SAVE_INTERVAL);
    }

    private async _updateToken() {
        try {
            await this.dataService.getToken();
        } catch (error) {
            this.props.history.push('/logout');
        }
    }

    public componentDidMount() {
        this._retrieveFormInformation();
    }

    public render(): React.ReactElement {
        const formItem = this.state.formItem as FormDirectoryIntakeResource;
        const { formConfiguration, formVisible, formValidation, UserFirstName, UserLastName, UserEmail, clearDialogHidden, submitDialogHidden, amendDialogHidden, helpRequestDialogHidden, notificationDialogHidden, notificationDialogTitle, notificationDialogMessage } = this.state;
        const spinner = !formVisible ? <Spinner size={SpinnerSize.large} label="Loading..." ariaLive="assertive" /> : null;
        const specialityAreaCheckBoxes = this._loadSpecialityAreaChoices();
        const CoPInfoCheckBoxes = this._loadCheckboxes("CoPInfo", this.CoPInfoChoices);
        const clearFormButton = <DefaultButton className="clearButton" text="Clear Form" onClick={this._displayClearDialog.bind(this)} disabled={this.state.isFormDisabled} />;
        const saveButton = formItem.status === 'Draft' ? <DefaultButton text="Save Form" onClick={() => this._saveFormDirectoryIntake()} disabled={this.state.isFormDisabled} /> : null;
        const submitButton = formItem.status === 'Draft' ? <PrimaryButton text="Submit Form" onClick={this._displaySubmitDialog.bind(this)} disabled={this.state.isFormDisabled} /> : null;
        const amendButton = (formItem.status === 'Submitted' || formItem.status === 'Rejected') ? <PrimaryButton text="Amend Form" onClick={this._displayAmendDialog.bind(this)} disabled={this.state.isFormDisabled} /> : null;
        const helpRequestButton = formItem.status === 'Draft' ? <DefaultButton className="floatHelpButton" text="Request Help" onClick={this._displayHelpDialog.bind(this)} disabled={this.state.isFormDisabled} /> : null;
        return (
            <main className="App-formbody">
                <Navigation
                    title="Provincial Research and Innovation Directory Form"
                    isForm={true}
                    UserFirstName={UserFirstName}
                    UserLastName={UserLastName}
                    UserEmail={UserEmail}
                    clearFormTimer={this._clearFormTimer.bind(this)} />
                {spinner}
                <form style={!formVisible ? { display: 'none' } : {}}>
                    <section className="applicationIDSection">
                        <span className='col1'><h2>Form Number: {formItem.applicationId}</h2></span>
                        <span className='col2'></span>
                    </section>
                    <section>
                        <Label>{this.utils.parseHTML(this.utils.getConfigurationValue(formConfiguration, 'FormHeader'))}</Label>
                    </section>
                    <section>
                        <h2 className='requiredItem'>1. Name</h2>
                        <TextField
                            className='requiredField'
                            errorMessage={this.utils.getErrorMessage(formValidation, 'IntakeUserName')}
                            componentRef={this.IntakeUserNameRef}
                            styles={TEXT_SINGLE_COLUMN}
                            value={formItem.IntakeUserName}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("IntakeUserName", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h2 className='requiredItem'>2. Title</h2>
                        <TextField
                            className='requiredField'
                            errorMessage={this.utils.getErrorMessage(formValidation, 'UserTitle')}
                            componentRef={this.UserTitleRef}
                            styles={TEXT_SINGLE_COLUMN}
                            value={formItem.UserTitle}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("UserTitle", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h2 className='requiredItem'>3. Institution / Company / Organization</h2>
                        <TextField
                            className='requiredField'
                            errorMessage={this.utils.getErrorMessage(formValidation, 'UserCompany')}
                            componentRef={this.UserCompanyRef}
                            styles={TEXT_SINGLE_COLUMN}
                            value={formItem.UserCompany}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("UserCompany", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h2 className='requiredItem'>4. Department / Faculty</h2>
                        <TextField
                            className='requiredField'
                            errorMessage={this.utils.getErrorMessage(formValidation, 'UserDepartment')}
                            componentRef={this.UserDepartmentRef}
                            styles={TEXT_SINGLE_COLUMN}
                            value={formItem.UserDepartment}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("UserDepartment", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h2>5. Email Address</h2>
                        <TextField
                            errorMessage={this.utils.getErrorMessage(formValidation, 'UserEmail')}
                            componentRef={this.UserEmailRef}
                            styles={TEXT_SINGLE_COLUMN}
                            value={formItem.UserEmail}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("UserEmail", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h2>6. Phone Number</h2>
                        <TextField
                            errorMessage={this.utils.getErrorMessage(formValidation, 'UserPhoneNumber')}
                            styles={TEXT_SINGLE_COLUMN}
                            value={formItem.UserPhoneNumber}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("UserPhoneNumber", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h2 className='requiredItem'>7. Select the role that best applies to you</h2>
                        <ChoiceGroupWithValidation
                            selectedKey={formItem.role}
                            onChange={(event, option) => { this._onChoiceGroupChange("role", event, option); }}
                            options={this.roleChoices}
                            componentRef={this.roleRef}
                            disabled={this.state.isFormDisabled}
                            styles={{ flexContainer: { display: "grid", gridAutoFlow: "column", gridTemplateRows: "repeat(11, auto)" } }}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'role')}
                        />
                        <TextField
                            disabled={formItem.role !== 'Other (please describe)' || this.state.isFormDisabled}
                            styles={{ root: { maxWidth: 400, width: '100%', } }}
                            ariaLabel="Other Role description"
                            errorMessage={this.utils.getErrorMessage(formValidation, 'roleOther')}
                            componentRef={this.roleOtherRef}
                            value={formItem.roleOther}
                            onChange={(event, newValue) => { this._onTextFieldChange("roleOther", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h2 className='requiredItem'>8. Disease or Speciality Area</h2>
                        <h3>Select up to 3</h3>
                        <div>
                            <Stack styles={{ root: { marginTop: '15px' } }} tokens={{ childrenGap: "10" }}>
                                {specialityAreaCheckBoxes}
                            </Stack><span>
                                <div role="alert">
                                    <p className="ms-TextField-errorMessage">
                                        <span data-automation-id="error-message">{this.utils.getErrorMessage(formValidation, 'specialityArea')}</span>
                                    </p>
                                </div>
                            </span><br></br>
                        </div>
                    </section>
                    <section>
                        <h2 className='requiredItem'>9. Topics of Interest</h2>
                        <h3>Please separate topics by comma</h3>
                        <TextField
                            className='requiredField'
                            errorMessage={this.utils.getErrorMessage(formValidation, 'topics')}
                            componentRef={this.topicsRef}
                            styles={TEXT_SINGLE_COLUMN}
                            value={formItem.topics}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("topics", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h2>10. Research Type</h2>
                        <ChoiceGroupWithValidation
                            selectedKey={formItem.researchType}
                            onChange={(event, option) => { this._onChoiceGroupChange("researchType", event, option); }}
                            options={this.researchTypeChoices}
                            componentRef={this.researchTypeRef}
                            disabled={this.state.isFormDisabled}
                            styles={{ flexContainer: { display: "grid", gridAutoFlow: "column", gridTemplateRows: "repeat(11, auto)" } }}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'researchType')}
                        />
                        <TextField
                            disabled={formItem.researchType !== 'Other (please describe)' || this.state.isFormDisabled}
                            styles={{ root: { marginLeft: 'initial !important', maxWidth: 400, width: '100%', } }}
                            ariaLabel="Other research type description"
                            errorMessage={this.utils.getErrorMessage(formValidation, 'researchTypeOther')}
                            componentRef={this.researchTypeOtherRef}
                            value={formItem.researchTypeOther}
                            onChange={(event, newValue) => { this._onTextFieldChange("researchTypeOther", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h2>11. ORCID Digital Identifier or LinkedIn Profile</h2>
                        <TextField
                            styles={TEXT_SINGLE_COLUMN}
                            value={formItem.ORCIDLinkedIn}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("ORCIDLinkedIn", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h2>12. Do you have Covenant Health Privileges?</h2>
                        <ChoiceGroup
                            selectedKey={formItem.covenantHealthPrivileges}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, option) => { this._onChoiceGroupChange("covenantHealthPrivileges", event, option); }}
                            options={this.covenantHealthPrivilegesChoices}
                        />
                    </section>
                    <section>
                        <h2>13. What type of information would you like to receive in a Community of Practice (CoP)?</h2>
                        <Stack styles={{ root: { marginTop: '15px' } }} tokens={{ childrenGap: "10" }}>
                            {CoPInfoCheckBoxes}
                        </Stack>
                        <TextField
                            disabled={!formItem.CoPInfo.includes('Other (please describe)') || this.state.isFormDisabled}
                            styles={{ root: { marginLeft: 'initial !important', maxWidth: 400, width: '100%', } }}
                            ariaLabel="Other information description"
                            errorMessage={this.utils.getErrorMessage(formValidation, 'CoPInfoOther')}
                            componentRef={this.CoPInfoOtherRef}
                            value={formItem.CoPInfoOther}
                            onChange={(event, newValue) => { this._onTextFieldChange("CoPInfoOther", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h1>Disclaimer</h1>
                        <Label>{this.utils.parseHTML(this.utils.getConfigurationValue(formConfiguration, 'TermsOfUse'))}</Label>
                        {helpRequestButton}
                        <div style={{ display: 'grid', gridTemplateColumns: '115px 140px auto 130px', gridTemplateRows: 'auto', marginTop: '15px' }}>
                            <div style={{ gridArea: '1 / 1 / 1 / 1' }}>
                                {clearFormButton}
                            </div>
                            <div style={{ gridArea: '1 / 2 / 1 / 2', marginLeft: '10px' }}>
                                {saveButton}
                                {amendButton}
                            </div>
                            <div style={{ gridArea: '1 / 4 / 1 / 4', textAlign: 'right' }}>
                                {submitButton}
                            </div>
                        </div>
                    </section>
                    <Dialog
                        className='renderLinefeed'
                        hidden={clearDialogHidden}
                        onDismiss={() => { this._dismissClearDialog(); }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: 'Clear Form',
                            subText: 'Are you sure you want to clear the form? \nThis will remove all entered data.',
                        }}
                        modalProps={{
                            isBlocking: true,
                            styles: { main: { maxWidth: 450 } },
                            dragOptions: {
                                moveMenuItemText: 'Move',
                                closeMenuItemText: 'Close',
                                menu: ContextualMenu,
                            },
                        }}
                    >
                        <DialogFooter>
                            <DefaultButton onClick={() => { this._dismissClearDialog(); }} text="Cancel" />
                            <PrimaryButton onClick={() => { this._clearFormDirectoryIntake(); }} text="Yes, Clear Form" />
                        </DialogFooter>
                    </Dialog>
                    <Dialog
                        className='renderLinefeed'
                        hidden={submitDialogHidden}
                        onDismiss={() => { this._dismissSubmitDialog(); }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: 'Submit form',
                            subText: `Are you sure you want to submit this form ${formItem.applicationId.length > 0 ? '(' + formItem.applicationId + ')' : ''}?`,
                        }}
                        modalProps={{
                            isBlocking: true,
                            styles: { main: { maxWidth: 450 } },
                            dragOptions: {
                                moveMenuItemText: 'Move',
                                closeMenuItemText: 'Close',
                                menu: ContextualMenu,
                            },
                        }}
                    >
                        <DialogFooter>
                            <DefaultButton onClick={() => { this._dismissSubmitDialog(); }} text="Cancel" />
                            <PrimaryButton onClick={() => { this._submitFormDirectoryIntake(); }} text="Yes, Submit Form" />
                        </DialogFooter>
                    </Dialog>
                    <Dialog
                        className='renderLinefeed'
                        hidden={amendDialogHidden}
                        onDismiss={() => { this._dismissAmendDialog(); }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: 'Amend Form',
                            subText: `You are about to modify your submitted form ${formItem.applicationId}. \nPlease select "Yes, Amend Form" to proceed with your changes.`,
                        }}
                        modalProps={{
                            isBlocking: true,
                            styles: { main: { maxWidth: 450 } },
                            dragOptions: {
                                moveMenuItemText: 'Move',
                                closeMenuItemText: 'Close',
                                menu: ContextualMenu,
                            },
                        }}
                    >
                        <DialogFooter>
                            <DefaultButton onClick={() => { this._dismissAmendDialog(); }} text="Cancel" />
                            <PrimaryButton onClick={() => { this._amendFormDirectoryIntake(); }} text="Yes, Amend Form" />
                        </DialogFooter>
                    </Dialog>
                    <Dialog
                        className='renderLinefeed'
                        hidden={helpRequestDialogHidden}
                        onDismiss={() => { this._dismissHelpDialog(); }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: 'Request Help',
                            subText: `Do you want to save the form as is and request help from the case manager to complete filling the form? Please complete as much fields as possible before requesting help.`,
                        }}
                        modalProps={{
                            isBlocking: true,
                            styles: { main: { maxWidth: 450 } },
                            dragOptions: {
                                moveMenuItemText: 'Move',
                                closeMenuItemText: 'Close',
                                menu: ContextualMenu,
                            },
                        }}
                    >
                        <DialogFooter>
                            <DefaultButton onClick={() => { this._dismissHelpDialog(); }} text="Cancel" />
                            <PrimaryButton onClick={() => { this._helpRequestFormDirectoryIntake(); }} text="Yes, request for help" />
                        </DialogFooter>
                    </Dialog>
                    <Dialog
                        className='renderLinefeed'
                        hidden={notificationDialogHidden}
                        onDismiss={() => { this._dismissNotificationDialog(); }}
                        dialogContentProps={{
                            type: DialogType.close,
                            title: notificationDialogTitle,
                            subText: notificationDialogMessage,
                        }}
                    />
                </form>
            </main>
        );
    }
}