import Auth from '../Auth';
import {FormACRCCTAConciergeIntakeResource} from '../dto/FormACRCCTAConciergeIntakeResource';
import {FormARECCISecondOpinionResource} from '../dto/FormARECCISecondOpinionResource';
import {FormCovenantHealthRequestResource} from '../dto/FormCovenantHealthRequestResource';
import {FormLetterSupportResource} from '../dto/FormLetterSupportResource';
import {FormServiceRequestResource} from '../dto/FormServiceRequestResource';
import {FormDirectoryIntakeResource} from '../dto/FormDirectoryIntakeResource';
import {TFormResource} from '../types/TFormResource';
import {IAttachmentDocument} from '../interfaces/IAttachmentDocument';
import {IFormConfiguration} from '../interfaces/IFormConfiguration';
import {IFormDashboard} from '../interfaces/IFormDashboard';
import {IFormMethodOutput} from '../interfaces/IFormMethodOutput';
import {saveAs} from 'file-saver';
import axios, { AxiosInstance } from 'axios';

export default class DataService {
    private static _current: DataService | null = null;
    private static API_HOST = process.env.REACT_APP_API_URL;
    private static FORM_NAMES = [
        {applicationName: 'ServiceRequest', formName: 'FormServiceRequest', displayName: 'Service Request'},
        {applicationName: 'LetterOfSupport', formName: 'FormLetterSupport', displayName: 'Letter of Support'},
        // {
        //     applicationName: 'ARECCISecondOpinionReview',
        //     formName: 'FormARECCISecondOpinion',
        //     displayName: 'ARECCI Second Opinion',
        // },
        // {
        //     applicationName: 'CovenantHealthRequest',
        //     formName: 'FormCovenantHealthRequest',
        //     displayName: 'Covenant Health Request',
        // },
        {
            applicationName: 'ACRCCTAConciergeIntakeForm',
            formName: 'FormACRCCTAConciergeIntake',
            displayName: 'ACRC/CTA Concierge Intake',
        },
        {applicationName: 'DirectoryIntakeForm', formName: 'FormDirectoryIntake', displayName: 'Directory Intake'},
    ];
    private axiosInstance: AxiosInstance;

    public static current() {
        return this._current ? this._current : (this._current = new DataService());
    }

    constructor()
    {
        this.axiosInstance = axios.create({
            baseURL: `${DataService.API_HOST}`,
            headers: {
                'Content-Type': 'application/json'
            }
        });

        this.axiosInstance.interceptors.request.use(async config => {
            const authResult = await Auth.acquireTokenSilent();
            config.headers.Authorization = `Bearer ${authResult.accessToken}`
            return config;
        });
    }

    public async getToken() {
        const authResult = await Auth.acquireTokenSilent();
        return authResult.accessToken;
    }

    private _setFormItem(applicationName: string, operationOutput: IFormMethodOutput, dataItem: any) {
        switch (applicationName) {
            case 'ServiceRequest':
                operationOutput.formItem = FormServiceRequestResource.fromJson(dataItem);
                break;
            case 'LetterOfSupport':
                operationOutput.formItem = FormLetterSupportResource.fromJson(dataItem);
                break;
            case 'ARECCISecondOpinionReview':
                operationOutput.formItem = FormARECCISecondOpinionResource.fromJson(dataItem);
                break;
            case 'CovenantHealthRequest':
                operationOutput.formItem = FormCovenantHealthRequestResource.fromJson(dataItem);
                break;
            case 'ACRCCTAConciergeIntakeForm':
                operationOutput.formItem = FormACRCCTAConciergeIntakeResource.fromJson(dataItem);
                break;
            case 'DirectoryIntakeForm':
                operationOutput.formItem = FormDirectoryIntakeResource.fromJson(dataItem);
                break;
        }
    }

    private _formatFormData(applicationName: string, formItem: TFormResource, newEntry: boolean) {
        let formData;
        switch (applicationName) {
            case 'ServiceRequest':
                formData = FormServiceRequestResource.toJson(formItem as FormServiceRequestResource, newEntry);
                break;
            case 'LetterOfSupport':
                formData = FormLetterSupportResource.toJson(formItem as FormLetterSupportResource, newEntry);
                break;
            case 'ARECCISecondOpinionReview':
                formData = FormARECCISecondOpinionResource.toJson(
                    formItem as FormARECCISecondOpinionResource,
                    newEntry
                );
                break;
            case 'CovenantHealthRequest':
                formData = FormCovenantHealthRequestResource.toJson(
                    formItem as FormCovenantHealthRequestResource,
                    newEntry
                );
                break;
            case 'ACRCCTAConciergeIntakeForm':
                formData = FormACRCCTAConciergeIntakeResource.toJson(
                    formItem as FormACRCCTAConciergeIntakeResource,
                    newEntry
                );
                break;
            case 'DirectoryIntakeForm':
                formData = FormDirectoryIntakeResource.toJson(formItem as FormDirectoryIntakeResource, newEntry);
        }
        return formData;
    }

    public async getFormConfiguration(applicationName: string): Promise<IFormConfiguration[]> {
        let operationOutput: IFormConfiguration[] = [];
        let dataItem: any;
        try {
            const response = await this.axiosInstance.get(`/applications/${applicationName}/config`);
            operationOutput = Object.keys(response.data).map(key => {
                return { property: key, value: response.data[key] }
            });

            // const accessToken = await this.getToken();
            // dataItem = await fetch(`${DataService.API_HOST}/applications/${applicationName}/config`, {
            //     method: 'GET',
            //     cache: 'no-cache',
            //     mode: 'cors',
            //     headers: {
            //         'Content-type': 'application/json',
            //         Authorization: `Bearer ${accessToken}`,
            //     },
            // }).then((response) => {
            //     if (response.ok) {
            //         return response.json();
            //     } else {
            //         console.log(response);
            //         throw new Error(response.statusText);
            //     }
            // });
            // if (dataItem) {
            //     Object.keys(dataItem).forEach(function (key) {
            //         const itemObj: IFormConfiguration = {
            //             property: key,
            //             value: dataItem[key],
            //         };
            //         operationOutput.push(itemObj);
            //     });
            // }
        } catch (error: any) {
            console.log(dataItem);
        }
        return operationOutput;
    }

    public async getFormItems(): Promise<IFormDashboard[]> {
        let dashboardItems: IFormDashboard[] = [];
        try {
            const results = await Promise.all(DataService.FORM_NAMES.map(async formName => {
                const response = await this.axiosInstance.get((`/applications/${formName.applicationName}/submissions`));
                return response.data.items.map((item: any) => {
                    return {
                        formName: formName.formName,
                        applicationName: formName.applicationName,
                        displayName: formName.displayName,
                        applicationId: item.applicationId ? item.applicationId : '',
                        Modified: item.Modified ? new Date(item.Modified) : undefined,
                        status: item.status ? item.status : ''
                    };
                });
            }));
            dashboardItems = results.reduce((acc, curr) => [...acc, ...curr], []);

            // for (const formName of DataService.FORM_NAMES) {
            //     const dataItem = await fetch(
            //         `${DataService.API_HOST}/applications/${formName.applicationName}/submissions`,
            //         {
            //             method: 'GET',
            //             cache: 'no-cache',
            //             mode: 'cors',
            //             headers: {
            //                 'Content-type': 'application/json',
            //                 Authorization: `Bearer ${accessToken}`,
            //             },
            //         }
            //     ).then((response) => {
            //         if (response.ok) {
            //             return response.json();
            //         } else {
            //             console.log(response);
            //             throw new Error(response.statusText);
            //         }
            //     });
            //     if (dataItem) {
            //         for (const item of dataItem.items) {
            //             const dashboardItem: IFormDashboard = {
            //                 formName: formName.formName,
            //                 applicationName: formName.applicationName,
            //                 displayName: formName.displayName,
            //                 applicationId: item.applicationId ? item.applicationId : '',
            //                 Modified: item.Modified ? new Date(item.Modified) : undefined,
            //                 status: item.status ? item.status : '',
            //             };
            //             dashboardItems.push(dashboardItem);
            //         }
            //     }
            // }
        } catch (error: any) {
            console.log(typeof error === 'string' ? error : error.message);
        }
        return dashboardItems;
    }

    public async getFormItem(applicationName: string, applicationId: string): Promise<IFormMethodOutput> {
        const operationOutput: IFormMethodOutput = {message: '', completed: false, formItem: undefined};
        let dataItem: any;
        try {
            const accessToken = await this.getToken();
            dataItem = await fetch(
                `${DataService.API_HOST}/applications/${applicationName}/submissions/${applicationId}`,
                {
                    method: 'GET',
                    cache: 'no-cache',
                    mode: 'cors',
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            ).then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    console.log(response);
                    throw new Error(response.statusText);
                }
            });
            if (dataItem) {
                operationOutput.message = 'Item retrieved';
                operationOutput.completed = true;
                this._setFormItem(applicationName, operationOutput, dataItem);
            }
        } catch (error: any) {
            console.log(dataItem);
            operationOutput.message = typeof error == 'string' ? error : error.message;
        }
        return operationOutput;
    }

    public async deleteFormItem(applicationName: string, applicationId: string): Promise<boolean> {
        let success: boolean;
        try {
            const accessToken = await this.getToken();
            success = await fetch(
                `${DataService.API_HOST}/applications/${applicationName}/submissions/${applicationId}`,
                {
                    method: 'DELETE',
                    cache: 'no-cache',
                    mode: 'cors',
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            ).then((response) => {
                if (response.ok) {
                    return true;
                } else {
                    console.log(response);
                    throw new Error(response.statusText);
                }
            });
        } catch (error: any) {
            console.log(error);
            success = false;
        }
        return success;
    }

    public async updateFormItem(applicationName: string, formItem: TFormResource): Promise<IFormMethodOutput> {
        const operationOutput: IFormMethodOutput = {message: '', completed: false, formItem: undefined};
        let dataItem: any;
        try {
            const accessToken = await this.getToken();
            const formData = this._formatFormData(applicationName, formItem, false);
            dataItem = await fetch(
                `${DataService.API_HOST}/applications/${applicationName}/submissions/${formItem.applicationId}`,
                {
                    method: 'PATCH',
                    cache: 'no-cache',
                    mode: 'cors',
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(formData),
                }
            ).then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    console.log(response);
                    throw new Error(response.statusText);
                }
            });
            if (dataItem) {
                operationOutput.message = 'Item updated';
                operationOutput.completed = true;
                this._setFormItem(applicationName, operationOutput, dataItem);
            }
        } catch (error: any) {
            console.log(dataItem);
            operationOutput.message = typeof error == 'string' ? error : error.message;
        }
        return operationOutput;
    }

    public async insertFormItem(applicationName: string, formItem: TFormResource): Promise<IFormMethodOutput> {
        const operationOutput: IFormMethodOutput = {message: '', completed: false, formItem: undefined};
        let dataItem: any;
        try {
            const accessToken = await this.getToken();
            const formData = this._formatFormData(applicationName, formItem, true);
            dataItem = await fetch(`${DataService.API_HOST}/applications/${applicationName}/submissions`, {
                method: 'POST',
                cache: 'no-cache',
                mode: 'cors',
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(formData),
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    console.log(response);
                    throw new Error(response.statusText);
                }
            });
            if (dataItem) {
                operationOutput.message = 'Item inserted';
                operationOutput.completed = true;
                this._setFormItem(applicationName, operationOutput, dataItem);
            }
        } catch (error: any) {
            console.log(dataItem);
            operationOutput.message = typeof error == 'string' ? error : error.message;
        }
        return operationOutput;
    }

    public async submitFormItem(applicationName: string, applicationId: string): Promise<IFormMethodOutput> {
        const operationOutput: IFormMethodOutput = {
            message: '',
            completed: false,
            formItem: undefined,
        };
        let dataItem: any;
        try {
            const accessToken = await this.getToken();
            dataItem = await fetch(
                `${DataService.API_HOST}/applications/${applicationName}/submissions/${applicationId}/actions/submit`,
                {
                    method: 'POST',
                    cache: 'no-cache',
                    mode: 'cors',
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            ).then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    console.log(response);
                    throw new Error(response.statusText);
                }
            });
            if (dataItem) {
                operationOutput.message = 'Item submitted';
                operationOutput.completed = true;
                this._setFormItem(applicationName, operationOutput, dataItem);
            }
        } catch (error: any) {
            console.log(dataItem);
            operationOutput.message = typeof error == 'string' ? error : error.message;
        }
        return operationOutput;
    }

    public async requestHelpFormItem(applicationName: string, applicationId: string): Promise<IFormMethodOutput> {
        const operationOutput: IFormMethodOutput = {
            message: '',
            completed: false,
            formItem: undefined,
        };
        let dataItem: any;
        try {
            const accessToken = await this.getToken();
            dataItem = await fetch(
                `${DataService.API_HOST}/applications/${applicationName}/submissions/${applicationId}/actions/requesthelp`,
                {
                    method: 'POST',
                    cache: 'no-cache',
                    mode: 'cors',
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            ).then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    console.log(response);
                    throw new Error(response.statusText);
                }
            });
            if (dataItem) {
                operationOutput.message = 'Help requested for Item';
                operationOutput.completed = true;
                this._setFormItem(applicationName, operationOutput, dataItem);
            }
        } catch (error: any) {
            console.log(dataItem);
            operationOutput.message = typeof error == 'string' ? error : error.message;
        }
        return operationOutput;
    }

    public async amendFormItem(applicationName: string, formItem: TFormResource): Promise<IFormMethodOutput> {
        const operationOutput: IFormMethodOutput = {
            message: '',
            completed: false,
            formItem: undefined,
        };
        let dataItem: any;
        try {
            const accessToken = await this.getToken();
            const formData = this._formatFormData(applicationName, formItem, true);
            dataItem = await fetch(`${DataService.API_HOST}/applications/${applicationName}/amendments`, {
                method: 'POST',
                cache: 'no-cache',
                mode: 'cors',
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(formData),
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    console.log(response);
                    throw new Error(response.statusText);
                }
            });
            if (dataItem) {
                operationOutput.message = 'Item amended';
                operationOutput.completed = true;
                this._setFormItem(applicationName, operationOutput, dataItem);
            }
        } catch (error: any) {
            console.log(dataItem);
            operationOutput.message = typeof error == 'string' ? error : error.message;
        }
        return operationOutput;
    }

    public async getFormAttachments(applicationName: string, formItem: TFormResource): Promise<IAttachmentDocument[]> {
        const dataItem: IAttachmentDocument[] = [];
        if (formItem.applicationId !== '') {
            try {
                const accessToken = await this.getToken();
                let endpoint = `${DataService.API_HOST}/applications/${applicationName}/submissions/${formItem.applicationId}/attachments`;
                if (formItem.status !== 'Draft') {
                    endpoint = `${DataService.API_HOST}/applications/${applicationName}/amendments/${formItem.amendmentId}/attachments`;
                }
                const results = await fetch(endpoint, {
                    method: 'GET',
                    cache: 'no-cache',
                    mode: 'cors',
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                }).then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        console.log(response);
                        throw new Error(response.statusText);
                    }
                });
                if (results && results.length > 0) {
                    results.forEach((item: IAttachmentDocument) => {
                        item.filename = decodeURI(item.filename);
                        dataItem.push(item);
                    });
                }
            } catch (error: any) {
                console.log(error);
            }
        }
        return dataItem;
    }

    public async getFormAttachment(fileName: string, path: string): Promise<boolean> {
        let success: boolean;
        try {
            const accessToken = await this.getToken();
            const blob = await fetch(path, {
                method: 'GET',
                cache: 'no-cache',
                mode: 'cors',
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            }).then((res) => {
                return res.blob();
            });
            saveAs(blob, fileName);
            success = true;
        } catch (error: any) {
            console.log(error);
            success = false;
        }
        return success;
    }

    public async deleteFormAttachment(path: string): Promise<boolean> {
        let success: boolean;
        try {
            const accessToken = await this.getToken();
            success = await fetch(path, {
                method: 'DELETE',
                cache: 'no-cache',
                mode: 'cors',
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            }).then((response) => {
                if (response.ok) {
                    return true;
                } else {
                    console.log(response);
                    throw new Error(response.statusText);
                }
            });
        } catch (error: any) {
            console.log(error);
            success = false;
        }
        return success;
    }

    public async addFormAttachment(
        applicationName: string,
        formItem: TFormResource,
        file: File,
        request: XMLHttpRequest
    ): Promise<boolean> {
        let uploadTriggered: boolean = false;
        try {
            const accessToken = await this.getToken();
            let endpoint = encodeURI(
                `${DataService.API_HOST}/applications/${applicationName}/submissions/${formItem.applicationId}/attachments/${file.name}`
            );
            if (formItem.amendmentId !== '') {
                endpoint = encodeURI(
                    `${DataService.API_HOST}/applications/${applicationName}/amendments/${formItem.amendmentId}/attachments/${file.name}`
                );
            }
            request.open('POST', endpoint);
            request.setRequestHeader('Authorization', `Bearer ${accessToken}`);
            request.setRequestHeader('Content-type', 'application/octet-stream');
            request.send(file);
            uploadTriggered = true;
        } catch (error: any) {
            console.log(error);
        }
        return uploadTriggered;
    }
}
