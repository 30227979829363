export class Form {
    public applicationId: string = '';
    public amendmentId: string = '';
    public edgeId: string = '';
    public userId: string = '';
    public status: 'Draft' | 'Help Requested' | 'Submitted' | 'Rejected' | 'Approved' | 'Completed';
    public additionalComments: string = '';
    public rejectionComments: string = '';
    public submittedDate: Date | undefined = undefined;
    public rejectedDate: Date | undefined = undefined;
    public approvedDate: Date | undefined = undefined;
    public notificationSendDate: Date | undefined = undefined;
    public Created: Date | undefined = undefined;
    public Modified: Date | undefined = undefined;

    constructor(applicationId?: string) {
        this.applicationId = applicationId ? applicationId : '';
        this.status = 'Draft';
    }
}
