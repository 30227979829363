import React from 'react';
import AbILogo from '../assets/AbILogo.png';

export const Header: React.FunctionComponent = (props: any) => {
    return (
        <header className="App-header">
            <img src={AbILogo} alt="logo" />
        </header>
    );
}

export default Header;