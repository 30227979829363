import React, { createRef } from 'react';
import DataService from '../data/DataService';
import { FormServiceRequestResource } from '../dto/FormServiceRequestResource';
import { IFormMethodOutput } from '../interfaces/IFormMethodOutput';
import { IFormConfiguration } from '../interfaces/IFormConfiguration';
import { IFormState } from '../interfaces/IFormState';
import { IFormValidation } from '../interfaces/IFormValidation';
import Navigation from '../layouts/Navigation';
import { Checkbox, ChoiceGroup, DatePicker, defaultCalendarStrings, IChoiceGroupOption, Label, PrimaryButton, Spinner, SpinnerSize, Stack, TextField, IChoiceGroupOptionStyles, IRefObject, DefaultButton, Dialog, DialogFooter, DialogType, ContextualMenu, TooltipHost, Icon, ICheckboxStyleProps, ICheckboxStyles, ComboBox, SelectableOptionMenuItemType, Dropdown } from '@fluentui/react';
import Upload from '../components/Upload';
import ChoiceGroupWithValidation from '../components/ChoiceGroupWithValidation';
import TextFieldWithVisualLimit from '../components/TextFieldWithVisualLimit';
import Utils from '../utils/Utils';
import Auth from '../Auth';
import { RouteComponentProps } from 'react-router-dom';
import FormLogo from '../assets/AbSPORULogo.png';
import CheckboxWithValidation from '../components/CheckboxWithValidation';
import { ISelectableOptionWithTooltip } from '../interfaces/ISelectableOptionWithTooltip';
import dateFormat from 'dateformat';
import DatePickerWithValidation from '../components/DatePickerWithValidation';
import { TEXT_DOUBLE_COLUMN, TEXT_SINGLE_COLUMN, FILE_SIZE_LIMIT, AUTO_SAVE_INTERVAL, TEXT_DOUBLE_COLUMN_COMBOBOX, ORGANIZATION_DEFAULTS } from '../constants/Constants';

export interface IFormServiceRequestProps extends RouteComponentProps {
}

export default class FormServiceRequest extends React.Component<IFormServiceRequestProps, IFormState> {
    applicationName: string = 'ServiceRequest';
    fileSizeLimit: number = FILE_SIZE_LIMIT;
    dataService: DataService = new DataService();
    utils: Utils = new Utils();
    pathApplicationId = (window.location.pathname.split('/').pop() as string);
    saveIntervalId: number;

    // create and set choices fields (checkbox and dropdown (ISelectableOptionWithTooltip[]), radio (IChoiceGroupOption[]))
    roleChoices: IChoiceGroupOption[] = this._setRoleChoices();
    previousInteractionChoices: IChoiceGroupOption[] = this._setPreviousInteractionChoices();
    ethicsApprovalChoices: IChoiceGroupOption[] = this._setEthicsApprovalChoices();
    receiveNewsletterChoices: IChoiceGroupOption[] = this._setReceiveNewsletterChoices();
    studyTypeChoices: ISelectableOptionWithTooltip[] = this._setStudyTypeChoices();
    projectFundedChoices: ISelectableOptionWithTooltip[] = this._setProjectFundedChoices();
    projectFundedInitiativesChoices: ISelectableOptionWithTooltip[] = this._setProjectFundedInitiativesChoices();
    strategicClinicalNetworkChoices: ISelectableOptionWithTooltip[] = this._setStrategicClinicalNetworkChoices();
    nationalSPORNetworkChoices: ISelectableOptionWithTooltip[] = this._setNationalSPORNetworkChoices();
    SPORSupportUnitChoices: ISelectableOptionWithTooltip[] = this._setSPORSupportUnitChoices();
    EDIPriorityAreaChoicesA: ISelectableOptionWithTooltip[] = this._setEDIPriorityAreaChoicesA();
    EDIPriorityAreaChoicesB: ISelectableOptionWithTooltip[] = this._setEDIPriorityAreaChoicesB();
    supportRequestedAssistanceChoices: ISelectableOptionWithTooltip[] = this._setSupportRequestedAssistanceChoices();
    attachedDocumentsInfoChoices: ISelectableOptionWithTooltip[] = this._setAttachedDocumentsInfoChoices();

    // create a ref for each field that can be focused. For example: On a validation error
    requesterNameRef: IRefObject<any> = createRef();
    requesterPhoneRef: IRefObject<any> = createRef();
    requesterEmailRef: IRefObject<any> = createRef();
    requesterInstitutionRef: IRefObject<any> = createRef();
    requesterDepartmentRef: IRefObject<any> = createRef();
    applicantEmailRef: IRefObject<any> = createRef();
    roleRef: IRefObject<any> = createRef();
    roleOtherRef: IRefObject<any> = createRef();
    previousInteractionWhoRef: IRefObject<any> = createRef();
    studyLongTitleRef: IRefObject<any> = createRef();
    ethicsApplicationNumberRef: IRefObject<any> = createRef();
    targetCompletionDateRef: IRefObject<any> = createRef();
    EDIPriorityAreaParticularGroupRef: IRefObject<any> = createRef();
    layAbstractRef: IRefObject<any> = createRef();
    potentialImpactRef: IRefObject<any> = createRef();
    formAgreementRef: IRefObject<any> = createRef();
    uploadRef: React.RefObject<Upload> = createRef<Upload>();

    constructor(props: IFormServiceRequestProps) {
        super(props);
        const formConfiguration: IFormConfiguration[] = [];
        const formItem: FormServiceRequestResource = new FormServiceRequestResource();
        const formItemLastSaved: FormServiceRequestResource = new FormServiceRequestResource();
        const formValidation: IFormValidation[] = [];
        // add validation information for each field to be validated
        formValidation.push({ propertyName: 'requesterName', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.requesterNameRef });
        formValidation.push({ propertyName: 'requesterPhone', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.requesterPhoneRef });
        formValidation.push({ propertyName: 'requesterEmail', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.requesterEmailRef });
        formValidation.push({ propertyName: 'requesterInstitution', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.requesterInstitutionRef });
        formValidation.push({ propertyName: 'requesterDepartment', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.requesterDepartmentRef });
        formValidation.push({ propertyName: 'applicantEmail', isValid: true, validationType: 'custom', errorMessage: '', propertyRef: this.applicantEmailRef });
        formValidation.push({ propertyName: 'role', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.roleRef });
        formValidation.push({ propertyName: 'roleOther', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.roleOtherRef });
        formValidation.push({ propertyName: 'previousInteractionWho', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.previousInteractionWhoRef });
        formValidation.push({ propertyName: 'studyLongTitle', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.studyLongTitleRef });
        formValidation.push({ propertyName: 'ethicsApplicationNumber', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.ethicsApplicationNumberRef });
        formValidation.push({ propertyName: 'targetCompletionDate', isValid: true, validationType: 'custom', errorMessage: 'This date cannot be before the Target Start Date', propertyRef: this.targetCompletionDateRef });
        formValidation.push({ propertyName: 'EDIPriorityAreaParticularGroup', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.EDIPriorityAreaParticularGroupRef });
        formValidation.push({ propertyName: 'layAbstract', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.layAbstractRef, charLimit: 1500, remainingChars: 1500 });
        formValidation.push({ propertyName: 'potentialImpact', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.potentialImpactRef });
        formValidation.push({ propertyName: 'formAgreement', isValid: true, validationType: 'custom', errorMessage: 'You must agree to the disclaimer and the terms of use to submit your application.', propertyRef: this.formAgreementRef });

        this.state = {
            formConfiguration: formConfiguration,
            formItem: formItem,
            formItemLastSaved: formItemLastSaved,
            formVisible: false,
            formValidation: formValidation,
            UserFirstName: '',
            UserLastName: '',
            UserEmail: '',
            UserPhoneNumber: '',
            clearDialogHidden: true,
            submitDialogHidden: true,
            amendDialogHidden: true,
            helpRequestDialogHidden: true,
            notificationDialogHidden: true,
            notificationDialogTitle: '',
            notificationDialogMessage: '',
            redirectToDashboard: false,
            formAgreement: false,
            uploadElementKey: 1,
            showApplicantInstitutionOther: false,
            showRequesterInstitutionOther: false,
            isFormDisabled: false
        };
    };

    private _onAgreementChange = (propertyName: string, ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined): void => {
        this.setState({
            formAgreement: checked ? true : false,
        });
        this._clearErrorMessage(propertyName);
    }

    private _onTextFieldChange = (propertyName: string, event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        const formValidation = this.state.formValidation;
        const formValidationItem = this.utils.getFormValidationItem(formValidation, propertyName);
        (this.state.formItem as any)[propertyName] = newValue;
        this.setState({
            formItem: this.state.formItem,
        });
        if (formValidationItem && formValidationItem.charLimit) {
            formValidationItem.remainingChars = formValidationItem.charLimit - (newValue ? newValue.length : 0);
            this.setState({
                formValidation: formValidation,
            });
        }
        this._clearErrorMessage(propertyName);
    }

    private _onChoiceGroupChange = (propertyName: string, ev?: React.FormEvent<HTMLInputElement | HTMLElement>, option?: IChoiceGroupOption): void => {
        (this.state.formItem as any)[propertyName] = option?.key.toString();
        this.setState({
            formItem: this.state.formItem,
        });
        this._clearOtherFieldValues(propertyName);
        this._clearErrorMessage(propertyName);
    }

    private _onDatePickerChange = (propertyName: string, date: Date | null | undefined): void => {
        (this.state.formItem as any)[propertyName] = date;
        this.setState({
            formItem: this.state.formItem,
        });
        this._clearErrorMessage(propertyName);
    }

    private _onCheckBoxChange = (propertyName: string, ev?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean, isYesNoCheck?: boolean): void => {
        const newOptions: string[] = [];
        const checkBoxValue: string = (ev?.target as HTMLInputElement).name;
        if (isYesNoCheck) {
            (this.state.formItem as any)[propertyName] = ((this.state.formItem as any)[propertyName] === "" || (this.state.formItem as any)[propertyName] === "No") ? "Yes" : "No";
        } else {
            if ((this.state.formItem as any)[propertyName]) {
                (this.state.formItem as any)[propertyName].forEach((selectedOption: string) => {
                    newOptions.push(selectedOption);
                });
            }
            if (checked && !newOptions.includes(checkBoxValue)) {
                newOptions.push(checkBoxValue);
            } else {
                newOptions.splice(newOptions.indexOf(checkBoxValue), 1);
            }
            (this.state.formItem as any)[propertyName] = newOptions;
        }
        this.setState({
            formItem: this.state.formItem,
        });
        this._clearOtherFieldValues(propertyName);
        this._clearErrorMessage(propertyName);
    }

    private _loadCheckboxes(propertyName: string, choices: ISelectableOptionWithTooltip[], componentRef?: IRefObject<any>, width?: number) {
        const Checkboxes: JSX.Element[] = [];
        const newOptions: string[] = [];
        const checkboxStyles = (styleProps: ICheckboxStyleProps): ICheckboxStyles => {
            const chkStyles: ICheckboxStyles = width ? {
                root: {
                    width: width,
                }, label: { alignItems: 'flex-start' }, checkbox: [
                    { backgroundColor: "white" },
                    styleProps.checked && {
                        backgroundColor: "rgb(0, 90, 158)",
                    },
                ],
            } : {
                label: { alignItems: 'flex-start' }, checkbox: [
                    { backgroundColor: "white" },
                    styleProps.checked && {
                        backgroundColor: "rgb(0, 90, 158)",
                    },
                ],
            };
            return chkStyles;
        };
        if ((this.state.formItem as any)[propertyName]) {
            (this.state.formItem as any)[propertyName].forEach((selectedOption: string) => {
                newOptions.push(selectedOption);
            });
        }
        choices.forEach((choice: ISelectableOptionWithTooltip, index) => {
            let isChecked: boolean = newOptions.includes((choice.key as string)) ? true : false;
            if (choice.tooltipText) {
                Checkboxes.push(
                    <span key={index} style={{ display: 'flex' }}>
                        <Checkbox {...(componentRef && Checkboxes.length === 0 ? { componentRef: componentRef } : {})} disabled={this.state.isFormDisabled} styles={checkboxStyles} key={(choice.key as string)} label={(choice.text as string)} name={(choice.key as string)} checked={isChecked} onChange={(ev, checked) => { this._onCheckBoxChange(propertyName, ev, checked); }}
                            onRenderLabel={(p) => { return <span className="ms-Checkbox-text" style={{ marginLeft: '4px', color: 'rgb(50, 49, 48)' }}>{choice.customLabel ? choice.customLabel : choice.text}</span> }}
                        />
                        <TooltipHost
                            styles={{ root: { marginLeft: '8px', alignSelf: 'center' } }}
                            content={choice.tooltipText}
                            tooltipProps={{
                                styles: {
                                    content: { fontWeight: '600' }
                                }
                            }}>
                            <Icon iconName="Info" />
                        </TooltipHost>
                    </span>
                );
            } else {
                Checkboxes.push(
                    <Checkbox {...(componentRef && Checkboxes.length === 0 ? { componentRef: componentRef } : {})} disabled={this.state.isFormDisabled} styles={checkboxStyles} key={(choice.key as string)} label={(choice.text as string)} name={(choice.key as string)} checked={isChecked} onChange={(ev, checked) => { this._onCheckBoxChange(propertyName, ev, checked); }}
                        onRenderLabel={(p) => { return <span className="ms-Checkbox-text" style={{ marginLeft: '4px', color: 'rgb(50, 49, 48)' }}>{choice.customLabel ? choice.customLabel : choice.text}</span> }}
                    />
                );
            }
        });
        return Checkboxes;
    }

    private _setRoleChoices() {
        const choices: IChoiceGroupOption[] = [];
        const style: IChoiceGroupOptionStyles = {
            root: {
                alignItems: 'flex-start',
                width: 380,
            },
        };
        choices.push({ key: "Academic", text: "Academic", styles: style });
        choices.push({ key: "Clinician", text: "Clinician", styles: style });
        choices.push({ key: "Community/Municipal Organization Rep.", text: "Community/Municipal Organization Representative (including policy maker)", styles: style });
        choices.push({ key: "Federal/Provincial Rep.", text: "Federal/Provincial Representative (including policy maker)", styles: style });
        choices.push({ key: "Healthcare Provider", text: "Healthcare Provider", styles: style });
        choices.push({ key: "Health System/Care Manager", text: "Health System/Care Manager", styles: style });
        choices.push({ key: "Masters Student", text: "Masters Student", styles: style });
        choices.push({ key: "PaCER", text: "Patient and Community Engagement Researcher (PaCER)", styles: style });
        choices.push({ key: "Patient Partner", text: "Patient Partner", styles: style });
        choices.push({ key: "PhD Student", text: "PhD Student", styles: style });
        choices.push({ key: "Postdoc/Fellow", text: "Postdoctoral/Fellow", styles: style });
        choices.push({ key: "Resident", text: "Resident", styles: style });
        choices.push({ key: "Undergraduate Student", text: "Undergraduate Student", styles: style });
        choices.push({ key: "Other", text: "Other (describe below)", styles: style });
        return choices;
    }

    private _setPreviousInteractionChoices() {
        const choices: IChoiceGroupOption[] = [];
        choices.push({ key: "Yes", text: "Yes", });
        choices.push({ key: "No", text: "No", });
        return choices;
    }

    private _setStudyTypeChoices() {
        const choices: ISelectableOptionWithTooltip[] = [];
        choices.push({ key: "Chart Review", text: "Chart Review", });
        choices.push({ key: "Clinical Trial", text: "Clinical Trial", });
        choices.push({ key: "Comparative Effectiveness Research", text: "Comparative Effectiveness Research", });
        choices.push({ key: "Epidemiological Study", text: "Epidemiological Study", });
        choices.push({ key: "Health Systems & Policy Research", text: "Health Systems & Policy Research", });
        choices.push({ key: "Implementation (Science) Study (inc. ISC projects)", text: "Implementation (Science) Study (inc. ISC projects)", });
        choices.push({ key: "Innovation Study", text: "Innovation Study", });
        choices.push({ key: "Knowledge Synthesis", text: "Knowledge Synthesis", });
        choices.push({ key: "Multi-Centre Trial", text: "Multi-Centre Trial", });
        choices.push({ key: "Pilot Study", text: "Pilot Study", });
        choices.push({ key: "Program Evaluation", text: "Program Evaluation", });
        choices.push({ key: "Qualitative Study", text: "Qualitative Study", });
        choices.push({ key: "Real World Evidence (RWE)", text: "Real World Evidence (RWE)", });
        choices.push({ key: "Technology Assessment/Development", text: "Technology Assessment/Development", });
        choices.push({ key: "Validation Study", text: "Validation Study", });
        choices.push({ key: "Other", text: "Other", });
        return choices;
    }

    private _setEthicsApprovalChoices() {
        const choices: IChoiceGroupOption[] = [];
        choices.push({ key: "Ethics approval not required at this stage", text: "ETHICS APPROVAL NOT REQUIRED AT THIS STUDY STAGE - (i.e. Grant application, project planning etc.)", });
        choices.push({ key: "Not required", text: "ETHICS APPROVAL NOT REQUIRED FOR THIS PROJECT", });
        choices.push({ key: "Ethics approval obtained", text: "ETHICS APPROVAL OBTAINED - include a copy of the Ethics Approval (and renewal if applicable). If requesting data, also include the Research Proposal and list of data requirements. Per the Health Information Act, research-related requests cannot be processed until this documentation is received.", });
        return choices;
    }

    private _setProjectFundedChoices() {
        const choices: ISelectableOptionWithTooltip[] = [];
        choices.push({ key: "Yes - Grant", text: "Yes - Grant", });
        choices.push({ key: "Yes - Industry", text: "Yes - Industry", });
        choices.push({ key: "Yes - Other", text: "Yes - Other", });
        choices.push({ key: "No", text: "No", });
        choices.push({ key: "Applying for Funding", text: "Applying for Funding", });
        return choices;
    }

    private _setProjectFundedInitiativesChoices() {
        const choices: ISelectableOptionWithTooltip[] = [];
        choices.push({ key: "_N/A", text: "Not Applicable", });
        choices.push({ key: "iCT Catalyst Grants", text: "iCT Catalyst Grants", });
        choices.push({ key: "iCT Chairs", text: "iCT Chairs", });
        choices.push({ key: "iCT Multi-Year Grants - Launch #1 (Open)", text: "iCT Multi-Year Grants - Launch #1 (Open)", });
        choices.push({ key: "iCT Multi-Year Grants - Launch #2 (JDRF)", text: "iCT Multi-Year Grants - Launch #2 (JDRF)", });
        choices.push({ key: "PE Collaborative Grants", text: "Patient Engagement Collaboration Grants", });
        choices.push({ key: "POR Collaborative Grants", text: "Patient Oriented Research Collaboration Grants", });
        choices.push({ key: "RS - Business Case Dev Grants", text: "Rewarding Success - Business Case Development Grants", });
        choices.push({ key: "RS - Team Grants", text: "Rewarding Success - Team Grants", });
        choices.push({ key: "RS - Travel Awards", text: "Rewarding Success - Travel Awards", });
        choices.push({ key: "SPOR Clinical Trial Grant", text: "SPOR Clinical Trial Grant", });
        return choices;
    }

    private _setStrategicClinicalNetworkChoices() {
        const choices: ISelectableOptionWithTooltip[] = [];
        choices.push({ key: "_N/A", text: "Not Applicable", });
        choices.push({ key: "Bone and Joint Health", text: "Bone and Joint Health", });
        choices.push({ key: "Cancer", text: "Cancer", });
        choices.push({ key: "Cardiovascular Health and Stroke", text: "Cardiovascular Health and Stroke", });
        choices.push({ key: "Critical Care", text: "Critical Care", });
        choices.push({ key: "Diabetes, Obesity and Nutrition", text: "Diabetes, Obesity and Nutrition", });
        choices.push({ key: "Digestive Health", text: "Digestive Health", });
        choices.push({ key: "Emergency", text: "Emergency", });
        choices.push({ key: "Maternal Newborn Child & Youth", text: "Maternal Newborn Child & Youth", });
        choices.push({ key: "Medicine", text: "Medicine", });
        choices.push({ key: "NeuroSciences, Rehabilitation and Vision", text: "Neurosciences, Rehabilitation and Vision", });
        choices.push({ key: "Surgery", text: "Surgery", });
        return choices;
    }

    private _setNationalSPORNetworkChoices() {
        const choices: ISelectableOptionWithTooltip[] = [];
        choices.push({ key: "_N/A", text: "Not Applicable", });
        choices.push({ key: "Child Bright", text: "CHILD-BRIGHT Network", });
        choices.push({ key: "Chronic Pain", text: "Chronic Pain Network", });
        choices.push({ key: "Diabetes Action Canada", text: "Diabetes Action Canada", });
        choices.push({ key: "IMAGINE", text: "Inflammation, Microbiome, and Alimentation: Gastro-Intestinal and Neuropsychiatric Effects: the IMAGINE-SPOR Chronic Disease Network", });
        choices.push({ key: "CAN SOLVE CKD", text: "Listening, Learning, Leading: Canadians Seeking Solutions and Innovations to Overcome Chronic Kidney Disease (Can-SOLVE CKD)", });
        choices.push({ key: "PIHCI", text: "Primary and Integrated Health Care Innovations -PIHCI", });
        choices.push({ key: "ACCESS Open Minds", text: "Youth and Adolescent Mental Health - ACCESS Open Minds", });
        return choices;
    }

    private _setSPORSupportUnitChoices() {
        const choices: ISelectableOptionWithTooltip[] = [];
        choices.push({ key: "_N/A", text: "Not Applicable", });
        choices.push({ key: "BC", text: "British Columbia", });
        choices.push({ key: "MB", text: "Manitoba", });
        choices.push({ key: "Maritime", text: "Maritime Support Unit", });
        choices.push({ key: "NL/Labrador", text: "Newfoundland & Labrador", });
        choices.push({ key: "NT", text: "Northwest Territories", });
        choices.push({ key: "NU", text: "Nunavut", });
        choices.push({ key: "ON", text: "Ontario", });
        choices.push({ key: "QB", text: "Quebec", });
        choices.push({ key: "SK", text: "Saskatchewan", });
        choices.push({ key: "YK", text: "Yukon", });
        return choices;
    }

    private _setEDIPriorityAreaChoicesA() {
        const choices: ISelectableOptionWithTooltip[] = [];
        choices.push({ key: "N/A", text: "Not Applicable", });
        choices.push({ key: "Indigenous Peoples", text: "This research includes Indigenous Peoples", });
        return choices;
    }

    private _setEDIPriorityAreaChoicesB() {
        const choices: ISelectableOptionWithTooltip[] = [];
        choices.push({ key: "Racial or Ethnic Group", text: "A particular racial or ethnic group", });
        choices.push({ key: "Gender Identity/Role", text: "A particular gender identity/role", });
        choices.push({ key: "Sex", text: "A particular sex", });
        choices.push({ key: "Sexuality/Sexual Orientation", text: "A particular sexuality/sexual orientation", });
        choices.push({ key: "Newcomers to Canada", text: "Newcomers to Canada", });
        choices.push({ key: "Structural or Geographic Vulnerability", text: "A group affected by a particular structural or geographic vulnerability", });
        choices.push({ key: "Other", text: "A particular group not already listed (please specify)", });
        return choices;
    }

    private _setSupportRequestedAssistanceChoices() {
        const choices: ISelectableOptionWithTooltip[] = [];
        choices.push({ key: "Data & Research Services", text: "Data & Research Services (Research Design, Data Extraction & Linkages, Data Management, Data Analysis)", customLabel: <span><span style={{ fontWeight: '600' }}>Data & Research Services</span> (Research Design, Data Extraction & Linkages, Data Management, Data Analysis)</span> });
        choices.push({ key: "Patient Engagement", text: "Patient Engagement (Patient Engagement Plans, Patient Partners, Training & Mentorship, Evaluation & Certification)", customLabel: <span><span style={{ fontWeight: '600' }}>Patient Engagement</span> (Patient Engagement Plans, Patient Partners, Training & Mentorship, Evaluation & Certification)</span> });
        choices.push({ key: "Learning Health Systems", text: "Learning Health Systems (Knowledge Translation, Implementation)", customLabel: <span><span style={{ fontWeight: '600' }}>Learning Health Systems</span> (Knowledge Translation, Implementation)</span> });
        return choices;
    }

    private _setAttachedDocumentsInfoChoices() {
        const choices: ISelectableOptionWithTooltip[] = [];
        choices.push({ key: "Research Protocol/Project Description", text: "Research Protocol/Project Description", });
        choices.push({ key: "Ethics Approval, if applicable (as per Question #7)", text: "Ethics Approval, if applicable (as per Question #7)", });
        choices.push({ key: "Other Relevant Documents", text: "Other Relevant Documents", });
        return choices;
    }

    private _setReceiveNewsletterChoices() {
        const choices: IChoiceGroupOption[] = [];
        choices.push({ key: "Yes", text: "Yes", });
        choices.push({ key: "No", text: "No", });
        return choices;
    }

    private _displayClearDialog() {
        this.setState({
            clearDialogHidden: false,
        });
    }

    private _displaySubmitDialog() {
        this.setState({
            submitDialogHidden: false,
        });
    }

    private _displayAmendDialog() {
        this.setState({
            amendDialogHidden: false,
        });
    }

    private _displayHelpDialog() {
        this.setState({
            helpRequestDialogHidden: false,
        });
    }

    private async _dismissClearDialog() {
        this.setState({
            clearDialogHidden: true
        });
    }

    private async _dismissSubmitDialog() {
        this.setState({
            submitDialogHidden: true
        });
    }

    private async _dismissAmendDialog() {
        this.setState({
            amendDialogHidden: true
        });
    }

    private async _dismissHelpDialog() {
        this.setState({
            helpRequestDialogHidden: true
        });
    }

    private async _dismissNotificationDialog() {
        if (this.state.redirectToDashboard) {
            this._clearFormTimer();
            this.props.history.push('/');
        } else {
            this.setState({
                notificationDialogHidden: true,
            });
        }
    }

    private async _getFormConfiguration() {
        const formConfiguration = await this.dataService.getFormConfiguration(this.applicationName);
        this.setState({
            formConfiguration: formConfiguration,
        });
    }

    private _calculateRemainingChars(formItem: FormServiceRequestResource) {
        const formValidation = this.state.formValidation;
        const formValidationItem = this.utils.getFormValidationItem(formValidation, 'layAbstract');
        if (formValidationItem && formValidationItem.charLimit) {
            formValidationItem.remainingChars = formValidationItem.charLimit - (formItem.layAbstract ? formItem.layAbstract.length : 0);
            this.setState({
                formValidation: formValidation,
            });
        }
    }

    private async _getFormServiceRequest(applicationId: string) {
        const formOperationOutput = await this.dataService.getFormItem(this.applicationName, applicationId);
        let formItem = (formOperationOutput.formItem as FormServiceRequestResource);
        if (formItem.status === "Submitted" || formItem.status === "Rejected") {
            const amendmentItem = new FormServiceRequestResource();
            amendmentItem.applicationId = formItem.applicationId;
            amendmentItem.amendmentId = formItem.amendmentId;
            amendmentItem.edgeId = formItem.edgeId;
            amendmentItem.userId = formItem.userId;
            amendmentItem.status = formItem.status;
            formItem = amendmentItem;
        }
        if (formOperationOutput.completed) {
            this._calculateRemainingChars(formItem);
            this.setState({
                formItem: formItem,
                formItemLastSaved: {...formItem},
                formVisible: true,
                isFormDisabled: formItem.status === 'Approved' || formItem.status === 'Completed', // If form is complete, make read-only
                showRequesterInstitutionOther: !(ORGANIZATION_DEFAULTS.some(i => i.key === formItem.requesterInstitution) || formItem.requesterInstitution === ''),
                showApplicantInstitutionOther: !(ORGANIZATION_DEFAULTS.some(i => i.key === formItem.applicantInstitution) || formItem.applicantInstitution === '')
            });
            await this.uploadRef.current && this.uploadRef.current!.callGetAttachments(formItem);
        } else {
            alert('Could not get form data.');
            this.props.history.push('/');
        }
    }

    private _clearOtherFieldValues(propertyName: string) {
        let changed = false;
        const formItem = this.state.formItem as FormServiceRequestResource;
        switch (propertyName) {
            case "role":
                if (formItem.role !== "Other" && formItem.roleOther !== "") {
                    formItem.roleOther = "";
                    changed = true;
                }
                break;
            case "previousInteraction":
                if (formItem.previousInteraction !== "Yes" && formItem.previousInteractionWho !== "") {
                    formItem.previousInteractionWho = "";
                    changed = true;
                }
                break;
            case "ethicsApproval":
                if (formItem.ethicsApproval !== 'Ethics approval obtained' && formItem.ethicsApplicationNumber !== "") {
                    formItem.ethicsApplicationNumber = "";
                    changed = true;
                }
                break;
            case 'EDIPriorityArea':
                if (!formItem.EDIPriorityArea.includes('Other') && formItem.EDIPriorityAreaParticularGroup !== "") {
                    formItem.EDIPriorityAreaParticularGroup = "";
                    changed = true;
                }
                break;
        }
        if (changed) {
            this.setState({
                formItem: formItem,
            });
        }
    }

    private _clearErrorMessage(propertyName: string) {
        const properties: string[] = [];
        properties.push(propertyName);
        switch (propertyName) {
            case "role":
                properties.push('roleOther');
                break;
            case "previousInteraction":
                properties.push('previousInteractionWho');
                break;
            case "ethicsApproval":
                properties.push('ethicsApplicationNumber');
                break;
            case "EDIPriorityArea":
                properties.push('EDIPriorityAreaParticularGroup');
                break;
        }
        const formValidation = this.state.formValidation;
        let changed = false;
        properties.forEach((property) => {
            const formValidationItem = this.utils.getFormValidationItem(formValidation, property);
            if (formValidationItem && !formValidationItem.isValid) {
                formValidationItem.isValid = true;
                changed = true;
            }
        });
        if (changed) {
            this.setState({
                formValidation: formValidation,
            });
        }
    }

    private _validateForm() {
        let isValid = true;
        const formItem = this.state.formItem as FormServiceRequestResource;
        const formValidation = this.state.formValidation;
        let focusSet = false;
        let focusItem: IRefObject<any> | null = null;
        for (const formValidationItem of formValidation) {
            switch (formValidationItem.validationType) {
                case 'required':
                    if ((formItem as any)[formValidationItem.propertyName] === undefined || (formItem as any)[formValidationItem.propertyName] === null || (formItem as any)[formValidationItem.propertyName].length === 0) {
                        isValid = false;
                        formValidationItem.isValid = false;
                        if (!focusSet) {
                            focusItem = formValidationItem.propertyRef;
                            focusSet = true;
                        }
                    }
                    break;
                case 'custom':
                    switch (formValidationItem.propertyName) {
                        case 'requesterEmail':
                            if (formItem.requesterEmail.length === 0 || !this.utils.isValidEmail(formItem.requesterEmail)) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (formItem.requesterEmail.length === 0) {
                                    formValidationItem.errorMessage = 'This field cannot be empty';
                                } else {
                                    formValidationItem.errorMessage = 'Please enter a valid email address';
                                }
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'applicantEmail':
                            if (formItem.applicantEmail.length > 0 && !this.utils.isValidEmail(formItem.applicantEmail)) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                formValidationItem.errorMessage = 'Please enter a valid email address';
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'role':
                            if (formItem.role.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'roleOther':
                            if (formItem.role === 'Other' && formItem.roleOther.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'previousInteractionWho':
                            if (formItem.previousInteraction === 'Yes' && formItem.previousInteractionWho.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'ethicsApplicationNumber':
                            if (formItem.ethicsApproval === 'Ethics approval obtained' && formItem.ethicsApplicationNumber.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'targetCompletionDate':
                            if (!formItem.targetStartDate && formItem.targetCompletionDate) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                formValidationItem.errorMessage = 'No start date informed. Please enter a start date';
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            if (formItem.targetStartDate && formItem.targetCompletionDate && (formItem.targetStartDate > formItem.targetCompletionDate)) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'EDIPriorityAreaParticularGroup':
                            if (formItem.EDIPriorityArea.includes('Other') && formItem.EDIPriorityAreaParticularGroup.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'formAgreement':
                            if (!this.state.formAgreement) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                    }
                    break;
            }
        }
        if (!isValid) {
            this.setState({
                formValidation: formValidation,
            });
            this._dismissSubmitDialog().then(() => {
                if (focusItem) {
                    (focusItem as any).current.focus();
                }
            });
        }
        return isValid;
    }

    private async _saveFormServiceRequest(disableNotification?: boolean) {
        await this._updateToken();
        let formOperationOutput: IFormMethodOutput;
        const formItem = this.state.formItem;
        if (this.state.formItem.applicationId.length === 0) {
            formOperationOutput = await this.dataService.insertFormItem(this.applicationName, formItem);
        } else {
            formOperationOutput = await this.dataService.updateFormItem(this.applicationName, formItem);
        }
        if (formOperationOutput.completed) {
            await this.uploadRef.current && this.uploadRef.current!.uploadFiles(formOperationOutput.formItem as FormServiceRequestResource);
            this.setState({
                formItem: (formOperationOutput.formItem as FormServiceRequestResource),
                formItemLastSaved: {...(formOperationOutput.formItem as FormServiceRequestResource)},
                notificationDialogHidden: disableNotification ? true : false,
                notificationDialogTitle: 'Save Form',
                notificationDialogMessage: `Application (${formOperationOutput.formItem?.applicationId}) saved. \n\nThis application is in draft form and will not be processed until it is submitted. \n\nRemember that this application is available on the dashboard. You can come back and continue completing your application later.`,
            });
        } else {
            this.setState({
                notificationDialogHidden: disableNotification ? true : false,
                notificationDialogTitle: 'Error',
                notificationDialogMessage: 'An error occurred while saving the application.',
            });
        }
    }

    private async _submitFormServiceRequest() {
        if (this._validateForm()) {
            await this._saveFormServiceRequest(true);
            if (this.state.formItem.applicationId !== '') {
                const formOperationOutput = await this.dataService.submitFormItem(this.applicationName, this.state.formItem.applicationId);
                if (formOperationOutput.completed) {
                    this._clearFormTimer();
                    this.setState({
                        submitDialogHidden: true,
                        notificationDialogHidden: false,
                        notificationDialogTitle: 'Submit Application',
                        notificationDialogMessage: `This request has been completed and submitted. \n\nPlease take note of the Application ID: (${formOperationOutput.formItem?.applicationId}). Remember to check your e-mail regularly, as it will be the main source of contact during the approval process. \n\nSending this request does not guarantee its approval.`,
                        redirectToDashboard: true,
                    });
                } else {
                    this.setState({
                        submitDialogHidden: true,
                        notificationDialogHidden: false,
                        notificationDialogTitle: 'Error',
                        notificationDialogMessage: 'An error occurred while submitting the application.',
                    });
                }
            } else {
                this.setState({
                    submitDialogHidden: true,
                    notificationDialogHidden: false,
                    notificationDialogTitle: 'Error',
                    notificationDialogMessage: 'An error occurred while submitting the application.',
                });
            }
        }
    }

    private async _amendFormServiceRequest() {
        await this._updateToken();
        const formItem = this.state.formItem
        const formOperationOutput = await this.dataService.amendFormItem(this.applicationName, formItem);
        if (formOperationOutput.completed) {
            await this.uploadRef.current && this.uploadRef.current!.uploadFiles(formOperationOutput.formItem as FormServiceRequestResource);
            this._clearFormTimer();
            this.setState({
                amendDialogHidden: true,
                notificationDialogHidden: false,
                notificationDialogTitle: 'Amend Application',
                notificationDialogMessage: `Application (${formOperationOutput.formItem?.applicationId}) amended.`,
                redirectToDashboard: true,
            });
        } else {
            this.setState({
                amendDialogHidden: true,
                notificationDialogHidden: false,
                notificationDialogTitle: 'Error',
                notificationDialogMessage: 'An error occurred while amending the application.',
            });
        }
    }

    private async _helpRequestFormServiceRequest() {
        await this._updateToken();
        let formOperationOutput: IFormMethodOutput;
        const formItem = this.state.formItem;
        formItem.status = 'Help Requested';
        if (this.state.formItem.applicationId.length === 0) {
            formOperationOutput = await this.dataService.insertFormItem(this.applicationName, formItem);
        } else {
            formOperationOutput = await this.dataService.updateFormItem(this.applicationName, formItem);
        }
        if (formOperationOutput.completed) {
            if (this.state.formItem.applicationId !== '') {
                const formOperationOutput = await this.dataService.requestHelpFormItem(this.applicationName, this.state.formItem.applicationId);
                if (formOperationOutput.completed) {
                    this.setState({
                        helpRequestDialogHidden: true,
                        formItem: (formOperationOutput.formItem as FormServiceRequestResource),
                        notificationDialogHidden: false,
                        notificationDialogTitle: 'Request Help',
                        notificationDialogMessage: `Application (${formOperationOutput.formItem?.applicationId}) saved. \n\nA case manager will contact you to help.`,
                        redirectToDashboard: true,
                    });
                } else {
                    this.setState({
                        helpRequestDialogHidden: true,
                        notificationDialogHidden: false,
                        notificationDialogTitle: 'Error',
                        notificationDialogMessage: 'An error occurred while requesting help.',
                    });
                }
            } else {
                this.setState({
                    helpRequestDialogHidden: true,
                    notificationDialogHidden: false,
                    notificationDialogTitle: 'Error',
                    notificationDialogMessage: 'An error occurred while requesting help.',
                });
            }
        } else {
            this.setState({
                helpRequestDialogHidden: true,
                notificationDialogHidden: false,
                notificationDialogTitle: 'Error',
                notificationDialogMessage: 'An error occurred while requesting help.',
            });
        }
    }

    private async _clearFormServiceRequest() {
        await this._updateToken();
        const clearedItem = new FormServiceRequestResource();
        clearedItem.applicationId = this.state.formItem.applicationId;
        clearedItem.amendmentId = this.state.formItem.amendmentId;
        clearedItem.edgeId = this.state.formItem.edgeId;
        clearedItem.userId = this.state.formItem.userId;
        clearedItem.status = this.state.formItem.status;
        const formValidation = this.state.formValidation;
        for (const formValidationItem of formValidation) {
            formValidationItem.isValid = true;
        }
        // delete attachments if any
        if (this.state.formItem.applicationId.length > 0) {
            const attachments = await this.dataService.getFormAttachments(this.applicationName, this.state.formItem);
            for (const attachment of attachments) {
                await this.dataService.deleteFormAttachment(attachment.path);
            }
        }
        this.setState({
            formItem: clearedItem,
            formValidation: formValidation,
            ...(this.state.uploadElementKey && { uploadElementKey: this.state.uploadElementKey + 1 }),
        });
        this._dismissClearDialog().then(() => {
            if (this.requesterNameRef) {
                (this.requesterNameRef as any).current.focus();
            }
        });
    }

    private async _retrieveFormInformation() {
        await this._getUserInfo();
        await this._getFormConfiguration();
        this.saveIntervalId = this._saveFormTimer();
        if (this.pathApplicationId.length > 0 && this.pathApplicationId !== 'FormServiceRequest') {
            this._getFormServiceRequest(this.pathApplicationId);
        } else {
            if (this.state.formItem.applicationId.length === 0) {
                const formItem = this.state.formItem as FormServiceRequestResource;
                formItem.UserFirstName = this.state.UserFirstName;
                formItem.UserLastName = this.state.UserLastName;
                formItem.UserEmail = this.state.UserEmail;
                formItem.UserPhoneNumber = this.state.UserPhoneNumber;
                formItem.requesterName = this.state.UserFirstName + ' ' + this.state.UserLastName;
                formItem.requesterEmail = this.state.UserEmail;
                formItem.requesterPhone = this.state.UserPhoneNumber;
                this.setState({
                    formItem: formItem
                });
                await this._saveFormServiceRequest(true);
            }
            this.setState({
                formVisible: true
            });
        }
    }

    private async _getUserInfo() {
        const authResult = await Auth.acquireTokenSilent();
        if (authResult) {
            this.setState({
                UserFirstName: (authResult.idTokenClaims as any).given_name ? (authResult.idTokenClaims as any).given_name : '',
                UserLastName: (authResult.idTokenClaims as any).family_name ? (authResult.idTokenClaims as any).family_name : '',
                UserEmail: (authResult.idTokenClaims as any).emails[0] ? (authResult.idTokenClaims as any).emails[0] : '',
                UserPhoneNumber: (authResult.idTokenClaims as any).extension_PhoneNumber ? (authResult.idTokenClaims as any).extension_PhoneNumber : '',
            });
        }
    }

    private _clearFormTimer() {
        window.clearInterval(this.saveIntervalId);
    }

    private _saveFormTimer(): number {
        return window.setInterval(() => {
            if (this.state.formItem.status === 'Draft' && JSON.stringify(this.state.formItem) !== JSON.stringify(this.state.formItemLastSaved)) {
                this._saveFormServiceRequest(true);
            }
        }, AUTO_SAVE_INTERVAL);
    }

    private async _updateToken() {
        try {
            await this.dataService.getToken();
        } catch (error) {
            this.props.history.push('/logout');
        }
    }

    public componentDidMount() {
        this._retrieveFormInformation();
    }

    public render(): React.ReactElement {
        const formItem = this.state.formItem as FormServiceRequestResource;
        const { formConfiguration, formVisible, formValidation, UserFirstName, UserLastName, UserEmail, clearDialogHidden, submitDialogHidden, amendDialogHidden, helpRequestDialogHidden, notificationDialogHidden, notificationDialogTitle, notificationDialogMessage } = this.state;
        const spinner = !formVisible ? <Spinner size={SpinnerSize.large} label="Loading..." ariaLive="assertive" /> : null;
        const studyTypeCheckBoxes = this._loadCheckboxes("studyType", this.studyTypeChoices);
        const projectFundedCheckBoxes = this._loadCheckboxes("projectFunded", this.projectFundedChoices, undefined, 350);
        const projectFundedInitiativesCheckBoxes = this._loadCheckboxes("projectFundedInitiatives", this.projectFundedInitiativesChoices, undefined, 350);
        const strategicClinicalNetworkCheckBoxes = this._loadCheckboxes("strategicClinicalNetwork", this.strategicClinicalNetworkChoices, undefined, 350);
        const nationalSPORNetworkCheckBoxes = this._loadCheckboxes("nationalSPORNetwork", this.nationalSPORNetworkChoices, undefined, 350);
        const SPORSupportUnitCheckBoxes = this._loadCheckboxes("SPORSupportUnit", this.SPORSupportUnitChoices, undefined, 350);
        const EDIPriorityAreaCheckBoxesA = this._loadCheckboxes("EDIPriorityArea", this.EDIPriorityAreaChoicesA);
        const EDIPriorityAreaCheckBoxesB = this._loadCheckboxes("EDIPriorityArea", this.EDIPriorityAreaChoicesB);
        const supportRequestedAssistanceCheckBoxes = this._loadCheckboxes("supportRequestedAssistance", this.supportRequestedAssistanceChoices);
        const attachedDocumentsInfoCheckBoxes = this._loadCheckboxes("attachedDocumentsInfo", this.attachedDocumentsInfoChoices);
        const clearFormButton = <DefaultButton className="clearButton" text="Clear Form" onClick={this._displayClearDialog.bind(this)} disabled={this.state.isFormDisabled} />;
        const saveButton = formItem.status === 'Draft' ? <DefaultButton text="Save Form" onClick={() => this._saveFormServiceRequest()} disabled={this.state.isFormDisabled} /> : null;
        const submitButton = formItem.status === 'Draft' ? <PrimaryButton text="Submit Form" onClick={this._displaySubmitDialog.bind(this)} disabled={this.state.isFormDisabled} /> : null;
        const amendButton = (formItem.status === 'Submitted' || formItem.status === 'Rejected') ? <PrimaryButton text="Amend Form" onClick={this._displayAmendDialog.bind(this)} disabled={this.state.isFormDisabled} /> : null;
        const helpRequestButton = formItem.status === 'Draft' ? <DefaultButton className="floatHelpButton" text="Request Help" onClick={this._displayHelpDialog.bind(this)} disabled={this.state.isFormDisabled} /> : null;

        return (
            <main className="App-formbody">
                <Navigation
                    title="Service Request Form"
                    isForm={true}
                    UserFirstName={UserFirstName}
                    UserLastName={UserLastName}
                    UserEmail={UserEmail}
                    clearFormTimer={this._clearFormTimer.bind(this)} />
                {spinner}
                <form style={!formVisible ? { display: 'none' } : {}}>
                    <section className="applicationIDSection">
                        <span className='col1'><h2>Application Number: {formItem.applicationId}</h2></span>
                        <span className='col2'><img src={FormLogo} alt="Form logo" /></span>
                    </section>
                    <section>
                        <h2>1. Requested by</h2>
                        <h3>Primary contact for which questions about your application will be directed to</h3>
                        <TextField
                            className='requiredField'
                            errorMessage={this.utils.getErrorMessage(formValidation, 'requesterName')}
                            label="Name"
                            componentRef={this.requesterNameRef}
                            styles={TEXT_SINGLE_COLUMN}
                            value={formItem.requesterName}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("requesterName", event, newValue); }}
                        />
                        <Stack wrap styles={{ root: { height: 90, maxWidth: 800 } }} >
                            <TextField
                                className='requiredField'
                                errorMessage={this.utils.getErrorMessage(formValidation, 'requesterPhone')}
                                label="Phone Number"
                                componentRef={this.requesterPhoneRef}
                                styles={TEXT_DOUBLE_COLUMN}
                                value={formItem.requesterPhone}
                                disabled={this.state.isFormDisabled}
                                onChange={(event, newValue) => { this._onTextFieldChange("requesterPhone", event, newValue); }}
                            />
                            <TextField
                                className='requiredField'
                                errorMessage={this.utils.getErrorMessage(formValidation, 'requesterEmail')}
                                label="Email"
                                componentRef={this.requesterEmailRef}
                                styles={TEXT_DOUBLE_COLUMN}
                                value={formItem.requesterEmail}
                                disabled={this.state.isFormDisabled}
                                onChange={(event, newValue) => { this._onTextFieldChange("requesterEmail", event, newValue); }}
                            />
                        </Stack>
                        <Stack wrap styles={{ root: { height: 62, maxWidth: 800 } }} >
                            <Dropdown
                                className='requiredField'
                                label="Institution/Organization"
                                styles={TEXT_DOUBLE_COLUMN}
                                componentRef={this.requesterInstitutionRef}
                                selectedKey={this.state.showRequesterInstitutionOther ? 'Other' : formItem.requesterInstitution}
                                options={ORGANIZATION_DEFAULTS}
                                disabled={this.state.isFormDisabled}
                                onChange={(event, item) => {
                                    if(item?.key !== 'Other')
                                    {
                                        this._onTextFieldChange('requesterInstitution', event as any, item?.key.toString());
                                        this.setState({ showRequesterInstitutionOther: false });
                                    }
                                    else
                                    {
                                        formItem.requesterInstitution = '';
                                        this.setState({ showRequesterInstitutionOther: true, formItem });
                                    }
                                }}
                            />
                            <TextField
                                className='requiredField'
                                errorMessage={this.utils.getErrorMessage(formValidation, 'requesterDepartment')}
                                label="Department"
                                componentRef={this.requesterDepartmentRef}
                                styles={TEXT_DOUBLE_COLUMN}
                                value={formItem.requesterDepartment}
                                disabled={this.state.isFormDisabled}
                                onChange={(event, newValue) => { this._onTextFieldChange("requesterDepartment", event, newValue); }}
                            />
                        </Stack>
                        <Stack wrap styles={{ root: { height: 62, maxWidth: 800 } }}>
                            <TextField
                                errorMessage={this.utils.getErrorMessage(formValidation, 'requesterInstitution')}
                                disabled={!this.state.showRequesterInstitutionOther || this.state.isFormDisabled}
                                styles={{ root: { maxWidth: 390, width: '100%', margin: '10px 0 0 0 !important' } }}
                                value={this.state.showRequesterInstitutionOther ? formItem.requesterInstitution : ''}
                                onChange={(event, newValue) => { this._onTextFieldChange("requesterInstitution", event, newValue); }}
                            />
                        </Stack>
                    </section>
                    <section>
                        <h2>2. Applicant/PI</h2>
                        <h3>If different from the primary contact identified above, i.e. project sponsor, PI</h3>
                        <TextField
                            label="Name"
                            styles={TEXT_SINGLE_COLUMN}
                            value={formItem.applicantName}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("applicantName", event, newValue); }}
                        />
                        <Stack wrap styles={{ root: { height: 90, maxWidth: 800 } }} >
                            <TextField
                                label="Phone Number"
                                styles={TEXT_DOUBLE_COLUMN}
                                value={formItem.applicantPhone}
                                disabled={this.state.isFormDisabled}
                                onChange={(event, newValue) => { this._onTextFieldChange("applicantPhone", event, newValue); }}
                            />
                            <TextField
                                errorMessage={this.utils.getErrorMessage(formValidation, 'applicantEmail')}
                                componentRef={this.applicantEmailRef}
                                label="Email"
                                styles={TEXT_DOUBLE_COLUMN}
                                value={formItem.applicantEmail}
                                disabled={this.state.isFormDisabled}
                                onChange={(event, newValue) => { this._onTextFieldChange("applicantEmail", event, newValue); }}
                            />
                        </Stack>
                        <Stack wrap styles={{ root: { height: 62, maxWidth: 800 } }} >
                            <Dropdown
                                label="Institution/Organization"
                                styles={TEXT_DOUBLE_COLUMN}
                                selectedKey={this.state.showApplicantInstitutionOther ? 'Other' : formItem.applicantInstitution}
                                options={ORGANIZATION_DEFAULTS}
                                disabled={this.state.isFormDisabled}
                                onChange={(event, item) => {
                                    if(item?.key !== 'Other')
                                    {
                                        this._onTextFieldChange('applicantInstitution', event as any, item?.key.toString());
                                        this.setState({ showApplicantInstitutionOther: false });
                                    }
                                    else
                                    {
                                        formItem.applicantInstitution = '';
                                        this.setState({ showApplicantInstitutionOther: true, formItem });
                                    }
                                }}
                            />
                            {/* <ComboBox
                                styles={TEXT_DOUBLE_COLUMN_COMBOBOX}
                                label="Institution/Organization"
                                text={this.state.showApplicantInstitutionOther ? 'Other' : formItem.applicantInstitution}
                                onChange={(event, newValue) => { 
                                    if(newValue?.key !== 'Other')
                                    {
                                        this._onTextFieldChange('applicantInstitution', event as any, newValue?.text);
                                        this.setState({ showApplicantInstitutionOther: false });
                                    }
                                    else
                                    {
                                        formItem.applicantInstitution = '';
                                        this.setState({ showApplicantInstitutionOther: true, formItem });
                                    }
                                }}
                                options={ORGANIZATION_DEFAULTS}
                            /> */}
                            <TextField
                                label="Department"
                                styles={TEXT_DOUBLE_COLUMN}
                                value={formItem.applicantDepartment}
                                disabled={this.state.isFormDisabled}
                                onChange={(event, newValue) => { this._onTextFieldChange("applicantDepartment", event, newValue); }}
                            />
                        </Stack>
                        <Stack wrap styles={{ root: { height: 62, maxWidth: 800 } }}>
                            <TextField
                                errorMessage={this.utils.getErrorMessage(formValidation, 'applicantInstitution')}
                                disabled={!this.state.showApplicantInstitutionOther || this.state.isFormDisabled}
                                styles={{ root: { maxWidth: 390, width: '100%', margin: '10px 0 0 0 !important' } }}
                                value={this.state.showApplicantInstitutionOther ? formItem.applicantInstitution : ''}
                                onChange={(event, newValue) => { this._onTextFieldChange("applicantInstitution", event, newValue); }}
                            />
                        </Stack>
                    </section>
                    <section>
                        <h2 className='requiredItem'>3. Select the role that best applies to the Applicant/PI</h2>
                        <ChoiceGroupWithValidation
                            selectedKey={formItem.role}
                            onChange={(event, option) => { this._onChoiceGroupChange("role", event, option); }}
                            options={this.roleChoices}
                            componentRef={this.roleRef}
                            styles={{ flexContainer: { display: "grid", gridAutoFlow: "column", gridTemplateRows: "repeat(7, auto)" } }}
                            disabled={this.state.isFormDisabled}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'role')}
                        />
                        <TextField
                            disabled={formItem.role !== 'Other' || this.state.isFormDisabled}
                            styles={{ root: { maxWidth: 400, width: '100%', } }}
                            ariaLabel="Other Role description"
                            errorMessage={this.utils.getErrorMessage(formValidation, 'roleOther')}
                            componentRef={this.roleOtherRef}
                            value={formItem.roleOther}
                            onChange={(event, newValue) => { this._onTextFieldChange("roleOther", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h2>4. Has there been a previous interaction with AbSPORU regarding this project?</h2>
                        <ChoiceGroup
                            selectedKey={formItem.previousInteraction}
                            onChange={(event, option) => { this._onChoiceGroupChange("previousInteraction", event, option); }}
                            options={this.previousInteractionChoices}
                            disabled={this.state.isFormDisabled}
                        />
                        <Label>If yes, who did you speak with?</Label>
                        <Label style={{ margin: 0, marginTop: '-10px', fontSize: '0.875rem', color: '#8a8a8a', fontWeight: 400 }}>Please provide the name of the person you previously spoke with or any relevant request numbers (i.e. EDGE ID, AHS RMT)</Label>
                        <TextField
                            disabled={formItem.previousInteraction !== 'Yes' || this.state.isFormDisabled}
                            styles={TEXT_SINGLE_COLUMN}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'previousInteractionWho')}
                            componentRef={this.previousInteractionWhoRef}
                            value={formItem.previousInteractionWho}
                            onChange={(event, newValue) => { this._onTextFieldChange("previousInteractionWho", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h2>5. Study Titles</h2>
                        <TextField
                            className='requiredField'
                            label="Study Long Title"
                            styles={TEXT_SINGLE_COLUMN}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'studyLongTitle')}
                            componentRef={this.studyLongTitleRef}
                            multiline={true}
                            rows={5}
                            value={formItem.studyLongTitle}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("studyLongTitle", event, newValue); }}
                        />
                        <Label>Study Short Title</Label>
                        <Label style={{ margin: 0, marginTop: '-10px', fontSize: '0.875rem', color: '#8a8a8a', fontWeight: 400 }}>If available, use the short title entered in the ethics application or (Sponsor Initials) ProtocolNumber_ShortTitle/Acronym</Label>
                        <TextField
                            styles={TEXT_SINGLE_COLUMN}
                            multiline={true}
                            rows={5}
                            value={formItem.studyShortTitle}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("studyShortTitle", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h2>6. Type of Study (select all that apply)</h2>
                        <Stack wrap styles={{ root: { marginTop: '15px', height: 290, maxWidth: 800 } }} tokens={{ childrenGap: "10" }} >
                            {studyTypeCheckBoxes}
                        </Stack>
                    </section>
                    <section>
                        <h2>7. Research Ethics Status <br /> Please see the Research Ethics Approval section for more details.</h2>
                        <ChoiceGroup
                            selectedKey={formItem.ethicsApproval}
                            onChange={(event, option) => { this._onChoiceGroupChange("ethicsApproval", event, option); }}
                            options={this.ethicsApprovalChoices}
                            disabled={this.state.isFormDisabled}
                        />
                        <TextField
                            disabled={formItem.ethicsApproval !== 'Ethics approval obtained' || this.state.isFormDisabled}
                            label="Please indicate the Ethics Application Number"
                            styles={TEXT_SINGLE_COLUMN}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'ethicsApplicationNumber')}
                            componentRef={this.ethicsApplicationNumberRef}
                            value={formItem.ethicsApplicationNumber}
                            onChange={(event, newValue) => { this._onTextFieldChange("ethicsApplicationNumber", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h2>8. Please list the PRIMARY SITE the research is taking place</h2>
                        <TextField
                            styles={TEXT_SINGLE_COLUMN}
                            ariaLabel='Please list the PRIMARY SITE the research is taking place'
                            value={formItem.primarySite}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("primarySite", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h2>9. Please indicate the target start and completion date(s) for AbSPORU services</h2>
                        <Stack wrap styles={{ root: { height: 90, maxWidth: 800 } }} >
                            <Label>Target Start Date</Label>
                            <DatePicker
                                showGoToToday
                                styles={{ root: { maxWidth: 390, width: '100%' } }}
                                ariaLabel='Target Start Date'
                                strings={defaultCalendarStrings}
                                value={!formItem.targetStartDate ? undefined : formItem.targetStartDate}
                                disabled={this.state.isFormDisabled}
                                formatDate={(date?: Date | undefined): string => { return dateFormat(date!, "dd/mm/yy"); }}
                                onSelectDate={(date) => { this._onDatePickerChange("targetStartDate", date); }}
                            />
                            <div style={{ width: '100%', marginLeft: '20px' }}>
                                <Label>Target Completion Date</Label>
                                <DatePickerWithValidation
                                    showGoToToday
                                    styles={{ root: { maxWidth: 390, width: '100%' } }}
                                    ariaLabel='Target Completion Date'
                                    strings={defaultCalendarStrings}
                                    errorMessage={this.utils.getErrorMessage(formValidation, 'targetCompletionDate')}
                                    componentRef={this.targetCompletionDateRef}
                                    value={!formItem.targetCompletionDate ? undefined : formItem.targetCompletionDate}
                                    disabled={this.state.isFormDisabled}
                                    formatDate={(date?: Date | undefined): string => { return dateFormat(date!, "dd/mm/yy"); }}
                                    onSelectDate={(date) => { this._onDatePickerChange("targetCompletionDate", date); }}
                                />
                            </div>
                        </Stack>
                    </section>
                    <section>
                        <h2>10. Project funding</h2>
                        <Label style={{ marginTop: '10px', fontSize: 'medium' }}>Is this project funded?</Label>
                        <Stack wrap styles={{ root: { marginTop: '15px', height: 95, maxWidth: 800 } }} tokens={{ childrenGap: "10" }} >
                            {projectFundedCheckBoxes}
                        </Stack>
                        <br />
                        <Label style={{ fontSize: 'medium' }}>Indicate if the project will be funded by the following initiative(s)?</Label>
                        <Stack wrap styles={{ root: { marginTop: '15px', height: 200, maxWidth: 800 } }} tokens={{ childrenGap: "10" }} >
                            {projectFundedInitiativesCheckBoxes}
                        </Stack>
                    </section>
                    <section>
                        <h2>11. Indicate whether your project involves one of the following networks or SPOR units</h2>
                        <Label style={{ marginTop: '10px', fontSize: 'medium' }}>Strategic Clinical Network(s)</Label>
                        <Stack wrap styles={{ root: { marginTop: '15px', height: 200, maxWidth: 800 } }} tokens={{ childrenGap: "10" }} >
                            {strategicClinicalNetworkCheckBoxes}
                        </Stack>
                        <br />
                        <Label style={{ fontSize: 'medium' }}>National SPOR Network(s)</Label>
                        <Stack wrap styles={{ root: { marginTop: '15px', height: 200, maxWidth: 800 } }} tokens={{ childrenGap: "10" }} >
                            {nationalSPORNetworkCheckBoxes}
                        </Stack>
                        <br />
                        <Label style={{ fontSize: 'medium' }}>SPOR SUPPORT Unit(s)</Label>
                        <Stack wrap styles={{ root: { marginTop: '15px', height: 200, maxWidth: 800 } }} tokens={{ childrenGap: "10" }} >
                            {SPORSupportUnitCheckBoxes}
                        </Stack>
                        <br />
                        <Label style={{ fontSize: 'medium' }}>EDI Priority Area(s) - Health Issues of</Label>
                        <Stack wrap styles={{ root: { marginTop: '15px', height: 70, maxWidth: 800 } }} tokens={{ childrenGap: "10" }} >
                            {EDIPriorityAreaCheckBoxesA}
                        </Stack>
                        <Label style={{ fontSize: 'medium' }}>This research is aimed to specifically address the health of the following groups (select all that apply)</Label>
                        <Stack wrap styles={{ root: { marginTop: '15px', height: 230, maxWidth: 800 } }} tokens={{ childrenGap: "10" }} >
                            {EDIPriorityAreaCheckBoxesB}
                        </Stack>
                        <TextField
                            disabled={!formItem.EDIPriorityArea.includes('Other') || this.state.isFormDisabled}
                            label="Particular group not already listed"
                            styles={TEXT_SINGLE_COLUMN}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'EDIPriorityAreaParticularGroup')}
                            componentRef={this.EDIPriorityAreaParticularGroupRef}
                            value={formItem.EDIPriorityAreaParticularGroup}
                            onChange={(event, newValue) => { this._onTextFieldChange("EDIPriorityAreaParticularGroup", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h2 className='requiredItem'>12. Lay Abstract</h2>
                        <TextFieldWithVisualLimit
                            labelText="Describe the research in plain language suitable for someone with no experience in your research area."
                            styles={TEXT_SINGLE_COLUMN}
                            multiline={true}
                            rows={5}
                            value={formItem.layAbstract}
                            ariaLabel='Lay Abstract'
                            onChange={(event, newValue) => { this._onTextFieldChange("layAbstract", event, newValue); }}
                            disabled={this.state.isFormDisabled}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'layAbstract')}
                            charLimit={this.utils.getFormValidationItem(formValidation, 'layAbstract').charLimit as number}
                            remainingChars={this.utils.getFormValidationItem(formValidation, 'layAbstract').remainingChars as number}
                            componentRef={this.layAbstractRef}
                        />
                    </section>
                    <section>
                        <h2 className='requiredItem'>13. Potential for Impact</h2>
                        <TextField
                            label="Explain whether patient partners helped identify the research problem and/or priority, and how the research/ project outcomes might have an impact on patients' health or the health system."
                            styles={TEXT_SINGLE_COLUMN}
                            multiline={true}
                            rows={5}
                            value={formItem.potentialImpact}
                            disabled={this.state.isFormDisabled}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'potentialImpact')}
                            componentRef={this.potentialImpactRef}
                            onChange={(event, newValue) => { this._onTextFieldChange("potentialImpact", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h2>14. AbSPORU Support Requested</h2>
                        <TextField
                            label="Provide a detailed description of the support you require"
                            styles={TEXT_SINGLE_COLUMN}
                            multiline={true}
                            rows={5}
                            value={formItem.supportRequestedDescription}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("supportRequestedDescription", event, newValue); }}
                        />
                        <br />
                        <Label>Which AbSPORU support(s) do you require assistance with?<br />For more information about the services provided, please visit our website: <a target="_blank" rel="noreferrer" href='https://absporu.ca/research-services/'>https://absporu.ca/research-services/</a></Label>
                        <Stack tokens={{ childrenGap: 10 }}>
                            {supportRequestedAssistanceCheckBoxes}
                        </Stack>
                    </section>
                    <section>
                        <h2>15. Project Documents</h2>
                        <Label>Please attach a copy of the following documents, if available</Label>
                        <Stack tokens={{ childrenGap: 10 }}>
                            {attachedDocumentsInfoCheckBoxes}
                        </Stack>
                        <Upload
                            uploadKey={this.state.uploadElementKey}
                            ref={this.uploadRef}
                            disabled={this.state.isFormDisabled}
                            applicationName={this.applicationName}
                            fileSizeLimit={this.fileSizeLimit} />
                    </section>
                    <section>
                        <h2>16. AbSPORU Communications</h2>
                        <Label>Would you like to receive news, updates and opportunities from the Alberta SPOR SUPPORT Unit?</Label>
                        <ChoiceGroup
                            selectedKey={formItem.receiveNewsletter}
                            onChange={(event, option) => { this._onChoiceGroupChange("receiveNewsletter", event, option); }}
                            options={this.receiveNewsletterChoices}
                            disabled={this.state.isFormDisabled}
                        />
                    </section>
                    <section>
                        <h2>17. Additional Information</h2>
                        <TextField
                            label="Additional Comments/Notes"
                            styles={TEXT_SINGLE_COLUMN}
                            multiline={true}
                            rows={5}
                            value={formItem.additionalComments}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("additionalComments", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h1>Disclaimer</h1>
                        <h2>{this.utils.getConfigurationValue(formConfiguration, 'TermsOfUseTitle')}</h2>
                        <Label className='renderLinefeed'>{this.utils.getConfigurationValue(formConfiguration, 'TermsOfUse')}</Label>
                        <br />
                        <h2>{this.utils.getConfigurationValue(formConfiguration, 'UseDiscloseWarrantyTitle')}</h2>
                        <Label className='renderLinefeed'>{this.utils.getConfigurationValue(formConfiguration, 'UseDiscloseWarranty')}</Label>
                        <CheckboxWithValidation
                            label={"I Agree"}
                            componentRef={this.formAgreementRef}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'formAgreement')}
                            onChange={(event, checked) => { this._onAgreementChange("formAgreement", event, checked); }}
                            disabled={this.state.isFormDisabled}
                        />
                        <br />
                        <Label>If you have questions or concerns about any collection, use or disclosure of information by EDGE Alberta, please contact the AbSPORU Case Manager, by email at <a href='mailto:absporu@albertainnovates.ca'>absporu@albertainnovates.ca</a></Label>
                        {helpRequestButton}
                        <div style={{ display: 'grid', gridTemplateColumns: '115px 140px auto 130px', gridTemplateRows: 'auto', marginTop: '15px' }}>
                            <div style={{ gridArea: '1 / 1 / 1 / 1' }}>
                                {clearFormButton}
                            </div>
                            <div style={{ gridArea: '1 / 2 / 1 / 2', marginLeft: '10px' }}>
                                {saveButton}
                                {amendButton}
                            </div>
                            <div style={{ gridArea: '1 / 4 / 1 / 4', textAlign: 'right' }}>
                                {submitButton}
                            </div>
                        </div>
                    </section>
                    <Dialog
                        className='renderLinefeed'
                        hidden={clearDialogHidden}
                        onDismiss={() => { this._dismissClearDialog(); }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: 'Clear Form',
                            subText: 'Are you sure you want to clear the form? \nThis will remove all entered data.',
                        }}
                        modalProps={{
                            isBlocking: true,
                            styles: { main: { maxWidth: 450 } },
                            dragOptions: {
                                moveMenuItemText: 'Move',
                                closeMenuItemText: 'Close',
                                menu: ContextualMenu,
                            },
                        }}
                    >
                        <DialogFooter>
                            <DefaultButton onClick={() => { this._dismissClearDialog(); }} text="Cancel" />
                            <PrimaryButton onClick={() => { this._clearFormServiceRequest(); }} text="Yes, Clear Form" />
                        </DialogFooter>
                    </Dialog>
                    <Dialog
                        className='renderLinefeed'
                        hidden={submitDialogHidden}
                        onDismiss={() => { this._dismissSubmitDialog(); }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: 'Submit application',
                            subText: `Are you sure you want to submit this application ${formItem.applicationId.length > 0 ? '(' + formItem.applicationId + ')' : ''}?`,
                        }}
                        modalProps={{
                            isBlocking: true,
                            styles: { main: { maxWidth: 450 } },
                            dragOptions: {
                                moveMenuItemText: 'Move',
                                closeMenuItemText: 'Close',
                                menu: ContextualMenu,
                            },
                        }}
                    >
                        <DialogFooter>
                            <DefaultButton onClick={() => { this._dismissSubmitDialog(); }} text="Cancel" />
                            <PrimaryButton onClick={() => { this._submitFormServiceRequest(); }} text="Yes, Submit Application" />
                        </DialogFooter>
                    </Dialog>
                    <Dialog
                        className='renderLinefeed'
                        hidden={amendDialogHidden}
                        onDismiss={() => { this._dismissAmendDialog(); }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: 'Amend Application',
                            subText: `You are about to modify your submitted application ${formItem.applicationId}. \nPlease select "Yes, Amend Application" to proceed with your changes.`,
                        }}
                        modalProps={{
                            isBlocking: true,
                            styles: { main: { maxWidth: 450 } },
                            dragOptions: {
                                moveMenuItemText: 'Move',
                                closeMenuItemText: 'Close',
                                menu: ContextualMenu,
                            },
                        }}
                    >
                        <DialogFooter>
                            <DefaultButton onClick={() => { this._dismissAmendDialog(); }} text="Cancel" />
                            <PrimaryButton onClick={() => { this._amendFormServiceRequest(); }} text="Yes, Amend Application" />
                        </DialogFooter>
                    </Dialog>
                    <Dialog
                        className='renderLinefeed'
                        hidden={helpRequestDialogHidden}
                        onDismiss={() => { this._dismissHelpDialog(); }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: 'Request Help',
                            subText: `Do you want to save the form as is and request help from the case manager to complete filling the form? Please complete as much fields as possible before requesting help.`,
                        }}
                        modalProps={{
                            isBlocking: true,
                            styles: { main: { maxWidth: 450 } },
                            dragOptions: {
                                moveMenuItemText: 'Move',
                                closeMenuItemText: 'Close',
                                menu: ContextualMenu,
                            },
                        }}
                    >
                        <DialogFooter>
                            <DefaultButton onClick={() => { this._dismissHelpDialog(); }} text="Cancel" />
                            <PrimaryButton onClick={() => { this._helpRequestFormServiceRequest(); }} text="Yes, request for help" />
                        </DialogFooter>
                    </Dialog>
                    <Dialog
                        className='renderLinefeed'
                        hidden={notificationDialogHidden}
                        onDismiss={() => { this._dismissNotificationDialog(); }}
                        dialogContentProps={{
                            type: DialogType.close,
                            title: notificationDialogTitle,
                            subText: notificationDialogMessage,
                        }}
                    />
                </form>
            </main>
        );
    }
}