import React, { createRef } from 'react';
import DataService from '../data/DataService';
import { FormACRCCTAConciergeIntakeResource } from '../dto/FormACRCCTAConciergeIntakeResource';
import { IFormMethodOutput } from '../interfaces/IFormMethodOutput';
import { IFormConfiguration } from '../interfaces/IFormConfiguration';
import { IFormState } from '../interfaces/IFormState';
import { IFormValidation } from '../interfaces/IFormValidation';
import Navigation from '../layouts/Navigation';
import { Checkbox, IChoiceGroupOption, Label, PrimaryButton, Spinner, SpinnerSize, Stack, TextField, IRefObject, DefaultButton, DialogFooter, Dialog, DialogType, ContextualMenu, TooltipHost, Icon, IChoiceGroupOptionProps, ICheckboxStyles, ICheckboxStyleProps } from '@fluentui/react';
import Upload from '../components/Upload';
import ChoiceGroupWithValidation from '../components/ChoiceGroupWithValidation';
import Utils from '../utils/Utils';
import Auth from '../Auth';
import { RouteComponentProps } from 'react-router-dom';
import FormLogo from '../assets/ACRCLogo.png';
import CheckboxWithValidation from '../components/CheckboxWithValidation';
import { ISelectableOptionWithTooltip } from '../interfaces/ISelectableOptionWithTooltip';
import { TEXT_DOUBLE_COLUMN, TEXT_SINGLE_COLUMN, FILE_SIZE_LIMIT, AUTO_SAVE_INTERVAL } from '../constants/Constants';

export interface IFormACRCCTAConciergeIntakeProps extends RouteComponentProps {
}

export default class FormACRCCTAConciergeIntake extends React.Component<IFormACRCCTAConciergeIntakeProps, IFormState> {
    applicationName: string = 'ACRCCTAConciergeIntakeForm';
    fileSizeLimit: number = FILE_SIZE_LIMIT;
    dataService: DataService = new DataService();
    utils: Utils = new Utils();
    pathApplicationId = (window.location.pathname.split('/').pop() as string);
    saveIntervalId: number;

    // create and set choices fields (checkbox and dropdown (ISelectableOptionWithTooltip[]), radio (IChoiceGroupOption[]))
    howDidYouHearAboutACRCCTAChoices: ISelectableOptionWithTooltip[] = this._setHowDidYouHearAboutACRCCTAChoices();
    whatAssistanceWithChoices: ISelectableOptionWithTooltip[] = this._setWhatAssistanceWithChoices();
    inWhatAreaChoices: ISelectableOptionWithTooltip[] = this._setInWhatAreaChoices();
    whatAreaResearchRelatedChoices: ISelectableOptionWithTooltip[] = this._setWhatAreaResearchRelatedChoices();
    healthProductTypeChoices: ISelectableOptionWithTooltip[] = this._setHealthProductTypeChoices();
    clinicalResearchTypeChoices: ISelectableOptionWithTooltip[] = this._setClinicalResearchTypeChoices();
    whatStageOfDevelopmentChoices: IChoiceGroupOption[] = this._setWhatStageOfDevelopmentChoices();
    whichStageOfResearchChoices: IChoiceGroupOption[] = this._setWhichStageOfResearchChoices();
    whereCompanyOperatesChoices: ISelectableOptionWithTooltip[] = this._setWhereCompanyOperatesChoices();
    primaryLocationSiteChoices: ISelectableOptionWithTooltip[] = this._setPrimaryLocationSiteChoices();

    // create a ref for each field that can be focused. For example: On a validation error
    requesterNameRef: IRefObject<any> = createRef();
    requesterPhoneRef: IRefObject<any> = createRef();
    requesterEmailRef: IRefObject<any> = createRef();
    howDidYouHearAboutACRCCTARef: IRefObject<any> = createRef();
    howDidYouHearAboutACRCCTAOtherRef: IRefObject<any> = createRef();
    whatAssistanceWithRef: IRefObject<any> = createRef();
    whatAssistanceWithOtherRef: IRefObject<any> = createRef();
    inWhatAreaRef: IRefObject<any> = createRef();
    inWhatAreaOtherRef: IRefObject<any> = createRef();
    whatAreaResearchRelatedRef: IRefObject<any> = createRef();
    whatAreaResearchRelatedOtherRef: IRefObject<any> = createRef();
    nameOfResearchRef: IRefObject<any> = createRef();
    healthProductTypeRef: IRefObject<any> = createRef();
    healthProductTypeOtherRef: IRefObject<any> = createRef();
    clinicalResearchTypeRef: IRefObject<any> = createRef();
    clinicalResearchTypeOtherRef: IRefObject<any> = createRef();
    whatStageOfDevelopmentRef: IRefObject<any> = createRef();
    whichStageOfResearchRef: IRefObject<any> = createRef();
    whereCompanyOperatesOtherRef: IRefObject<any> = createRef();
    primaryLocationSiteRef: IRefObject<any> = createRef();
    primaryLocationSiteOtherRef: IRefObject<any> = createRef();
    formAgreementRef: IRefObject<any> = createRef();
    uploadRef: React.RefObject<Upload> = createRef<Upload>();

    constructor(props: IFormACRCCTAConciergeIntakeProps) {
        super(props);
        const formConfiguration: IFormConfiguration[] = [];
        const formItem: FormACRCCTAConciergeIntakeResource = new FormACRCCTAConciergeIntakeResource();
        const formItemLastSaved: FormACRCCTAConciergeIntakeResource = new FormACRCCTAConciergeIntakeResource();
        const formValidation: IFormValidation[] = [];
        // add validation information for each field to be validated
        formValidation.push({ propertyName: 'requesterName', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.requesterNameRef });
        formValidation.push({ propertyName: 'requesterPhone', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.requesterPhoneRef });
        formValidation.push({ propertyName: 'requesterEmail', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.requesterEmailRef });
        formValidation.push({ propertyName: 'howDidYouHearAboutACRCCTA', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.howDidYouHearAboutACRCCTARef });
        formValidation.push({ propertyName: 'howDidYouHearAboutACRCCTAOther', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.howDidYouHearAboutACRCCTAOtherRef });
        formValidation.push({ propertyName: 'whatAssistanceWith', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.whatAssistanceWithRef });
        formValidation.push({ propertyName: 'whatAssistanceWithOther', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.whatAssistanceWithOtherRef });
        formValidation.push({ propertyName: 'inWhatArea', isValid: true, validationType: 'required', errorMessage: 'This field cannot be empty', propertyRef: this.inWhatAreaRef });
        formValidation.push({ propertyName: 'inWhatAreaOther', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.inWhatAreaOtherRef });
        formValidation.push({ propertyName: 'whatAreaResearchRelated', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.whatAreaResearchRelatedRef });
        formValidation.push({ propertyName: 'whatAreaResearchRelatedOther', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.whatAreaResearchRelatedOtherRef });
        formValidation.push({ propertyName: 'nameOfResearch', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.nameOfResearchRef });
        formValidation.push({ propertyName: 'healthProductType', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.healthProductTypeRef });
        formValidation.push({ propertyName: 'healthProductTypeOther', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.healthProductTypeOtherRef });
        formValidation.push({ propertyName: 'clinicalResearchType', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.clinicalResearchTypeRef });
        formValidation.push({ propertyName: 'clinicalResearchTypeOther', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.clinicalResearchTypeOtherRef });
        formValidation.push({ propertyName: 'whatStageOfDevelopment', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.whatStageOfDevelopmentRef });
        formValidation.push({ propertyName: 'whichStageOfResearch', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.whichStageOfResearchRef });
        formValidation.push({ propertyName: 'whereCompanyOperatesOther', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.whereCompanyOperatesOtherRef });
        formValidation.push({ propertyName: 'primaryLocationSite', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.primaryLocationSiteRef });
        formValidation.push({ propertyName: 'primaryLocationSiteOther', isValid: true, validationType: 'custom', errorMessage: 'This field cannot be empty', propertyRef: this.primaryLocationSiteOtherRef });
        formValidation.push({ propertyName: 'formAgreement', isValid: true, validationType: 'custom', errorMessage: 'You must agree to the disclaimer and the terms of use to submit your application.', propertyRef: this.formAgreementRef });

        this.state = {
            formConfiguration: formConfiguration,
            formItem: formItem,
            formItemLastSaved: formItemLastSaved,
            formVisible: false,
            formValidation: formValidation,
            UserFirstName: '',
            UserLastName: '',
            UserEmail: '',
            UserPhoneNumber: '',
            clearDialogHidden: true,
            submitDialogHidden: true,
            amendDialogHidden: true,
            helpRequestDialogHidden: true,
            notificationDialogHidden: true,
            notificationDialogTitle: '',
            notificationDialogMessage: '',
            redirectToDashboard: false,
            formAgreement: false,
            showApplicantInstitutionOther: false,
            showRequesterInstitutionOther: false,
            isFormDisabled: false
        };
    };

    private _onAgreementChange = (propertyName: string, ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined): void => {
        this.setState({
            formAgreement: checked ? true : false,
        });
        this._clearErrorMessage(propertyName);
    }

    private _onTextFieldChange = (propertyName: string, event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        const formValidation = this.state.formValidation;
        const formValidationItem = this.utils.getFormValidationItem(formValidation, propertyName);
        (this.state.formItem as any)[propertyName] = newValue;
        this.setState({
            formItem: this.state.formItem,
        });
        if (formValidationItem && formValidationItem.charLimit) {
            formValidationItem.remainingChars = formValidationItem.charLimit - (newValue ? newValue.length : 0);
            this.setState({
                formValidation: formValidation,
            });
        }
        this._clearErrorMessage(propertyName);
    }

    private _onChoiceGroupChange = (propertyName: string, ev?: React.FormEvent<HTMLInputElement | HTMLElement>, option?: IChoiceGroupOption): void => {
        (this.state.formItem as any)[propertyName] = option?.key.toString();
        this.setState({
            formItem: this.state.formItem,
        });
        this._clearOtherFieldValues(propertyName);
        this._clearErrorMessage(propertyName);
    }

    private _onDatePickerChange = (propertyName: string, date: Date | null | undefined): void => {
        (this.state.formItem as any)[propertyName] = date;
        this.setState({
            formItem: this.state.formItem,
        });
        this._clearErrorMessage(propertyName);
    }

    private _onCheckBoxChange = (propertyName: string, ev?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean, isYesNoCheck?: boolean): void => {
        const newOptions: string[] = [];
        const checkBoxValue: string = (ev?.target as HTMLInputElement).name;
        if (isYesNoCheck) {
            (this.state.formItem as any)[propertyName] = ((this.state.formItem as any)[propertyName] === "" || (this.state.formItem as any)[propertyName] === "No") ? "Yes" : "No";
        } else {
            if ((this.state.formItem as any)[propertyName]) {
                (this.state.formItem as any)[propertyName].forEach((selectedOption: string) => {
                    newOptions.push(selectedOption);
                });
            }
            if (checked && !newOptions.includes(checkBoxValue)) {
                newOptions.push(checkBoxValue);
            } else {
                newOptions.splice(newOptions.indexOf(checkBoxValue), 1);
            }
            (this.state.formItem as any)[propertyName] = newOptions;
        }
        this.setState({
            formItem: this.state.formItem,
        });
        this._clearOtherFieldValues(propertyName);
        this._clearErrorMessage(propertyName);
    }

    private _loadCheckboxes(propertyName: string, choices: ISelectableOptionWithTooltip[], componentRef?: IRefObject<any>, width?: number) {
        const Checkboxes: JSX.Element[] = [];
        const newOptions: string[] = [];
        const checkboxStyles = (styleProps: ICheckboxStyleProps): ICheckboxStyles => {
            const chkStyles: ICheckboxStyles = width ? {
                root: {
                    width: width,
                }, label: { alignItems: 'flex-start' }, checkbox: [
                    { backgroundColor: "white" },
                    styleProps.checked && {
                        backgroundColor: "rgb(0, 90, 158)",
                    },
                ],
            } : {
                label: { alignItems: 'flex-start' }, checkbox: [
                    { backgroundColor: "white" },
                    styleProps.checked && {
                        backgroundColor: "rgb(0, 90, 158)",
                    },
                ],
            };
            return chkStyles;
        };
        if ((this.state.formItem as any)[propertyName]) {
            (this.state.formItem as any)[propertyName].forEach((selectedOption: string) => {
                newOptions.push(selectedOption);
            });
        }
        choices.forEach((choice: ISelectableOptionWithTooltip, index) => {
            let isChecked: boolean = newOptions.includes((choice.key as string)) ? true : false;
            if (choice.tooltipText) {
                Checkboxes.push(
                    <span key={index} style={{ display: 'flex' }}>
                        <Checkbox {...(componentRef && Checkboxes.length === 0 ? { componentRef: componentRef } : {})} disabled={this.state.isFormDisabled} styles={checkboxStyles} key={(choice.key as string)} label={(choice.text as string)} name={(choice.key as string)} checked={isChecked} onChange={(ev, checked) => { this._onCheckBoxChange(propertyName, ev, checked); }}
                            onRenderLabel={(p) => { return <span className="ms-Checkbox-text" style={{ marginLeft: '4px', color: 'rgb(50, 49, 48)' }}>{choice.customLabel ? choice.customLabel : choice.text}</span> }}
                        />
                        <TooltipHost
                            styles={{ root: { marginLeft: '8px', alignSelf: 'center' } }}
                            content={choice.tooltipText}
                            tooltipProps={{
                                styles: {
                                    content: { fontWeight: '600' }
                                }
                            }}>
                            <Icon iconName="Info" />
                        </TooltipHost>
                    </span>
                );
            } else {
                Checkboxes.push(
                    <Checkbox {...(componentRef && Checkboxes.length === 0 ? { componentRef: componentRef } : {})} disabled={this.state.isFormDisabled} styles={checkboxStyles} key={(choice.key as string)} label={(choice.text as string)} name={(choice.key as string)} checked={isChecked} onChange={(ev, checked) => { this._onCheckBoxChange(propertyName, ev, checked); }}
                        onRenderLabel={(p) => { return <span className="ms-Checkbox-text" style={{ marginLeft: '4px', color: 'rgb(50, 49, 48)' }}>{choice.customLabel ? choice.customLabel : choice.text}</span> }}
                    />
                );
            }
        });
        return Checkboxes;
    }


    private _setHowDidYouHearAboutACRCCTAChoices() {
        const choices: ISelectableOptionWithTooltip[] = [];
        choices.push({ key: "Alberta Clinical Research Consortium (ACRC) website", text: "Alberta Clinical Research Consortium (ACRC) website", });
        choices.push({ key: "Clinical Research Source (CRS) newsletter", text: "Clinical Research Source (CRS) newsletter", });
        choices.push({ key: "Clinical Trials Alberta (CTA) website", text: "Clinical Trials Alberta (CTA) website", });
        choices.push({ key: "Search Engine", text: "Search Engine", });
        choices.push({ key: "Social Media", text: "Social Media", });
        choices.push({ key: "Word-of-Mouth", text: "Word-of-Mouth", });
        choices.push({ key: "Referral", text: "Referral", });
        choices.push({ key: "Other", text: "Other (please describe)", });
        return choices;
    }

    private _setWhatAssistanceWithChoices() {
        const choices: ISelectableOptionWithTooltip[] = [];
        choices.push({ key: "Health Product in Development", text: "Health Product in Development", });
        choices.push({ key: "Clinical Research Study/Clinical Trial", text: "Clinical Research Study/Clinical Trial", });
        choices.push({ key: "Information about Health Research Studies/Clinical Trials", text: "Information about Health Research Studies/Clinical Trials", customLabel: <span>Information about Health Research Studies/Clinical Trials<br></br><span style={{ fontStyle: 'italic' }}>(We will provide you with general information on how/where to access provincial health research study/clinical trial databases. Taking part in a research study/clinical trial is voluntary. You can talk to your doctor if you have questions about health research studies/clinical trials).</span></span> });
        choices.push({ key: "Other", text: "Other (please describe)", });
        return choices;
    }

    private _setInWhatAreaChoices() {
        const choices: ISelectableOptionWithTooltip[] = [];
        choices.push({ key: "Investigator Match", text: "Investigator Match", });
        choices.push({ key: "Participant Match", text: "Participant Match", });
        choices.push({ key: "Funding", text: "Funding", });
        choices.push({ key: "Procurement", text: "Procurement", });
        choices.push({ key: "Wayfinding", text: "Wayfinding", });
        choices.push({ key: "Other", text: "Other (please describe)", });
        return choices;
    }

    private _setWhatAreaResearchRelatedChoices() {
        const choices: ISelectableOptionWithTooltip[] = [];
        choices.push({ key: "Addictions and Mental Health", text: "Addictions and Mental Health", });
        choices.push({ key: "Anesthesia", text: "Anesthesia", });
        choices.push({ key: "Cardiac Sciences", text: "Cardiac Sciences", });
        choices.push({ key: "Child Health", text: "Child Health", });
        choices.push({ key: "Dental/Oral Health", text: "Dental/Oral Health", });
        choices.push({ key: "Dermatology", text: "Dermatology", });
        choices.push({ key: "Devices", text: "Devices", });
        choices.push({ key: "Diagnostic Imaging", text: "Diagnostic Imaging", });
        choices.push({ key: "Family Health", text: "Family Health", });
        choices.push({ key: "Geriatrics", text: "Geriatrics", });
        choices.push({ key: "ICU", text: "ICU", });
        choices.push({ key: "Laboratory", text: "Laboratory", });
        choices.push({ key: "Medicine", text: "Medicine", });
        choices.push({ key: "Oncology", text: "Oncology", });
        choices.push({ key: "Ophthalmology", text: "Ophthalmology", });
        choices.push({ key: "Palliative Care", text: "Palliative Care", });
        choices.push({ key: "Podiatry", text: "Podiatry", });
        choices.push({ key: "Practice (Physician, Nursing, Allied Health)", text: "Practice (Physician, Nursing, Allied Health)", });
        choices.push({ key: "Surgery", text: "Surgery", });
        choices.push({ key: "Women&apos;s Health", text: "Women's Health", });
        choices.push({ key: "Other", text: "Other (please describe)", });
        return choices;
    }

    private _setHealthProductTypeChoices() {
        const choices: ISelectableOptionWithTooltip[] = [];
        choices.push({ key: "Biomarkers", text: "Biomarkers", });
        choices.push({ key: "Biotechnology", text: "Biotechnology", });
        choices.push({ key: "Digital Health Technology - Direct-to-consumer", text: "Digital Health Technology - Direct-to-consumer", });
        choices.push({ key: "Digital Health Technology - Health Canada Approval Required (SaMD)", text: "Digital Health Technology - Health Canada approval required (i.e. Software as a Medical Device (SaMD))", });
        choices.push({ key: "Medical Device", text: "Medical Device", });
        choices.push({ key: "Nutraceutical or Natural Health Product", text: "Nutraceutical or Natural Health Product", });
        choices.push({ key: "Pharmaceutical/Medicinal Drug", text: "Pharmaceutical/Medicinal Drug", });
        choices.push({ key: "Other", text: "Other (please describe)", });
        return choices;
    }

    private _setClinicalResearchTypeChoices() {
        const choices: ISelectableOptionWithTooltip[] = [];
        choices.push({ key: "Chart Review", text: "Chart Review", });
        choices.push({ key: "Clinical Trial", text: "Clinical Trial", });
        choices.push({ key: "Comparative Effectiveness Research", text: "Comparative Effectiveness Research", });
        choices.push({ key: "Epidemiological Study", text: "Epidemiological Study", });
        choices.push({ key: "Health Systems &amp; Policy Research", text: "Health Systems & Policy Research", });
        choices.push({ key: "Implementation (Science) Study", text: "Implementation (Science) Study", });
        choices.push({ key: "Innovation Study", text: "Innovation Study", });
        choices.push({ key: "Knowledge Synthesis", text: "Knowledge Synthesis", });
        choices.push({ key: "Multi-Centre Trial", text: "Multi-Centre Trial", });
        choices.push({ key: "Pilot Study", text: "Pilot Study", });
        choices.push({ key: "Program Evaluation", text: "Program Evaluation", });
        choices.push({ key: "Qualitative Study", text: "Qualitative Study", });
        choices.push({ key: "Real World Evidence (RWE)", text: "Real World Evidence (RWE)", });
        choices.push({ key: "Technology Assessment/Development", text: "Technology Assessment/Development", });
        choices.push({ key: "Validation Study", text: "Validation Study", });
        choices.push({ key: "N/A", text: "N/A", });
        choices.push({ key: "Other", text: "Other (please describe)", });
        return choices;
    }

    private _setWhatStageOfDevelopmentChoices() {
        const choices: IChoiceGroupOption[] = [];
        choices.push({
            key: "Discovering", text: "Discovering", onRenderField: (props, render) => {
                return this._renderChoiceGroupOptionTooltip(props, render, "Insights into unmet health need and state of the art solutions.");
            }
        });
        choices.push({
            key: "Ideating", text: "Ideating", onRenderField: (props, render) => {
                return this._renderChoiceGroupOptionTooltip(props, render, "Potential solutions to unmet need described, evaluated, and selected.");
            }
        });
        choices.push({
            key: "Conceptualizing", text: "Conceptualizing", onRenderField: (props, render) => {
                return this._renderChoiceGroupOptionTooltip(props, render, "Key component concepts validated in models and value proposition tested.");
            }
        });
        choices.push({
            key: "Committing", text: "Committing", onRenderField: (props, render) => {
                return this._renderChoiceGroupOptionTooltip(props, render, "Feasibility of whole solution demonstrated in models and in feedback from stakeholders.");
            }
        });
        choices.push({
            key: "Validating", text: "Validating", onRenderField: (props, render) => {
                return this._renderChoiceGroupOptionTooltip(props, render, "Validation of the solution begins with clinical and economic evidence generation and the regulated production of prototypes.");
            }
        });
        choices.push({
            key: "Scaling", text: "Scaling", onRenderField: (props, render) => {
                return this._renderChoiceGroupOptionTooltip(props, render, "Institutional and regulatory approval received, and sales launched.");
            }
        });
        choices.push({
            key: "Establishing", text: "Establishing", onRenderField: (props, render) => {
                return this._renderChoiceGroupOptionTooltip(props, render, "The solution is used successfully in day-to-day clinical practice.");
            }
        });
        choices.push({
            key: "Leading", text: "Leading", onRenderField: (props, render) => {
                return this._renderChoiceGroupOptionTooltip(props, render, "The solution is recognized as the Standard of Care.");
            }
        });
        return choices;
    }

    private _renderChoiceGroupOptionTooltip(props: IChoiceGroupOptionProps | undefined, render: ((props?: IChoiceGroupOptionProps | undefined) => JSX.Element | null) | undefined, tooltipText: string): JSX.Element | null {
        return <div>
            {render!(props)}
            <TooltipHost
                styles={{ root: { marginLeft: '8px', verticalAlign: 'middle' } }}
                content={tooltipText}
                tooltipProps={{
                    styles: {
                        content: { fontWeight: '600' }
                    }
                }}>
                <Icon iconName="Info" />
            </TooltipHost>
        </div>;
    }

    private _setWhichStageOfResearchChoices() {
        const choices: IChoiceGroupOption[] = [];
        choices.push({
            key: "Concept", text: "Concept", onRenderField: (props, render) => {
                return this._renderChoiceGroupOptionTooltip(props, render, "The study is in the 'concept' or 'idea' stage. The goal is to identify a focused, answerable question that addresses a gap, contributes to the body of knowledge, enhances health servcies and/or delivery, and improves patient care or outcomes.");
            }
        });
        choices.push({
            key: "Development", text: "Development", onRenderField: (props, render) => {
                return this._renderChoiceGroupOptionTooltip(props, render, "Designing the study and identifying the research team. Once the research question has been formulated, the development stage involves identifying the target study or data population; where the research will be conducted, what is collected and how; research method and analysis; and who is involved and/or the expertise to be consulted.");
            }
        });
        choices.push({
            key: "Feasibility", text: "Feasibility", onRenderField: (props, render) => {
                return this._renderChoiceGroupOptionTooltip(props, render, "The research, project protocol, or proposal is close to final and in this stage, it is assessed whether the necessary resources including access to patients/data and other resources, are in place to successfully conduct the study/project.");
            }
        });
        choices.push({
            key: "Project in Setup", text: "Project in Setup", onRenderField: (props, render) => {
                return this._renderChoiceGroupOptionTooltip(props, render, "Once the protocol has been detailed and finalized, there are a number of approvals to help ensure participant protection, privacy and confidentiality, as well as, ethical and organizational oversight. Many of these steps proceed concurrently.");
            }
        });
        choices.push({
            key: "Active", text: "Active", onRenderField: (props, render) => {
                return this._renderChoiceGroupOptionTooltip(props, render, "Once all of the necessary approvals have been obtained, the study or project can begin (i.e. open to recruiting participants, obtaining charts or data for review). Throughout the study, annual ethics approvals; protocol and/or contract amendments; monitoring, etc. may be required. Note: during this stage, the study may progress to the stage of closed to enrolling additional participants, but still be active for data analysis or queries.");
            }
        });
        choices.push({
            key: "Closed", text: "Closed", onRenderField: (props, render) => {
                return this._renderChoiceGroupOptionTooltip(props, render, "All study-related activity is completed. Applicable particies who have been involved in the study are informed (i.e. research ethics board, research office, finance, purchased services, sponsor). At this point, research materials are gathered, and samples or data is shipped, saved, or destroyed.");
            }
        });
        return choices;
    }

    private _setWhereCompanyOperatesChoices() {
        const choices: ISelectableOptionWithTooltip[] = [];
        // choices.push({ key: "Calgary, Alberta / South Zone", text: "Calgary, Alberta / South Zone", });
        // choices.push({ key: "Edmonton, Alberta / North Zone", text: "Edmonton, Alberta / North Zone", });
        // choices.push({ key: "N/A", text: "N/A", });
        // choices.push({ key: "Other (please describe)", text: "Other (please describe)", });

        choices.push({ key: "Canada (Alberta - Calgary and South Zone)", text: "Calgary, Alberta / South Zone" });
        choices.push({ key: "Canada (Alberta - Edmonton and North Zone)", text: "Edmonton, Alberta / North Zone" });
        choices.push({ key: "Canada (British Columbia)", text: "British Columbia" });
        choices.push({ key: "Canada (Manitoba)", text: "Manitoba" });
        choices.push({ key: "Canada (New Brunswick)", text: "New Brunswick" });
        choices.push({ key: "Canada (Newfoundland)", text: "Newfoundland" });
        choices.push({ key: "Canada (Northwest Territories)", text: "Northwest Territories" });
        choices.push({ key: "Canada (Nova Scotia)", text: "Nova Scotia" });
        choices.push({ key: "Canada (Nunavut)", text: "Nunavut" });
        choices.push({ key: "Canada (Ontario)", text: "Ontario" });
        choices.push({ key: "Canada (Prince Edward Island)", text: "Prince Edward Island" });
        choices.push({ key: "Canada (Quebec)", text: "Quebec" });
        choices.push({ key: "Canada (Saskatchewan)", text: "Saskatchewan" });
        choices.push({ key: "Canada (Yukon)", text: "Yukon" });
        choices.push({ key: "N/A", text: "N/A", });
        choices.push({ key: "Other", text: "Other (please describe)", });

        return choices;
    }

    private _setPrimaryLocationSiteChoices() {
        const choices: ISelectableOptionWithTooltip[] = [];

        choices.push({ key: "Calgary, Alberta / South Zone", text: "Calgary, Alberta / South Zone", });
        choices.push({ key: "Edmonton, Alberta / North Zone", text: "Edmonton, Alberta / North Zone", });
        choices.push({ key: "N/A", text: "N/A", });
        choices.push({ key: "Other", text: "Other (please describe)", });
        
        return choices;
    }

    private _displayClearDialog() {
        this.setState({
            clearDialogHidden: false,
        });
    }

    private _displaySubmitDialog() {
        this.setState({
            submitDialogHidden: false,
        });
    }

    private _displayAmendDialog() {
        this.setState({
            amendDialogHidden: false,
        });
    }

    private _displayHelpDialog() {
        this.setState({
            helpRequestDialogHidden: false,
        });
    }

    private async _dismissClearDialog() {
        this.setState({
            clearDialogHidden: true
        });
    }

    private async _dismissSubmitDialog() {
        this.setState({
            submitDialogHidden: true
        });
    }

    private async _dismissAmendDialog() {
        this.setState({
            amendDialogHidden: true
        });
    }

    private async _dismissHelpDialog() {
        this.setState({
            helpRequestDialogHidden: true
        });
    }

    private async _dismissNotificationDialog() {
        if (this.state.redirectToDashboard) {
            this._clearFormTimer();
            this.props.history.push('/');
        } else {
            this.setState({
                notificationDialogHidden: true,
            });
        }
    }

    private async _getFormConfiguration() {
        const formConfiguration = await this.dataService.getFormConfiguration(this.applicationName);
        this.setState({
            formConfiguration: formConfiguration,
        });
    }

    private async _getFormACRCCTAConciergeIntake(applicationId: string) {
        const formOperationOutput = await this.dataService.getFormItem(this.applicationName, applicationId);
        let formItem = (formOperationOutput.formItem as FormACRCCTAConciergeIntakeResource);
        if (formItem.status === "Submitted" || formItem.status === "Rejected") {
            const amendmentItem = new FormACRCCTAConciergeIntakeResource();
            amendmentItem.applicationId = formItem.applicationId;
            amendmentItem.amendmentId = formItem.amendmentId;
            amendmentItem.edgeId = formItem.edgeId;
            amendmentItem.userId = formItem.userId;
            amendmentItem.status = formItem.status;
            formItem = amendmentItem;
        }
        if (formOperationOutput.completed) {
            this.setState({
                formItem: formItem,
                formItemLastSaved: {...formItem},
                formVisible: true,
                isFormDisabled: formItem.status === 'Approved' || formItem.status === 'Completed' 
            });
            await this.uploadRef.current && this.uploadRef.current!.callGetAttachments(formItem);
        } else {
            alert('Could not get form data.');
            this.props.history.push('/');
        }
    }

    private _clearOtherFieldValues(propertyName: string) {
        let changed = false;
        const formItem = this.state.formItem as FormACRCCTAConciergeIntakeResource;
        switch (propertyName) {
            case "howDidYouHearAboutACRCCTA":
                if (!formItem.howDidYouHearAboutACRCCTA.includes('Other') && formItem.howDidYouHearAboutACRCCTAOther !== "") {
                    formItem.howDidYouHearAboutACRCCTAOther = "";
                    changed = true;
                }
                break;
            case "whatAssistanceWith":
                if (!formItem.whatAssistanceWith.includes('Other') && formItem.whatAssistanceWithOther !== "") {
                    formItem.whatAssistanceWithOther = "";
                    changed = true;
                }
                break;
            case "inWhatArea":
                if (!formItem.inWhatArea.includes('Other') && formItem.inWhatAreaOther !== "") {
                    formItem.inWhatAreaOther = "";
                    changed = true;
                }
                break;
            case "whatAreaResearchRelated":
                if (!formItem.whatAreaResearchRelated.includes('Other') && formItem.whatAreaResearchRelatedOther !== "") {
                    formItem.whatAreaResearchRelatedOther = "";
                    changed = true;
                }
                break;
            case "healthProductType":
                if (!formItem.healthProductType.includes('Other') && formItem.healthProductTypeOther !== "") {
                    formItem.healthProductTypeOther = "";
                    changed = true;
                }
                break;
            case "clinicalResearchType":
                if (!formItem.clinicalResearchType.includes('Other') && formItem.clinicalResearchTypeOther !== "") {
                    formItem.clinicalResearchTypeOther = "";
                    changed = true;
                }
                break;
            case "whereCompanyOperates":
                if (!formItem.whereCompanyOperates.includes('Other') && formItem.whereCompanyOperatesOther !== "") {
                    formItem.whereCompanyOperatesOther = "";
                    changed = true;
                }
                break;
            case "primaryLocationSite":
                if (!formItem.primaryLocationSite.includes('Other') && formItem.primaryLocationSiteOther !== "") {
                    formItem.primaryLocationSiteOther = "";
                    changed = true;
                }
                break;
        }
        if (changed) {
            this.setState({
                formItem: formItem,
            });
        }
    }

    private _clearErrorMessage(propertyName: string) {
        const properties: string[] = [];
        properties.push(propertyName);
        switch (propertyName) {
            case "howDidYouHearAboutACRCCTA":
                properties.push('howDidYouHearAboutACRCCTAOther');
                break;
            case "whatAssistanceWith":
                properties.push('whatAssistanceWithOther');
                break;
            case "inWhatArea":
                properties.push('inWhatAreaOther');
                break;
            case "whatAreaResearchRelated":
                properties.push('whatAreaResearchRelatedOther');
                break;
            case "healthProductType":
                properties.push('healthProductTypeOther');
                break;
            case "clinicalResearchType":
                properties.push('clinicalResearchTypeOther');
                break;
            case "whereCompanyOperates":
                properties.push('whereCompanyOperatesOther');
                break;
            case "primaryLocationSite":
                properties.push('primaryLocationSiteOther');
                break;
        }
        const formValidation = this.state.formValidation;
        let changed = false;
        properties.forEach((property) => {
            const formValidationItem = this.utils.getFormValidationItem(formValidation, property);
            if (formValidationItem && !formValidationItem.isValid) {
                formValidationItem.isValid = true;
                changed = true;
            }
        });
        if (changed) {
            this.setState({
                formValidation: formValidation,
            });
        }
    }

    private _validateForm() {
        let isValid = true;
        const formItem = this.state.formItem as FormACRCCTAConciergeIntakeResource;
        const formValidation = this.state.formValidation;
        let focusSet = false;
        let focusItem: IRefObject<any> | null = null;
        for (const formValidationItem of formValidation) {
            switch (formValidationItem.validationType) {
                case 'required':
                    if ((formItem as any)[formValidationItem.propertyName] === undefined || (formItem as any)[formValidationItem.propertyName] === null || (formItem as any)[formValidationItem.propertyName].length === 0) {
                        isValid = false;
                        formValidationItem.isValid = false;
                        if (!focusSet) {
                            focusItem = formValidationItem.propertyRef;
                            focusSet = true;
                        }
                    }
                    break;
                case 'custom':
                    switch (formValidationItem.propertyName) {
                        case 'requesterEmail':
                            if (formItem.requesterEmail.length === 0 || !this.utils.isValidEmail(formItem.requesterEmail)) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (formItem.requesterEmail.length === 0) {
                                    formValidationItem.errorMessage = 'This field cannot be empty';
                                } else {
                                    formValidationItem.errorMessage = 'Please enter a valid email address';
                                }
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'howDidYouHearAboutACRCCTAOther':
                            if (formItem.howDidYouHearAboutACRCCTA.includes('Other') && formItem.howDidYouHearAboutACRCCTAOther.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'whatAssistanceWithOther':
                            if (formItem.whatAssistanceWith.includes('Other') && formItem.whatAssistanceWithOther.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'inWhatAreaOther':
                            if (formItem.inWhatArea.includes('Other') && formItem.inWhatAreaOther.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'whatAreaResearchRelated':
                        case 'nameOfResearch':
                        case 'healthProductType':
                        case 'clinicalResearchType':
                        case 'primaryLocationSite':
                            if (formItem.whatAssistanceWith.includes('Health Product in Development') ||
                                formItem.whatAssistanceWith.includes('Clinical Research Study/Clinical Trial') ||
                                formItem.whatAssistanceWith.includes('Other')) {
                                if ((formItem as any)[formValidationItem.propertyName] === undefined || (formItem as any)[formValidationItem.propertyName] === null || (formItem as any)[formValidationItem.propertyName].length === 0) {
                                    isValid = false;
                                    formValidationItem.isValid = false;
                                    if (!focusSet) {
                                        focusItem = formValidationItem.propertyRef;
                                        focusSet = true;
                                    }
                                }
                            }
                            break;
                        case 'whatAreaResearchRelatedOther':
                            if (formItem.whatAreaResearchRelated.includes('Other') && formItem.whatAreaResearchRelatedOther.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'healthProductTypeOther':
                            if (formItem.healthProductType.includes('Other') && formItem.healthProductTypeOther.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'clinicalResearchTypeOther':
                            if (formItem.clinicalResearchType.includes('Other') && formItem.clinicalResearchTypeOther.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'whatStageOfDevelopment':
                            if (formItem.whatAssistanceWith.includes('Health Product in Development') ||
                                formItem.whatAssistanceWith.includes('Other')) {
                                if ((formItem as any)[formValidationItem.propertyName] === undefined || (formItem as any)[formValidationItem.propertyName] === null || (formItem as any)[formValidationItem.propertyName].length === 0) {
                                    isValid = false;
                                    formValidationItem.isValid = false;
                                    if (!focusSet) {
                                        focusItem = formValidationItem.propertyRef;
                                        focusSet = true;
                                    }
                                }
                            }
                            break;
                        case 'whichStageOfResearch':
                            if (formItem.whatAssistanceWith.includes('Clinical Research Study/Clinical Trial') ||
                                formItem.whatAssistanceWith.includes('Other')) {
                                if ((formItem as any)[formValidationItem.propertyName] === undefined || (formItem as any)[formValidationItem.propertyName] === null || (formItem as any)[formValidationItem.propertyName].length === 0) {
                                    isValid = false;
                                    formValidationItem.isValid = false;
                                    if (!focusSet) {
                                        focusItem = formValidationItem.propertyRef;
                                        focusSet = true;
                                    }
                                }
                            }
                            break;
                        case 'whereCompanyOperatesOther':
                            if (formItem.whereCompanyOperates.includes('Other') && formItem.whereCompanyOperatesOther.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'primaryLocationSiteOther':
                            if (formItem.primaryLocationSite.includes('Other') && formItem.primaryLocationSiteOther.length === 0) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                        case 'formAgreement':
                            if (!this.state.formAgreement) {
                                isValid = false;
                                formValidationItem.isValid = false;
                                if (!focusSet) {
                                    focusItem = formValidationItem.propertyRef;
                                    focusSet = true;
                                }
                            }
                            break;
                    }
                    break;
            }
        }
        if (!isValid) {
            this.setState({
                formValidation: formValidation,
            });
            this._dismissSubmitDialog().then(() => {
                if (focusItem) {
                    (focusItem as any).current.focus();
                }
            });
        }
        return isValid;
    }

    private async _saveFormACRCCTAConciergeIntake(disableNotification?: boolean) {
        await this._updateToken();
        let formOperationOutput: IFormMethodOutput;
        const formItem = this.state.formItem;
        if (this.state.formItem.applicationId.length === 0) {
            formOperationOutput = await this.dataService.insertFormItem(this.applicationName, formItem);
        } else {
            formOperationOutput = await this.dataService.updateFormItem(this.applicationName, formItem);
        }
        if (formOperationOutput.completed) {
            await this.uploadRef.current && this.uploadRef.current!.uploadFiles(formOperationOutput.formItem as FormACRCCTAConciergeIntakeResource);
            this.setState({
                formItem: (formOperationOutput.formItem as FormACRCCTAConciergeIntakeResource),
                formItemLastSaved: {...(formOperationOutput.formItem as FormACRCCTAConciergeIntakeResource)},
                notificationDialogHidden: disableNotification ? true : false,
                notificationDialogTitle: 'Save Form',
                notificationDialogMessage: `Application (${formOperationOutput.formItem?.applicationId}) saved. \n\nThis application is in draft form and will not be processed until it is submitted. \n\nRemember that this application is available on the dashboard. You can come back and continue completing your application later.`,
            });
        } else {
            this.setState({
                notificationDialogHidden: disableNotification ? true : false,
                notificationDialogTitle: 'Error',
                notificationDialogMessage: 'An error occurred while saving the application.',
            });
        }
    }

    private async _submitFormACRCCTAConciergeIntake() {
        if (this._validateForm()) {
            await this._saveFormACRCCTAConciergeIntake(true);
            if (this.state.formItem.applicationId !== '') {
                const formOperationOutput = await this.dataService.submitFormItem(this.applicationName, this.state.formItem.applicationId);
                if (formOperationOutput.completed) {
                    this._clearFormTimer();
                    this.setState({
                        submitDialogHidden: true,
                        notificationDialogHidden: false,
                        notificationDialogTitle: 'Submit Application',
                        notificationDialogMessage: `This request has been completed and submitted. \n\nPlease take note of the Application ID: (${formOperationOutput.formItem?.applicationId}). Remember to check your e-mail regularly, as it will be the main source of contact during the approval process. \n\nSending this request does not guarantee its approval.`,
                        redirectToDashboard: true,
                    });
                } else {
                    this.setState({
                        submitDialogHidden: true,
                        notificationDialogHidden: false,
                        notificationDialogTitle: 'Error',
                        notificationDialogMessage: 'An error occurred while submitting the application.',
                    });
                }
            } else {
                this.setState({
                    submitDialogHidden: true,
                    notificationDialogHidden: false,
                    notificationDialogTitle: 'Error',
                    notificationDialogMessage: 'An error occurred while submitting the application.',
                });
            }
        }
    }

    private async _amendFormACRCCTAConciergeIntake() {
        await this._updateToken();
        const formItem = this.state.formItem
        const formOperationOutput = await this.dataService.amendFormItem(this.applicationName, formItem);
        if (formOperationOutput.completed) {
            await this.uploadRef.current && this.uploadRef.current!.uploadFiles(formOperationOutput.formItem as FormACRCCTAConciergeIntakeResource);
            this._clearFormTimer();
            this.setState({
                amendDialogHidden: true,
                notificationDialogHidden: false,
                notificationDialogTitle: 'Amend Application',
                notificationDialogMessage: `Application (${formOperationOutput.formItem?.applicationId}) amended.`,
                redirectToDashboard: true,
            });
        } else {
            this.setState({
                amendDialogHidden: true,
                notificationDialogHidden: false,
                notificationDialogTitle: 'Error',
                notificationDialogMessage: 'An error occurred while amending the application.',
            });
        }
    }

    private async _helpRequestFormACRCCTAConciergeIntake() {
        await this._updateToken();
        let formOperationOutput: IFormMethodOutput;
        const formItem = this.state.formItem;
        formItem.status = 'Help Requested';
        if (this.state.formItem.applicationId.length === 0) {
            formOperationOutput = await this.dataService.insertFormItem(this.applicationName, formItem);
        } else {
            formOperationOutput = await this.dataService.updateFormItem(this.applicationName, formItem);
        }
        if (formOperationOutput.completed) {
            if (this.state.formItem.applicationId !== '') {
                const formOperationOutput = await this.dataService.requestHelpFormItem(this.applicationName, this.state.formItem.applicationId);
                if (formOperationOutput.completed) {
                    this.setState({
                        helpRequestDialogHidden: true,
                        formItem: (formOperationOutput.formItem as FormACRCCTAConciergeIntakeResource),
                        notificationDialogHidden: false,
                        notificationDialogTitle: 'Request Help',
                        notificationDialogMessage: `Application (${formOperationOutput.formItem?.applicationId}) saved. \n\nA case manager will contact you to help.`,
                        redirectToDashboard: true,
                    });
                } else {
                    this.setState({
                        helpRequestDialogHidden: true,
                        notificationDialogHidden: false,
                        notificationDialogTitle: 'Error',
                        notificationDialogMessage: 'An error occurred while requesting help.',
                    });
                }
            } else {
                this.setState({
                    helpRequestDialogHidden: true,
                    notificationDialogHidden: false,
                    notificationDialogTitle: 'Error',
                    notificationDialogMessage: 'An error occurred while requesting help.',
                });
            }
        } else {
            this.setState({
                helpRequestDialogHidden: true,
                notificationDialogHidden: false,
                notificationDialogTitle: 'Error',
                notificationDialogMessage: 'An error occurred while requesting help.',
            });
        }
    }

    private async _clearFormACRCCTAConciergeIntake() {
        await this._updateToken();
        const clearedItem = new FormACRCCTAConciergeIntakeResource();
        clearedItem.applicationId = this.state.formItem.applicationId;
        clearedItem.amendmentId = this.state.formItem.amendmentId;
        clearedItem.edgeId = this.state.formItem.edgeId;
        clearedItem.userId = this.state.formItem.userId;
        clearedItem.status = this.state.formItem.status;
        const formValidation = this.state.formValidation;
        for (const formValidationItem of formValidation) {
            formValidationItem.isValid = true;
        }
        // delete attachments if any
        if (this.state.formItem.applicationId.length > 0) {
            const attachments = await this.dataService.getFormAttachments(this.applicationName, this.state.formItem);
            for (const attachment of attachments) {
                await this.dataService.deleteFormAttachment(attachment.path);
            }
        }
        this.setState({
            formItem: clearedItem,
            formValidation: formValidation,
        });
        this._dismissClearDialog().then(() => {
            if (this.requesterNameRef) {
                (this.requesterNameRef as any).current.focus();
            }
        });
    }

    private async _retrieveFormInformation() {
        await this._getUserInfo();
        await this._getFormConfiguration();
        this.saveIntervalId = this._saveFormTimer();
        if (this.pathApplicationId.length > 0 && this.pathApplicationId !== 'FormACRCCTAConciergeIntake') {
            this._getFormACRCCTAConciergeIntake(this.pathApplicationId);
        } else {
            if (this.state.formItem.applicationId.length === 0) {
                const formItem = this.state.formItem as FormACRCCTAConciergeIntakeResource;
                formItem.UserFirstName = this.state.UserFirstName;
                formItem.UserLastName = this.state.UserLastName;
                formItem.UserEmail = this.state.UserEmail;
                formItem.UserPhoneNumber = this.state.UserPhoneNumber;
                formItem.requesterName = this.state.UserFirstName + ' ' + this.state.UserLastName;
                formItem.requesterEmail = this.state.UserEmail;
                formItem.requesterPhone = this.state.UserPhoneNumber;
                this.setState({
                    formItem: formItem
                });
                await this._saveFormACRCCTAConciergeIntake(true);
            }
            this.setState({
                formVisible: true
            });
        }
    }

    private async _getUserInfo() {
        const authResult = await Auth.acquireTokenSilent();
        if (authResult) {
            this.setState({
                UserFirstName: (authResult.idTokenClaims as any).given_name ? (authResult.idTokenClaims as any).given_name : '',
                UserLastName: (authResult.idTokenClaims as any).family_name ? (authResult.idTokenClaims as any).family_name : '',
                UserEmail: (authResult.idTokenClaims as any).emails[0] ? (authResult.idTokenClaims as any).emails[0] : '',
                UserPhoneNumber: (authResult.idTokenClaims as any).extension_PhoneNumber ? (authResult.idTokenClaims as any).extension_PhoneNumber : '',
            });
        }
    }

    private _clearFormTimer() {
        window.clearInterval(this.saveIntervalId);
    }

    private _saveFormTimer(): number {
        return window.setInterval(() => {
            if (this.state.formItem.status === 'Draft' && JSON.stringify(this.state.formItem) !== JSON.stringify(this.state.formItemLastSaved)) {
                this._saveFormACRCCTAConciergeIntake(true);
            }
        }, AUTO_SAVE_INTERVAL);
    }

    private async _updateToken() {
        try {
            await this.dataService.getToken();
        } catch (error) {
            this.props.history.push('/logout');
        }
    }

    public componentDidMount() {
        this._retrieveFormInformation();
    }

    public render(): React.ReactElement {
        const formItem = this.state.formItem as FormACRCCTAConciergeIntakeResource;
        const { formConfiguration, formVisible, formValidation, UserFirstName, UserLastName, UserEmail, clearDialogHidden, submitDialogHidden, amendDialogHidden, helpRequestDialogHidden, notificationDialogHidden, notificationDialogTitle, notificationDialogMessage } = this.state;
        const spinner = !formVisible ? <Spinner size={SpinnerSize.large} label="Loading..." ariaLive="assertive" /> : null;
        const howDidYouHearAboutACRCCTACheckBoxes = this._loadCheckboxes("howDidYouHearAboutACRCCTA", this.howDidYouHearAboutACRCCTAChoices, this.howDidYouHearAboutACRCCTARef);
        const whatAssistanceWithCheckBoxes = this._loadCheckboxes("whatAssistanceWith", this.whatAssistanceWithChoices, this.whatAssistanceWithRef);
        const inWhatAreaCheckBoxes = this._loadCheckboxes("inWhatArea", this.inWhatAreaChoices, this.inWhatAreaRef);
        const whatAreaResearchRelatedCheckBoxes = this._loadCheckboxes("whatAreaResearchRelated", this.whatAreaResearchRelatedChoices, this.whatAreaResearchRelatedRef);
        const healthProductTypeCheckBoxes = this._loadCheckboxes("healthProductType", this.healthProductTypeChoices, this.healthProductTypeRef, 350);
        const clinicalResearchTypeCheckBoxes = this._loadCheckboxes("clinicalResearchType", this.clinicalResearchTypeChoices, this.clinicalResearchTypeRef);
        const whereCompanyOperatesCheckBoxes = this._loadCheckboxes("whereCompanyOperates", this.whereCompanyOperatesChoices);
        const primaryLocationSiteCheckBoxes = this._loadCheckboxes("primaryLocationSite", this.primaryLocationSiteChoices, this.primaryLocationSiteRef);
        const clearFormButton = <DefaultButton className="clearButton" text="Clear Form" onClick={this._displayClearDialog.bind(this)} disabled={this.state.isFormDisabled} />;
        const saveButton = formItem.status === 'Draft' ? <DefaultButton text="Save Form" onClick={() => this._saveFormACRCCTAConciergeIntake()} disabled={this.state.isFormDisabled} /> : null;
        const submitButton = formItem.status === 'Draft' ? <PrimaryButton text="Submit Form" onClick={this._displaySubmitDialog.bind(this)} disabled={this.state.isFormDisabled} /> : null;
        const amendButton = (formItem.status === 'Submitted' || formItem.status === 'Rejected') ? <PrimaryButton text="Amend Form" onClick={this._displayAmendDialog.bind(this)} disabled={this.state.isFormDisabled} /> : null;
        const helpRequestButton = formItem.status === 'Draft' ? <DefaultButton className="floatHelpButton" text="Request Help" onClick={this._displayHelpDialog.bind(this)} disabled={this.state.isFormDisabled} /> : null;

        // display items based on value of whatAreaResearchRelated
        let displayABD: boolean = false;
        let displayAD: boolean = false;
        let displayBD: boolean = false;
        if (formItem.whatAssistanceWith.includes('Health Product in Development') ||
            formItem.whatAssistanceWith.includes('Clinical Research Study/Clinical Trial') ||
            formItem.whatAssistanceWith.includes('Other')) {
            displayABD = true;
        }
        if (formItem.whatAssistanceWith.includes('Health Product in Development') ||
            formItem.whatAssistanceWith.includes('Other')) {
            displayAD = true;
        }
        if (formItem.whatAssistanceWith.includes('Clinical Research Study/Clinical Trial') ||
            formItem.whatAssistanceWith.includes('Other')) {
            displayBD = true;
        }

        return (
            <main className="App-formbody">
                <Navigation
                    title="ACRC/CTA Concierge Intake Form"
                    isForm={true}
                    UserFirstName={UserFirstName}
                    UserLastName={UserLastName}
                    UserEmail={UserEmail}
                    clearFormTimer={this._clearFormTimer.bind(this)} />
                {spinner}
                <form style={!formVisible ? { display: 'none' } : {}}>
                    <section className="applicationIDSection">
                        <span className='col1'><h2>Application Number: {formItem.applicationId}</h2><h3>{formItem.applicationId.length === 0 ? 'The application number will be displayed once the form is saved or submitted.' : ''}</h3></span>
                        <span className='col2'><img style={{ maxWidth: "199px" }} src={FormLogo} alt="Form logo" /></span>
                    </section>
                    <section>
                        <h2>Requested by</h2>
                        <h3>Primary contact for which questions about your application will be directed to</h3>
                        <TextField
                            className='requiredField'
                            errorMessage={this.utils.getErrorMessage(formValidation, 'requesterName')}
                            label="Name"
                            componentRef={this.requesterNameRef}
                            styles={TEXT_SINGLE_COLUMN}
                            value={formItem.requesterName}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("requesterName", event, newValue); }}
                        />
                        <Stack wrap styles={{ root: { height: 90, maxWidth: 800 } }} >
                            <TextField
                                className='requiredField'
                                errorMessage={this.utils.getErrorMessage(formValidation, 'requesterPhone')}
                                label="Phone Number"
                                componentRef={this.requesterPhoneRef}
                                styles={TEXT_DOUBLE_COLUMN}
                                value={formItem.requesterPhone}
                                disabled={this.state.isFormDisabled}
                                onChange={(event, newValue) => { this._onTextFieldChange("requesterPhone", event, newValue); }}
                            />
                            <TextField
                                className='requiredField'
                                errorMessage={this.utils.getErrorMessage(formValidation, 'requesterEmail')}
                                label="Email"
                                componentRef={this.requesterEmailRef}
                                styles={TEXT_DOUBLE_COLUMN}
                                value={formItem.requesterEmail}
                                disabled={this.state.isFormDisabled}
                                onChange={(event, newValue) => { this._onTextFieldChange("requesterEmail", event, newValue); }}
                            />
                        </Stack>
                        <TextField
                            label="Position Title"
                            styles={TEXT_SINGLE_COLUMN}
                            value={formItem.requesterPositionTitle}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("requesterPositionTitle", event, newValue); }}
                        />
                        <TextField
                            label="Company Name"
                            styles={TEXT_SINGLE_COLUMN}
                            value={formItem.requesterCompanyName}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("requesterCompanyName", event, newValue); }}
                        />
                        <TextField
                            label="Company Website"
                            styles={TEXT_SINGLE_COLUMN}
                            value={formItem.requesterCompanyWebsite}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("requesterCompanyWebsite", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h2 className='requiredItem'>How did you hear about the Alberta Clinical Research Consortium (ACRC) or Clinical Trials Alberta (CTA)?</h2>
                        <Stack wrap styles={{ root: { marginTop: '15px', height: 140, maxWidth: 800 }, inner: { width: 'calc(78%)' } }} tokens={{ childrenGap: "10" }} >
                            {howDidYouHearAboutACRCCTACheckBoxes}
                        </Stack>
                        <div>
                            <TextField
                                disabled={!formItem.howDidYouHearAboutACRCCTA.includes('Other') || this.state.isFormDisabled}
                                styles={{ root: { maxWidth: 400, width: '100%', } }}
                                ariaLabel="How did you hear about the Alberta Clinical Research Consortium (ACRC) or Clinical Trials Alberta (CTA) - Other"
                                errorMessage={this.utils.getErrorMessage(formValidation, 'howDidYouHearAboutACRCCTAOther')}
                                componentRef={this.howDidYouHearAboutACRCCTAOtherRef}
                                value={formItem.howDidYouHearAboutACRCCTAOther}
                                onChange={(event, newValue) => { this._onTextFieldChange("howDidYouHearAboutACRCCTAOther", event, newValue); }}
                            />
                        </div>
                        <span>
                            <div role="alert">
                                <p className="ms-TextField-errorMessage">
                                    <span data-automation-id="error-message">{this.utils.getErrorMessage(formValidation, 'howDidYouHearAboutACRCCTA')}</span>
                                </p>
                            </div>
                        </span>
                    </section>
                    <section>
                        <h2 className='requiredItem'>What are you looking for assistance with?</h2>
                        <h3>(Please do not include personally identifiable information or personal medical/health information)</h3>
                        <Stack wrap styles={{ root: { marginTop: '15px', height: 195, maxWidth: 800 }, inner: { width: 'calc(78%)' } }} tokens={{ childrenGap: "10" }} >
                            {whatAssistanceWithCheckBoxes}
                        </Stack>
                        <div>
                            <TextField
                                disabled={!formItem.whatAssistanceWith.includes('Other') || this.state.isFormDisabled}
                                ariaLabel="What are you looking for assistance with - Other"
                                errorMessage={this.utils.getErrorMessage(formValidation, 'whatAssistanceWithOther')}
                                componentRef={this.whatAssistanceWithOtherRef}
                                value={formItem.whatAssistanceWithOther}
                                onChange={(event, newValue) => { this._onTextFieldChange("whatAssistanceWithOther", event, newValue); }}
                            />
                        </div>
                        <span>
                            <div role="alert">
                                <p className="ms-TextField-errorMessage">
                                    <span data-automation-id="error-message">{this.utils.getErrorMessage(formValidation, 'whatAssistanceWith')}</span>
                                </p>
                            </div>
                        </span>
                    </section>
                    <section>
                        <h2 className='requiredItem'>In what specific area?</h2>
                        <Stack wrap styles={{ root: { marginTop: '15px', height: 110, maxWidth: 800 }, inner: { width: 'calc(100%)' } }} tokens={{ childrenGap: "10" }} >
                            {inWhatAreaCheckBoxes}
                        </Stack>
                        <div>
                            <TextField
                                disabled={!formItem.inWhatArea.includes('Other') || this.state.isFormDisabled}
                                styles={{ root: { maxWidth: 415, width: '100%', } }}
                                ariaLabel="In what specific area - Other"
                                errorMessage={this.utils.getErrorMessage(formValidation, 'inWhatAreaOther')}
                                componentRef={this.inWhatAreaOtherRef}
                                value={formItem.inWhatAreaOther}
                                onChange={(event, newValue) => { this._onTextFieldChange("inWhatAreaOther", event, newValue); }}
                            />
                        </div>
                        <span>
                            <div role="alert">
                                <p className="ms-TextField-errorMessage">
                                    <span data-automation-id="error-message">{this.utils.getErrorMessage(formValidation, 'inWhatArea')}</span>
                                </p>
                            </div>
                        </span>
                    </section>
                    <section style={displayABD ? { display: 'block' } : { display: 'none' }}>
                        <h2 className='requiredItem'>What area/speciality is this product development or research study related to?</h2>
                        <Stack wrap styles={{ root: { marginTop: '15px', height: 365, maxWidth: 800 } }} tokens={{ childrenGap: "10" }} >
                            {whatAreaResearchRelatedCheckBoxes}
                        </Stack>
                        <div>
                            <TextField
                                disabled={!formItem.whatAreaResearchRelated.includes('Other') || this.state.isFormDisabled}
                                styles={{ root: { marginTop: '-15px', maxWidth: 435, width: '100%', } }}
                                ariaLabel="What area/speciality is this product development or research study related to - Other"
                                errorMessage={this.utils.getErrorMessage(formValidation, 'whatAreaResearchRelatedOther')}
                                componentRef={this.whatAreaResearchRelatedOtherRef}
                                value={formItem.whatAreaResearchRelatedOther}
                                onChange={(event, newValue) => { this._onTextFieldChange("whatAreaResearchRelatedOther", event, newValue); }}
                            />
                        </div>
                        <span>
                            <div role="alert">
                                <p className="ms-TextField-errorMessage">
                                    <span data-automation-id="error-message">{this.utils.getErrorMessage(formValidation, 'whatAreaResearchRelated')}</span>
                                </p>
                            </div>
                        </span>
                    </section>
                    <section style={displayABD ? { display: 'block' } : { display: 'none' }}>
                        <h2 className='requiredItem'>What is the name of your clinical research study or health product in development?</h2>
                        <TextField
                            styles={{ root: { marginTop: '15px' } }}
                            ariaLabel="What is the name of your clinical research study or health product in development"
                            errorMessage={this.utils.getErrorMessage(formValidation, 'nameOfResearch')}
                            componentRef={this.nameOfResearchRef}
                            value={formItem.nameOfResearch}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("nameOfResearch", event, newValue); }}
                        />
                    </section>
                    <section style={displayABD ? { display: 'block' } : { display: 'none' }}>
                        <h2 className='requiredItem'>Health Product Type</h2>
                        <Stack wrap styles={{ root: { marginTop: '15px', height: 160, maxWidth: 800 } }} tokens={{ childrenGap: "10" }} >
                            {healthProductTypeCheckBoxes}
                        </Stack>
                        <div>
                            <TextField
                                disabled={!formItem.healthProductType.includes('Other') || this.state.isFormDisabled}
                                styles={{ root: { marginTop: '-20px', maxWidth: 360, width: '100%', } }}
                                ariaLabel="Health Product Type - Other"
                                errorMessage={this.utils.getErrorMessage(formValidation, 'healthProductTypeOther')}
                                componentRef={this.healthProductTypeOtherRef}
                                value={formItem.healthProductTypeOther}
                                onChange={(event, newValue) => { this._onTextFieldChange("healthProductTypeOther", event, newValue); }}
                            />
                        </div>
                        <span>
                            <div role="alert">
                                <p className="ms-TextField-errorMessage">
                                    <span data-automation-id="error-message">{this.utils.getErrorMessage(formValidation, 'healthProductType')}</span>
                                </p>
                            </div>
                        </span>
                    </section>
                    <section style={displayABD ? { display: 'block' } : { display: 'none' }}>
                        <h2 className='requiredItem'>Clinical Research Study Type</h2>
                        <Stack wrap styles={{ root: { marginTop: '15px', height: 300, maxWidth: 800 } }} tokens={{ childrenGap: "10" }} >
                            {clinicalResearchTypeCheckBoxes}
                        </Stack>
                        <div>
                            <TextField
                                disabled={!formItem.clinicalResearchType.includes('Other') || this.state.isFormDisabled}
                                styles={{ root: { marginTop: '-20px', maxWidth: 405, width: '100%', } }}
                                ariaLabel="Clinical Research Study Type - Other"
                                errorMessage={this.utils.getErrorMessage(formValidation, 'clinicalResearchTypeOther')}
                                componentRef={this.clinicalResearchTypeOtherRef}
                                value={formItem.clinicalResearchTypeOther}
                                onChange={(event, newValue) => { this._onTextFieldChange("clinicalResearchTypeOther", event, newValue); }}
                            />
                        </div>
                        <span>
                            <div role="alert">
                                <p className="ms-TextField-errorMessage">
                                    <span data-automation-id="error-message">{this.utils.getErrorMessage(formValidation, 'clinicalResearchType')}</span>
                                </p>
                            </div>
                        </span>
                    </section>
                    <section style={displayAD ? { display: 'block' } : { display: 'none' }}>
                        <h2 className='requiredItem'>What stage of development is your health product? (using the Health Innovation Pathway)</h2>
                        <Label><a rel="noreferrer" target='_blank' href='https://albertainnovates.ca/app/uploads/2022/02/Health-Innovation-Client-Journey-AICE-Program-Mapping.pdf'>Health Innovation Pathway</a></Label>
                        <ChoiceGroupWithValidation
                            selectedKey={formItem.whatStageOfDevelopment}
                            onChange={(event, option) => { this._onChoiceGroupChange("whatStageOfDevelopment", event, option); }}
                            options={this.whatStageOfDevelopmentChoices}
                            componentRef={this.whatStageOfDevelopmentRef}
                            styles={{ flexContainer: { display: "grid", gridAutoFlow: "column", gridTemplateRows: "repeat(4, auto)" } }}
                            disabled={this.state.isFormDisabled}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'whatStageOfDevelopment')}
                        />
                    </section>
                    <section style={displayBD ? { display: 'block' } : { display: 'none' }}>
                        <h2 className='requiredItem'>Which stage is your clinical health research at? (using the Alberta Clinical Research Consortium Roadmap)</h2>
                        <Label><a rel="noreferrer" target='_blank' href='https://www.acrc.albertainnovates.ca/#phase-1'>Alberta Clinical Research Consortium Roadmap</a></Label>
                        <ChoiceGroupWithValidation
                            selectedKey={formItem.whichStageOfResearch}
                            onChange={(event, option) => { this._onChoiceGroupChange("whichStageOfResearch", event, option); }}
                            options={this.whichStageOfResearchChoices}
                            componentRef={this.whichStageOfResearchRef}
                            styles={{ flexContainer: { display: "grid", gridAutoFlow: "column", gridTemplateRows: "repeat(3, auto)" } }}
                            disabled={this.state.isFormDisabled}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'whichStageOfResearch')}
                        />
                    </section>
                    <section style={displayABD ? { display: 'block' } : { display: 'none' }}>
                        <h2>Where is your company currently operating?</h2>
                        <Stack wrap styles={{ root: { marginTop: '15px', maxWidth: 800 } }} tokens={{ childrenGap: "10" }} >
                            {whereCompanyOperatesCheckBoxes}
                        </Stack>
                        <TextField
                            disabled={!formItem.whereCompanyOperates.includes('Other') || this.state.isFormDisabled}
                            styles={{ root: { marginTop: '10px', maxWidth: 800, width: '100%', } }}
                            ariaLabel="Where is your company currently operating - Other"
                            errorMessage={this.utils.getErrorMessage(formValidation, 'whereCompanyOperatesOther')}
                            componentRef={this.whereCompanyOperatesOtherRef}
                            value={formItem.whereCompanyOperatesOther}
                            onChange={(event, newValue) => { this._onTextFieldChange("whereCompanyOperatesOther", event, newValue); }}
                        />
                    </section>
                    <section style={displayABD ? { display: 'block' } : { display: 'none' }}>
                        <h2 className='requiredItem'>Indicate the primary location of your research study site or where your health product is being developed</h2>
                        <Stack wrap styles={{ root: { marginTop: '15px', maxWidth: 800 } }} tokens={{ childrenGap: "10" }} >
                            {primaryLocationSiteCheckBoxes}
                        </Stack>
                        <div>
                            <TextField
                                disabled={!formItem.primaryLocationSite.includes('Other') || this.state.isFormDisabled}
                                styles={{ root: { marginTop: '10px', maxWidth: 800, width: '100%', } }}
                                ariaLabel="Indicate the primary location of your research study site or where your health product is being developed - Other"
                                errorMessage={this.utils.getErrorMessage(formValidation, 'primaryLocationSiteOther')}
                                componentRef={this.primaryLocationSiteOtherRef}
                                value={formItem.primaryLocationSiteOther}
                                onChange={(event, newValue) => { this._onTextFieldChange("primaryLocationSiteOther", event, newValue); }}
                            />
                        </div>
                        <span>
                            <div role="alert">
                                <p className="ms-TextField-errorMessage">
                                    <span data-automation-id="error-message">{this.utils.getErrorMessage(formValidation, 'primaryLocationSite')}</span>
                                </p>
                            </div>
                        </span>
                    </section>
                    <section>
                        <h2>Who else/what other groups have you consulted with in Alberta?</h2>
                        <br></br>
                        <TextField
                            styles={TEXT_SINGLE_COLUMN}
                            value={formItem.whoElseConsulted}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("whoElseConsulted", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h2>Additional Information</h2>
                        <Label>Additional Comments/Notes<br />(Please do not include personally identifiable information or personal medical/health information)</Label>
                        <TextField
                            styles={TEXT_SINGLE_COLUMN}
                            multiline={true}
                            rows={5}
                            value={formItem.additionalComments}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, newValue) => { this._onTextFieldChange("additionalComments", event, newValue); }}
                        />
                    </section>
                    <section>
                        <h1>Disclaimer</h1>
                        <h2>Alberta Clinical Research Consortium (ACRC)/Clinical Trials Alberta (CTA)</h2><br></br>
                        <h2>{this.utils.getConfigurationValue(formConfiguration, 'TermsOfUseTitle')}</h2>
                        <Label className='renderLinefeed'>{this.utils.getConfigurationValue(formConfiguration, 'TermsOfUse')}</Label>
                        <br />
                        <h2>{this.utils.getConfigurationValue(formConfiguration, 'UseDiscloseWarrantyTitle')}</h2>
                        <Label className='renderLinefeed'>{this.utils.getConfigurationValue(formConfiguration, 'UseDiscloseWarranty')}</Label>
                        <CheckboxWithValidation
                            label={"I Agree"}
                            componentRef={this.formAgreementRef}
                            errorMessage={this.utils.getErrorMessage(formValidation, 'formAgreement')}
                            disabled={this.state.isFormDisabled}
                            onChange={(event, checked) => { this._onAgreementChange("formAgreement", event, checked); }}
                        />
                        <br />
                        <Label>If you have questions or concerns about any collection, use or disclosure of information by EDGE Alberta, please contact the Project Advisor by email at <a href='mailto:acrc@albertainnovates.ca'>acrc@albertainnovates.ca</a></Label>
                        {helpRequestButton}
                        <div style={{ display: 'grid', gridTemplateColumns: '115px 140px auto 130px', gridTemplateRows: 'auto', marginTop: '15px' }}>
                            <div style={{ gridArea: '1 / 1 / 1 / 1' }}>
                                {clearFormButton}
                            </div>
                            <div style={{ gridArea: '1 / 2 / 1 / 2', marginLeft: '10px' }}>
                                {saveButton}
                                {amendButton}
                            </div>
                            <div style={{ gridArea: '1 / 4 / 1 / 4', textAlign: 'right' }}>
                                {submitButton}
                            </div>
                        </div>
                    </section>
                    <Dialog
                        className='renderLinefeed'
                        hidden={clearDialogHidden}
                        onDismiss={() => { this._dismissClearDialog(); }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: 'Clear Form',
                            subText: 'Are you sure you want to clear the form? \nThis will remove all entered data.',
                        }}
                        modalProps={{
                            isBlocking: true,
                            styles: { main: { maxWidth: 450 } },
                            dragOptions: {
                                moveMenuItemText: 'Move',
                                closeMenuItemText: 'Close',
                                menu: ContextualMenu,
                            },
                        }}
                    >
                        <DialogFooter>
                            <DefaultButton onClick={() => { this._dismissClearDialog(); }} text="Cancel" />
                            <PrimaryButton onClick={() => { this._clearFormACRCCTAConciergeIntake(); }} text="Yes, Clear Form" />
                        </DialogFooter>
                    </Dialog>
                    <Dialog
                        className='renderLinefeed'
                        hidden={submitDialogHidden}
                        onDismiss={() => { this._dismissSubmitDialog(); }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: 'Submit application',
                            subText: `Are you sure you want to submit this application ${formItem.applicationId.length > 0 ? '(' + formItem.applicationId + ')' : ''}?`,
                        }}
                        modalProps={{
                            isBlocking: true,
                            styles: { main: { maxWidth: 450 } },
                            dragOptions: {
                                moveMenuItemText: 'Move',
                                closeMenuItemText: 'Close',
                                menu: ContextualMenu,
                            },
                        }}
                    >
                        <DialogFooter>
                            <DefaultButton onClick={() => { this._dismissSubmitDialog(); }} text="Cancel" />
                            <PrimaryButton onClick={() => { this._submitFormACRCCTAConciergeIntake(); }} text="Yes, Submit Application" />
                        </DialogFooter>
                    </Dialog>
                    <Dialog
                        className='renderLinefeed'
                        hidden={amendDialogHidden}
                        onDismiss={() => { this._dismissAmendDialog(); }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: 'Amend Application',
                            subText: `You are about to modify your submitted application ${formItem.applicationId}. \nPlease select "Yes, Amend Application" to proceed with your changes.`,
                        }}
                        modalProps={{
                            isBlocking: true,
                            styles: { main: { maxWidth: 450 } },
                            dragOptions: {
                                moveMenuItemText: 'Move',
                                closeMenuItemText: 'Close',
                                menu: ContextualMenu,
                            },
                        }}
                    >
                        <DialogFooter>
                            <DefaultButton onClick={() => { this._dismissAmendDialog(); }} text="Cancel" />
                            <PrimaryButton onClick={() => { this._amendFormACRCCTAConciergeIntake(); }} text="Yes, Amend Application" />
                        </DialogFooter>
                    </Dialog>
                    <Dialog
                        className='renderLinefeed'
                        hidden={helpRequestDialogHidden}
                        onDismiss={() => { this._dismissHelpDialog(); }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: 'Request Help',
                            subText: `Do you want to save the form as is and request help from the case manager to complete filling the form? Please complete as much fields as possible before requesting help.`,
                        }}
                        modalProps={{
                            isBlocking: true,
                            styles: { main: { maxWidth: 450 } },
                            dragOptions: {
                                moveMenuItemText: 'Move',
                                closeMenuItemText: 'Close',
                                menu: ContextualMenu,
                            },
                        }}
                    >
                        <DialogFooter>
                            <DefaultButton onClick={() => { this._dismissHelpDialog(); }} text="Cancel" />
                            <PrimaryButton onClick={() => { this._helpRequestFormACRCCTAConciergeIntake(); }} text="Yes, request for help" />
                        </DialogFooter>
                    </Dialog>
                    <Dialog
                        className='renderLinefeed'
                        hidden={notificationDialogHidden}
                        onDismiss={() => { this._dismissNotificationDialog(); }}
                        dialogContentProps={{
                            type: DialogType.close,
                            title: notificationDialogTitle,
                            subText: notificationDialogMessage,
                        }}
                    />
                </form>
            </main>
        );
    }
}