import {Form} from './Form';

export class FormACRCCTAConciergeIntakeResource extends Form {
    public UserFirstName: string = '';
    public UserLastName: string = '';
    public UserEmail: string = '';
    public UserPhoneNumber: string = '';
    public requesterName: string = '';
    public requesterPhone: string = '';
    public requesterEmail: string = '';
    public requesterPositionTitle: string = '';
    public requesterCompanyName: string = '';
    public requesterCompanyWebsite: string = '';
    public howDidYouHearAboutACRCCTA: string[] = [];
    public howDidYouHearAboutACRCCTAOther: string = '';
    public whatAssistanceWith: string[] = [];
    public whatAssistanceWithOther: string = '';
    public inWhatArea: string[] = [];
    public inWhatAreaOther: string = '';
    public whatAreaResearchRelated: string[] = [];
    public whatAreaResearchRelatedOther: string = '';
    public nameOfResearch: string = '';
    public healthProductType: string[] = [];
    public healthProductTypeOther: string = '';
    public clinicalResearchType: string[] = [];
    public clinicalResearchTypeOther: string = '';
    public whatStageOfDevelopment: string = '';
    public whichStageOfResearch: string = '';
    public whereCompanyOperates: string[] = [];
    public whereCompanyOperatesOther: string = '';
    public primaryLocationSite: string[] = [];
    public primaryLocationSiteOther: string = '';
    public whoElseConsulted: string = '';

    public static fromJson(dataItem: any) {
        let item = new FormACRCCTAConciergeIntakeResource(dataItem.applicationId);
        item.amendmentId = dataItem.amendmentId ? dataItem.amendmentId : '';
        item.edgeId = dataItem.edgeId ? dataItem.edgeId : '';
        item.userId = dataItem.userId ? dataItem.userId : '';
        item.status = dataItem.status ? dataItem.status : '';
        item.UserFirstName = dataItem.UserFirstName ? dataItem.UserFirstName : '';
        item.UserLastName = dataItem.UserLastName ? dataItem.UserLastName : '';
        item.UserEmail = dataItem.UserEmail ? dataItem.UserEmail : '';
        item.UserPhoneNumber = dataItem.UserPhoneNumber ? dataItem.UserPhoneNumber : '';
        item.requesterName = dataItem.requesterName ? dataItem.requesterName : '';
        item.requesterPhone = dataItem.requesterPhone ? dataItem.requesterPhone : '';
        item.requesterEmail = dataItem.requesterEmail ? dataItem.requesterEmail : '';
        item.requesterPositionTitle = dataItem.requesterPositionTitle ? dataItem.requesterPositionTitle : '';
        item.requesterCompanyName = dataItem.requesterCompanyName ? dataItem.requesterCompanyName : '';
        item.requesterCompanyWebsite = dataItem.requesterCompanyWebsite ? dataItem.requesterCompanyWebsite : '';
        item.howDidYouHearAboutACRCCTA = dataItem.howDidYouHearAboutACRCCTA ? dataItem.howDidYouHearAboutACRCCTA : [];
        item.howDidYouHearAboutACRCCTAOther = dataItem.howDidYouHearAboutACRCCTAOther
            ? dataItem.howDidYouHearAboutACRCCTAOther
            : '';
        item.whatAssistanceWith = dataItem.whatAssistanceWith ? dataItem.whatAssistanceWith : [];
        item.whatAssistanceWithOther = dataItem.whatAssistanceWithOther ? dataItem.whatAssistanceWithOther : '';
        item.inWhatArea = dataItem.inWhatArea ? dataItem.inWhatArea : [];
        item.inWhatAreaOther = dataItem.inWhatAreaOther ? dataItem.inWhatAreaOther : '';
        item.whatAreaResearchRelated = dataItem.whatAreaResearchRelated ? dataItem.whatAreaResearchRelated : [];
        item.whatAreaResearchRelatedOther = dataItem.whatAreaResearchRelatedOther
            ? dataItem.whatAreaResearchRelatedOther
            : '';
        item.nameOfResearch = dataItem.nameOfResearch ? dataItem.nameOfResearch : '';
        item.healthProductType = dataItem.healthProductType ? dataItem.healthProductType : [];
        item.healthProductTypeOther = dataItem.healthProductTypeOther ? dataItem.healthProductTypeOther : '';
        item.clinicalResearchType = dataItem.clinicalResearchType ? dataItem.clinicalResearchType : [];
        item.clinicalResearchTypeOther = dataItem.clinicalResearchTypeOther ? dataItem.clinicalResearchTypeOther : '';
        item.whatStageOfDevelopment = dataItem.whatStageOfDevelopment ? dataItem.whatStageOfDevelopment : '';
        item.whichStageOfResearch = dataItem.whichStageOfResearch ? dataItem.whichStageOfResearch : '';
        item.whereCompanyOperates = dataItem.whereCompanyOperates ? dataItem.whereCompanyOperates : [];
        item.whereCompanyOperatesOther = dataItem.whereCompanyOperatesOther ? dataItem.whereCompanyOperatesOther : '';
        item.primaryLocationSite = dataItem.primaryLocationSite ? dataItem.primaryLocationSite : [];
        item.primaryLocationSiteOther = dataItem.primaryLocationSiteOther ? dataItem.primaryLocationSiteOther : '';
        item.whoElseConsulted = dataItem.whoElseConsulted ? dataItem.whoElseConsulted : '';
        item.additionalComments = dataItem.additionalComments ? dataItem.additionalComments : '';
        item.rejectionComments = dataItem.rejectionComments ? dataItem.rejectionComments : '';
        item.submittedDate = dataItem.submittedDate ? new Date(dataItem.submittedDate) : undefined;
        item.rejectedDate = dataItem.rejectedDate ? new Date(dataItem.rejectedDate) : undefined;
        item.approvedDate = dataItem.approvedDate ? new Date(dataItem.approvedDate) : undefined;
        item.notificationSendDate = dataItem.notificationSendDate ? new Date(dataItem.notificationSendDate) : undefined;
        item.Created = dataItem.Created ? new Date(dataItem.Created) : undefined;
        item.Modified = dataItem.Modified ? new Date(dataItem.Modified) : undefined;
        return item;
    }

    public static toJson(item: FormACRCCTAConciergeIntakeResource, newEntry: boolean) {
        let dataItem = {
            ...(newEntry && item.applicationId && {applicationId: item.applicationId}),
            ...(newEntry && item.amendmentId && {amendmentId: item.amendmentId}),
            ...(newEntry && item.userId && {userId: item.userId}),
            UserFirstName: item.UserFirstName,
            UserLastName: item.UserLastName,
            UserEmail: item.UserEmail,
            UserPhoneNumber: item.UserPhoneNumber,
            requesterName: item.requesterName,
            requesterPhone: item.requesterPhone,
            requesterEmail: item.requesterEmail,
            requesterPositionTitle: item.requesterPositionTitle,
            requesterCompanyName: item.requesterCompanyName,
            requesterCompanyWebsite: item.requesterCompanyWebsite,
            howDidYouHearAboutACRCCTA: item.howDidYouHearAboutACRCCTA,
            howDidYouHearAboutACRCCTAOther: item.howDidYouHearAboutACRCCTAOther,
            whatAssistanceWith: item.whatAssistanceWith,
            whatAssistanceWithOther: item.whatAssistanceWithOther,
            inWhatArea: item.inWhatArea,
            inWhatAreaOther: item.inWhatAreaOther,
            whatAreaResearchRelated: item.whatAreaResearchRelated,
            whatAreaResearchRelatedOther: item.whatAreaResearchRelatedOther,
            nameOfResearch: item.nameOfResearch,
            healthProductType: item.healthProductType,
            healthProductTypeOther: item.healthProductTypeOther,
            clinicalResearchType: item.clinicalResearchType,
            clinicalResearchTypeOther: item.clinicalResearchTypeOther,
            whatStageOfDevelopment: item.whatStageOfDevelopment,
            whichStageOfResearch: item.whichStageOfResearch,
            whereCompanyOperates: item.whereCompanyOperates,
            whereCompanyOperatesOther: item.whereCompanyOperatesOther,
            primaryLocationSite: item.primaryLocationSite,
            primaryLocationSiteOther: item.primaryLocationSiteOther,
            whoElseConsulted: item.whoElseConsulted,
            additionalComments: item.additionalComments,
            rejectionComments: item.rejectionComments,
        };
        return dataItem;
    }
}
